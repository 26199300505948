import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-title-selector",
  templateUrl: "./title-selector.component.html",
})
export class TitleSelectorComponent implements OnInit {
  @Input() value;
  expenseForm: UntypedFormGroup;
  titleControl: UntypedFormControl;

  constructor(private controlContainer: ControlContainer) {
    this.titleControl = new UntypedFormControl(
      this.value ? this.value : "",
      Validators.required
    );
  }

  ngOnInit() {
    this.expenseForm = <UntypedFormGroup>this.controlContainer.control;
    this.expenseForm.setControl("title", this.titleControl);
  }
}
