<div class="custom-modal-wrapper link-with p-0">
  <div class="modal-title-custom p-20">
    {{ "LABEL.LINK-DOCUMENT" | translate }}
  </div>

  <div class="clear"></div>

  <form [formGroup]="form">
    <div class="modal-body-custom p-20">
      <div class="row">
        <div class="form-group w-100 resource-selector resource-type m-b-10">
          <div class="tag-wrapper">
            <div class="select-group-block">
              <mat-label>
                {{ "LABEL.SELECT-RESOURCE-TYPE" | translate }}
              </mat-label>
              <mat-form-field appearance="outline" class="w-100 p-0">
                <mat-select formControlName="resource_type">
                  <mat-option
                    *ngFor="let resourceType of resourceTypes$ | async"
                    [value]="resourceType.value"
                  >
                    {{ resourceType.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-form-errors [control]="form.get('resource_type')">
              </app-form-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group w-100 resource-selector">
          <div class="tag-wrapper">
            <div class="select-group-block">
              <mat-label>
                {{ "LABEL.SELECT-RESOURCE" | translate }}
              </mat-label>
              <mat-form-field appearance="outline" class="w-100 p-0">
                <mat-select formControlName="resource_id" multiple>
                  <mat-option>
                    <ngx-mat-select-search
                      [placeholderLabel]="'LABEL.SELECT-RESOURCE' | translate"
                      [noEntriesFoundLabel]="
                        'GENERALS.NO-ITEMS-DISPLAY' | translate
                      "
                      [formControl]="searchText"
                      disableOptionCentering
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let resource of resources$ | async"
                    [value]="resource.value"
                  >
                    {{ resource.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-form-errors [control]="form.get('resource_id')">
              </app-form-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="clear"></div>
  <div class="modal-footer-custom bottom-buttons p-20">
    <ng-container *ngIf="!isLoading; else loadingButtons">
      <button class="btn gray-bg fl-l modal-button" (click)="cancel()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>

    <ng-template #loadingButtons>
      <app-loader-spinner-general
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
    </ng-template>

    <div class="clear"></div>
  </div>
</div>
