import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AssignmentSelectorComponent } from "./components/assignment-selector/assignment-selector.component";
import { ClientsSelectorComponent } from "./components/clients-selector/clients-selector.component";
import { ExpatSelectorComponent } from "./components/expat-selector/expat-selector.component";
import { OrderSelectorComponent } from "./components/order-selector/order-selector.component";
import { ServicesSelectorComponent } from "./components/services-selector/services-selector.component";
import { SubjectSelectorComponent } from "./components/subject-selector/subject-selector.component";
import { ServiceSelectorItemComponent } from "./components/service-selector-item/service-selector-item.component";
import { SharedModule } from "@modules/shared/shared.module";
import { OrderServicesSelectorComponent } from "./components/order-services-selector/order-services-selector.component";
import { DocumentSelectorComponent } from "@modules/cases/cases-forms/components/document-selector/document-selector.component";

@NgModule({
  declarations: [
    AssignmentSelectorComponent,
    ClientsSelectorComponent,
    ExpatSelectorComponent,
    OrderSelectorComponent,
    OrderServicesSelectorComponent,
    ServicesSelectorComponent,
    ServiceSelectorItemComponent,
    SubjectSelectorComponent,
    DocumentSelectorComponent,
  ],
  imports: [CommonModule, SharedModule],
  providers: [],
  exports: [
    OrderServicesSelectorComponent,
    ClientsSelectorComponent,
    ExpatSelectorComponent,
    AssignmentSelectorComponent,
    SubjectSelectorComponent,
    OrderSelectorComponent,
    ServicesSelectorComponent,
    DocumentSelectorComponent,
  ],
})
export class CasesFormsModule {}
