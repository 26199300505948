import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ServiceCategory } from "../../../../_models/servicecategory";

@Component({
  selector: "app-add-commissions",
  templateUrl: "./add-commissions.component.html",
  styleUrls: ["./add-commissions.component.scss"],
})
export class AddCommissionsComponent implements OnChanges {
  selection = new SelectionModel(true, []);

  @Input() level: number | string;
  @Input() serviceCategories: ServiceCategory[];
  @Input() parentIsSelected = false;

  @Input() set selectedServiceCategories(data) {
    if (data) {
      const value = { ...data };
      const keys = Object.keys(value);
      keys.forEach((key) => {
        const idx = this.serviceCategories.findIndex(
          (f) => f.id === value[key].id
        );
        if (idx > -1) {
          this.selection.select(this.serviceCategories[idx]);
        }
      });
      this.previouslySelectedCategory = data;
    }
  }

  @Output() onselect: EventEmitter<any> = new EventEmitter();

  isLoading: boolean;
  previouslySelectedCategory: ServiceCategory[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentIsSelected && changes.parentIsSelected.previousValue) {
      changes.parentIsSelected.currentValue
        ? this.serviceCategories.forEach((row) => this.selection.select(row))
        : this.selection.clear();
    } else if (
      changes.parentIsSelected &&
      changes.parentIsSelected.currentValue
    ) {
      this.serviceCategories.forEach((row) => this.selection.select(row));
    }
  }

  setDate(e, node) {
    const toDate = e.end_date;
    const fromDate = e.start_date;

    node.start_date = fromDate
      ? fromDate.year + "-" + fromDate.month + "-" + fromDate.day
      : null;

    node.end_date = toDate
      ? toDate.year + "-" + toDate.month + "-" + toDate.day
      : fromDate.year + "-" + fromDate.month + "-" + fromDate.day;
  }

  onEvent(event) {
    event.stopPropagation();
  }

  /**
   * Change checkbox selection
   */
  changeSelection(item) {
    this.selection.toggle(item);
    this.onselect.emit(this.selection);
  }

  parseSelectedCategory(path) {
    if (path && this.previouslySelectedCategory) {
      const path_segments = path.split("/");

      return this.previouslySelectedCategory[
        path_segments[path_segments.length - 1]
      ]
        ? this.previouslySelectedCategory[
            path_segments[path_segments.length - 1]
          ].children
        : null;
    }
    return null;
  }

  trackById(index, item) {
    return item.id;
  }
}
