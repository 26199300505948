import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { User } from "../../../../_models/user";
import { CompanyUserData } from "@api/entities/models/company-user.model";

@Component({
  selector: "app-user-profile-image",
  templateUrl: "./user-profile-image.component.html",
})
export class UserProfileImageComponent {
  @Input() user: User | CompanyUserData;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(public ngxSmartModalService: NgxSmartModalService) {}

  onChange(event) {
    this.change.emit(event);
  }

  onProfileImageError(event) {
    event.target.src = "../assets/images/profile-pics-placeholder.jpg";
  }

  openDocModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").open();
  }
}
