import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-details-navigation",
  templateUrl: "./details-navigation.component.html",
})
export class DetailsNavigationComponent {
  @Input() menu;

  constructor(public router: Router) {}
}
