import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Permission } from "@modules/authorization/models/permission.model";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { Observable } from "rxjs";
import { Expat } from "../../../../../_models/expat";
import { DictionariesService } from "../../../../../_services/dictionaries.service";
import { ExpatsService } from "../../../services/expats.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-expat-details",
  templateUrl: "./expat-details.component.html",
  styleUrls: ["./expat-details.component.scss"],

  providers: [],
})
export class ExpatDetailsComponent implements OnInit {
  @Input() user;
  @Input() entity_type;
  @Input() changeStatusPermission = false;
  @Input() readonly;

  @Input()
  public expat!: Expat;

  @Output()
  public detailsChange: EventEmitter<any> = new EventEmitter();

  public countries$: Observable<any>;
  public canViewContacts: Permission;

  constructor(
    private dictionariesService: DictionariesService,
    private expatService: ExpatsService,
    private activatedRoute: ActivatedRoute,
    protected dialog: MatDialog
  ) {}

  public ngOnInit() {
    this.getAll();
    this.canViewContacts = {
      action: PermissionActionEnum.VIEW,
      resource: PermissionResourceEnum.EXPAT,
      secondaryResource: PermissionSecondaryResourceEnum.CONTACTS,
    };
  }

  handleEvent(event = null) {
    this.detailsChange.emit(event);
  }

  public onGettingNewExpat() {
    this.expatService.getExpatById(this.expatId).subscribe((data) => {
      this.expat = data.result;
    });
  }

  private getCountries() {
    this.countries$ = this.dictionariesService.getCountryList();
  }

  private get expatId() {
    return +this.activatedRoute.snapshot.params.expatId;
  }

  private getAll() {
    this.getCountries();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
