import { Component, Input, OnInit } from "@angular/core";
import { ColumnDefinition } from "../../../../_interfaces/column-definition.interface";

@Component({
  selector: "app-column-selection",
  templateUrl: "./column-selection.component.html",
  styleUrls: ["./column-selection.component.scss"],
})
export class ColumnSelectionComponent implements OnInit {
  @Input() columns: ColumnDefinition[];

  public columnList: ColumnDefinition[];

  ngOnInit(): void {
    this.columnList = JSON.parse(JSON.stringify(this.columns));
  }

  applyChanges() {
    this.columns.forEach((column: ColumnDefinition) => {
      const fieldCopy = this.columnList.find(
        (field) => field.name === column.name
      );
      column.visible = fieldCopy.visible;
    });
  }
}
