import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CasesService } from "@api/cases/services";
import { CaseMapperService } from "@api/cases/services";
import { EffectsModule } from "@ngrx/effects";
import { CaseEffects } from "./effects/case.effects";
import { StoreModule } from "@ngrx/store";
import { fromCase } from "./reducers";
import { ChangeEffects } from "./effects/change.effects";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CaseEffects, ChangeEffects]),
    StoreModule.forFeature(fromCase.casesFeatureKey, fromCase.reducer),
  ],
  providers: [CasesService, CaseMapperService],
})
export class CasesModule {}
