<div class="assign-expat-dialog">
  <div click-outside class="custom-modal-wrapper material-modal-dropdown-fix">
    <div class="modal-title-custom">
      {{ "PORTAL.ASSIGN-EXPAT" | translate }}
    </div>

    <div class="modal-body-custom">
      <div class="row">
        <app-offer-expat-item
          [form]="expatControl"
          [expatsList]="expatsList"
          class="w-100"
          [order]="order"
        ></app-offer-expat-item>
      </div>
    </div>

    <div class="clear"></div>

    <div class="modal-footer-custom">
      <ng-container *ngIf="!isLoading; else loadingButtons">
        <button class="btn gray-bg fl-l modal-button" mat-dialog-close>
          <mat-icon svgIcon="cancel"></mat-icon>
          {{ "GENERALS.CANCEL" | translate }}
        </button>
        <button class="btn orange-bg fl-r" (click)="save('save')">
          {{ "GENERALS.ASSIGN" | translate }}
        </button>
      </ng-container>
      <ng-template #loadingButtons>
        <app-loader-spinner-general
          [loadingType]="LoadingType.BUTTONS"
          [repeat]="2"
        ></app-loader-spinner-general>
      </ng-template>
      <div class="clear"></div>
    </div>
  </div>
</div>
