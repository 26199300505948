<!-- details navigation row (left title - right navigation) -->
<div windowScrollAppNav class="details-navigation">
  <!-- title -->
  <div class="fl-l big-title" *ngIf="order">
    <app-back-button></app-back-button>
    {{ "GENERALS.ORDER-NO" | translate }} {{ order.identity_number }}
  </div>
  <!-- end title -->

  <!-- navigation -->
  <div class="fl-r">
    <app-details-navigation [menu]="menu"></app-details-navigation>
  </div>
  <!-- end navigation -->
</div>
<!-- end details navigation row (left title - right navigation) -->
<div class="clear"></div>

<router-outlet></router-outlet>
