import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AccountService, UserData } from "@api/account";
import { finalize, take } from "rxjs";
import { Dictionary } from "src/app/_models/dictionary";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";

export interface AccountDialogComponentInput {
  user: UserData;
}

@Component({
  selector: "app-account-dialog",
  templateUrl: "./account-dialog.component.html",
  styleUrls: ["./account-dialog.component.scss"],
})
export class AccountDialogComponent implements OnInit {
  user: UserData;

  roles;
  prefix;
  countries;

  departments: Dictionary;

  isLoading: boolean = false;

  constructor(
    private dictionariesService: DictionariesService,
    private dialogRef: MatDialogRef<AccountDialogComponent>,
    private accountService: AccountService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA)
    public data: AccountDialogComponentInput
  ) {}

  ngOnInit() {
    this.user = this.data.user;
    if (!this.user) {
      this.dialogRef.close();
    }
    this.getDepartments();
    this.getCountryList();
    this.getUserRoles();
  }

  getUserRoles() {
    this.dictionariesService.getUserRoles("provider").subscribe((res) => {
      this.roles = res.result;
    });
  }

  getCountryList() {
    this.dictionariesService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result;
        this.prefix = data.result;
        this.prefix.map((p) => {
          if (this.user) {
            if (
              this.dictionariesService.getPhonePrefix(
                this.user.phone_prefix
              ) === this.dictionariesService.getPhonePrefix(p.phone_code)
            ) {
              p.phone_code =
                this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
              this.setPrefix(p);
            } else {
              p.phone_code =
                this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
            }
          } else {
            p.phone_code =
              this.dictionariesService.getPhonePrefix(p.phone_code) + p.name;
          }
        });
      }
    });
  }

  getDepartments() {
    this.dictionariesService.getDepartments().subscribe((data) => {
      this.departments = data.result;
    });
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setPrefix(e) {
    if (e) {
      const code = e.phone_code.match(/\((.*?)\)/);
      this.user.phone_prefix = code[1];
    }
  }

  submit() {
    this.isLoading = true;

    this.accountService
      .updateCurrentUser(this.user)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {
          this.dialogRef.close();
          this.alertService.saved();
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }
}
