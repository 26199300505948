import { Component, Input } from "@angular/core";
import { PreviewStrategy } from "../preview-strategy.component";
import { DocumentFile } from "@api/documents/models/document.model";

@Component({
  selector: "app-ngx-doc-viewer-strategy",
  templateUrl: "./ngx-doc-viewer-strategy.component.html",
  styleUrls: ["./ngx-doc-viewer-strategy.component.scss"],
})
export class NgxDocViewerStrategyComponent implements PreviewStrategy {
  @Input() public file: DocumentFile;

  public loading: boolean = true;
}
