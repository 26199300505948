import { Component, Input } from "@angular/core";
import { TimelineCaseTask } from "@api/case-tasks/models/timeline-case-task.model";
import { TimelineService } from "@modules/timeline/services/timeline.service";

@Component({
  selector: "app-timeline-sidebar-subtasks",
  templateUrl: "./timeline-sidebar-subtasks.component.html",
  styleUrls: ["./timeline-sidebar-subtasks.component.scss"],
})
export class TimelineSidebarSubtasksComponent {
  @Input() tasks: TimelineCaseTask[];
  constructor(public timelineService: TimelineService) {}
}
