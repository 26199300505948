import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Directive({
  selector: "[fileDragAndDrop]",
})
export class FileDragAndDropDirective {
  @Input() private allowed_extensions: Array<string> = [
    "pdf",
    "doc",
    "docx",
    "jpg",
    "jpeg",
    "png",
    "xlsx",
    "xls",
  ];
  @Output() private filesChangeEmitter: EventEmitter<any[]> =
    new EventEmitter();
  @HostBinding("style.background") private background = "#eee";
  @HostBinding("style.border") private borderStyle = "2px dashed";
  @HostBinding("style.border-color") private borderColor = "#696D7D";
  @HostBinding("style.border-radius") private borderRadius = "5px";

  @HostListener("dragover", ["$event"]) public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#FC8B56";
    this.borderColor = "#f96332";
    this.borderStyle = "2px dashed";
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#FBFCFE";
    this.borderColor = "#D0DAE4";
    this.borderStyle = "2px dashed";
  }

  @HostListener("drop", ["$event"]) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = "#FBFCFE";
    this.borderColor = "#D0DAE4";
    this.borderStyle = "2px dashed";
    const files = evt.dataTransfer.files;
    const filesArray = Object.values<any>(files);

    const valid_files = filesArray.filter(
      (file) =>
        !!this.allowed_extensions.find(
          (fileType: string) =>
            file.name.split(".")[file.name.split(".").length - 1] === fileType
        )
    );

    this.filesChangeEmitter.emit(valid_files);
  }
}
