import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@api/authentication";
import { OrdersComponent } from "@modules/order/order-shared/orders/orders.component";
import { environment } from "src/environments/environment";
import { AccessDeniedPageComponent } from "./components/access-denied/access-denied.component";
import { AccountDocumentsComponent } from "./components/account-settings/account-documents/account-documents.component";
import { AccountSettingsComponent } from "./components/account-settings/account-settings.component";
import { AccountComponent } from "./components/account-settings/account/account.component";
import { UserAgreementsComponent } from "./components/account-settings/user-agreements/user-agreements.component";
import { AdsComponent } from "./components/ads/ads.component";
import { ConfirmEmailComponent } from "./components/auth/confirm-email/confirm-email.component";
import { CreateProfileComponent } from "./components/auth/create-profile/create-profile.component";
import { LoginComponent } from "./components/auth/login/login.component";
import { RecoverPasswordComponent } from "./components/auth/recover-password/recover-password.component";
import { RegisterComponent } from "./components/auth/register/register.component";
import { ResetPasswordComponent } from "./components/auth/reset-password/reset-password.component";
import { ServicePortfolioComponent } from "./components/auth/service-portfolio/service-portfolio.component";
import { SsoComponent } from "./components/auth/sso/sso.component";
import { AccountActivityComponent } from "./components/dashboard/account-activity/account-activity.component";
import { ProviderCategoriesComponent } from "./components/dashboard/categories/provider-categories.component";
import { UsersListComponent } from "./components/dashboard/company-users/users-list/users-list.component";
import { InvoiceCommissionsComponent } from "./components/dashboard/invoices/commissions/invoice-commissions.component";
import { InvoiceComponent } from "./components/dashboard/invoices/invoice/invoice.component";
import { InvoicesListComponent } from "./components/dashboard/invoices/list/invoices-list.component";
import { OrderActivityComponent } from "./components/dashboard/orders/order-activity/order-activity.component";
import { OrderDetailsComponent } from "./components/dashboard/orders/order-details/order-details.component";
import { OrderDocumentsComponent } from "./components/dashboard/orders/order-documents/order-documents.component";
import { OrderInvoicesComponent } from "./components/dashboard/orders/order-invoices/order-invoices.component";
import { OrderQuotesComponent } from "./components/dashboard/orders/order-quotes/order-quotes.component";
import { OrderComponent } from "./components/dashboard/orders/order/order.component";

import { NotFoundComponent } from "./components/not-found/not-found.component";
import { PrivacyComponent } from "./components/static/privacy/privacy.component";
import { TermsComponent } from "./components/static/terms/terms.component";
import { WorkInProgressComponent } from "./components/work-in-progress/work-in-progress.component";
import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { DashboardLayoutComponent } from "./layout/dashboard-layout/dashboard-layout.component";
import { StaticLayoutComponent } from "./layout/static-layout/static-layout.component";
import { ProfileGuard } from "./_guards/profile.guard";
import { DetailsComponent as DocumentDetailsComponent } from "@modules/documents/documents-shared/details/details.component";
import { ActiveGuard } from "./_guards/active.guard";
import { SsoGuard } from "./components/auth/sso/sso.guard";
import { DocumentsComponent } from "@modules/documents/components/documents/documents.component";
import { DocumentHistoryComponent } from "@modules/documents/documents-shared/document-history/document-history.component";
import { CompanyMilestonesComponent } from "./components/account-settings/company-milestones/company-milestones.component";
import { MyAccountPageComponent } from "@modules/account";

const routes: Routes = [
  // redirect
  {
    path: "",
    redirectTo: environment.enabled_pages.dashboard ? "/dashboard" : "/welcome",
    pathMatch: "full",
  },
  {
    path: "service-offers",
    redirectTo: "/service-offers/list",
    pathMatch: "full",
  },
  {
    path: "sso",
    canActivate: [SsoGuard],
    component: SsoComponent,
  },
  // auth routes
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
      },

      {
        path: "recover-password",
        component: RecoverPasswordComponent,
      },
      {
        path: "sign-up",
        component: RegisterComponent,
      },
      {
        path: "set-password/:token",
        component: ResetPasswordComponent,
      },
      {
        path: "create-profile",
        canActivate: [AuthGuard],
        component: CreateProfileComponent,
      },
      {
        path: "service-portfolio",
        canActivate: [AuthGuard],
        component: ServicePortfolioComponent,
      },
      {
        path: "confirm-email/:id",
        component: ConfirmEmailComponent,
      },
    ],
  },

  // dashboard routes
  {
    path: "",
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard, ProfileGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        canActivate: [ActiveGuard],
        path: "docs/:doc",
        component: DocumentsComponent,
        data: {
          resource: "dashboardDocuments",
          isOnDashboard: true,
        },
        children: [
          {
            path: "",
            component: DocumentDetailsComponent,
          },
          {
            path: "history",
            component: DocumentHistoryComponent,
          },
        ],
      },
      {
        canActivate: [ActiveGuard],
        path: "template",
        loadChildren: () =>
          import("./modules/template/template.module").then(
            (m) => m.TemplateModule
          ),
      },
      {
        path: "my-account",
        component: MyAccountPageComponent,
      },
      {
        path: "settings",
        component: AccountComponent,
        children: [
          {
            path: "",
            component: AccountSettingsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "policies",
            component: UserAgreementsComponent,
          },
          {
            path: "milestones",
            component: CompanyMilestonesComponent,
          },
          {
            path: "xpath-terms-and-conditions",
            component: AccountDocumentsComponent,
          },
          {
            path: "history",
            component: AccountActivityComponent,
          },
        ],
      },
      {
        canActivate: [ActiveGuard],
        path: "company-users",
        component: UsersListComponent,
      },

      {
        canActivate: [ActiveGuard],
        path: "users/:userId",
        loadChildren: () =>
          import("./modules/users/users.module").then((m) => m.UsersModule),
      },
      {
        canActivate: [ActiveGuard],
        path: "orders",
        component: OrdersComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "orders/:id",
        component: OrderComponent,
        children: [
          {
            path: "",
            component: OrderDetailsComponent,
            data: { pathName: "Details" },
          },
          {
            path: "docs",
            component: OrderDocumentsComponent,
          },
          {
            path: "docs/:doc",
            component: DocumentsComponent,
            data: {
              resource: "orders",
            },
            children: [
              {
                path: "",
                component: DocumentDetailsComponent,
              },
            ],
          },
          {
            path: "invoices",
            component: OrderInvoicesComponent,
          },
          {
            path: "quotes",
            component: OrderQuotesComponent,
          },
          {
            path: "history",
            component: OrderActivityComponent,
          },
        ],
      },
      {
        canActivate: [ActiveGuard],
        path: "invoices/list",
        component: InvoicesListComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "invoices/commissions",
        component: InvoiceCommissionsComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "invoices/:id",
        component: InvoiceComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "categories",
        component: ProviderCategoriesComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "clients",
        loadChildren: () =>
          import("./modules/clients/clients.module").then(
            (m) => m.ClientsModule
          ),
      },
      {
        canActivate: [ActiveGuard],
        path: "documents",
        loadChildren: () =>
          import("./modules/documents/documents.module").then(
            (m) => m.DocumentsModule
          ),
      },
      {
        canActivate: [ProfileGuard, ActiveGuard],
        path: "service-offers",
        loadChildren: () =>
          import("./modules/service-offers/service-offers.module").then(
            (m) => m.ServiceOffersModule
          ),
      },
      {
        canActivate: [ActiveGuard],
        path: "cases",
        loadChildren: () =>
          import("./modules/cases/cases.module").then((m) => m.CasesModule),
      },
      {
        canActivate: [ActiveGuard],
        path: "tasks",
        loadChildren: () =>
          import("./modules/case-tasks/case-tasks.module").then(
            (m) => m.CaseTasksModule
          ),
      },
      {
        canActivate: [ProfileGuard, ActiveGuard],
        path: "rfq",
        loadChildren: () =>
          import("./modules/rfq/rfq.module").then((m) => m.RfqModule),
      },
      {
        canActivate: [ProfileGuard, ActiveGuard],
        path: "ads",
        component: AdsComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "welcome",
        component: WorkInProgressComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "reports",
        loadChildren: () =>
          import("./modules/reports/reports.module").then(
            (m) => m.ReportsModule
          ),
      },
      {
        canActivate: [ActiveGuard],
        path: "roles",
        component: WorkInProgressComponent,
      },
      {
        canActivate: [ActiveGuard],
        path: "billing",
        loadChildren: () =>
          import("./modules/billing/billing.module").then(
            (m) => m.BillingModule
          ),
      },
      {
        path: "not-found",
        component: NotFoundComponent,
      },
      {
        path: "**",
        redirectTo: "/not-found",
      },
      {
        path: "access-denied",
        component: AccessDeniedPageComponent,
      },
    ],
  },

  // public routes

  {
    path: "",
    component: StaticLayoutComponent,
    children: [
      {
        path: "terms",
        component: TermsComponent,
      },
      {
        path: "privacy-policy",
        component: PrivacyComponent,
      },
    ],
  },

  {
    canActivate: [ActiveGuard],
    path: "service-offers",
    loadChildren: () =>
      import("./modules/service-offers/service-offers.module").then(
        (m) => m.ServiceOffersModule
      ),
  },
  {
    canActivate: [ActiveGuard],
    path: "expats",
    loadChildren: () =>
      import("./modules/expats/expats.module").then((m) => m.ExpatsModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: "always",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
