<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="delete-order-invoice-{{ file_id }}"
    identifier="delete-order-invoice-{{ file_id }}"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">
        {{ "GENERALS.DELETE-ORDER-INVOICE" | translate }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <ng-container *ngIf="isLoading">
        <app-loader-spinner-general
          [loadingType]="LoadingType.BIG_INFO"
        ></app-loader-spinner-general>
      </ng-container>

      <div class="modal-form" *ngIf="!isLoading">
        <div class="row">
          <div class="form-group w-100">
            <label>{{ "GENERALS.INVOICE-DELETE-REASON" | translate }}</label>
            <input
              type="text"
              name="transaction_id"
              class="form-control"
              [(ngModel)]="orderInvoiceObj.reason"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clear"></div>
    <div class="modal-footer">
      <app-loader-spinner-general
        *ngIf="isLoading"
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
      <ng-container *ngIf="!isLoading">
        <button
          type="button"
          class="btn gray-bg modal-button"
          (click)="cancelModal()"
        >
          <mat-icon svgIcon="cancel"></mat-icon>
          {{ "GENERALS.CANCEL" | translate }}
        </button>

        <button
          [disabled]="isLoading"
          type="button"
          class="btn orange-bg modal-button"
          (click)="confirmDelete()"
        >
          <mat-icon svgIcon="save"></mat-icon>
          {{ "GENERALS.CONFIRM" | translate }}
        </button>
      </ng-container>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->
