import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { SharedModule } from "@modules/shared/shared.module";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ExpansesListComponent } from "./expanses-list/expanses-list.component";
import { CreateExpenseComponent } from "@modules/expenses/components/create/create.component";
import { DocumentsSharedModule } from "@modules/documents/documents-shared/document-shared.module";
import { TitleSelectorComponent } from "@modules/expenses/components/form/title-selector/title-selector.component";
import { ThirdPartySelectorComponent } from "@modules/expenses/components/form/third-party-selector/third-party-selector.component";
import { FileSelectorComponent } from "@modules/expenses/components/form/file-selector/file-selector.component";
import { CoveredBySelectorComponent } from "@modules/expenses/components/form/covered-by-selector/covered-by-selector.component";
import { CostSelectorComponent } from "@modules/expenses/components/form/cost-selector/cost-selector.component";
import { CostCategorySelectorComponent } from "@modules/expenses/components/form/cost-category-selector/cost-category-selector.component";
// tslint:disable-next-line:max-line-length
import { EditExpenseComponent } from "@modules/expenses/components/edit/edit.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    EditExpenseComponent,
    ExpansesListComponent,
    CreateExpenseComponent,
    TitleSelectorComponent,
    ThirdPartySelectorComponent,
    FileSelectorComponent,
    CoveredBySelectorComponent,
    CostSelectorComponent,
    CostCategorySelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    DocumentsSharedModule,
  ],
  providers: [DatePipe],
  exports: [ExpansesListComponent],
})
export class ExpensesSharedModule {}
