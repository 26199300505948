<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.INFO"
  ></app-loader-spinner-general>
</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="auth-form-wrapper">
    <div class="auth-form-title-wrapper">
      <div class="auth-form-title">
        {{ "AUTH.SIGN-UP" | translate }}
      </div>
    </div>

    <div *ngIf="user" class="registration-steps">
      <div class="registration-step-item">
        {{ "GENERALS.STEP-1" | translate }}
      </div>
    </div>

    <div *ngIf="user">
      <div class="row">
        <div class="form-group w-50">
          <label>{{ "LABEL.COMPANY-NAME" | translate }} *</label>
          <input
            type="text"
            name="company_name"
            class="form-control"
            [(ngModel)]="user.company_name"
          />
        </div>

        <div class="form-group w-50">
          <label>{{ "LABEL.COMPANY-LEGAL-NAME" | translate }}</label>
          <input
            type="text"
            name="company_legal_name "
            class="form-control"
            [(ngModel)]="user.company_legal_name"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group w-50">
          <label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</label>
          <input
            type="text"
            name="company_position"
            class="form-control"
            [(ngModel)]="user.company_position"
          />
        </div>

        <div class="form-group w-50">
          <label>{{ "LABEL.PHONE-NUMBER" | translate }} *</label>
          <div class="row">
            <div class="form-group w-50">
              <ng-select
                type="select"
                (change)="setPrefix($event)"
                class="customiz-select"
                [items]="countries"
                bindLabel="name"
                bindValue="phone_code"
                [(ngModel)]="user.phone_prefix"
                placeholder="{{ 'GENERALS.SELECT-PHONE-CODE' | translate }}"
                notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
              >
              </ng-select>
            </div>

            <div class="form-group w-50">
              <input
                type="tel"
                class="form-control"
                (keypress)="validateNumericalInput($event)"
                [(ngModel)]="user.phone"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group w-50">
          <label>{{ "LABEL.FIRST-NAME" | translate }} *</label>
          <input
            type="text"
            name="first_name"
            class="form-control"
            [(ngModel)]="user.first_name"
          />
        </div>

        <div class="form-group w-50">
          <label>{{ "LABEL.LAST-NAME" | translate }} *</label>
          <input
            type="text"
            name="last_name"
            class="form-control"
            [(ngModel)]="user.last_name"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group w-50">
          <label>{{ "LABEL.EMAIL" | translate }} *</label>
          <input
            type="text"
            name="email"
            class="form-control"
            [(ngModel)]="user.email"
          />
        </div>

        <div class="form-group w-50">
          <label>{{ "LABEL.PASSWORD" | translate }} *</label>
          <input
            type="password"
            name="password"
            class="form-control"
            [(ngModel)]="user.password"
          />
        </div>
      </div>

      <button [disabled]="isLoading" (click)="register()" class="btn orange-bg">
        {{ "GENERALS.NEXT" | translate }}
      </button>
    </div>

    <div *ngIf="user" class="registration-steps-dots">
      <div class="registration-step-dots-item active-setp-dot"></div>
      <div class="registration-step-dots-item"></div>

      <div class="registration-step-dots-item"></div>
    </div>
  </div>

  <div class="auth-footer login-footer">
    <p>
      {{ "AUTH.FOOTER-SING-IN-LINK" | translate }}
      <span class="action-link-orange" [routerLink]="['/login']">{{
        "AUTH.SIGN-IN" | translate
      }}</span
      >!
    </p>
    <p>
      {{ "AUTH.FOOTER-TC" | translate }}
      <span class="action-link-orange" (click)="goToTerms()">{{
        "GENERALS.TERMS" | translate
      }}</span>
      &
      <span class="action-link-orange" (click)="goToPrivacy()">{{
        "GENERALS.PRIVACY-POLICY" | translate
      }}</span>
    </p>
  </div>
</ng-container>
