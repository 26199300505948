import { Injectable } from "@angular/core";
import { AgreementData } from "@api/agreements/models/agreement.model";

@Injectable()
export class AgreementMapperService {
  mapOne(input: any): AgreementData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): AgreementData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: any) {
    return { ...input };
  }
}
