import {
  Component,
  Input,
  ViewChild,
  AfterContentInit,
  ComponentRef,
} from "@angular/core";
import { DocumentFile } from "@api/documents/models/document.model";
import { PreviewStrategyFactory } from "../services/preview-strategy-factory";
import { PreviewContainerDirective } from "../directives/preview-container.directive";
import { PreviewStrategy } from "../preview-strategies/preview-strategy.component";

@Component({
  selector: "app-preview-strategy-resolver",
  templateUrl: "./preview-strategy-resolver.component.html",
  styleUrls: ["./preview-strategy-resolver.component.css"],
})
export class PreviewStrategyResolverComponent implements AfterContentInit {
  @Input() file: DocumentFile;

  @ViewChild(PreviewContainerDirective, { static: true })
  private previewContainer: PreviewContainerDirective;

  constructor(private strategyFactory: PreviewStrategyFactory) {}

  ngAfterContentInit(): void {
    this.createPreviewComponent();
  }

  private createPreviewComponent(): void {
    const element = this.strategyFactory.make(this.file.type);

    const componentRef: ComponentRef<PreviewStrategy> =
      this.previewContainer.viewContainerRef.createComponent(element);
    componentRef.instance.file = this.file;
  }
}
