import { combineLatest, finalize } from "rxjs";
import { Component, Input, OnInit } from "@angular/core";
import { Expat } from "../../../../../_models/expat";
import { UsersService } from "../../../../../_services/users.service";
import { WindowResizeHelperService } from "../../../../../_services/window-resize-helper.service";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "src/app/_services/alert.service";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { OrderPopupComponent } from "@modules/shared/_components/order-popup/order-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-expats-list",
  templateUrl: "./expats-list.component.html",
  styleUrls: ["./expats-list.component.scss"],
})
export class ExpatsListComponent extends ItemsListComponent implements OnInit {
  private providerId: number;
  isFiltersLoaded: boolean = false;

  public isMobile: boolean;
  public items: Expat[];
  public url: string;

  private clientId: number;
  @Input() public showTitle = true;

  constructor(
    protected service: ExpatsService,
    private route: ActivatedRoute,
    private userService: UsersService,
    protected alertService: AlertService,
    public dialog: MatDialog,
    private router: Router,
    private windowResizeHelper: WindowResizeHelperService
  ) {
    super();
    this.clientId = this.route.snapshot.params.clientId;
    combineLatest([
      this.userService.getCurrentUser(),
      this.route.queryParams,
    ]).subscribe({
      next: ([user, query]) => {
        this.providerId = user.entity_details.entity_id;
        this.isLoading = true;
        this.watchQueryParams(query);
      },
      error: () => {
        this.isLoading = false;
      },
    });

    this.url = this.router.url;
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isMobile = data.responsiveStatus;
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = this.getParams(this.filters["page"]);

    this.isLoading = true;
    this.request_call = this.service
      .list(this.providerId, this.clientId, params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router
      .navigate([], {
        replaceUrl: true,
        relativeTo: this.route,
        queryParams: params,
      })
      .then();
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(OrderPopupComponent, {
      data: { type: "newExpat", openedFrom: "expats", clientId: this.clientId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== "cancel") {
        this.getItems();
      }
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
