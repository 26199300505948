<mat-form-field
  appearance="outline"
  class="w-100 doc-type-selector"
  [formGroup]="documentForm"
>
  <mat-label> {{ "LABEL.DOCS_TYPES" | translate }} </mat-label>
  <mat-select
    [formControl]="typeControl"
    [placeholder]="'GENERALS.SELECT-DOC-TYPE' | translate"
    #singleSelect
    (selectionChange)="handleDocumentType($event)"
    [title]="docType"
  >
    <mat-option>
      <!--      <ngx-mat-select-search-->
      <!--        [placeholderLabel]="'GENERALS.DOC-TYPE' | translate"-->
      <!--        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"-->
      <!--      ></ngx-mat-select-search>-->
    </mat-option>
    <mat-option *ngFor="let type of types" [value]="type.id">
      {{ type.text }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="documentForm.get('document_type_id')">
    </app-form-errors>
  </mat-error>
</mat-form-field>
