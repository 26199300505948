<div id="progressBar" *ngIf="loading">
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
</div>

<ngx-doc-viewer
  [url]="file.file_path"
  viewer="office"
  style="width: 100%"
  (loaded)="loading = false"
  class="excel-preview"
></ngx-doc-viewer>
