<mat-form-field appearance="outline">
  <button
    mat-button
    mat-icon-button
    matPrefix
    aria-label="Remove"
    [color]="getColor()"
    (click)="incrementValue(-_step)"
    [disabled]="shouldDisableDecrement(_value)"
  >
    <mat-icon>remove</mat-icon>
  </button>
  <input
    matInput
    type="number"
    [disabled]="disabled"
    [(ngModel)]="_value"
    (change)="this.onChange(_value)"
    (focus)="setColor('primary')"
    (blur)="setColor('default')"
  />
  <button
    mat-button
    mat-icon-button
    matSuffix
    aria-label="Add"
    [color]="getColor()"
    (click)="incrementValue(_step)"
    [disabled]="shouldDisableIncrement(_value)"
  >
    <mat-icon>add</mat-icon>
  </button>
</mat-form-field>
