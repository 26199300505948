import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { DocumentsService } from "src/app/_services/documents.service";
import { AlertService } from "src/app/_services/alert.service";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { ShareDataService } from "src/app/_services/share-data.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-add-profile-picture",
  templateUrl: "./add-profile-picture.component.html",
  styleUrls: ["./add-profile-picture.component.scss"],
})
export class AddProfilePictureComponent {
  @Input() entityType;
  @Input() entityId;
  @Input() title;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter();

  public fileUploadControl = new FileUploadControl();

  setModalOpen = true;
  isCanceled = false;
  isLoading = false;
  uploadedFiles = { file: null, name: null, status: null };
  statuses;

  sts = [{ id: 1, text: "signed" }];
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    protected service: DocumentsService,
    private alertService: AlertService,
    private shareDataService: ShareDataService
  ) {
    this.setModalOpen = false;
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModalOpen = false;
    this.fileUploadControl.clear();
  }
  cancelModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").close();
    this.isCanceled = true;
    this.uploadedFiles = { file: null, name: null, status: null };
    this.fileUploadControl.clear();
  }

  uploadImage() {
    this.isLoading = true;

    this.service
      .uploadProfilePicture(this.entityId, this.uploadedFiles, this.entityType)
      .subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.change.emit("newProfilePicture");
          this.shareDataService.sendData("newProfilePicture");
          this.cancelModal();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;

          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
