import { Injectable } from "@angular/core";
import { StorageKey, StorageService } from "@api/storage/storage.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TypedAction } from "@ngrx/store/src/models";
import { defer, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import * as AuthActions from "../actions/auth.actions";

@Injectable()
export class AuthEffects {
  authorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authorized),
        tap((action) =>
          this.storage.setData(StorageKey.AUTH_TOKEN, action.token).subscribe()
        )
      ),
    { dispatch: false }
  );

  deauthorize$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.unauthenticated, AuthActions.logout),
        tap((action) => {
          this.storage.remove(StorageKey.AUTH_TOKEN).subscribe();
        })
      ),
    { dispatch: false }
  );

  init$: Observable<TypedAction<any>> = createEffect(() =>
    defer(() => {
      return this.storage.getData(StorageKey.AUTH_TOKEN).pipe(
        map((data) => {
          if (!data || typeof data !== "string") {
            throw Error("no token");
          }
          return AuthActions.authorized({ token: data });
        }),
        catchError((err) => {
          return of(AuthActions.loaded());
        })
      );
    })
  );
  constructor(private actions$: Actions, private storage: StorageService) {}
}
