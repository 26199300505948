import { Injectable } from "@angular/core";
import { AccountSelectors, UserData } from "@api/account";
import {
  PermissionActionEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { RoleEnum } from "@modules/shared/_enums/role.enum";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { filterNullish } from "../../../lib";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  public currentUser: Observable<UserData>;

  constructor(private store: Store) {
    this.currentUser = this.store
      .select(AccountSelectors.selectUser)
      .pipe(filterNullish());
  }

  /**
   * Check if the user has a specific permission
   * @param action
   * @param resource
   * @param secondaryResource
   */
  public $can(
    action: string,
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    const permission = `${action} ${resource} ${secondaryResource}`;
    return this.currentUser.pipe(
      map((user) => user.permissions.indexOf(permission) > -1)
    );
  }

  /**
   * Check if the user does not have a specific permission
   * @param action
   * @param resource
   * @param secondaryResource
   */
  public cannot(
    action: string,
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    return this.$can(action, resource, secondaryResource).pipe(
      map((allowed) => !allowed)
    );
  }
  /**
   * Check if the user has view access on a resource
   * @param resource
   */
  public $hasResource(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.VIEW,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user has view access on a secondary resource
   * @param resource
   * @param secondaryResource
   */
  public $hasSecondaryResource(
    resource: string,
    secondaryResource: string
  ): Observable<boolean> {
    return this.$can(PermissionActionEnum.VIEW, resource, secondaryResource);
  }

  /**
   * Check if the user is allowed to create a resource
   * @param resource
   */
  public $canCreate(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.CREATE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user is allowed to update a resource
   * @param resource
   */
  public $canUpdate(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.UPDATE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  /**
   * Check if the user is allowed to manage a resource
   * @param resource
   */
  public $canManage(resource: string): Observable<boolean> {
    return this.$can(
      PermissionActionEnum.MANAGE,
      resource,
      PermissionSecondaryResourceEnum.DETAILS
    );
  }

  public $isAdmin(): Observable<boolean> {
    return this.currentUser.pipe(
      map((user) => user.role_name === RoleEnum.ADMIN)
    );
  }
}
