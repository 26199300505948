export enum LoadingTypeEnum {
  INFO = "info",
  BIG_INFO = "big_info",
  PROFILE = "profile",
  TABLE = "table",
  FILTER = "filter",
  CHART = "chart",
  PIE = "pie",
  BUTTONS = "buttons",
  SMALL_BUTTON = "small_button",
  WORKFLOW_BUTTON = "workflow-save-button",
  FULL_BUTTON = "full_button",
  INFO_BUTTONS = "info_buttons",
  INFO_ICON = "info_icon",
  TIMELINE = "timeline",
  INPUT = "input",
  BLOCK = "block",
}
