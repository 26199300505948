<mat-form-field appearance="outline" class="w-100" [formGroup]="expenseForm">
  <mat-label> {{ "EXPENSES.THIRD_PARTY_SUPPLIER" | translate }}</mat-label>
  <mat-select
    [formControl]="thirdPartySupplierControl"
    [placeholder]="'EXPENSES.SELECT_THIRD_PARTY_SUPPLIER' | translate"
    #singleSelect
    (selectionChange)="handleThirdPartySupplier($event)"
    [title]="thirdPartySupplier"
  >
    <mat-option
      *ngFor="let supplier of thirdPartySupplierList"
      [value]="supplier.id"
    >
      {{ supplier.text }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="expenseForm.get('third_party_supplier')">
    </app-form-errors>
  </mat-error>
</mat-form-field>
