<div class="custom-modal-wrapper edit-docs p-0">
  <div class="modal-title-custom p-20">
    {{ "DOCUMENTS.EDIT_DOCS" | translate }}
  </div>

  <div class="clear"></div>

  <form [formGroup]="documentForm" *ngIf="!data.shouldShowPreview">
    <div class="modal-body-custom p-20">
      <div class="row">
        <div class="p-0 w-50">
          <app-name-selector
            [formGroup]="documentForm"
            [value]="data.name"
          ></app-name-selector>
        </div>
        <div class="w-50 p-0 date">
          <app-date-range-calendar
            [placeholder]="'GENERALS.SELECT-EXPIRATION-DATE' | translate"
            (change)="onDateChange($event)"
            [singleDate]="documentForm.get('expiration_date').value"
          >
            <ng-container slot="label">{{
              "GENERALS.SELECT-EXPIRATION-DATE" | translate
            }}</ng-container>
          </app-date-range-calendar>
        </div>
        <div class="w-50 p-0">
          <app-type-selector
            [formGroup]="documentForm"
            [entityType]="data.entity_type"
            [value]="data.document_type.id"
          ></app-type-selector>
        </div>

        <div class="w-50 p-0">
          <app-number-selector
            [formGroup]="documentForm"
            [value]="data.number"
          ></app-number-selector>
        </div>

        <div class="w-50 p-0">
          <app-tag-selector
            [formGroup]="documentForm"
            [value]="data.tags"
          ></app-tag-selector>
        </div>

        <app-set-reminder [formGroup]="documentForm"></app-set-reminder>

        <div class="w-50 p-0">
          <app-country-select
            [formGroup]="documentForm"
            [value]="data.country?.alpha2"
          >
          </app-country-select>
        </div>
        <div class="w-50 p-0">
          <app-service-category-selector
            [formGroup]="documentForm"
            [value]="data.service_category?.id"
          ></app-service-category-selector>
        </div>
      </div>
    </div>
  </form>

  <form
    *ngIf="data.shouldShowPreview"
    [formGroup]="documentForm"
    class="preview-edit-doc"
  >
    <div class="modal-body-custom p-20">
      <div class="row">
        <div class="w-50">
          <app-document-preview [document]="data"></app-document-preview>
        </div>

        <div class="w-50 p-0">
          <div class="edit-field">
            <app-name-selector
              [formGroup]="documentForm"
              [value]="data.name"
            ></app-name-selector>
          </div>
          <div class="edit-field">
            <app-date-range-calendar
              [placeholder]="'GENERALS.SELECT-EXPIRATION-DATE' | translate"
              (change)="onDateChange($event)"
              [singleDate]="documentForm.get('expiration_date').value"
            >
              <ng-container slot="label">{{
                "GENERALS.SELECT-EXPIRATION-DATE" | translate
              }}</ng-container>
            </app-date-range-calendar>
          </div>
          <div class="edit-field">
            <app-type-selector
              [formGroup]="documentForm"
              [entityType]="data.entity_type"
              [value]="data.document_type.id"
            ></app-type-selector>
          </div>
          <div class="edit-field">
            <app-number-selector
              [formGroup]="documentForm"
              [value]="data.number"
            ></app-number-selector>
          </div>
          <div class="edit-field">
            <app-tag-selector
              [formGroup]="documentForm"
              [value]="data.tags"
            ></app-tag-selector>
          </div>
          <div class="edit-field">
            <app-country-select
              [formGroup]="documentForm"
              [value]="data.country?.alpha2"
            ></app-country-select>
          </div>
          <div class="edit-field">
            <app-service-category-selector
              [formGroup]="documentForm"
              [value]="data.service_category?.id"
            ></app-service-category-selector>
          </div>
        </div>

        <app-set-reminder [formGroup]="documentForm"></app-set-reminder>
      </div>
    </div>
  </form>

  <div class="clear"></div>
  <div class="modal-footer-custom bottom-buttons p-20">
    <ng-container *ngIf="!isLoading; else loadingButtons">
      <button class="btn gray-bg fl-l modal-button" (click)="close()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <ng-template #loadingButtons>
      <app-loader-spinner-general
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
    </ng-template>
    <div class="clear"></div>
  </div>
</div>
