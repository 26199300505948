<div class="details-body-wrapper m-b-15">
  <div class="white-box-wrapper">
    <div class="white-box-row">
      <div class="white-box-header">
        <div class="medium-title fl-l">
          {{ "GENERALS.INVOICE-DETAILS" | translate }}
        </div>
        <app-loader-spinner-general
          *ngIf="isLoading"
          class="d-flex justify-content-end"
          [repeat]="3"
          [loadingType]="LoadingType.BUTTONS"
        ></app-loader-spinner-general>

        <div class="small-box-status fl-r d-flex" *ngIf="!isLoading">
          <pay-invoice
            class="pay-btn"
            *ngIf="!invoice.paid_on && invoice.id && +invoice.amount > 0"
            [invoiceId]="invoice.id"
          ></pay-invoice>

          <div class="btn download-invoice-btn m-l-10" (click)="download()">
            <i class="icon-download"></i>
            {{ "GENERALS.EXPORT-FILE" | translate }}
          </div>
          <div class="status-label-{{ invoice.status_id }} status-label m-l-10">
            {{ invoice.status }}
          </div>
        </div>
      </div>
      <div class="clear"></div>

      <div class="white-box-body">
        <div class="loading-wrapper" *ngIf="isLoading">
          <app-loader-spinner-general
            [loadingType]="LoadingType.BLOCK"
          ></app-loader-spinner-general>
        </div>
        <div class="row" *ngIf="!isLoading">
          <div class="w-33">
            <p>
              <span class="inner-label">
                {{ "INVOICES.INVOICE-NO" | translate }}:
              </span>
              {{ invoice.id }}
            </p>
            <p>
              <span class="inner-label">
                {{ "INVOICES.AMOUNT" | translate }}:
              </span>
              {{ invoice.amount | currency: invoice.currency_code }}
            </p>
            <p>
              <span class="inner-label">
                {{ "GENERALS.BILLING_PERIOD" | translate }}:
              </span>
              {{ invoice.start_date | date: "dd/MM/yyyy" }} -
              {{ invoice.end_date | date: "dd/MM/yyyy" }}
            </p>
          </div>
          <div class="w-33">
            <p>
              <span class="inner-label">
                {{ "INVOICES.ISSUED-ON" | translate }}:
              </span>
              {{ invoice.issued_on | date: "dd/MM/yyyy" }}
            </p>
            <p>
              <span class="inner-label">
                {{ "INVOICES.DUE-ON" | translate }}:
              </span>
              {{ invoice.due_on | date: "dd/MM/yyyy" }}
            </p>
            <p>
              <span class="inner-label">
                {{ "INVOICES.PAID-ON" | translate }}:
              </span>
              {{ invoice.paid_on | date: "dd/MM/yyyy" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-invoice-commissions
  [hideFilters]="true"
  [invoiceId]="invoiceId"
></app-invoice-commissions>
