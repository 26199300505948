import { Component, Input } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

@Component({
  selector: "app-details-campaign",
  templateUrl: "./details-campaign.component.html",
  styleUrls: ["./details-campaign.component.scss"],
})
export class DetailsCampaignComponent {
  @Input() item;

  constructor(public ngxSmartModalService: NgxSmartModalService) {}

  close() {
    this.ngxSmartModalService.closeLatestModal();
  }
}
