import { Injectable } from "@angular/core";
import { RFQOverview } from "@api/stats/models";
import { OrdersByStatus } from "@api/stats/models/orders-by-status.model";
import {ServicesByStatus} from '@api/stats/models/services-by.status';

@Injectable()
export class StatsMapperService {
  mapRFQOverview(input: any): RFQOverview {
    return {
      ...input,
    };
  }

  mapManyByStatus(input: any[]): (OrdersByStatus | ServicesByStatus)[] {
    return input.map((i) => this.mapByStatus(i));
  }

  mapByStatus(input: any): OrdersByStatus | ServicesByStatus {
    return {
      ...input,
    };
  }
}
