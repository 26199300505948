import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ClientData } from "@modules/clients/models/client.model";
import { ContactsMapperService } from "@modules/contacts/services/contacts-mapper.service";

@Injectable()
export class ClientMapperService {
  clientModel: ClientData;

  constructor(
    private http: HttpClient,
    private contactMapper: ContactsMapperService
  ) {}

  provide(input: ClientData) {
    return {
      ...input,
    };
  }

  mapOne({ primary_contact, ...input }: any): ClientData {
    return {
      ...input,
      primaryContact: primary_contact
        ? this.contactMapper.mapOne(primary_contact)
        : undefined,
    };
  }

  mapMany(input: any[]): ClientData[] {
    return input.map((i) => this.mapOne(i));
  }
}
