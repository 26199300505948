<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div *ngIf="!hideFilters" class="dashboard-body-top">
  <!-- left -->
  <div class="fl-l big-title">{{ "INVOICES.COMMISSIONS" | translate }}</div>
  <!-- end left -->
</div>

<div class="clear"></div>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action d-flex" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div *ngIf="!hideFilters" class="fl-l advanced-search-wrapper w-100 p-0">
    <app-filters
      [showCalendar]="true"
      [searchPlaceholder]="'GENERALS.SEARCH'"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      (date)="setFilteringDate($event); navigateByUrl()"
      [total]="total"
      searchPlaceholder="INVOICES.COMMISSIONS"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded && !hideFilters"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table
        matSort
        (matSortChange)="sortData($event)"
        [matSortActive]="active"
        [matSortDirection]="direction"
      >
        <thead>
          <tr>
            <th mat-sort-header="transaction_date">
              {{ "GENERALS.DATE" | translate }}
            </th>
            <th mat-sort-header="invoice_id">
              {{ "INVOICES.INVOICE-NO" | translate }}
            </th>
            <th mat-sort-header="identity_number">
              {{ "GENERALS.ORDER-NO" | translate }}
            </th>
            <th mat-sort-header="offer_id">
              {{ "INVOICES.OFFER-NO" | translate }}
            </th>
            <th mat-sort-header="service_category_name">
              {{ "GENERALS.SERVICE-CATEGORY" | translate }}
            </th>
            <th mat-sort-header="amount">
              {{ "GENERALS.AMOUNT" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr
            *ngFor="
              let commission of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <td class="gray-pointer">
              {{ commission.transaction_date | date: "dd/MM/yyyy HH:mm:ss" }}
            </td>
            <td class="gray-pointer">
              <a
                *ngIf="commission.invoice_id"
                href="/invoices/{{ commission.invoice_id }}"
                [routerLink]="['/invoices/' + commission.invoice_id]"
                >#{{ commission.invoice_id }}</a
              >
              <span *ngIf="!commission.invoice_id">n/a</span>
            </td>
            <td>
              <a
                href="/orders/{{ commission.order_identity_number }}"
                [routerLink]="['/orders/' + commission.order_identity_number]"
                class="action-link-orange"
                >#{{ commission.order_identity_number }}</a
              >
            </td>
            <td>
              <a
                href="/service-offers/{{ commission.offer_id }}"
                [routerLink]="['/service-offers/' + commission.offer_id]"
                class="action-link-gray"
                >#{{ commission.offer_id }}</a
              >
            </td>
            <td class="gray-pointer">
              {{ commission.category.name }}
            </td>
            <td class="gray-pointer">
              {{ commission.amount | currency: commission.currency_code }}
            </td>
            <td class="gray-pointer">
              <div class="status-label-{{ commission.status_id }}">
                {{
                  "STATUSES.COMMISSION-BILLED-" + commission.status_id
                    | translate
                }}
              </div>
              <div *ngIf="commission.is_refunded === true">
                {{ "STATUSES.REFUNDED" | translate }}
              </div>
            </td>

            <!--end table cel with action -->
          </tr>
          <tr *ngIf="items?.length === 0">
            <td colspan="7" class="gray">
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check"></div>

    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoading">
      <div
        class="resposive-table-wrapper"
        *ngFor="
          let commission of items
            | paginate
              : {
                  itemsPerPage: itemsPage,
                  currentPage: p,
                  totalItems: total
                };
          let i = index
        "
      >
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.DATE" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.transaction_date | date: "dd/MM/yyyy HH:mm:ss" }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.INVOICE-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.invoice_id ? "#" + commission.invoice_id : "n/a" }}
            <span
              *ngIf="commission.invoice_id"
              [routerLink]="['/invoices/' + commission.invoice_id]"
              class="action-link-orange-sm"
            >
              - {{ "GENERALS.DETAILS" | translate }}
            </span>
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.ORDER-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            #{{ commission.order_identity_number }}
            <span
              [routerLink]="['/orders/' + commission.order_identity_number]"
              class="action-link-orange-sm"
            >
              - {{ "GENERALS.DETAILS" | translate }}</span
            >
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.OFFER-NO" | translate }}
          </div>
          <div class="responsive-table-data-item">
            #{{ commission.offer_id }}
            <span
              [routerLink]="['/service-offers/' + commission.offer_id]"
              class="action-link-orange-sm"
            >
              - {{ "GENERALS.DETAILS" | translate }}</span
            >
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.SERVICE-CATEGORY" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.category.name }}
          </div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "INVOICES.COMMISSION" | translate }}
          </div>
          <div class="responsive-table-data-item">
            {{ commission.amount | currency: commission.currency_code }}
          </div>
        </div>

        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ commission.status_id }}">
              {{
                "STATUSES.COMMISSION-BILLED-" + commission.status_id | translate
              }}
            </span>
            <span *ngIf="commission.is_refunded === true">
              {{ "STATUSES.REFUNDED" | translate }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
