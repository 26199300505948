import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExpensesService } from "@api/expenses/services/expenses.service";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { fileValidator } from "@modules/shared/_validators/file-extension.validator";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { AlertService } from "../../../../_services/alert.service";
import moment from "moment";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-create-expense",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateExpenseComponent implements OnInit, OnDestroy {
  expenseForm: UntypedFormGroup;
  isLoading = true;
  public fileUploadControl = new FileUploadControl();
  private destroyed$: Subject<void> = new Subject();

  entityType: string;
  entityId: string;

  constructor(
    private expensesService: ExpensesService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data,
    protected dialogRef: MatDialogRef<CreateExpenseComponent>,
    public translate: TranslateService
  ) {
    this.expenseForm = new UntypedFormGroup({
      file: new UntypedFormControl([], [Validators.required, fileValidator()]),
      incurred_at: new UntypedFormControl("", [Validators.required]),
      currency_code: new UntypedFormControl("", Validators.required),
    });

    this.expenseForm
      .get("file")
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.expenseForm.get("file").hasError("fileExtension")) {
          this.alertService.error([
            this.translate.instant("VALIDATIONS.FILE_EXTENSION_TYPE"),
          ]);
        }
      });
  }

  ngOnInit() {
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  submit() {
    this.expenseForm.markAllAsTouched();
    if (!this.expenseForm.valid) {
      return;
    }
    const data = this.expenseForm.getRawValue();
    data.task_id = this.data.taskId;

    this.isLoading = true;

    this.expensesService
      .create(
        this.dialogRef.componentInstance.entityType,
        this.dialogRef.componentInstance.entityId,
        data
      )
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe((documentData) => {
        this.dialogRef.close({
          type: "saved",
          documentData,
        });
      });
  }

  close() {
    this.dialogRef.close();
  }

  setDate(date: any): void {
    if (!moment(date).isValid()) {
      return;
    }

    this.expenseForm.get("incurred_at").setValue(moment(date));
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
