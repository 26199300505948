import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { RFQData, RFQStatusID } from "@api/rfq";

export class RfqHelper {
  constructor(private translate: TranslateService) {}

  canAddOffers(rfq: RFQData) {
    return (
      rfq.status === RFQStatusID.OPEN || rfq.status === RFQStatusID.EXTENDED
    );
  }

  timeLeft(rfq: RFQData) {
    const endDate = moment(rfq.end_date);
    const now = moment.now();

    // if the submission period hasn't started (RFQ is not open), time left is not available
    if (rfq.status !== RFQStatusID.OPEN && rfq.status !== RFQStatusID.CLOSED) {
      return "N/A";
    }

    // if the submission period has closed, RFQ must be listed as Expired
    if (endDate.isBefore(now)) {
      return this.translate.instant("RFQ.EXPIRED");
    }

    const timeLeft = moment.duration(endDate.diff(now));

    if (endDate.diff(now, "minutes") < 60) {
      return this.translate.instant("GENERALS.LESS-THAN-AN-HOUR");
    }

    let label = "";

    if (timeLeft.days()) {
      label =
        label + timeLeft.days() + " " + this.translate.instant("GENERALS.DAYS");
    }

    if (timeLeft.hours()) {
      label =
        label +
        " " +
        timeLeft.hours() +
        " " +
        this.translate.instant("GENERALS.HOURS");
    }

    return label;
  }
}
