export enum LoggerEntityEnum {
  expat = "expat",
  order = "order",
  orderDetail = "order_detail",
  provider = "provider",
  offer = "offer",
  client = "client",
  file = "file",
  rfq = "rfqs/bids",
  user = "user",
  adBannerType = "ad_banner_type",
  adBanner = "ad_banner",
}
