import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { Observable, combineLatest, map, of, switchMap } from "rxjs";

@Component({
  selector: "app-document-history",
  templateUrl: "./document-history.component.html",
  styleUrls: ["./document-history.component.scss"],
})
export class DocumentHistoryComponent implements OnInit {
  public entityType$: Observable<string>;
  public entityId$: Observable<number>;
  public docId$: Observable<number>;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthorizationService
  ) {}

  public ngOnInit(): void {
    this.docId$ = this.route.params.pipe(map((params) => params.doc));
    this.entityType$ = this.route.data.pipe(
      switchMap((data) => {
        if (data["resource"]) {
          return of(data["resource"]);
        }

        return of("provider");
      })
    );
    this.entityId$ = combineLatest([
      this.route.params,
      this.route.data,
      this.auth.currentUser,
    ]).pipe(
      switchMap(([params, data, user]) => {
        if (params[data.entityIdParamName]) {
          return of(params[data.entityIdParamName]);
        }

        return of(user.entity_id);
      })
    );
  }
}
