import { Component, OnDestroy, OnInit } from "@angular/core";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfigMilestoneModalComponent } from "@modules/reports/components/custom/milestones/config-modals/config-milestone-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, Subject } from "rxjs";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { finalize, take } from "rxjs/operators";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { ReportsService } from "@api/reports/services/reports.service";

@Component({
  selector: "app-custom",
  templateUrl: "./custom.component.html",
  styleUrls: ["./custom.component.scss"],
})
export class CustomComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy
{
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private reports: ReportsService,
    private breadCrumbs: BreadcrumbService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.breadCrumbs.changeBreadcrumbs(this.breadCrumbs.setForCustomReports());
    this.watchQueryParams();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoading = true;

    const params = this.getParams(this.filters.page);
    if (params.page === undefined) {
      params.page = 1;
    }

    this.request_call = this.reports
      .customMilestoneList(params)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (data) => {
          this.processResponse({ result: data.result });
        },
        error: (error) => {
          this.items = [];
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: params,
        replaceUrl: true,
      })
      .then();
  }

  openMilestoneConfigModal() {
    const dialogRef = this.dialog.open(ConfigMilestoneModalComponent, {
      data: {
        saveButton: false,
      },
    });

    dialogRef.afterClosed().subscribe((params) => {
      const url = this.router
        .createUrlTree(["reports/milestone/new"], {
          queryParams: params,
        })
        .toString();

      window.open(url, "_blank");
    });
  }

  openMilestoneReportPage(item) {
    const url = this.router
      .createUrlTree(["reports/milestone/" + item.id], {
        queryParams: JSON.parse(item.params),
      })
      .toString();

    window.open(url, "_blank");
  }
  protected watchQueryParams() {
    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      ([params, query]) => {
        const filters = { ...params, ...query };

        super.watchQueryParams(filters);
      }
    );
  }

  protected getService() {
    return this.reports;
  }

  export(params) {
    this.isLoading = true;
    this.filters = JSON.parse(params);
    this.exportItems("milestones");
  }

  remove(id) {
    this.reports
      .removeCustomMilestone(id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.getItems(this.filters.page ?? 1);
          this.alertService.success(
            this.translate.instant("GENERALS.SUCCESSFULLY-DELETED")
          );
        },
        error: (error) => {
          this.items = [];
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }
  get LoadingType() {
    return LoadingTypeEnum;
  }
}
