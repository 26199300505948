import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { OfficeService } from "@modules/clients/services/office.service";
import { OfficeModel } from "src/app/_models/office";
import { ValueList } from "@modules/shared/models/value-list.model";
import { Country } from "src/app/_models/country";
import { DeleteModalComponent } from "@modules/shared/_components/delete-modal/delete-modal.component";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { OfficeComponent } from "../office/office.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-office-card",
  templateUrl: "./office-card.component.html",
  styleUrls: ["./office-card.component.scss"],
})
export class OfficeCardComponent implements OnInit {
  @Input() clientId: number;

  private filters: ValueList = {};

  public offices$!: Observable<OfficeModel[]>;
  public countries$!: Observable<Country[]>;
  public isLoadingGeneral = true;

  constructor(
    private officeService: OfficeService,
    private dialog: MatDialog,
    private dictionariesService: DictionariesService,
    private translationService: TranslateService
  ) {}

  public ngOnInit() {
    this.getCountries();
    this.getOffices();
  }

  public addOrEdit(countries: Country[], office?: OfficeModel) {
    const inEditMode = !!office;
    this.dialog
      .open(OfficeComponent, {
        data: {
          office: office,
          countries: countries,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.isLoadingGeneral = true;
          this.updateOrCreate(inEditMode, data.office);
        }
      });
  }

  public delete(office: OfficeModel) {
    this.dialog
      .open(DeleteModalComponent, {
        data: {
          message: this.translationService.instant("EXPATS.DELETE-OFFICE"),
        },
        maxWidth: "440px",
      })
      .afterClosed()
      .subscribe((data) => {
        if (data && data.isDeleted) {
          this.officeService
            .deleteOffice(this.clientId, office)
            .subscribe(() => this.getOffices());
        }
      });
  }

  private updateOrCreate(inEditMode: boolean, office: OfficeModel) {
    if (inEditMode) {
      this.officeService.updateOffice(this.clientId, office).subscribe(
        () => this.getOffices(),
        (error) => (this.isLoadingGeneral = false)
      );
    } else {
      office.entityId = this.clientId;
      this.officeService.createOffice(this.clientId, office).subscribe(
        () => this.getOffices(),
        (error) => (this.isLoadingGeneral = false)
      );
    }
  }

  private getOffices() {
    this.offices$ = this.officeService
      .fetchAll(this.clientId, this.filters)
      .pipe(tap(() => (this.isLoadingGeneral = false)));
  }

  private getCountries() {
    this.countries$ = this.dictionariesService.getCountryList();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
