import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
} from "@angular/core";

@Directive({
  selector: "[expand-menu]",
})
export class ExpandMenuDirective {
  @HostBinding("class.sub-is-open") isOpen = false;

  constructor(private el: ElementRef) {}

  @HostListener("click", ["$event"])
  toggleOpen($event) {
    if (!this.el.nativeElement.classList.contains("has-sub-nav")) {
      return;
    }
    this.isOpen = !this.isOpen;
  }
}
