<div class="custom-modal-wrapper">
  <div class="modal-title-custom">
    {{ "GENERALS.COMPANY-INFORMATION" | translate }}
  </div>

  <div class="modal-body-custom">
    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-NAME" | translate }} *</label>
        <input
          type="text"
          name="company_name"
          class="form-control"
          [(ngModel)]="provider.company_name"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-TYPE" | translate }} *</label>
        <ng-select
          class="customiz-select"
          [items]="companyTypes"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'GENERALS.SELECT-COMPANYTY-TYPES' | translate }}"
          [(ngModel)]="provider.company_type_id"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        >
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-TAX-NUMBER" | translate }} *</label>
        <input
          type="text"
          name="company_tax_number"
          class="form-control"
          [(ngModel)]="provider.company_tax_number"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "GENERALS.CURRENCY" | translate }} *</label>
        <ng-select
          class="customiz-select"
          [items]="currencies"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="provider.currency_id"
          placeholder="{{ 'GENERALS.SELECT-CURRENCY' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [disabled]="preselectedCurrency"
        >
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.PROF-ASSOCIATION" | translate }}</label>
        <ng-select
          class="customiz-select"
          [items]="professionalAssociation"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'FORMS.SELECT_PROF_ASSOCIATION' | translate }}"
          [(ngModel)]="provider.professional_association_id"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        >
        </ng-select>
      </div>
      <div class="form-group w-50">
        <label>{{ "LABEL.NUMBER_OF_EMPLOYEES" | translate }}</label>
        <ng-select
          class="customiz-select"
          [items]="employeesNumber"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'FORMS.SELECT_COMPANY_SIZE' | translate }}"
          [(ngModel)]="provider.number_of_employees_id"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-LEGAL-NAME" | translate }}</label>
        <input
          type="text"
          name="company_legal_name "
          class="form-control"
          [(ngModel)]="provider.company_legal_name"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group w-100">
        <label>{{ "LABEL.COMPANY-SHORT-DESCRIPTION" | translate }} *</label>
        <textarea
          name="short_description"
          class="textarea-control"
          [(ngModel)]="provider.short_description"
        ></textarea>
      </div>
    </div>

    <div class="row">
      <div class="w-100">
        <div class="form-group-title">
          {{ "GENERALS.COMPANY-ADDRESS" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-ADDRESS" | translate }} *</label>
        <input
          type="text"
          name="address"
          class="form-control"
          [(ngModel)]="provider.address"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-COUNTRY" | translate }} *</label>
        <ng-select
          class="customiz-select"
          [items]="countries"
          bindLabel="name"
          bindValue="country_code"
          placeholder="{{ 'GENERALS.SELECT-COMPANYTY-COUNTRY' | translate }}"
          [(ngModel)]="provider.country_code"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          (change)="countryChanged()"
        >
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <div class="row">
          <div class="form-group w-50">
            <label>{{ "LABEL.REGION" | translate }} *</label>
            <input
              type="text"
              name="region"
              class="form-control"
              [(ngModel)]="provider.region"
            />
          </div>
          <div class="form-group w-50">
            <label>{{ "LABEL.ZIP-CODE" | translate }} *</label>
            <input
              type="text"
              name="zip_code"
              class="form-control"
              [(ngModel)]="provider.zip_code"
            />
          </div>
        </div>
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.COMPANY-CITY" | translate }} *</label>
        <ng-select
          class="customiz-select"
          [items]="cities"
          bindLabel="name"
          bindValue="name"
          placeholder="{{ 'GENERALS.SELECT-CITY' | translate }}"
          [(ngModel)]="provider.city"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          (search)="loadCities($event)"
          (focus)="loadCities()"
        >
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="w-100">
        <div class="form-group-title">
          {{ "GENERALS.BANK-DETAILS" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <div class="row">
          <div class="form-group w-50">
            <label>{{ "LABEL.BANK-NAME" | translate }} *</label>
            <input
              type="text"
              name="bank_name"
              class="form-control"
              [(ngModel)]="provider.bank_name"
            />
          </div>
          <div class="form-group w-50">
            <label>{{ "LABEL.SWIFT-CODE" | translate }} *</label>
            <input
              type="text"
              name="swift_code"
              class="form-control"
              [(ngModel)]="provider.swift_code"
            />
          </div>
        </div>
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }} *</label>
        <input
          type="text"
          name="bank_account_number"
          class="form-control"
          [(ngModel)]="provider.bank_account_number"
        />
      </div>
    </div>

    <div class="row">
      <div class="w-100">
        <div class="form-group-title">
          {{ "GENERALS.LEGAL-REPRESENTATIVE" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label
          >{{
            "LABEL.LEGAL-REPRESENTATIVE-FIRST-LAST-NAME" | translate
          }}
          *</label
        >
        <input
          type="text"
          name="legal_representative_name"
          class="form-control"
          [(ngModel)]="provider.legal_representative_name"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.LEGAL-REPRESENTATIVE-EMAIL" | translate }} *</label>
        <input
          type="text"
          name="legal_representative_email"
          class="form-control"
          [(ngModel)]="provider.legal_representative_email"
        />
      </div>
    </div>

    <!--
  <div class="row">


    <div class="form-group w-50">
      <label>{{ 'LABEL.DEPARTMENT' | translate }}</label>
      <ng-select
        class="customiz-select"
        [items]="departments"
        bindLabel="text"
        bindValue="id"
        placeholder="{{ 'GENERALS.SELECT-DEPARTMENT' | translate }}"
        [(ngModel)]="provider.department_id"
        notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      >
      </ng-select>
    </div>


  </div> -->

    <!-- <div class="row">
    <div class="form-group w-100">
      <label>{{ 'LABEL.COMPANY-SHORT-DESCRIPTION' | translate }} *</label>
      <textarea
        name="short_description"
        class="textarea-control"
        [(ngModel)]="provider.short_description"
      ></textarea>
    </div>
  </div> -->
  </div>

  <div class="clear"></div>

  <div class="modal-footer-custom">
    <ng-container *ngIf="!isLoading; else loadingButtons">
      <button class="btn gray-bg fl-l modal-button" mat- (click)="close()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="save()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <ng-template #loadingButtons>
      <app-loader-spinner-general
        [loadingType]="LoadingType.BUTTONS"
        *ngIf="isLoading"
        [repeat]="2"
      ></app-loader-spinner-general>
    </ng-template>
    <div class="clear"></div>
  </div>
</div>
