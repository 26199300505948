<app-dialog-header>
  {{ "Assign expats" | translate }}
  <div
    slot="subtitle"
    class="text-secondary font-medium text-base pt-1.5"
    *ngIf="showAlreadyAddedExpats()"
  >
    {{
      "PORTAL.SELECT-MORE-EXPATS" | translate: { expatCount: expatsRemaining() }
    }}
  </div>
</app-dialog-header>

<div class="dialog-body">
  <ng-container *ngFor="let controls of expatControlsArr">
    <app-offer-expat-item
      [form]="controls"
      [expatsList]="expatsList"
      [selectedExpatsList]="selectedExpatsList"
      class="w-full"
      [order]="order"
    ></app-offer-expat-item>
  </ng-container>
  <div
    *ngIf="
      totalNoExpats > 5 &&
      this.expatControls.controls.length < expatsRemaining()
    "
    class="w-full"
  >
    <p class="action-link-orange fw-500" (click)="addMoreExpats()">
      <i class="icon-plus"></i>
      {{ "Add more expats" | translate }}
    </p>
  </div>
</div>

<div class="dialog-footer">
  <ng-container *ngIf="!isLoading; else loadingButtons">
    <button class="btn-secondary" mat-dialog-close>
      <mat-icon svgIcon="cancel" class="btn-icon"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button class="btn-primary" (click)="save('save')">
      <mat-icon svgIcon="check-outline" class="btn-icon"></mat-icon>
      {{ "GENERALS.ASSIGN" | translate }}
    </button>
  </ng-container>
  <ng-template #loadingButtons>
    <app-loader-spinner-general
      [loadingType]="LoadingType.BUTTONS"
      [repeat]="2"
    ></app-loader-spinner-general>
  </ng-template>
</div>
