<div class="custom-modal-wrapper p-0">
  <div class="text-2xl border-b !p-5 font-semibold">
    {{ "GENERALS.ADD-FOLLOWER" | translate }}
  </div>
  <mat-dialog-content class="!m-0 !p-5">
    <div class="text-secondary font-medium mb-2.5">
      {{ "Select additional users to be given access to the case" | translate }}
    </div>
    <form [formGroup]="form">
      <app-loader-spinner-general
        *ngIf="isLoading"
        [repeat]="1"
        [loadingType]="LoadingType.INPUT"
      ></app-loader-spinner-general>

      <ng-container *ngIf="users$ | async as users">
        <mat-form-field
          appearance="outline"
          class="w-100 px-0"
          *ngIf="!isLoading"
        >
          <mat-label>{{ "LABEL.USER" | translate }} </mat-label>
          <mat-select
            formControlName="user_id"
            disableOptionCentering
            panelClass="user-panel"
          >
            <mat-option *ngFor="let user of users" [value]="user.id">
              {{ user.name }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="user_id"> </mat-error>
        </mat-form-field>
      </ng-container>
    </form>
  </mat-dialog-content>

  <div class="clear"></div>

  <div class="flex justify-end !p-5 w-full border-t">
    <app-loader-spinner-general
      *ngIf="isLoading"
      [repeat]="2"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoading">
      <button class="btn-secondary" (click)="close()">
        <mat-icon svgIcon="cancel" class="btn-icon"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button
        class="btn-primary"
        (click)="submit()"
        [disabled]="!(form.valid && form.dirty)"
      >
        <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>

    <div class="clear"></div>
  </div>
</div>
