import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class InvoicesService {
  private host = environment.gateway_endpoint;

  constructor(private http: HttpClient) {}

  getInvoicesList(params = {}): Observable<any> {
    return this.http.get<any>(this.host + "entities/providers/invoices/list", {
      params: params,
    });
  }

  getCommissions(params = {}): Observable<any> {
    return this.http.get<any>(
      this.host + "entities/providers/commissions/billed",
      {
        params: params,
      }
    );
  }

  exportCsv(invoiceId) {
    return this.http.get(
      this.host + "entities/providers/invoices/export-csv/" + invoiceId,
      { responseType: "blob" }
    );
  }

  exportPdf(invoiceId) {
    const headers = new HttpHeaders({
      Accept: "application/pdf",
    });
    return this.http.get(
      this.host + "entities/providers/invoices/export-pdf/" + invoiceId,
      {
        headers: headers,
        responseType: "blob",
      }
    );
  }

  getInvoiceDetails(invoiceId): Observable<any> {
    return this.http.get<any>(
      this.host + "entities/providers/invoices/view/" + invoiceId
    );
  }
}
