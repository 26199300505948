<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.TABLE"
  ></app-loader-spinner-general>
</ng-container>

<div class="white-box-wrapper" *ngIf="!isLoading">
  <div class="d-flex align-items-center header-milestones pt-2 pb-2">
    <div class="d-flex align-items-center" style="width: calc(100% - 182px)">
      <div class="title-milestones m-r-20 text-nowrap">
        {{ "GENERALS.MILESTONES" | translate }}
      </div>
      <div class="search-field row w-100">
        <mat-icon> search</mat-icon>
        <input
          matInput
          class="search-milestones"
          type="text"
          [placeholder]="'LABEL.MILESTONE_SEARCH_TEXT' | translate"
          [(ngModel)]="filter"
          (input)="filterResults(filter)"
        />
      </div>
    </div>
    <div class="btn-add-milestones w-auto" *canView="canCreate">
      <button mat-button (click)="onAdd()">
        <mat-icon>add_circle_outline</mat-icon>
        {{ "GENERALS.ADD-MILESTONE" | translate }}
      </button>
    </div>
  </div>
  <div class="list-milestones py-3">
    <div
      class="add-milestones-item d-flex justify-content-between align-items-center"
      *ngIf="isAddEnable"
    >
      <div class="name-milestones-item w-70">
        <div>
          <input
            matInput
            class="input-milestones-item w-100"
            [placeholder]="'LABEL.MILESTONE_ADD_TEXT' | translate"
            [(ngModel)]="milestoneName"
          />
        </div>
      </div>
      <div class="edit-button-row" *canView="canUpdate">
        <button class="pointer btn cancel-btn m-r-10" (click)="hideAddButton()">
          <mat-icon>highlight_off</mat-icon>
          <div class="text-cancel">{{ "GENERALS.CANCEL" | translate }}</div>
        </button>
        <button
          *canView="canDelete"
          class="pointer btn orange-bg save-btn"
          (click)="create(milestoneName)"
        >
          <img src="assets/icons/button-save-icon.svg" alt="" />
          <div class="text-save">{{ "GENERALS.SAVE" | translate }}</div>
        </button>
      </div>
    </div>
    <div
      class="list-milestone-item d-flex justify-content-between align-items-center"
      *ngFor="let milestone of filterMilestoneList"
    >
      <div class="name-milestones-item w-10">
        <span>#{{ milestone.id }}</span>
      </div>
      <div class="name-milestones-item w-75">
        <div *ngIf="isEditEnable === milestone.id">
          <input
            matInput
            [(ngModel)]="editMilestoneName"
            class="input-milestones-item w-100"
          />
        </div>
        <span *ngIf="isEditEnable !== milestone.id">{{
          milestone.name | titlecase
        }}</span>
      </div>
      <div class="edit-button-row" *ngIf="isEditEnable === milestone.id">
        <button class="pointer btn cancel-btn m-r-10" (click)="onClose()">
          <mat-icon>highlight_off</mat-icon>
          <div class="text-cancel">{{ "GENERALS.CANCEL" | translate }}</div>
        </button>
        <button
          class="pointer btn orange-bg save-btn"
          (click)="update(milestone)"
        >
          <img src="assets/icons/button-save-icon.svg" alt="" />
          <div class="text-save">{{ "GENERALS.SAVE" | translate }}</div>
        </button>
      </div>
      <div *ngIf="isEditEnable !== milestone.id">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            class="sub-menu-item"
            mat-menu-item
            *ngIf="isEditEnable !== milestone.id"
            (click)="onEdit(isEditEnable, milestone)"
          >
            <mat-icon>edit</mat-icon>
            <span>{{ "GENERALS.EDIT" | translate }}</span>
          </button>
          <button
            class="sub-menu-item"
            mat-menu-item
            (click)="delete(milestone.id)"
          >
            <mat-icon>delete</mat-icon>
            <span>{{ "GENERALS.DELETE" | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div
      class="white-box-row medium-title no-items"
      *ngIf="filterMilestoneList.length === 0"
    >
      {{ "GENERALS.NO-ITEMS-FOUND" | translate }}
    </div>
  </div>
</div>
