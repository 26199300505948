import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appTaskStatus]",
})
export class TaskStatusDirective implements OnInit {
  @Input() status = "";
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.updateElements();
  }

  private updateElements() {
    this.el.nativeElement.className = `status ${this.status}`;

    switch (this.status) {
      case "pending": {
        this.el.nativeElement.children[0].className =
          "icon-attention small pending";
        break;
      }
      case "in_progress": {
        this.el.nativeElement.children[0].className = "icon-ok small success";
        break;
      }
      case "cancelled": {
        this.el.nativeElement.children[0].className =
          "icon-cancel small cancelled";
        break;
      }
    }
  }
}
