<div>
  <div>
    <ngx-smart-modal
      id="userDetails"
      #userDetails
      identifier="userDetails"
      [dismissable]="false"
    >
      <div class="custom-modal-wrapper" *ngIf="user">
        <div class="modal-title-custom">
          {{ "GENERALS.ABOUT-THE" | translate }} {{ type }}
        </div>
        <div class="details-body-wrapper">
          <!-- 2 col details -->
          <div class="details-2c">
            <!-- left -->
            <div class="details-2c-left">
              <div class="small-box">
                <div class="small-box-header-img">
                  <img src="../../../assets/images/footer-img.jpg" alt="img" />
                </div>
                <div class="small-box-details">
                  <div class="medium-title" title="{{ user.name }}">
                    {{ user.name }}
                  </div>
                  <div class="gray fw-500" *ngIf="user && user.created_at">
                    {{ "PORTAL.REGISTRED-SINCE" | translate }}:
                    {{ user.created_at | date: "dd/MM/yyyy" }}
                  </div>
                </div>
              </div>
            </div>
            <!-- end left -->

            <!-- right -->
            <div class="details-2c-right">
              <div class="white-box-wrapper">
                <div
                  class="white-box-row"
                  *ngFor="let info of user.details; let i = index"
                >
                  <div class="white-box-header">
                    <div class="medium-title fl-l">
                      {{ info.title | translate }}
                    </div>
                  </div>
                  <div class="clear"></div>
                  <div class="white-box-body">
                    <p *ngFor="let item of info.value">
                      <b *ngIf="item.label">{{ item.label | translate }}:</b>
                      {{ item.value }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- end right -->
            </div>
            <!-- end 2 col details -->
          </div>
        </div>
        <div class="clear"></div>
        <div class="modal-footer-custom">
          <button class="btn gray-bg fl-l modal-button" (click)="cancelModal()">
            <mat-icon svgIcon="cancel"></mat-icon>
            {{ "GENERALS.CANCEL" | translate }}
          </button>

          <div class="clear"></div>
        </div>
      </div>
    </ngx-smart-modal>
  </div>
</div>
