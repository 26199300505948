<ng-container *ngIf="!id">
  <app-loader-spinner-general
    class="d-flex justify-content-end"
    [loadingType]="LoadingType.BUTTONS"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    [loadingType]="LoadingType.FILTER"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    [loadingType]="LoadingType.TABLE"
  ></app-loader-spinner-general>
</ng-container>

<div class="details-body-wrapper">
  <ng-container *ngIf="id">
    <app-documents
      [entityType]="'orders'"
      [entityId]="id"
      [hideTitle]="true"
      [docType]="orderService.docTypeInvoice"
    ></app-documents>
  </ng-container>
</div>
