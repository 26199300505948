<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>
</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="details-body-wrapper" *ngIf="categories && !edit">
    <!-- 1 col details -->
    <div class="details-1c">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.SERVICE-CATEGORIES-COMMISSIONS" | translate }}
            </div>
            <div class="white-box-header-action fl-r" (click)="edit = true">
              {{ "COMMISSION.EDIT-CATEGORY-COUNTRY" | translate }}
              <i class="icon-cog-outline"></i>
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <app-categories-tree
              [categories]="categories"
              (onchange)="removeCategory($event)"
            >
            </app-categories-tree>
          </div>
        </div>

        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.COUNTRIES-WHERE-DELIVER" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body" *ngIf="provider">
            <div class="row">
              <div class="w-100">
                <ng-container
                  *ngFor="let item of provider.countries; let isLast = last"
                >
                  <span> {{ item.name }}{{ isLast ? "" : ", " }} </span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.INVOICE_DUE_DAYS" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body" *ngIf="provider">
            <div class="row">
              <div *ngIf="provider.invoice_due_days !== 1" class="w-100">
                {{
                  "GENERALS.INVOICE_DUE_DAYS_NOTICE"
                    | translate: { days: provider.invoice_due_days }
                }}
              </div>
              <div *ngIf="provider.invoice_due_days === 1" class="w-100">
                {{
                  "GENERALS.INVOICE_DUE_DAY_NOTICE"
                    | translate: { days: provider.invoice_due_days }
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end 1 col details -->
  </div>

  <div class="details-body-wrapper" *ngIf="categories && edit && allCategories">
    <!-- 1 col details -->
    <div class="details-1c">
      <div class="white-box-wrapper">
        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "COMMISSION.EDIT-CATEGORIES-COMMISSIONS" | translate }}
            </div>
            <div class="white-box-header-action fl-r" (click)="cancel()">
              {{ "GENERALS.CANCEL" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body">
            <app-add-categories
              [serviceCategories]="allCategories"
              [selectedServiceCategories]="categories"
              [level]="0"
              (onselect)="updateCategorySelection($event)"
              [providerId]="id"
            ></app-add-categories>
          </div>
        </div>

        <div class="white-box-row">
          <div class="white-box-header">
            <div class="medium-title fl-l">
              {{ "GENERALS.COUNTRIES-WHERE-DELIVER" | translate }}
            </div>
          </div>
          <div class="clear"></div>
          <div class="white-box-body" *ngIf="provider">
            <div class="row">
              <div class="w-100">
                <app-chips-select
                  *ngIf="selectedCountries"
                  [items]="countries"
                  [multiple]="true"
                  [placeholder]="'GENERALS.COUNTRIES-CHIPS-PLACEHOLDER'"
                  [bindValue]="'name'"
                  [bindLabel]="'name'"
                  [preselectedItems]="selectedCountries"
                  (onselect)="selectedCountries = $event"
                ></app-chips-select>
              </div>
            </div>
          </div>
        </div>
        <div class="clear"></div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn gray-bg modal-button"
            (click)="cancel()"
          >
            <mat-icon svgIcon="cancel"></mat-icon>
            {{ "GENERALS.CANCEL" | translate }}
          </button>
          <button
            type="button"
            class="btn orange-bg modal-button"
            (click)="previewCategories()"
          >
            <mat-icon svgIcon="save"></mat-icon>
            {{ "GENERALS.SAVE" | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- end 1 col details -->
  </div>

  <!-- modal -->
  <ngx-smart-modal
    id="confirmCategories"
    #confirmCategories
    identifier="confirmCategories"
    [dismissable]="false"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">{{ "GENERALS.ACCEPT-TERMS" | translate }}</div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <div class="modal-form">
        <div class="modal-subtitle">
          {{ "GENERALS.ACCEPT-TERMS-SUBTITLE" | translate }}:
        </div>
        <div class="row pd-l-20" *ngIf="displayCategories.length">
          <ul>
            <ng-container *ngFor="let category of displayCategories">
              <li class="pd-all fw-600">
                <div class="fs-15">
                  {{ category.label }}
                  <span class="pd-l-20 fs-15">
                    <span
                      class="orange m-0"
                      *ngIf="category.commission_type_id.toString() !== '22'"
                      >{{ category.commission | currency: "EUR" }}</span
                    >
                    <span
                      class="orange m-0"
                      *ngIf="category.commission_type_id.toString() === '22'"
                      >{{ category.commission }} %</span
                    >
                    {{ "GENERALS.COMMISSION" | translate }}</span
                  >
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="modal-subtitle">
        {{ "GENERALS.ACCEPT-TERMS-FOOTER" | translate }}
      </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn gray-bg modal-button"
        (click)="confirmCategories.close()"
      >
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button type="button" class="btn orange-bg" (click)="addCategories()">
        {{ "GENERALS.ADD" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
  <!-- end modal -->
</ng-container>
