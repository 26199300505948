<div class="details-navigation d-flex justify-content-between">
  <!-- title -->
  <div class="big-title">
    <app-back-button></app-back-button>
    <ng-container *ngIf="docName$ | async as name">{{ name }}</ng-container>
  </div>
  <div>
    <app-details-navigation [menu]="menu"></app-details-navigation>
  </div>
  <!-- end title -->
</div>

<div class="clear"></div>

<router-outlet></router-outlet>
