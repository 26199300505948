import { Component, Input, OnInit } from "@angular/core";
import { ItemsListComponent } from "../../../../modules/shared/_components/items-list/items-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "src/app/_services/alert.service";
import { InvoicesService } from "../../../../_services/invoices.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { OrderCommission } from "../../../../_models/order-commission";
import { MatDialog } from "@angular/material/dialog";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { finalize } from "rxjs";

@Component({
  selector: "app-invoice-commissions",
  templateUrl: "./invoice-commissions.component.html",
})
export class InvoiceCommissionsComponent
  extends ItemsListComponent
  implements OnInit
{
  @Input() hideFilters: boolean;
  @Input() invoiceId: number;

  isFiltersLoaded: boolean = false;
  items: OrderCommission[];
  request_call;
  active = "";
  isLoading = false;
  isXs: boolean;

  constructor(
    private service: InvoicesService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/invoices/commissions", "commissions")
    );
    this.route.queryParams.subscribe((query) => {
      this.isLoading = true;
      this.watchQueryParams(query);
    });

    this.showBulkActions = false;
  }

  ngOnInit(): void {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });

    if (this.invoiceId) {
      this.filters["invoice_id"] = this.invoiceId;
      this.navigateByUrl();
    }
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router.navigate([], {
      queryParams: params,
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params: any = {};
    params = { ...this.filters };
    this.request_call = this.service
      .getCommissions(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.processResponse(data);
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  setFilteringDate(e) {
    if (e) {
      this.startDate = e.start_date;
      this.endDate = e.end_date;
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
