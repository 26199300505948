import { Component, OnInit } from "@angular/core";
import { AccountSelectors } from "@api/account";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Store } from "@ngrx/store";
import { NgxSmartModalService } from "ngx-smart-modal";
import { take } from "rxjs";
import { DocumentsService } from "src/app/_services/documents.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { filterNullish } from "src/app/lib";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";

@Component({
  selector: "app-user-agreements",
  templateUrl: "./user-agreements.component.html",
  styleUrls: ["./user-agreements.component.scss"],
})
export class UserAgreementsComponent implements OnInit {
  isLoading = false;
  entityId;
  items;
  isXs: boolean;
  title: string;

  constructor(
    protected service: DocumentsService,
    public ngxSmartModalService: NgxSmartModalService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService,
    private store: Store
  ) {
    this.title = "Documents";
    this.store
      .select(AccountSelectors.selectUser)
      .pipe(filterNullish(), take(1))
      .subscribe((user) => {
        this.entityId = user.entity_details.entity_id;
      });

    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setCompanyDocs()
    );
    this.getUserAgreements();
  }

  getUserAgreements() {
    this.isLoading = true;
    this.service.getUserAgreements(this.entityId).subscribe((data) => {
      this.items = data.result;
      this.isLoading = false;
    });
  }

  archiveDoc(entId, docId) {
    this.service.archiveUserAgreements(entId, docId).subscribe((data) => {
      this.getUserAgreements();
    });
  }

  openDocModal() {
    this.ngxSmartModalService.getModal("addDoc").open();
  }

  deleteDocument(id) {
    this.service.deleteDocument(id).subscribe((data) => {
      this.getUserAgreements();
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
