<div class="white-box-wrapper" *ngIf="countries">
  <div class="white-box-row">
    <div class="white-box-header">
      <div class="medium-title fl-l">
        {{ "EXPATS.CITIZENSHIPS" | translate }}
      </div>
      <button
        class="white-box-header-action fl-r add d-flex align-items-end border-white transparent-bg p-0 btn"
        (click)="handleEdit()"
      >
        <mat-icon svgIcon="plus-circle" class="plus-circle"></mat-icon>
        {{ "GENERALS.ADD" | translate }}
      </button>
    </div>
    <div class="clear"></div>
    <div class="white-box-body">
      <ng-container *ngIf="(citizenships$ | async)?.result as citizenships">
        <div class="table-wrapper">
          <div class="table-responsive">
            <table>
              <thead>
                <tr>
                  <th class="p-l-0">{{ "EXPATS.CITIZENSHIP" | translate }}</th>
                  <th class="column-center">
                    {{ "EXPATS.IS-PRIMARY" | translate }}
                  </th>
                  <th class="text-center">{{ "EXPATS.STATUS" | translate }}</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let citizenship of citizenships; let i = index">
                  <td class="column-2 p-l-0">
                    {{ citizenship.country }}
                  </td>

                  <td class="column-2 column-center">
                    <ng-container
                      *ngIf="citizenship.is_primary; else notPrimary"
                    >
                      <mat-icon
                        svgIcon="save-outline"
                        class="light-green"
                      ></mat-icon>
                    </ng-container>
                    <ng-template #notPrimary
                      ><mat-icon class="gray" svgIcon="cancel"></mat-icon
                    ></ng-template>
                  </td>
                  <td class="column-2">
                    <span
                      [ngClass]="
                        'secondary-status-' +
                        (citizenship.status_name | lowercase)
                      "
                      class="w-100 text-center"
                    >
                      {{ citizenship.status_name | uppercase }}
                    </span>
                  </td>

                  <td class="ta-r">
                    <!-- table action with overlay -->
                    <div toggle-action-table class="table-action-wrapper">
                      <i class="icon-dot-3"></i>

                      <div class="table-action">
                        <ul>
                          <li
                            (click)="handleEdit(citizenship)"
                            (keyup)="handleEdit(citizenship)"
                          >
                            {{ "edit" | translate }}
                          </li>
                          <li
                            (click)="handleDelete(citizenship)"
                            (keyup)="handleDelete(citizenship)"
                          >
                            {{ "delete" | translate }}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- end table action with overlay -->
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!citizenships || citizenships.length === 0">
                <tr>
                  <td colspan="100" class="gray p-l-0">
                    {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
