import { Pipe, PipeTransform } from "@angular/core";
import { MomentInput, unitOfTime } from "moment";
import { DifferencePipe } from "ngx-moment";

@Pipe({
  name: "timeRemaining",
})
export class TimeRemainingPipe extends DifferencePipe implements PipeTransform {
  transform(
    value: MomentInput,
    otherValue: MomentInput,
    unit?: unitOfTime.Diff,
    precision?: boolean
  ): number {
    const diff = super.transform(value, otherValue, unit, precision);
    if (diff < 0) {
      return 0;
    }
    return diff;
  }
}
