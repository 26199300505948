<div class="custom-modal-wrapper add-template p-0">
  <div>
    <div class="modal-title-custom p-20">
      {{ (data ? "TEMPLATE.EDIT" : "TEMPLATE.NEW") | translate }}
    </div>

    <div class="clear"></div>

    <ng-container *ngIf="isLoading">
      <app-loader-spinner-general
        [loadingType]="LoadingType.BIG_INFO"
      ></app-loader-spinner-general>
      <app-loader-spinner-general
        [repeat]="2"
        class="d-flex justify-content-end"
        [loadingType]="LoadingType.BUTTONS"
      ></app-loader-spinner-general>
    </ng-container>

    <form [formGroup]="form" *ngIf="!isLoading">
      <div class="modal-body-custom p-20">
        <div class="row mt-1">
          <div class="form-group w-50 file-wrapper">
            <file-upload
              class="w-100"
              [(ngModel)]="uploadedFiles.file"
              [ngModelOptions]="{ standalone: true }"
              [control]="fileUploadControl"
              accept="application/pdf,.docx,.doc"
            >
              <ng-template #placeholder>
                <span><i class="icon-upload-cloud"></i></span>
                <span class="m-l-5"
                  >{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }}
                </span>
                <span class="orange"> {{ "GENERALS.BROWSE" | translate }}</span>
                <span class="d-block">{{
                  "TEMPLATE.ACCEPTED-FILE-TYPES" | translate
                }}</span>
              </ng-template>
              <ng-template
                let-i="index"
                let-file="file"
                let-control="control"
                #item
              >
                <div class="file-info">
                  <span class="file-name">{{ file.name }}</span>
                  <span class="remove-file" (click)="control.removeFile(file)">
                    <i class="icon-trash"></i
                    >{{ "GENERALS.REMOVE-FILE" | translate }}</span
                  >
                </div>
              </ng-template>
            </file-upload>
            <div class="clear"></div>
          </div>

          <div class="form-group w-50">
            <div class="row">
              <div class="form-group w-100">
                <mat-form-field
                  appearance="outline"
                  class="w-100 template-name"
                >
                  <mat-label>{{
                    "TEMPLATE.DOCUMENT-NAME" | translate
                  }}</mat-label>
                  <input matInput type="text" formControlName="name" />
                </mat-form-field>
                <mat-error
                  class="template-name"
                  *ngIf="
                    form.controls.name.errors?.required &&
                    form.controls.name.touched
                  "
                >
                  {{ "GENERALS.FIELD-REQUIRED" | translate }}
                </mat-error>
              </div>
              <div class="form-group w-100">
                <mat-form-field
                  appearance="outline"
                  class="w-100 template-countries"
                >
                  <mat-label> {{ "LABEL.COUNTRY" | translate }} </mat-label>
                  <mat-select
                    [(ngModel)]="selectedCountries"
                    [formControl]="countriesField"
                    placeholder="{{ 'LABEL.COUNTRY' | translate }}"
                    multiple
                    panelClass="countries-select"
                    disableOptionCentering
                  >
                    <mat-select-trigger>
                      <mat-chip-list>
                        <mat-chip
                          *ngFor="let country of countriesField.value"
                          [removable]="true"
                          (removed)="onCountryRemoved(country)"
                        >
                          {{ country }}
                          <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </mat-chip-list>
                    </mat-select-trigger>
                    <mat-option>
                      <ngx-mat-select-search
                        [placeholderLabel]="'LABEL.COUNTRY' | translate"
                        [noEntriesFoundLabel]="
                          'GENERALS.NO-ITEMS-DISPLAY' | translate
                        "
                        [formControl]="countryMultiFilterCtrl"
                        disableOptionCentering
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let country of filteredCountryMulti | async"
                      [value]="country.code"
                    >
                      {{ country.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="clear"></div>
    <div class="modal-footer-custom bottom-buttons p-20" *ngIf="!isLoading">
      <button class="btn gray-bg fl-l modal-button" (click)="closeDialog()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r" (click)="submitTemplate()">
        {{ (data ? "TEMPLATE.SAVE" : "TEMPLATE.NEXT") | translate }}
      </button>
      <div class="clear"></div>
    </div>
  </div>
</div>
