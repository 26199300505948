<div class="fl w-100 p-0">
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-loader-spinner-general
      [loadingType]="LoadingType.BLOCK"
    ></app-loader-spinner-general>
  </div>
  <button
    *ngIf="!isLoading"
    (click)="payInvoice()"
    class="btn btn-info btn-block orange-bg w-100"
  >
    {{ "DOCUMENTS.PAY-INVOICE" | translate }}
  </button>
</div>
