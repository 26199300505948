<div class="white-box-wrapper expat-details" *ngIf="expat">
  <div class="white-box-row">
    <div class="white-box-header">
      <div class="medium-title fl-l">
        {{ "EXPATS.PERSONAL-INFORMATION" | translate }}
      </div>
      <button
        class="white-box-header-action fl-r edit-info border-white transparent-bg p-0 btn"
        *ngIf="countries && maritalStatuses"
        (click)="handleEdit()"
      >
        <mat-icon svgIcon="edit" class="button-icon"></mat-icon>
        {{ "GENERALS.EDIT" | translate }}
      </button>
    </div>
    <div class="clear"></div>
    <div class="white-box-body expat-personal-information">
      <div class="row">
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.BORN-ON" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.birth_date | date: "dd/MM/yyyy" | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.COUNTRY-OF-BIRTH" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.birth_country | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.CITY-OF-BIRTH" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.birth_city | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.LANGUAGE" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.primary_language | titlecase | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.MARITAL-STATUS" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.marital_status?.text | titlecase | notAvailable }}
          </p>
        </div>
        <div class="w-50">
          <span class="m-b-10 d-flex">
            {{ "EXPATS.HIGHEST-FORM-EDUCATION" | translate }}
          </span>
          <p class="case-label">
            {{ expat.details.education?.text | titlecase | notAvailable }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
