<div class="custom-modal-wrapper add-expat">
  <div class="modal-title-custom">
    {{ "GENERALS.ADD-SERVICE" | translate }}
  </div>
  <app-loader-spinner-general
    *ngIf="isLoadingGeneral"
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>
  <form [formGroup]="caseForm">
    <div class="modal-body-custom" *ngIf="!isLoadingGeneral">
      <div class="row">
        <div class="w-50">
          <mat-form-field
            appearance="outline"
            class="w-100"
            [formGroup]="caseForm"
          >
            <mat-label> {{ "GENERALS.CLIENT" | translate }} </mat-label>
            <mat-select
              [placeholder]="'GENERALS.CLIENT' | translate"
              #singleSelect
              [value]="case.client_id"
              [disabled]="true"
            >
              <mat-option [value]="case.client_id">
                {{ case.client_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="w-50" *ngIf="case.expat_id">
          <mat-form-field
            appearance="outline"
            class="w-100"
            [formGroup]="caseForm"
          >
            <mat-label> {{ "GENERALS.EXPAT" | translate }} </mat-label>
            <mat-select
              [placeholder]="'GENERALS.EXPAT' | translate"
              #singleSelect
              [value]="case.expat_id"
              [disabled]="true"
            >
              <mat-option [value]="case.expat_id">
                {{ case.expat_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="w-50" *ngIf="case.assignment">
          <mat-form-field
            appearance="outline"
            class="w-100"
            [formGroup]="caseForm"
          >
            <mat-label> {{ "LABEL.ASSIGNMENT" | translate }} </mat-label>
            <mat-select
              [placeholder]="'LABEL.ASSIGNMENT' | translate"
              #singleSelect
              [value]="case.assignment.id"
              [disabled]="true"
            >
              <mat-option [value]="case.assignment.id">
                {{ case.assignment.home_country_data?.name }} to
                {{ case.assignment.host_country_data?.name }}
                ( {{ case.assignment.start_date | date: "dd/MM/yyyy" }} -
                {{ case.assignment.end_date | date: "dd/MM/yyyy" }} )
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-50" *ngIf="order$ | async as order">
          <mat-form-field
            appearance="outline"
            class="w-100"
            [formGroup]="caseForm"
          >
            <mat-label> {{ "LABEL.ORDER" | translate }} </mat-label>
            <mat-select
              [placeholder]="'LABEL.ORDER' | translate"
              #singleSelect
              [value]="order.identity_number"
              [disabled]="true"
            >
              <mat-option [value]="order.identity_number">
                {{ order.identity_number }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row p-t-15 w-100" *ngIf="order$ | async as order">
        <app-order-services-selector
          class="d-block w-100"
          [formGroup]="caseForm"
          [order]="order"
          [submitEvent]="submitForm.asObservable()"
        ></app-order-services-selector>
      </div>
      <div class="row p-t-15 w-100" *ngIf="case.type === CaseType.ADMIN">
        <app-services-selector
          class="d-block w-100"
          [formGroup]="caseForm"
          [submitEvent]="submitForm.asObservable()"
        ></app-services-selector>
      </div>
    </div>
  </form>
  <div class="clear"></div>

  <div class="modal-footer-custom bottom-buttons p-b-15">
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.BUTTONS"
      [repeat]="2"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoadingGeneral">
      <button class="btn gray-bg fl-l modal-button" (click)="close()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>

    <div class="clear"></div>
  </div>
</div>
