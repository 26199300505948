<ngx-smart-modal
  id="confirmDelete"
  #confirmDelete
  identifier="confirmDelete"
  [dismissable]="false"
  (onAnyCloseEvent)="close()"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "GENERALS.CONFIRM-DELETE" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <!-- Modal body -->
  <div class="custom-modal-body">
    <div class="row">
      <div class="w-100 m-b-10">
        {{ "GENERALS.SURE-WANT-DELETE" | translate }}
      </div>
    </div>

    <div class="row">
      <div class="w-100 m-b-10">
        {{ "GENERALS.CURRENT-OFFER" | translate }}:
      </div>
    </div>

    <div class="row" *ngIf="quotationOffers">
      <div class="w-100">
        <div
          class="action-link-gray fw-500"
          [routerLink]="['/service-offers/', quotationOffers.offer_id]"
        >
          {{ quotationOffers.offer_name }}
        </div>

        <div>
          {{ "GENERALS.PRICE" | translate }}:
          <span class="orange">{{
            quotationOffers.price | currency: quotationOffers.currency_code
          }}</span>
        </div>
        <div>
          {{ "GENERALS.QUANTITY" | translate }}:
          <span class="orange">{{ quotationOffers.quantity }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button type="button" class="btn gray-bg modal-button" (click)="close()">
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      type="button"
      class="btn orange-bg modal-button"
      (click)="confirmDeleteQuotationOffers()"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.CONFIRM" | translate }}
    </button>
  </div>
</ngx-smart-modal>
