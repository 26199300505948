import { Component, HostListener, OnInit } from "@angular/core";
import { CurrentUserHelper } from "@helpers/current.user.helper";
import { AuthenticationService } from "../../_services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../../_services/alert.service";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { switchMap, take } from "rxjs";
import { filterNullish } from "src/app/lib";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
})
export class DashboardLayoutComponent implements OnInit {
  innerWidth;
  xs;

  constructor(
    public userHelper: CurrentUserHelper,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private alertService: AlertService,
    public timelineService: TimelineService
  ) {}

  ngOnInit() {
    this.onResize();
  }

  get statusBanner() {
    return this.userHelper.notificationBanner;
  }

  get userIsConfirmed() {
    return this.userHelper.currentStatus.emailConfirmed;
  }

  resendConfirmationMail() {
    this.authenticationService.currentUser
      .pipe(
        filterNullish(),
        take(1),
        switchMap((user) =>
          this.authenticationService.resendConfirmationMail(user.id)
        )
      )
      .subscribe({
        next: () => {
          this.translate
            .get("GENERALS.MAIL-WAS-SENT-SUCCESS")
            .subscribe((data) => {
              this.alertService.success(data);
            });
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.innerWidth = window.innerWidth;

    this.xs = this.innerWidth <= 992;
  }
}
