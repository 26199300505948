import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ResourcePermissionMapperService } from "@api/users/services/resource-permission-mapper.service";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class PermissionsService {
  constructor(
    private http: HttpClient,
    private readonly permissionMapper: ResourcePermissionMapperService
  ) {}

  list(model, modelId, params = {}): Observable<any> {
    return this.http
      .get(`${environment.gateway_endpoint}${model}/${modelId}/permissions`, {
        params,
      })
      .pipe(
        map((response: any) => this.permissionMapper.mapMany(response.result))
      );
  }
}
