import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CaseTaskData, CaseTasksService } from "@api/case-tasks";
import { ReminderData } from "@api/reminders/models/reminder.model";
import { RemindersService } from "@api/reminders/services/reminders.service";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import moment from "moment";
import { Observable, combineLatest, debounceTime, finalize } from "rxjs";
import { CreateComponent } from "../create/create.component";
// import { CreateComponent } from "../create/create.component";

@Component({
  selector: "app-reminders-list",
  templateUrl: "./reminders-list.component.html",
  styleUrls: ["./reminders-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemindersListComponent
  extends ItemsListComponent
  implements OnInit
{
  isXs: boolean;
  searchFilters = [];
  items: ReminderData[];
  isFormattedParams = true;
  @Input() showCreateButton = true;
  @Input() entityType: string;
  @Input() entityId: string;
  @Input() subjectType;
  @Input() subjectId;
  @Input() eligibleUsersEntity;

  @Output() addReminder: EventEmitter<any> = new EventEmitter();
  task$: Observable<CaseTaskData>;

  params: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private remindersService: RemindersService,
    public dialog: MatDialog,
    private caseTasksService: CaseTasksService
  ) {
    super();
  }

  canEdit(reminder: ReminderData) {
    return !(moment(reminder.remind_at).local().diff(moment()) < 0);
  }

  ngOnInit(): void {
    this.watchQueryParams();

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        subject_type: this.subjectType,
        subject_id: this.subjectId,
      },
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoading = true;
    this.changeDetectorRef.markForCheck();
    const params = this.getParams(page);

    this.request_call = this.remindersService
      .list({
        ...params,
        subject_type: this.subjectType,
        subject_id: this.subjectId,
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.searchFilters = data.filters;
        this.processResponse({ result: data });
        this.changeDetectorRef.markForCheck();
      });
  }

  navigateByUrl(page = 1, id?) {
    const params = this.getParams(page);
    this.router.navigate([], {
      queryParams: params,
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateComponent, {
      data: {
        eligibleUsersEntity: this.eligibleUsersEntity,
        subjectType: this.subjectType,
        subjectId: this.subjectId,
        edit: false,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data === "saved") {
        this.navigateByUrl(1);
        this.getItems();
        this.addReminder.emit();
      }
    });
    // });
  }

  protected watchQueryParams() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(debounceTime(500))
      .subscribe(([params, query]) => {
        this.task$ = this.caseTasksService.details(params["task"]);

        const filters = { ...params, ...query };
        super.watchQueryParams(filters);
      });
  }

  public onDelete(id: number | string): void {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          needs_confirmation: true,
          text: `delete reminder`,
        },
      },
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.remindersService.delete(id).subscribe(() => {
          this.navigateByUrl(1);
          this.getItems();
        });
      }
    });
  }

  public onEdit(item: ReminderData) {
    const dialogRef = this.dialog.open(CreateComponent, {
      data: {
        eligibleUsersEntity: this.eligibleUsersEntity,
        subjectType: this.subjectType,
        subjectId: this.subjectId,
        edit: true,
        reminder: item,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.type === "saved") {
        this.navigateByUrl(1);
      }
      this.getItems();
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
