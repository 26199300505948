import { Dictionary } from "./dictionary";
import { OfficeModel } from "./office";
import { User } from "./user";

export class Expat {
  id?: number;
  primary_image_path?: string;
  client_id?: number;
  company_name?: string;
  user_id?: number;
  user_name?: number;
  name?: string;
  email?: string;
  payroll_id?: string;
  adults_number?: number;
  children_number?: number;
  status_id?: number;
  status_name?: string;
  address?: string;
  region?: string;
  city?: string;
  zip_code?: string;
  country_code?: string;
  bank_name?: string;
  swift_code?: string;
  bank_account_number?: string;
  declined_reason?: string;
  main_register?: number;
  phone_prefix?: string;
  phone?: string;
  expat_id?: number;
  first_name?: string;
  last_name?: string;
  created_at: string;
  citizenship?: string;
  department?: string;
  allowed_actions?: {
    delete: boolean;
    update: boolean;
  };
  details?: {
    education?: Dictionary;
    marital_status?: Dictionary;
    office?: OfficeModel;
    marital_status_id?: number;
    education_form_id?: number;
    office_id?: number;
    manager_id?: number | null;
    manager_email?: string;
    manager_name?: string;
    department_name?: string;
    company_position?: string;
    birth_date?: string;
    birth_country?: string;
    birth_city?: string;

    primary_language?: string;
  };
  user?: User;
  department_name: string;
  primary_citizenship?: {
    country: string;
  };
}
