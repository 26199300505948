import { Injectable } from "@angular/core";
import { Dictionary } from "../../../_models/dictionary";

@Injectable({
  providedIn: "root",
})
export class DictionaryMapperService {
  provide(input: Dictionary) {
    return {
      ...input,
    };
  }

  mapOne({ ...input }: any): Dictionary {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): Dictionary[] {
    return input.map((i) => this.mapOne(i));
  }
}
