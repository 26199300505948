import { EntityUser } from "src/app/_models/entity.user";
import { ProviderData } from "src/app/_models/provider";

export interface CompanyData extends ProviderData {
  id: number;
  primary_image_path: string;
  country_code: string;
  region: string;
  city: string;
  address: string;
  company_tax_number: string;
  zip_code: string;
  company_name: string;
  slug: string;
  users: EntityUser[];
  status_id: number;
  status_name: string;
  status_reason: string;
  company_type_id: number;
  company_type_name: string;
  profile_picture: string;
  main_register: number;
  created_at: Date;
  company_legal_name: string;
  no_of_ratings: number;
  hubspot_id: string | null;
  rating: any;
  legal_representative_name: string;
  legal_representative_email: string;
  legal_representative_phone_prefix: string;
  legal_representative_phone: string;
  short_description: string;
  bank_account_number: string;
  bank_name: string;
  swift_code: string;
  currency_id: number;
  currency_code: string;
  professional_association_id: number;
  professional_association_name: string;
  number_of_employees_id: number;
  number_of_employees_name: string;
  invoice_due_days: number;
  google_places_id: string;
  is_managed: boolean;
  service_offers: number;
}
export interface UserEntityData {
  id: number;
  user_id: number;
  entity_id: number;
  company_position: string;
  is_primary: boolean;
  department_id: number;
  department_name: string;
  company: CompanyData;
  status: string;
}

export interface UserData {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  name: string;
  email: string;
  company_position: string;
  entity_id: number;
  entity_type: string;
  is_primary: boolean;
  phone: string;
  phone_prefix: string;
  profile_image: string | null;
  status_id: number;
  admin_access: number;
  auid: number;
  primary_image_path: string;
  status_name: string;
  status_reason: string;
  confirmed_account: boolean;
  last_login: Date;
  created_at: Date;
  updated_at: Date;
  role: string;
  role_name: string;
  role_id: number;
  permissions: Array<string>;
  stripe_account: any;
  entity_details: UserEntityData;
}
