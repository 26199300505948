<nav *ngIf="menu" mat-tab-nav-bar [disableRipple]="true" [tabPanel]="tabPanel">
  <ng-container *ngFor="let item of menu.links">
    <a
      *canView="item.permission"
      class="nav-tab"
      mat-tab-link
      replaceUrl
      [routerLink]="item.link"
      [queryParams]="item.filters"
      [active]="router.url.split('?')[0] === item.link"
    >
      {{ item.label }}
    </a>
  </ng-container>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
