import { AbstractControl, ValidatorFn } from "@angular/forms";

export function dateRangeValidator(min: string, max: string): ValidatorFn {
  return (controls: AbstractControl) => {
    const minValue = controls.get(min).value;
    const maxValue = controls.get(max).value;
    let invalid = false;

    if (minValue && maxValue) {
      invalid = new Date(minValue).valueOf() > new Date(maxValue).valueOf();
    }
    return invalid ? { invalidRange: { minValue, maxValue } } : null;
  };
}
