import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AuthActions } from "@api/authentication";
import { Store } from "@ngrx/store";
import { Observable, catchError, map, of } from "rxjs";
import { ProvidersService } from "src/app/_services/providers.service";

@Injectable({
  providedIn: "root",
})
export class SsoGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private providerService: ProvidersService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = route.queryParamMap.get("token");
    try {
      const data = JSON.parse(atob(token));
      this.store.dispatch(AuthActions.reset());
      return this.providerService.switchToProvider(data).pipe(
        map((res) => {
          this.store.dispatch(
            AuthActions.authorized({ token: res.result.session.token })
          );
          return this.router.createUrlTree(["/dashboard"]);
        }),
        catchError(() => of(this.router.createUrlTree(["/login"])))
      );
    } catch (e) {
      return of(this.router.createUrlTree(["/login"]));
    }
  }
}
