import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { UserData } from "@api/account";
import { ClientsService } from "@api/clients/services";
import { ClientData } from "@modules/clients/models/client.model";
import { PageData } from "@modules/shared/models/page.model";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  debounceTime,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { UsersService } from "src/app/_services/users.service";
import { filterNullish } from "src/app/lib";
import { LoadingTypeEnum } from "./../../../../shared/_enums/loading-type.enum";

@Component({
  selector: "app-client-selector",
  styleUrls: ["./clients-selector.component.scss"],
  templateUrl: "./clients-selector.component.html",
})
export class ClientsSelectorComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();

  @Input()
  required = false;

  caseForm: UntypedFormGroup;
  clientsSearchForm: UntypedFormControl;
  filteredClients$: BehaviorSubject<ClientData[]> = new BehaviorSubject([]);
  totalClients = 0;
  clientsState = [];
  params = {
    search_text: "",
    page: 1,
    per_page: 25,
  };
  isLoadingClient: boolean = false;
  isLoading: boolean = false;
  @ViewChild("singleSelect", { static: false }) singleSelect: MatSelect;

  currentUser$: Observable<UserData>;

  clientFormControl: UntypedFormControl;

  @Input() submitEvent: Observable<void>;

  caseClient: ClientData;

  constructor(
    private readonly userService: UsersService,
    private controlContainer: ControlContainer,
    private readonly clientsService: ClientsService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    const validators = this.required ? Validators.required : [];

    this.clientFormControl = new UntypedFormControl("", validators);
    this.clientsSearchForm = new UntypedFormControl("");

    this.currentUser$ = this.userService.getCurrentUser();
    this.submitEvent.subscribe(() => {
      if (!this.clientFormControl.valid) {
        this.clientFormControl.setErrors({ required: true });
        this.clientFormControl.markAsTouched();
      }
    });

    this.caseForm = <UntypedFormGroup>this.controlContainer.control;

    this.caseForm.setControl(
      "client_name",
      new UntypedFormControl("", validators)
    );
    this.clientFormControl.disable();

    if (this.caseForm.value.client_id) {
      this.isLoadingClient = true;

      this.currentUser$
        .pipe(
          switchMap((user) =>
            this.clientsService.show(
              user.entity_details.entity_id,
              this.caseForm.value.client_id
            )
          )
        )
        .pipe(take(1))
        .subscribe((client) => {
          this.caseClient = client;
          this.getData();
        });
    } else {
      this.getData();
    }

    if (this.caseForm.get("client_id").value) {
      this.filteredClients$
        .pipe(
          // Behaiviorsubject will emit [] on first take
          take(2),
          map((clients) =>
            clients.find(
              ({ client_id }) =>
                client_id == this.caseForm.get("client_id").value
            )
          ),
          filterNullish()
        )
        .subscribe((client) => {
          this.clientFormControl.setValue(client);
        });
    }

    this.clientsSearchForm.valueChanges
      .pipe(startWith(""), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe({
        next: (searchInput) => {
          if (
            this.params.search_text === searchInput ||
            (this.params.search_text && !searchInput)
          ) {
            return;
          }

          this.params.page = 1;
          this.params.search_text = searchInput;

          this.getData();
        },
      });

    this.clientFormControl.valueChanges.subscribe((client: ClientData) => {
      this.caseForm.patchValue({
        client_name: client.company_name,
        client_id: client.client_id,
      });
    });
  }

  getData(triggeredByScroll: boolean = false): void {
    this.isLoading = true;

    this.currentUser$
      .pipe(
        switchMap((user) =>
          this.clientsService.list(user.entity_details.entity_id, this.params)
        ),
        tap((data: PageData<ClientData>) => {
          this.clientFormControl.enable({ emitEvent: false });

          if (this.singleSelect) {
            // this stopes the scroll from returning to the first option when new options are added
            this.singleSelect._keyManager.setActiveItem(
              this.clientsState.length
            );
          }

          if (triggeredByScroll) {
            this.clientsState = [...this.clientsState, ...data.items];
          } else {
            this.clientsState = data.items;
          }

          if (this.caseClient) {
            const item = this.clientsState.find(
              (client) => client.client_id === this.caseClient.id
            );

            if (!item) {
              this.clientsState = [
                ...this.clientsState,
                { ...this.caseClient, client_id: this.caseClient.id },
              ];
            }
          }

          this.clientsState = this.clientsState.filter((item, index) => {
            return (
              index ===
              this.clientsState.findIndex(
                (client) => item.client_id === client.client_id
              )
            );
          });

          this.totalClients = data.total;
          this.filteredClients$.next(this.clientsState);
        })
      )
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          this.isLoadingClient = false;
        })
      )
      .subscribe();
  }

  getNextBatch(): void {
    this.params.page += 1;
    this.getData(true);
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
