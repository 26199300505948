import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { Country } from "src/app/_models/country";
import { TranslateService } from "@ngx-translate/core";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TemplateService, TemplateData } from "@api/templates";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-template-alert",
  templateUrl: "./template-alert.component.html",
  styleUrls: ["./template-alert.component.scss"],
})
export class TemplateAlertComponent implements OnInit {
  public form: FormGroup;

  public countries: Country[];

  countriesField = new FormControl([]);

  public selectedCountries: any[] = [];

  public countryMultiFilterCtrl: FormControl = new FormControl();

  public filteredCountryMulti: ReplaySubject<Country[]> = new ReplaySubject<
    Country[]
  >(1);

  protected _onDestroy = new Subject<void>();

  public fileUploadControl = new FileUploadControl();

  public isLoading = false;

  uploadedFiles = { file: null, name: null, status: null };

  constructor(
    public templateService: TemplateService,
    public dialogRef: MatDialogRef<TemplateAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TemplateData,
    private fb: FormBuilder,
    private translate: TranslateService,
    private dictionariesService: DictionariesService,
    protected alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.setupTemplateForm();
    this.getCountries();

    this.countryMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountryMulti();
      });

    this.countryMultiFilterCtrl.setValue("");
  }

  private setupTemplateForm(): void {
    this.form = this.fb.group({
      id: [""],
      name: ["", Validators.required],
      description: [""],
      status: "draft",
      document_id: [""],
      archived: [false],
      countries: [],
      elements: [],
    });
  }

  private fillTemplateForm(): void {
    if (this.data) {
      this.form.patchValue(this.data);

      this.data.countries.forEach((country) => {
        this.selectedCountries.push(country.code);
      });

      this.uploadedFiles.file = this.data.document.files;
    }
  }

  private getCountries() {
    this.dictionariesService.getCountryList().subscribe((countries) => {
      this.countries = countries.result.map((country: Country) => ({
        name: country.name,
        code: country.country_code,
      }));

      this.fillTemplateForm();
    });
  }

  submitTemplate(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.isLoading = true;
    const obj = this.uploadedFiles;
    const isUploaded =
      obj.file && obj.file.length > 0 && obj.file[0]["file_path"];

    if (
      (!obj.file && !this.data) ||
      (obj.file && !this.data) ||
      (obj.file && !isUploaded && this.data)
    ) {
      this.templateService.uploadFiles(obj).subscribe(
        (response) => {
          this.form.get("document_id").setValue(response.id);
          this.form.get("elements").setValue([]);
          this.form.get("status").setValue("draft");

          this.form.value.countries = [];

          this.selectedCountries.forEach((countryCode) => {
            this.form.value.countries.push({ code: countryCode });
          });

          this.createUpdate(this.form.value, this.data);
        },
        (error) => {
          if (error.error.errors) {
            const errors = error.error.errors;

            for (const [input, messages] of Object.entries(errors)) {
              errors[input] = errors[input].map((message) => {
                return this.translate.instant(message);
              });
            }

            this.alertService.errors(errors);
            this.isLoading = false;
          }
        }
      );
    }

    if (this.data && isUploaded) {
      this.createUpdate(this.form.value, this.data);
    }
  }

  createUpdate(form: TemplateData, data: any) {
    this.form.value.countries = [];

    this.selectedCountries.forEach((countryCode) => {
      this.form.value.countries.push({ code: countryCode });
    });

    const message = this.data
      ? this.translate.instant("TEMPLATE.UPDATED")
      : this.translate.instant("TEMPLATE.CREATED");

    this.templateService
      .createUpdate(form, data, data ? data.id : "")
      .subscribe(
        () => {
          this.templateService.listenForChanges.next("");

          this.alertService.success(message);
          this.isLoading = false;
          this.closeDialog();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
            this.isLoading = false;
            this.closeDialog();
          }
        }
      );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onCountryRemoved(country: string): void {
    const chosenCountries = this.countriesField.value as string[];
    this.removeFirst(chosenCountries, country);
    this.countriesField.setValue(chosenCountries);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  protected filterCountryMulti(): void {
    if (!this.countries) {
      return;
    }

    let search = this.countryMultiFilterCtrl.value;

    if (!search) {
      this.filteredCountryMulti.next(this.countries.slice());

      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountryMulti.next(
      this.countries.filter(
        (country) => country.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
