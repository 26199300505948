import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";

// use a declare to allow the compiler find the gtag function
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  private subscription: Subscription;

  constructor(private router: Router) {}

  public subscribe() {
    if (!this.subscription) {
      this.subscription = this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          try {
            gtag("config", environment.google_tracking_id, {
              page_title: `Title: ${e.urlAfterRedirects}`,
              page_path: `${e.urlAfterRedirects}`,
            });
          } catch {
            /* empty */
          }
        }
      });
    }
  }

  public unsubscribe() {
    if (this.subscription) {
      // --- clear our observable subscription
      this.subscription.unsubscribe();
    }
  }
}
