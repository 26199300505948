import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CurrencyData } from "@api/expenses/models/currency.model";
import { QuoteModel } from "@modules/quotes/models/quote.model";
import { combineLatest, Observable } from "rxjs";
import { shareReplay, switchMap, take } from "rxjs/operators";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { UsersService } from "src/app/_services/users.service";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-create-order",
  templateUrl: "./create-order.component.html",
  styleUrls: ["./create-order.component.scss"],
})
export class CreateOrderComponent implements OnInit {
  isLoaded = true;
  quotes: QuoteModel[];

  orderForm = new UntypedFormGroup({
    currency_id: new UntypedFormControl(null),
    currency_code: new UntypedFormControl(null),
    quotes: new UntypedFormArray([]),
  });

  total;

  currencies$: Observable<CurrencyData[]>;
  user$: Observable<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { quotes: QuoteModel[]; client_id: number },
    private dialogRef: MatDialogRef<CreateOrderComponent>,
    private dictionary: DictionariesService,
    private service: QuotesService,
    private userService: UsersService,
    private router: Router,
    protected alertService: AlertService,
    protected translate: TranslateService
  ) {}

  ngOnInit() {
    this.user$ = this.userService.getCurrentUser().pipe(shareReplay(1));
    this.quotes = [...this.data.quotes];
    this.currencies$ = this.dictionary.getCurrencies();
    combineLatest([
      this.orderForm.get("currency_id").valueChanges,
      this.currencies$,
    ]).subscribe(([val, currencies]) => {
      // tslint:disable-next-line:no-shadowed-variable
      const curr = currencies.find((curr) => curr.id === val);
      this.orderForm.get("currency_code").setValue(curr.code);
    });
    this.quotes.forEach((quote) => {
      (this.orderForm.get("quotes") as UntypedFormArray).push(
        new UntypedFormGroup({
          quote: new UntypedFormControl(quote),
          id: new UntypedFormControl(quote.id, Validators.required),
          quantity: new UntypedFormControl(quote.quantity || 1),
        })
      );
    });

    this.total = 0;

    this.quotes.forEach((quote) => {
      this.total += (quote.quantity || 1) * quote.unit_price;
    });

    this.orderForm.get("quotes").valueChanges.subscribe((quotes) => {
      this.total = 0;

      quotes.forEach((quote) => {
        this.total += quote.quantity * quote.quote.unit_price;
      });
    });
  }

  submit() {
    this.isLoaded = false;
    this.user$
      .pipe(
        take(1),
        switchMap(({ entity_id }) =>
          this.service.placeOrder({
            client_id: this.data.client_id,
            provider_id: entity_id,
            ...this.orderForm.getRawValue(),
          })
        )
      )
      .subscribe({
        next: (orders) => {
          this.isLoaded = false;
          this.router.navigate(["/orders", orders[0]?.identity_number]).then();
          this.alertService.success(
            this.translate.instant("Order placed successfully")
          );
          this.dialogRef.close(orders);
        },
        error: (error) => {
          this.isLoaded = false;

          const values: string[] = Object.values(error?.error?.errors);

          if (values.every((val) => val[0] === values[0][0])) {
            this.alertService.stringError(values[0][0]);

            return;
          }

          this.alertService.errors(error.error.errors);
        },
      });
  }
  close() {
    this.dialogRef.close();
  }
}
