import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { BillingRoutingModule } from "./billing-routing.module";
import { BillingComponent } from "./components/billing/billing.component";
import { SharedModule } from "@modules/shared/shared.module";
import { BillingSharedModule } from "./billing-shared/billing-shared.module";
import { PlansComponent } from "./components/plans/plans.component";
import { BillingInfoComponent } from "./components/billing-info/billing-info.component";

@NgModule({
  declarations: [BillingComponent, PlansComponent, BillingInfoComponent],
  imports: [
    CommonModule,
    BillingRoutingModule,
    SharedModule,
    BillingSharedModule,
  ],
})
export class BillingModule {}
