<form [formGroup]="caseForm">
  <mat-form-field class="w-full">
    <mat-label>
      {{ "LABEL.NAME" | translate }}
    </mat-label>
    <input matInput formControlName="name" />
    <mat-error formControlError="name"></mat-error>
  </mat-form-field>
  <app-services-selector
    class="block w-full"
    [formGroup]="caseForm"
    [submitEvent]="submitForm.asObservable()"
  ></app-services-selector>
  <app-client-selector
    class="w-full"
    [formGroup]="caseForm"
    [submitEvent]="submitForm.asObservable()"
  ></app-client-selector>
</form>
