import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentService } from "./services/documents.service";
import { DocumentMapperService } from "./services/document-mapper.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [DocumentService, DocumentMapperService],
})
export class DocumentsModule {}
