<form [formGroup]="form" *ngIf="form">
  <div class="white-box-wrapper p-0">
    <div class="white-box-row">
      <div class="white-box-header p-20">
        <div class="medium-title modal-title-custom fl-l">
          {{ "EXPATS.CITIZENSHIPS" | translate }}
        </div>
      </div>
      <div class="clear"></div>
      <div class="white-box-body p-20">
        <div class="row">
          <div class="w-50 p-0">
            <app-date-range-calendar
              [singleDate]="form.get('obtained_date').value"
              [maxDate]="maxDate"
              (change)="setDate($event, 'obtained_date')"
              [showLabel]="true"
              [placeholder]="'EXPATS.OBTAINED-DATE' | translate"
            ></app-date-range-calendar>
          </div>
          <div class="w-50 p-0">
            <app-date-range-calendar
              [singleDate]="form.get('revoked_date').value"
              [maxDate]="maxDate"
              (change)="setDate($event, 'revoked_date')"
              [placeholder]="'EXPATS.REVOKED-DATE' | translate"
            ></app-date-range-calendar>
            <div class="clear"></div>
          </div>
          <div class="w-50 p-0">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select
                formControlName="country"
                [placeholder]="'LABEL.COUNTRY' | translate"
                #singleSelect
              >
                <mat-option>
                  <ngx-mat-select-search
                    [placeholderLabel]="'LABEL.COUNTRY' | translate"
                    [noEntriesFoundLabel]="
                      'GENERALS.NO-ITEMS-DISPLAY' | translate
                    "
                    [formControl]="countryFilterControl"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let country of filteredCountries"
                  [value]="country.country_code"
                >
                  {{ country.name }}
                </mat-option>
              </mat-select>
              <mat-error>
                <app-form-errors [control]="form.get('country')">
                </app-form-errors>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="w-50 m-t-10 m-b-10">
            <span class="m-r-10">
              <mat-slide-toggle formControlName="status_id"></mat-slide-toggle>
            </span>
            <span>{{ "EXPATS.ACTIVE-CITIZENSHIP" | translate }}</span>
          </div>
        </div>
        <div class="row">
          <div class="w-50 m-t-15 m-b-15">
            <span class="m-r-10">
              <mat-slide-toggle formControlName="is_primary"></mat-slide-toggle>
            </span>
            <span>{{ "EXPATS.PRIMARY-CITIZENSHIP" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer-custom d-flex justify-content-end p-20">
        <button class="btn gray-bg fl-l modal-button" (click)="closeModal()">
          <mat-icon svgIcon="cancel"></mat-icon>
          {{ "GENERALS.CANCEL" | translate }}
        </button>
        <button class="btn orange-bg fl-r m-l-20 modal-button" (click)="save()">
          <mat-icon svgIcon="save"></mat-icon>
          {{ "GENERALS.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
