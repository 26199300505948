<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.TABLE"
  ></app-loader-spinner-general>
</ng-container>

<div class="details-body-wrapper" *ngIf="!isLoading">
  <!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
  <div windowScrollDashboardBodyTop class="dashboard-body-top">
    <!-- right -->
    <div class="nowrap-btn-row">
      <div class="btn white-bg" (click)="openDocModal()">
        <i class="icon-upload-cloud"></i> {{ "DOCUMENTS.UPLOAD" | translate }}
      </div>
    </div>
    <!-- end right -->
  </div>
  <!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

  <div class="clear"></div>

  <!-- table -->
  <div class="table-wrapper">
    <ng-container *ngIf="!isXs; else responsiveTable">
      <div class="table-responsive">
        <table>
          <thead>
            <tr>
              <th>{{ "DOCUMENTS.ID" | translate }}</th>
              <th>{{ "DOCUMENTS.NAME" | translate }}</th>
              <th>
                {{ "DOCUMENTS.UPLOADED_BY" | translate }}
              </th>
              <th>
                {{ "GENERALS.STATUS" | translate }}
              </th>
              <th class="ta-r"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of items | sortBy: 'desc':'created_at';
                let i = index
              "
            >
              <td class="gray">{{ item.id }}</td>
              <td class="gray">{{ item.name }}</td>
              <td class="gray">
                {{ item.user_name }}
              </td>
              <td>
                <div class="status-label-{{ item.status }}">
                  {{ item.status }}
                </div>
              </td>

              <!-- table cel with action -->
              <td class="ta-r">
                <div toggle-action-table class="table-action-wrapper">
                  <i class="icon-dot-3"></i>

                  <div class="table-action">
                    <ul>
                      <li>
                        <a href="{{ item.file }}" target="_blank">
                          {{ "GENERALS.VIEW-DOCUMENT" | translate }}
                        </a>
                      </li>
                      <li
                        *ngIf="item.status !== 'archived'"
                        (click)="archiveDoc(item.entity_id, item.id)"
                      >
                        {{ "GENERALS.ARCHIVE" | translate }}
                      </li>
                      <!-- <li (click)="deleteDocument(item.id)">
                     {{ 'GENERALS.DELETE' | translate }}
                   </li>            -->
                    </ul>
                  </div>
                </div>
              </td>
              <!--end table cel with action -->
            </tr>

            <tr *ngIf="items?.length === 0">
              <td colspan="6" class="gray">
                {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-template #responsiveTable>
      <!-- responsive table -->

      <div
        class="resposive-table-wrapper"
        *ngFor="let item of items | sortBy: 'desc':'created_at'; let i = index"
      >
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "DOCUMENTS.ID" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.id }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "DOCUMENTS.NAME" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.name }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "DOCUMENTS.UPLOADED_BY" | translate }}
          </div>
          <div class="responsive-table-data-item">{{ item.user_name }}</div>
        </div>
        <div class="responsive-table-row">
          <div class="responsive-table-header-item">
            {{ "GENERALS.STATUS" | translate }}
          </div>
          <div class="responsive-table-data-item">
            <span class="status-label-{{ item.status }}">{{
              item.status
            }}</span>
          </div>
        </div>
        <div class="responsive-table-action">
          <span>
            <a href href="{{ item.file }}">{{
              "GENERALS.VIEW-DOCUMENT" | translate
            }}</a></span
          >
          <span
            *ngIf="item.status !== 'archived'"
            (click)="archiveDoc(item.entity_id, item.id)"
            >{{ "GENERALS.ARCHIVE" | translate }}</span
          >
          <!-- <span (click)="deleteDocument(item.id)">
            {{ 'GENERALS.DELETE' | translate }}
        </span>  -->
        </div>
      </div>
      <!-- end responsive table -->
    </ng-template>
  </div>
  <!-- end table -->

  <!-- add doc modal -->
  <app-add-doc
    [entityType]="'user-agreements'"
    [entityId]="entityId"
    [docType]="'user-agreements'"
    (changeDoc)="getUserAgreements()"
  ></app-add-doc>
  <!-- add doc modal -->
</div>
