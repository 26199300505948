<div class="sidebar__card-body__subtasks-list mt-3 position-relative">
  <ng-container *ngFor="let item of tasks">
    <div
      class="sidebar__card-body__subtasks-holder row align-items-center position-relative mx-0 px-0 py-3"
      [class.faded-timeline]="!item.isCurrentMonthSubTaskActive"
    >
      <div
        class="sidebar__card-body__subtasks-timeline"
        appSidebarTimeline
        [task]="item"
        [ngClass]="item.activeStatus"
        [class.next-month-overlap]="item.isNextMonthOverlapped"
        [class.previous-month-overlap]="item.isPreviousMonthOverlapped"
        *ngIf="item.isCurrentMonthSubTaskActive"
      >
        {{ item.completion_rate | percent }}
        <ng-container
          *ngIf="item.endDateDay !== 1 && item.startDateDay !== item.endDateDay"
        >
          | {{ item.expireIn }} days
        </ng-container>
      </div>

      <div
        class="grid-horizontal-border"
        appSidebarWidth
        [currentMonthDays]="timelineService.currentMonthDays.length"
      ></div>
      <div
        class="sidebar__card-body__subtasks-holder-status col-sm-1 me-2"
        [ngClass]="item.activeStatus"
      ></div>
      <div class="sidebar__card-body__subtasks-holder-text col-sm ps-0">
        <a [routerLink]="['/', 'cases', item.expat_case_id, 'task', item.id]">
          {{ item.name }}
        </a>
      </div>
    </div>
  </ng-container>
</div>
