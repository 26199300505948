import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { Permission } from "@modules/authorization/models/permission.model";

@Directive({
  selector: "[canView]",
})
export class CanViewDirective {
  @Input()
  set canView(val: Permission) {
    if (val) {
      this.authService
        .$can(val.action, val.resource, val.secondaryResource)
        .subscribe((show: boolean) => {
          if (show) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        });
    } else {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthorizationService
  ) {}
}
