import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { PageData } from "@modules/shared/models/page.model";
import { map, Observable } from "rxjs";
import { ReminderData } from "../models/reminder.model";
import { RemindersMapperService } from "./reminders-mapper.service";

@Injectable({
  providedIn: "root",
})
export class RemindersService {
  constructor(
    private http: HttpClient,
    private remindersMapper: RemindersMapperService
  ) {}

  public list(params = {}): Observable<PageData<ReminderData>> {
    return this.http
      .get<any>(environment.gateway_endpoint + `reminders`, {
        params: params,
      })
      .pipe(
        map((response) => {
          const { items, ...pageData } = response;

          return {
            ...pageData,
            items: this.remindersMapper.mapMany(items),
          } as PageData<ReminderData>;
        })
      );
  }

  public create(data: any): Observable<ReminderData> {
    return this.http.post<ReminderData>(
      environment.gateway_endpoint + `reminders`,
      data
    );
  }

  public edit(id: number | string, data: any): Observable<ReminderData> {
    return this.http.put<ReminderData>(
      environment.gateway_endpoint + `reminders/` + id,
      data
    );
  }

  public delete(id: number | string): Observable<any> {
    return this.http.delete(environment.gateway_endpoint + "reminders/" + id);
  }
}
