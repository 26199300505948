<div class="set-reminder-wrapper w-100 p-0">
  <input
    class="checkbox-switch m-l-10"
    type="checkbox"
    [(ngModel)]="setReminder"
    (ngModelChange)="handleSetReminder($event)"
    [disabled]="!enabled"
  />
  Remind me
  <mat-form-field appearance="outline">
    <mat-select
      [formControl]="reminderControl"
      disableOptionCentering
      panelClass="currency-panel"
      [placeholder]="'GENERALS.SELECT-PERIOD' | translate"
    >
      <mat-option *ngFor="let option of options" [value]="option.value">{{
        option.label
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error *ngIf="documentForm.errors?.invalidReminderPeriod && setReminder">
    {{
      "GENERALS.AUTOMATIC-REMINDER-ERROR"
        | translate: { amount: reminderControl.value }
    }}
    {{ reminderControl.value > 1 ? "months" : "month" }}</mat-error
  >
  before the document expires
</div>
