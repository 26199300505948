<div class="container">
  <div class="row mb-5">
    <div class="col-6">
      <div class="d-flex">
        <button
          class="btn gray-bg pl-5 pr-5 modal-button cancel"
          (click)="openDialog('cancel')"
        >
          <mat-icon svgIcon="cancel"></mat-icon>
          {{ "TEMPLATE.CANCEL" | translate }}
        </button>
        <button class="btn white-bg ml-1 pl-5 pr-5 disabled" [disabled]="true">
          {{ "TEMPLATE.PREVIOUS" | translate }}
        </button>
      </div>
    </div>
    <div class="col-6 text-right">
      <div class="d-flex justify-content-end">
        <button class="btn white-bg pl-5 pr-5" (click)="templatePreview()">
          <ng-container *ngIf="!templateService.isTemplatePreview">
            {{ "TEMPLATE.PREVIEW" | translate }}
          </ng-container>
          <ng-container *ngIf="templateService.isTemplatePreview">
            {{ "GENERALS.CLOSE" | translate }}
          </ng-container>
        </button>
        <button
          class="btn orange-bg ml-1 pl-5 pr-5 modal-button"
          (click)="openDialog('save')"
        >
          <mat-icon svgIcon="save"></mat-icon>
          {{ "TEMPLATE.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div
      class="sidebar col-12 col-md-3"
      [class.d-none]="templateService.isTemplatePreview"
    >
      <app-template-sidebar-fields></app-template-sidebar-fields>
    </div>
    <div class="template-preview col-12 col-md-9">
      <app-template-preview
        [isReadonly]="this.templateService.isTemplatePreview"
      ></app-template-preview>
    </div>
  </div>
</div>
