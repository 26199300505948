import { Injectable } from "@angular/core";
import { AssignmentData } from "../models/assignment.model";

@Injectable({
  providedIn: "root",
})
export class AssignmentMapperService {
  provide(input: AssignmentData) {
    return {
      ...input,
    };
  }

  mapOne({ ...input }: any): AssignmentData {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): AssignmentData[] {
    return input.map((i) => this.mapOne(i));
  }
}
