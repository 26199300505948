<div class="details-2c h-100">
  <!-- left -->
  <div class="h-100" *ngIf="user">
    <!-- <div><mat-icon>edit</mat-icon> <span> EDIT </span></div> -->
    <div class="small-box h-100">
      <div class="small-box-header-img" (click)="openDocModal()">
        <img
          (error)="
            user.primary_image_path =
              '../assets/images/profile-pics-placeholder.jpg'
          "
          [src]="
            user.primary_image_path
              ? user.primary_image_path
              : '../assets/images/profile-pics-placeholder.jpg'
          "
          alt="img"
          class="profile-pic"
        />
        <div class="edit-trigger">{{ "GENERALS.CHANGE-PIC" | translate }}</div>
      </div>

      <div class="small-box-details border-0">
        <div class="medium-title m-r-20 mb-0" title="{{ user.name }}">
          {{ user.name }}
        </div>

        <div class="status-title">
          {{ "GENERALS.STATUS-TITLE" | translate }}
        </div>

        <div *ngIf="!readonly" class="small-box-status mt-0">
          <ng-container *ngIf="entity_type === 'expat'">
            <div
              class=" w-100 float-none text-center entity_status-label-{{
                user.user.status_id
              }}"
            >
              {{ user.user.status | uppercase }}
            </div>
          </ng-container>

          <ng-container *ngIf="entity_type !== 'expat'">
            <div
              class=" w-100 text-center entity_status-label-{{
                user.status_id
              }}"
            >
              {{ user.status_name | uppercase }}
            </div>
          </ng-container>
        </div>

        <ng-container>
          <div class="fw-500" *ngIf="user.declined_reason || ''">
            <span class="gray block-span"
              >{{ "GENERALS.DECLINE-MESSAGE" | translate }}:</span
            >
            {{ user.declined_reason }}
          </div>
        </ng-container>

        <div class="fw-500" *ngIf="user && user.created_at">
          <span class="gray block-span"
            >{{ "PORTAL.REGISTRED-SINCE" | translate }}:</span
          >
          {{ user.created_at | date: "dd/MM/yyyy" }}
        </div>
      </div>
      <div class="small-box-list">
        <ul *ngIf="changeStatusPermission">
          <ng-container *ngFor="let status of user.statuses">
            <li>
              <div (click)="handleEvent(status)" class="btn-small orange-bg">
                {{ status.text | translate }}
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <!-- end left -->

  <!-- end 2 col details -->
</div>

<app-add-profile-picture
  [entityType]="entity_type"
  [entityId]="user?.id"
  [title]="'PORTAL.UPLOAD-PIC'"
  (change)="handleEvent('newProfilePicture')"
></app-add-profile-picture>
