<div class="auth-wrapper">
  <!-- language selection -->
  <div class="auth-lang-selector">
    <app-translate></app-translate>
  </div>
  <!-- end language selection -->

  <div class="auth-left-wrapper">
    <div class="auth-left-inner">
      <div class="logo">
        <a [href]="environment.marketplace + '/home'"
          ><img ngSrc="assets/images/logo.svg" alt="" height="250" width="65"
        /></a>
      </div>
      <div class="eu-funds-img flex justify-items-start">
        <img
          ngSrc="assets/images/eu/eu_fedr.svg"
          alt=""
          height="62"
          width="62"
        />
        <img ngSrc="assets/images/eu/gr.svg" alt="" height="62" width="62" />
        <img ngSrc="assets/images/eu/regio.png" alt="" height="62" width="62" />
        <img ngSrc="assets/images/eu/is.svg" alt="" height="62" width="62" />
      </div>
      <div class="auth-left-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="auth-right-wrapper">
    <div class="auth-right-inner">
      <p class="auth-right-title">{{ "AUTH.RIGHT-TITLE" | translate }}</p>
      <div class="auth-right-line"></div>
      <ul class="auth-right-desc">
        <li>
          {{ "AUTH.RIGHT-DESCRIPTION-BULLET-1" | translate }}
        </li>
        <li>
          {{ "AUTH.RIGHT-DESCRIPTION-BULLET-2" | translate }}
        </li>
        <li>
          {{ "AUTH.RIGHT-DESCRIPTION-BULLET-3" | translate }}
        </li>
      </ul>
      <p class="auth-right-desc">
        {{ "AUTH.RIGHT-BOTTOM-DESCRIPTION" | translate }}
      </p>
    </div>
  </div>
</div>
