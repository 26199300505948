<ng-container *ngIf="isLoading[CardType.REVENUE]">
  <app-loader-spinner-general
    [loadingType]="LoadingType.CHART"
  ></app-loader-spinner-general>
</ng-container>
<app-report-card
  [ngClass]="{ 'd-none': isLoading[CardType.REVENUE] }"
  [cardType]="CardType.REVENUE"
  (triggerLoading)="setLoading(CardType.REVENUE, $event)"
></app-report-card>

<ng-container *ngIf="isLoading[CardType.COSTS]">
  <app-loader-spinner-general
    [loadingType]="LoadingType.CHART"
  ></app-loader-spinner-general>
</ng-container>
<app-report-card
  [ngClass]="{ 'd-none': isLoading[CardType.COSTS] }"
  [cardType]="CardType.COSTS"
  (triggerLoading)="setLoading(CardType.COSTS, $event)"
></app-report-card>

<ng-container *ngIf="isLoading[CardType.PROFIT]">
  <app-loader-spinner-general
    [loadingType]="LoadingType.CHART"
  ></app-loader-spinner-general>
</ng-container>
<app-report-card
  [ngClass]="{ 'd-none': isLoading[CardType.PROFIT] }"
  [cardType]="CardType.PROFIT"
  (triggerLoading)="setLoading(CardType.PROFIT, $event)"
></app-report-card>
