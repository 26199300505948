<div class="custom-modal-wrapper place-order p-0">
  <title mat-dialog-title class="modal-title-custom p-20">
    {{ "PAGES.QUOTES.CREATE_ORDER_TITLE" | translate }}
  </title>
  <mat-dialog-content class="p-20">
    <form [formGroup]="orderForm">
      <div class="modal-body-custom p-20 m-0">
        <mat-list>
          <div class="d-flex service-head">
            <b class="service-name">{{
              "PAGES.QUOTES.SERVICE-NAME" | translate
            }}</b>
            <b class="service-price">{{
              "PAGES.QUOTES.SERVICE-PRICE" | translate
            }}</b>
            <b class="service-qty">{{
              "PAGES.QUOTES.SERVICE-QTY" | translate
            }}</b>
            <b class="service-total">{{ "PAGES.QUOTES.TOTAL" | translate }}</b>
          </div>
          <ng-container
            *ngFor="
              let formGroup of orderForm?.get('quotes')['controls'];
              last as l
            "
          >
            <app-quote-order-item [quoteForm]="formGroup">
            </app-quote-order-item>
            <mat-divider *ngIf="!l"></mat-divider>
          </ng-container>
          <div class="d-flex justify-content-end total-wrapper">
            <div class="text-center total">
              <b>{{
                total
                  | currency
                    : this.orderForm.get("quotes").value[0]?.quote.currency_code
              }}</b>
            </div>
          </div>
        </mat-list>
      </div>

      <div class="modal-footer-custom bottom-buttons p-20 m-0">
        <button class="btn gray-bg modal-button" (click)="close()">
          <mat-icon svgIcon="cancel"></mat-icon>
          {{ "GENERALS.CANCEL" | translate }}
        </button>
        <button
          [disabled]="!isLoaded"
          class="modal-button orange-bg btn"
          (click)="submit()"
        >
          <mat-icon svgIcon="save"></mat-icon>
          {{ "GENERALS.SAVE" | translate }}
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
