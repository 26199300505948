import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})
export class DeleteModalComponent implements OnInit {
  public message!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<DeleteModalComponent>
  ) {}

  ngOnInit() {
    this.setupMessage();
  }

  public delete(isDeleting: boolean) {
    this.dialogRef.close({ isDeleted: isDeleting });
  }

  private setupMessage() {
    this.message = this.data.message;
  }
}
