export class AccountDetails {
  links: Array<any>;

  constructor() {
    this.links = [
      {
        link: "/settings",
        filters: {},
        label: "Details",
        page: "details",
      },
      {
        link: "/settings/milestones",
        filters: {},
        label: "Milestones",
        page: "milestone",
      },
      {
        link: "/settings/xpath-terms-and-conditions",
        filters: {},
        label: "Xpath T&Cs",
        page: "xpath-tc",
      },
      {
        link: "/settings/policies",
        filters: {},
        label: "Policies",
        page: "policies",
      },
      {
        link: "/settings/history",
        filters: {},
        label: "History",
        page: "history",
      },
    ];
  }
}
