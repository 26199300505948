import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { DocumentsService } from "src/app/_services/documents.service";
import { AlertService } from "src/app/_services/alert.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "delete-order-invoice",
  templateUrl: "./delete-order-invoice.component.html",
  styleUrls: ["./delete-order-invoice.component.scss"],
})
export class DeleteOrderInvoiceComponent {
  file_id;
  @Input() set fileId(value) {
    if (value) {
      this.file_id = value;
      this.orderInvoiceObj.file_id = value;
    }
  }
  @Output() confirmDeleteOrderInvoiceEvent: EventEmitter<any> =
    new EventEmitter();

  setModalOpen = true;
  isCanceled = false;
  isLoading = false;
  orderInvoiceObj = { file_id: null, reason: null };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    protected service: DocumentsService,
    private alertService: AlertService
  ) {
    this.setModalOpen = false;
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModalOpen = false;
  }

  cancelModal() {
    this.ngxSmartModalService
      .getModal("delete-order-invoice-" + this.file_id)
      .close();
    this.isCanceled = true;
  }

  confirmDelete() {
    if (this.orderInvoiceObj.reason) {
      this.isLoading = true;
      this.service.confirmDeleteOrderInvoice(this.orderInvoiceObj).subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.confirmDeleteOrderInvoiceEvent.emit();
          this.isLoading = false;

          this.cancelModal();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoading = false;
        }
      );
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
