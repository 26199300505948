import { Component, OnInit, OnDestroy } from "@angular/core";
import { environment } from "@environment/environment";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["auth-layout.component.scss"],
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  ngOnInit() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("auth");
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("auth");
  }

  protected readonly environment = environment;
}
