import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DocumentService } from "@api/documents/services/documents.service";
import { finalize, take } from "rxjs/operators";

@Component({
  selector: "app-user-selector",
  templateUrl: "./user-selector.component.html",
  styleUrls: ["./user-selector.component.scss"],
})
export class UserSelectorComponent implements OnInit {
  documentForm: UntypedFormGroup;
  @Input() documentId;
  userControl: UntypedFormControl;
  public filteredUsers;
  public users;
  public isLoading = false;

  @Input()
  type: string;

  @Input()
  id: any;

  searchTerm: string = "";

  constructor(
    private controlContainer: ControlContainer,
    private documentService: DocumentService
  ) {
    this.userControl = new UntypedFormControl([]);

    this.userControl = new UntypedFormControl([], Validators.required);
  }

  ngOnInit() {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("entities", this.userControl);

    this.documentService
      .sharedEligibleUsers(this.documentId, this.type, this.id)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((users) => {
        this.users = users["result"].map((user) => ({
          ...user,
          text: `${user.company_name} (${user.entity_type})`,
        }));
      });
  }

  onSearch(event) {
    this.searchTerm = event.term;
  }

  focusOut() {
    if (this.searchTerm.length) {
      const value = this.documentForm.get("users").value;

      value.push(this.searchTerm);

      this.documentForm.get("users").setValue(value);

      this.searchTerm = "";
    }
  }
}
