<div class="nowrap-btn-row mt-3 mb-3" *canView="canAddPermission">
  <div class="btn orange-bg" (click)="openDialog()">
    <i class="icon-plus-1"></i> {{ "GENERALS.ADD" | translate }}
  </div>
</div>

<!-- table -->
<div class="details-body-wrapper expats-list">
  <div class="table-wrapper">
    <ng-container>
      <div class="table-responsive">
        <table>
          <thead>
            <tr>
              <th>{{ "permissions.headers.id" | translate }}</th>
              <th>{{ "permissions.headers.user_name" | translate }}</th>
              <th>{{ "permissions.headers.permissions" | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="isLoading">
            <tr>
              <td colspan="100">
                <app-loader-spinner-general
                  [loadingType]="LoadingType.TABLE"
                ></app-loader-spinner-general>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isLoading">
            <tr *ngFor="let user of items">
              <td class="gray-pointer">
                {{ user.id }}
              </td>
              <td class="gray-pointer">
                {{ user.name | titlecase }}
              </td>
              <td>
                <div
                  class="gray-pointer"
                  *ngFor="let permission of user.permissions"
                >
                  <span>
                    {{ permission.name }}
                  </span>
                  <span class="icon-trash gray cursor-pointer"></span>
                </div>
              </td>
            </tr>

            <tr *ngIf="items?.length === 0">
              <td colspan="6" class="gray">
                {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
<!-- end table -->
