import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../../shared/shared.module";
import { OfficeComponent } from "./components/office/office.component";
import { OfficeCardComponent } from "./components/office-card/office-card.component";
@NgModule({
  exports: [OfficeCardComponent],
  providers: [],
  declarations: [OfficeComponent, OfficeCardComponent],
  imports: [CommonModule, SharedModule],
  entryComponents: [OfficeComponent],
})
export class ClientSharedModule {}
