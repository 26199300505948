<ng-container *ngIf="!document.files.length">
  {{ "DOCUMENTS.NO-FILES-UPLOADED" | translate }}
</ng-container>
<div class="doc-carousel">
  <owl-carousel-o [options]="options">
    <ng-container *ngFor="let file of document.files">
      <ng-template carouselSlide
        ><app-preview-strategy-resolver
          [file]="file"
        ></app-preview-strategy-resolver>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
