<div class="left-navigation-wrapper">
  <div class="logo" [routerLink]="['/dashboard']">
    <img src="assets/images/logo.svg" alt="" />
  </div>
  <div class="eu-funds-img mb-2" *ngIf="hasCrm">
    <img src="assets/images/eu/eu_fedr.svg" alt="" />
    <img src="assets/images/eu/gr.svg" alt="" />
    <img src="assets/images/eu/regio.png" alt="" />
    <img src="assets/images/eu/is.svg" alt="" />
  </div>
  <div class="btn-wrapper">
    <a class="btn orange-bg" (click)="goToMarketplace()">{{
      "GENERALS.MARKETPLACE" | translate
    }}</a>
  </div>

  <div class="left-navigation-elem-wrapper" *ngIf="this.menuList">
    <ul>
      <ng-template #loading>
        <app-loader-spinner-general
          [repeat]="15"
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </ng-template>
      <!-- dynamic menu -->
      <ng-container *ngIf="status$ | async as status; else loading">
        <ng-container *ngFor="let nav of menuList.children">
          <ng-container *ngIf="this.canDisplay(nav) | async">
            <li
              (click)="navigateTo(nav)"
              (keyup)="navigateTo(nav)"
              [ngClass]="{
                'active-nav': nav.active,
                'has-sub-nav': nav.children && nav.children.length,
                'sub-is-open': nav.children?.length && nav.active,
                disabled:
                  nav.activeCheck &&
                  (status.user_status_id !== UserStatus.active ||
                    status.entity_status_id !== UserStatus.active)
              }"
              expand-menu
            >
              <a [routerLink]="nav.children.length ? null : nav.routerLink">
                <mat-icon
                  class="orange"
                  [svgIcon]="nav.icon"
                  [ngClass]="{ 'orange-icon': nav.name === 'documents' }"
                ></mat-icon>
                {{ nav.label | translate }}
                <span
                  class="side-menu-badge orange-bg"
                  *ngIf="nav.badge | async as badge"
                >
                  {{ badge }}
                </span>
              </a>
            </li>
            <div
              class="sub-navigation open-sub-navigation"
              *ngIf="nav.children?.length"
            >
              <ul>
                <ng-container *ngFor="let subNav of nav.children">
                  <li
                    *ngIf="canDisplay(subNav) | async"
                    (click)="navigateTo(subNav)"
                    (keyup)="navigateTo(subNav)"
                    [ngClass]="{
                      'active-sub-nav': subNav.active
                    }"
                    expand-menu
                  >
                    <a [routerLink]="subNav.routerLink">
                      <i [ngClass]="subNav.icon"></i>
                      {{ subNav.label | translate }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>
