import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UserData } from "@api/account";
import { AuthenticationService } from "@api/authentication";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { PermissionResourceEnum } from "@modules/shared/_enums/permission-resource.enum";
import { QueryParamsService } from "src/app/_services/query-params.service";
import { UsersService } from "src/app/_services/users.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-top-menu",
  templateUrl: "./top-menu.component.html",
})
export class TopMenuComponent implements OnInit {
  public user: UserData;
  public userMenuIsOpen = false;
  public isLoggedInUser = false;
  public interval;
  public canViewCompanyDetails = false;
  public canViewUsers = false;
  localUser;

  public isSubscribed = false;

  // public echo = new Echo({
  //   broadcaster: 'pusher',
  //   key: environment.pusher_key,
  //   wsHost: environment.wsHost,
  //   wsPort: environment.wsPort,
  //   wssPort: environment.wsPort,
  //   disableStats: true,
  //   enabledTransports: ['ws', 'wss'],
  // });

  @ViewChild("shoppingCartMenu")
  shoppingCartMenu: ElementRef;
  innerWidth;
  xs;

  constructor(
    private authService: AuthenticationService,
    private userService: UsersService,
    private router: Router,
    private queryParamsService: QueryParamsService,
    public auth: AuthorizationService
  ) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }
  }

  ngOnInit() {
    this.localUser = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );

    if (this.localUser) {
      this.isLoggedInUser = true;
      this.getCurrentUser();
      this.subscribe();
    }

    this.setPermissions();
  }

  setPermissions() {
    this.auth
      .$hasResource(PermissionResourceEnum.PROVIDER)
      .subscribe((value) => {
        this.canViewCompanyDetails = value;
      });
    this.auth.$hasResource(PermissionResourceEnum.USER).subscribe((value) => {
      this.canViewUsers = value;
    });
  }

  initIfAdmin() {
    if (this.localUser.admin_access) {
      this.localUser.first_name = this.user.first_name;
      this.localUser.last_name = this.user.last_name;
      this.localUser.name = this.user.name;
      localStorage.setItem(
        environment.user_local_storage_key,
        JSON.stringify(this.localUser)
      );
    }
  }

  onProfileImageError(event) {
    event.target.src = "../assets/images/profile-pics-placeholder.jpg";
  }

  subscribe() {
    // get notifications only if we have a logged in user
    if (!this.localUser) {
      return;
    }

    if (this.isSubscribed) {
      this.unsubscribe();
    }

    this.isSubscribed = true;

    // this.echo
    //   .channel('unread-' + this.localUser.id)
    //   // listen for event(MessageAdded)
    //   .listen('UpdateCount', (e) => {
    //     this.messagesBadge = e.count;
    //     if (this.messagesBadge > 0) {
    //       this.titleService.setTitle(
    //         'xpath.global Provider (' + this.messagesBadge + ')'
    //       );
    //     }
    //   });
  }

  unsubscribe() {
    // this.echo
    //   .channel('unread-' + this.localUser.id)
    //   // listen for event(MessageAdded)
    //   .stopListening('UpdateCount');
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe((data) => {
      if (data) {
        this.user = data;
      } else {
        this.user = this.localUser;
      }

      this.initIfAdmin();
    });
  }

  logout() {
    this.authService.logout().subscribe((_data) => {
      clearInterval(this.interval);
      this.goToProvider();
    });
  }

  goToMarketplace() {
    window.location.href = environment.marketplace + "home";
  }

  goToProvider() {
    this.router.navigate(["/login"]);
  }

  onResize() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }
  }

  about() {
    window.open("https://xpath.global/eu-funds/", "_blank");
  }

  get defaultParams() {
    return this.queryParamsService.defaultParams;
  }
}
