import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogHeaderComponent } from "./components/dialog-header/dialog-header.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [DialogHeaderComponent],
  exports: [DialogHeaderComponent],
  imports: [CommonModule, MatIconModule, TranslateModule.forChild()],
})
export class DialogModule {}
