import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ServiceCategory } from "../../../../_models/servicecategory";

@Component({
  selector: "app-category-selection",
  templateUrl: "./category-selection.component.html",
})
export class CategorySelectionComponent implements OnInit, OnChanges {
  public selectedCategory: ServiceCategory;
  public selectedPath: string;
  placeholder;
  preselectedCateg;
  @Input() categories: any[];
  @Input() preselectedPath;
  @Input() level;
  @Input() clear;
  @Input() showLabel = true;
  @Input() helper;
  @Input() bannerType;
  @Input() mandatoryField;
  @Input() isAd;

  @Output() categorySelected: EventEmitter<any> = new EventEmitter();
  @Output() intermediaryCategory: EventEmitter<any> = new EventEmitter();
  @Output() categoryChange: EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (this.preselectedPath && this.preselectedPath.length) {
      this.selectedPath = this.preselectedPath[this.level];
    }

    if (this.level === 0) {
      this.placeholder = this.translate.instant(
        "GENERALS.SELECT-SERVICE-CATEGORY"
      );
    } else {
      this.placeholder = this.translate.instant("GENERALS.SELECT-SUBCATEGORY");
    }
  }

  ngOnChanges(change) {
    if (change.categories && change.categories.currentValue) {
      this.selectedCategory = null;
      this.selectedPath = null;
      this.checkPreselectedPath();
    }

    if (change.clear && change.clear.currentValue) {
      this.selectedCategory = null;
      this.selectedPath = null;
    }

    if (change.preselectedPath && change.preselectedPath.currentValue) {
      this.checkPreselectedPath();
    }

    if (this.bannerType) {
      this.selectedCategory = null;
      this.selectedPath = null;
    }
  }

  checkPreselectedPath() {
    if (this.preselectedPath && this.preselectedPath.length) {
      this.selectedPath = this.preselectedPath[this.level];

      if (this.categories) {
        this.selectedCategory = this.categories[this.selectedPath];

        if (this.selectedCategory === undefined) {
          this.preselectedPath.pop();
          this.selectedPath = this.preselectedPath[this.level];
        }

        this.preselectedCateg =
          this.categories[this.preselectedPath.slice(-1)[0]];
      }
    }
  }

  selectCategory(selection) {
    this.categoryChange.emit();

    this.selectedCategory = this.categories[selection];

    if (
      !this.selectedCategory ||
      (this.selectedCategory && this.selectedCategory.children_count === 0)
    ) {
      this.categorySelected.emit(this.selectedCategory);
    }

    if (
      !this.selectedCategory ||
      (this.selectedCategory && this.selectedCategory.children_count > 0)
    ) {
      this.intermediaryCategory.emit();
    }

    this.preselectedPath = null;

    if (this.isAd) {
      this.categorySelected.emit(this.selectedCategory);
    }
    if (this.bannerType && this.bannerType.key === "main-category") {
      this.selectedCategory = null;
    }
  }

  onClickChild() {
    this.categoryChange.emit();
  }

  /**
   * Forward the received event to the parent
   *
   * @param event :the child category selected
   */
  forward(event) {
    this.categorySelected.emit(event);
  }
}
