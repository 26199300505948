<div [formGroup]="caseForm">
  <mat-label *ngIf="servicesFromArray.controls.length">
    {{ "LABEL.SERVICES" | translate }}
  </mat-label>
  <div class="create-case-slide">
    <ng-container
      formArrayName="services"
      *ngFor="let service of serviceControls; index as i"
    >
      <app-service-selector-item [serviceControl]="service">
      </app-service-selector-item>
    </ng-container>
    <mat-error *ngIf="servicesFromArray.controls.length">
      <app-form-errors [control]="servicesFromArray" #errors></app-form-errors>
    </mat-error>
  </div>
</div>
