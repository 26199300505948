<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingTypeEnum.TABLE"
  ></app-loader-spinner-general>
</ng-container>

<ng-container *ngIf="items$ | async as items">
  <div class="white-box-wrapper m-0">
    <div class="white-box-row">
      <div class="white-box-header d-flex justify-content-between">
        <div class="medium-title fl-l">
          {{ "DOCUMENTS.LINKED-WITH" | translate }}
        </div>
        <ng-container *ngIf="isLoadingAddButton; else buttonLoaded">
          <app-loader-spinner-general
            [loadingType]="LoadingTypeEnum.BUTTONS"
          ></app-loader-spinner-general>
        </ng-container>
        <ng-template #buttonLoaded>
          <div
            #buttonLoaded
            class="white-box-header-action edit-info fl-r add d-flex align-items-end"
            (click)="add()"
          >
            <mat-icon svgIcon="plus-circle" class="plus-circle"></mat-icon>
            {{ "GENERALS.LINK" | translate }}
          </div>
        </ng-template>
      </div>
      <div class="clear"></div>
      <div class="white-box-body pb-0">
        <div class="table-wrapper" *ngIf="items?.length; else noItems">
          <div class="table-responsive">
            <table>
              <tbody>
                <ng-container *ngFor="let item of items; let i = index">
                  <tr *ngIf="canViewRow(item)">
                    <td class="no-right-border">
                      <a [href]="resolveResourcePageUrl(item)">
                        <ng-container [ngSwitch]="item.resource_type">
                          <ng-container *ngSwitchCase="EntityTypeEnum.CLIENT">
                            {{ item.resource_data.client_name }}
                            ({{ item.resource_data.type }})
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            {{ item.resource_data.label }}
                          </ng-container>
                        </ng-container>
                      </a>
                    </td>

                    <td class="ta-r">
                      <!-- table action with overlay -->
                      <div toggle-action-table class="table-action-wrapper">
                        <i class="icon-dot-3"></i>

                        <div class="table-action" *ngIf="items.length !== 1">
                          <ul>
                            <li (click)="unlink(item.id)">
                              {{ "DOCUMENTS.UNLINK" | translate }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <hr *ngIf="items && i < items.length - 1" />
                  </tr>
                  <tr *ngIf="!items.length">
                    {{
                      "GENERALS.NO-ITEMS-DISPLAY" | translate
                    }}
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <ng-template #noItems>
          <span class="gray">
            {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
