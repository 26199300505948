import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportCardComponent } from "./report-card/report-card.component";
import { SharedModule } from "@modules/shared/shared.module";
import { CasesReportCardComponent } from "./cases-report-card/cases-report-card.component";
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
  declarations: [ReportCardComponent, CasesReportCardComponent],
  exports: [ReportCardComponent, CasesReportCardComponent],
  imports: [CommonModule, SharedModule, NgApexchartsModule],
})
export class ReportsSharedModule {}
