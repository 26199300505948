import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DocumentData } from "@api/documents/models/document.model";
import { SharedEntity } from "@api/documents/models/shared-entity.model";
import { DocumentService } from "@api/documents/services/documents.service";
import { ShareComponent } from "@modules/documents/components/share/share.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  selector: "app-document-shared-with",
  templateUrl: "./document-shared-with.component.html",
  styleUrls: ["./document-shared-with.component.scss"],
})
export class DocumentSharedWithComponent implements OnInit, OnDestroy {
  @Input() public document: DocumentData;
  @Input() public docId$: Observable<number>;
  @Input() public entityType$: Observable<string>;
  @Input() public entityId$: Observable<number>;

  public isLoading: boolean = false;

  public list$: Observable<SharedEntity[]>;

  public isLoadingAddButton: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  private refresh$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  constructor(
    private documentService: DocumentService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private alertService: AlertService
  ) {}

  public ngOnInit(): void {
    this.list$ = this.refresh$.pipe(
      tap(() => (this.isLoading = true)),
      switchMap(() =>
        combineLatest([this.entityId$, this.entityType$]).pipe(
          switchMap(([entityId, entityType]: [number, string]) =>
            this.documentService
              .sharedWithList(this.document.id, entityType, entityId)
              .pipe(finalize(() => (this.isLoading = false)))
          )
        )
      )
    );

    this.refresh();
  }

  private refresh(): void {
    this.refresh$.next();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public add(): void {
    this.isLoadingAddButton = true;

    const data$ = combineLatest([
      this.docId$,
      this.entityType$,
      this.entityId$,
    ]).pipe(
      switchMap(([docId, entityType, entityId]) =>
        this.dialog
          .open(ShareComponent, {
            data: { documentIds: docId, type: entityType, id: entityId },
          })
          .afterClosed()
      )
    );

    data$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.isLoadingAddButton = false;

      if (res?.type === "saved") {
        this.alertService.success(
          this.translateService.instant("DOCUMENTS.SHARED-SUCCESSFULLY")
        );

        this.refresh();
      }
    });
  }

  public stopSharing(user: SharedEntity): void {
    this.documentService
      .revoke(this.document.id, {
        entity_id: user.entity_id,
        entity_type: user.entity_type,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.refresh();

        this.alertService.success(
          this.translateService.instant("DOCUMENTS.REVOKED-SUCCESSFULLY")
        );
      });
  }

  public get LoadingTypeEnum(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
