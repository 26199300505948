import { Component, OnInit } from "@angular/core";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";

@Component({
  templateUrl: "./cases.component.html",
  styleUrls: ["./cases.component.scss"],
})
export class CasesComponent implements OnInit {
  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForCasesReports()
    );
  }
}
