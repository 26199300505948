<ng-template #loading>
  <app-loader-spinner-general
    [loadingType]="LoadingType.INFO"
  ></app-loader-spinner-general>
</ng-template>
<div class="set-password-wrapper" *ngIf="!isLoading; else loading">
  <div class="auth-form-wrapper">
    <div class="auth-form-title-wrapper">
      <div class="auth-form-title">
        {{ "AUTH.SET-PASSWORD" | translate }}
      </div>
    </div>
    <div [formGroup]="passwordFormGroup" class="mt-all">
      <mat-form-field
        appearance="outline"
        class="row w-100 password_validate_field px-1"
      >
        <mat-label>{{ "GENERALS.PASSWORD" | translate }}</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          (focusin)="hideError = true"
          (focusout)="hideError = false"
          formControlName="password"
        />
        <app-form-errors
          *ngIf="hideError"
          [control]="passwordFormGroup.get('password')"
        >
        </app-form-errors>
        <mat-icon class="show-pass center" (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="row w-100 px-1">
        <mat-label>{{ "LABEL.CONFIRM-PASSWORD" | translate }}</mat-label>
        <input
          matInput
          [type]="hide_confirmed_pass ? 'password' : 'text'"
          formControlName="repeatPassword"
        />
        <mat-error>
          <app-form-errors [control]="passwordFormGroup.get('repeatPassword')">
          </app-form-errors>
        </mat-error>
        <mat-icon
          class="show-pass center"
          (click)="hide_confirmed_pass = !hide_confirmed_pass"
        >
          {{ hide_confirmed_pass ? "visibility_off" : "visibility" }}
        </mat-icon>
      </mat-form-field>
      <div class="row w-100">
        <button
          (click)="resetPassword()"
          [disabled]="isLoading || !passwordFormGroup.valid"
          class="btn orange-bg"
        >
          {{ "GENERALS.DONE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
