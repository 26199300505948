import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { DocumentsService } from "src/app/_services/documents.service";
import { InvoiceExtraData } from "../../../_models/invoice-extra-data";
import { OrdersService } from "../../../_services/orders.service";

@Component({
  selector: "app-add-doc",
  templateUrl: "./add-doc.component.html",
  styleUrls: ["./add-doc.component.scss"],
})
export class AddDocComponent implements OnInit {
  @Input() entityType;
  @Input() entityId;
  @Input() docType;

  @Output() changeDoc: EventEmitter<any> = new EventEmitter();

  public fileUploadControl = new FileUploadControl();

  setModalOpen = true;
  isCanceled = false;
  isLoading = false;
  uploadedFiles = { file: null, name: null, status: null };
  statuses;
  invoiceExtraData = new InvoiceExtraData();
  public currencies = [];
  public orderInvoiceStatuses;

  sts = [{ id: 1, text: "signed" }];
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    protected service: DocumentsService,
    private alertService: AlertService,
    public ordersService: OrdersService
  ) {
    this.setModalOpen = false;
  }

  ngOnInit() {
    this.getStatuses();
    this.getOrderInvoiceStatuses();
    this.getCurrencies();
  }

  limitFilesUpload(e) {
    if (e && e.length > 1) {
      this.uploadedFiles.file = [e[0]];
    }
  }

  getCurrencies() {
    this.service.getCurrencies().subscribe((res) => {
      this.currencies = res;
    });
  }

  getStatuses() {
    this.service.getStatuses().subscribe((res) => {
      this.statuses = res.result;
    });
  }

  getOrderInvoiceStatuses() {
    this.service.getOrderInvoiceStatuses().subscribe((res) => {
      this.orderInvoiceStatuses = res.result;
    });
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModalOpen = false;
    this.fileUploadControl.clear();
  }
  cancelModal() {
    this.ngxSmartModalService.getModal("addDoc").close();
    this.isCanceled = true;
    this.uploadedFiles = { file: null, name: null, status: null };
    this.fileUploadControl.clear();
    this.invoiceExtraData = new InvoiceExtraData();
  }

  uploadFile() {
    this.isLoading = true;

    this.service
      .uploadFiles(
        this.entityId,
        this.docType,
        this.uploadedFiles,
        this.entityType,
        {
          ...this.invoiceExtraData,
          invoice_due_date: this.getDateString(
            this.invoiceExtraData.invoice_due_date
          ),
          invoice_issue_date: this.getDateString(
            this.invoiceExtraData.invoice_issue_date
          ),
        }
      )
      .subscribe(
        (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.changeDoc.emit();

          this.cancelModal();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoading = false;
          // this.uploadedFiles = { file: null, name: null, status: null };
          // this.invoiceExtraData = new InvoiceExtraData();
          // this.fileUploadControl.clear();
        }
      );
  }

  setDate(date: any, propertyName: string): void {
    if (!moment(date).isValid()) {
      return;
    }

    this.invoiceExtraData[propertyName] = moment(date);
  }

  getDateString(event) {
    const d = new Date(event);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
