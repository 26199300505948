import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  OnInit,
} from "@angular/core";

@Directive({
  selector: "[windowScrollDashboardBodyTop]",
})
export class WindowScrollDashboardBodyTopDirective implements OnInit {
  elemPosition;
  innerWidth;
  xs;
  heleprElem;

  constructor(public el: ElementRef, public renderer: Renderer2) {
    this.innerWidth = window.innerWidth;
    if (innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }
  }
  ngOnInit() {
    // add position fixed helper
    if (document.querySelector(".small-dashboard-body-top-helper")) {
      return;
    }
    this.heleprElem = document.createElement("div");
    this.heleprElem.classList.add("small-dashboard-body-top-helper");
    this.el.nativeElement.before(this.heleprElem);

    this.renderer.setStyle(
      this.heleprElem,
      "height",
      this.el.nativeElement.clientHeight + 15 + "px"
    );
    this.renderer.setStyle(
      document.querySelector(".small-dashboard-body-top-helper"),
      "display",
      "none"
    );

    this.elemPosition = this.el.nativeElement.offsetTop;
  }

  @HostListener("window:scroll", ["$event"])
  @HostListener("window:resize", ["$event"])
  onScroll() {
    this.renderer.setStyle(
      this.heleprElem,
      "height",
      this.el.nativeElement.clientHeight + 15 + "px"
    );
    this.innerWidth = window.innerWidth;

    if (innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }

    if (!this.xs) {
      const scrolled =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentElement ||
              document.body
            ).scrollTop;

      // 51 = small header height;
      const detailsNav = document.querySelectorAll("[windowscrollappnav]")[0];

      if (detailsNav) {
        if (scrolled >= this.elemPosition - 51 - 41) {
          this.renderer.addClass(
            this.el.nativeElement,
            "small-dashboard-body-top"
          );
          this.renderer.addClass(
            this.el.nativeElement,
            "small-dashboard-body-top-1ElemUp"
          );
          this.renderer.setStyle(
            document.querySelector(".small-dashboard-body-top-helper"),
            "display",
            "inline-block"
          );
        } else {
          this.renderer.removeClass(
            this.el.nativeElement,
            "small-dashboard-body-top"
          );
          this.renderer.removeClass(
            this.el.nativeElement,
            "small-dashboard-body-top-1ElemUp"
          );
          this.renderer.setStyle(
            document.querySelector(".small-dashboard-body-top-helper"),
            "display",
            "none"
          );
        }
      } else {
        if (scrolled >= this.elemPosition - 51) {
          this.renderer.addClass(
            this.el.nativeElement,
            "small-dashboard-body-top"
          );
          this.renderer.setStyle(
            document.querySelector(".small-dashboard-body-top-helper"),
            "display",
            "inline-block"
          );
        } else {
          this.renderer.removeClass(
            this.el.nativeElement,
            "small-dashboard-body-top"
          );
          this.renderer.setStyle(
            document.querySelector(".small-dashboard-body-top-helper"),
            "display",
            "none"
          );
        }
      }
    }
  }
}
