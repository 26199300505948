import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { CostCategoryData } from "@api/expenses/models/cost-category.model";
import { ExpensesService } from "@api/expenses/services/expenses.service";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-cost-category-selector",
  templateUrl: "./cost-category-selector.component.html",
})
export class CostCategorySelectorComponent implements OnInit, OnDestroy {
  @Input() value;
  public expenseForm: UntypedFormGroup;
  public costCategoryControl: UntypedFormControl;
  public isLoading = false;
  public costCategoryList;
  public costCategory: string;
  private destroyed$: Subject<void> = new Subject();

  handleCostCategory(event: MatSelectChange) {
    this.costCategory = event.source.triggerValue;
  }

  constructor(
    private controlContainer: ControlContainer,
    private expensesService: ExpensesService
  ) {
    this.costCategoryControl = new UntypedFormControl(
      this.value ? this.value : "",
      Validators.required
    );
  }

  ngOnInit() {
    this.expenseForm = <UntypedFormGroup>this.controlContainer.control;
    this.expenseForm.setControl("cost_category_id", this.costCategoryControl);

    this.expensesService
      .getCostCategory()
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((type: CostCategoryData) => {
        this.costCategoryList = type["result"];
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }
}
