import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Subject } from "rxjs";

@Component({
  templateUrl: "./save-custom-report-modal.component.html",
  styleUrls: ["./save-custom-report-modal.component.scss"],
})
export class SaveCustomReportModalComponent implements OnInit {
  submitForm: Subject<void> = new Subject<void>();

  customReportForm: UntypedFormGroup;
  name = new UntypedFormControl("", Validators.required);
  isLoading: boolean = false;

  constructor(
    protected dialogRef: MatDialogRef<SaveCustomReportModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: { report: Partial<any> }
  ) {}

  ngOnInit() {
    this.customReportForm = new UntypedFormGroup({
      name: this.name,
    });
  }

  submit() {
    this.submitForm.next();

    if (!this.customReportForm.valid) {
      return;
    }
    this.isLoading = true;

    const data = {
      ...this.customReportForm.getRawValue(),
    };
    this.dialogRef.close(data);
  }

  close() {
    this.dialogRef.close();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
