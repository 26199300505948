import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { debounceTime, finalize } from "rxjs/operators";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { TemplateAlertComponent } from "../template-alert/template-alert.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { MatDialog } from "@angular/material/dialog";
import { TemplateData, TemplateService } from "@api/templates";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-template-list",
  templateUrl: "./template-list.component.html",
  styleUrls: ["./template-list.component.scss"],
})
export class TemplateListComponent
  extends ItemsListComponent
  implements OnInit
{
  items: TemplateData[];
  isFiltersLoaded: boolean = false;
  searchFilters = [];

  constructor(
    public templateService: TemplateService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
  }

  ngOnInit(): void {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/template", "template")
    );
    this.isLoading = true;
    this.watchQueryParams();
    this.templateService.listenForChanges.subscribe(() => {
      this.getItems(this.p);
    });
  }

  openDialog(): void {
    this.dialog.open(TemplateAlertComponent);
  }

  editTemplate(item: TemplateData) {
    this.dialog.open(TemplateAlertComponent, {
      data: item,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.isLoading = true;

    const params = this.getParams(page);
    this.request_call = this.templateService
      .list(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.searchFilters = data.filters;
          this.processResponse({ result: data });
        },
        error: (error) => {
          this.items = [];
          if (error.error.errors && error.error.errors.length > 0) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);

    this.getItems();

    this.router.navigate([], {
      queryParams: params,
      relativeTo: this.route,
      replaceUrl: true,
    });
  }

  protected watchQueryParams() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(debounceTime(500))
      .subscribe(([params, query]) => {
        const filters = { ...params, ...query };
        super.watchQueryParams(filters);
      });
  }

  openArchiveDialog(item: TemplateData): void {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          needs_confirmation: true,
          text: `archive ${item.name}`,
        },
      },
    });

    dialogRef.afterClosed().subscribe((reason: string) => {
      if (reason) {
        this.templateService.onChangeArchiveStatus(item, true);
      }
    });
  }

  openUnarchivedDialog(item: TemplateData): void {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          needs_confirmation: true,
          text: `unarchive ${item.name}`,
        },
      },
    });

    dialogRef.afterClosed().subscribe((reason: string) => {
      if (reason) {
        this.templateService.onChangeArchiveStatus(item, false);
      }
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
