import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ValueList } from "../../shared/models/value-list.model";
import { ContactData } from "../models/contact.model";

@Injectable()
export abstract class ContactsService {
  abstract fetchAll(
    contactable: any,
    filters: ValueList<string>
  ): Observable<ContactData[]>;

  abstract update(
    contactable: any,
    contact: ContactData
  ): Observable<ContactData>;

  abstract create(
    contactable: any,
    contact: ContactData
  ): Observable<ContactData>;

  abstract remove(contactable: any, contact: ContactData): Observable<any>;
}
