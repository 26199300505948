import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { QuestionsWithAnswers } from "@modules/service-offers/models/question.model";
import { ServiceOffersService } from "@modules/service-offers/services/service-offers.service";
import { combineLatest, finalize, map, Observable } from "rxjs";
import { AnswerStatus } from "src/app/_enums/answer-status.enum";
import { OrderQuestionsComponent } from "../order-questions/order-questions.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-questions-button",
  templateUrl: "./questions-button.component.html",
  styleUrls: ["./questions-button.component.scss"],
})
export class QuestionsButtonComponent implements OnInit {
  @Input()
  offerId: string | number;
  @Input()
  orderId: string | number;
  @Input()
  answerStatusId: string | number;

  questions$: Observable<QuestionsWithAnswers[]>;
  isLoading = false;

  constructor(
    private offerService: ServiceOffersService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.questions$ = combineLatest([
      this.offerService.getQuestions(this.offerId),
      this.offerService.getAnswers(this.offerId, this.orderId),
    ]).pipe(
      finalize(() => (this.isLoading = false)),
      map(([questions, answers]) => {
        return questions.map((question, i) => {
          return {
            id: question.id,
            offer_id: question.offer_id,
            answer: answers[answers.length - 1 - i],
            text: question.text,
          };
        });
      })
    );
  }

  openQuestions(questions: QuestionsWithAnswers[]): void {
    this.dialog.open(OrderQuestionsComponent, {
      data: questions,
    });
  }

  get AnswerStatus() {
    return AnswerStatus;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
