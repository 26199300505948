<div class="question-wrapper p-0">
  <div class="dialog-header">
    <div class="dialog-title">
      {{ "LABEL.ASSESMENT_QUESTIONS" | translate }}
    </div>
    <div class="dialog-close">
      <button class="btn-outline-none-primary" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content class="!m-0 !p-6">
    <div class="mb-3" *ngFor="let question of data; let i = index">
      <div class="question">{{ i + 1 }}. {{ question.text }}:</div>
      <div class="display-field">
        <span *ngIf="question.answer.answer">
          {{ question.answer.answer }}
        </span>
        <span *ngIf="!question.answer.answer">
          {{ "GENERALS.NO-ANSWER" | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
