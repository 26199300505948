export class ExpatDependent {
  id?: number;
  first_name: string;
  last_name: string;
  relationship: string;
  residence_country: string;
  primary_citizenship: string;
  birth_date: string;
  birth_country: string;
  birth_city: string;
}
