<div class="add-quote p-0">
  <app-dialog-header>
    {{
      (data.quote ? "Edit quote" : "PAGES.ORDERS.ORDER_QUOTES.ADD_QUOTE")
        | translate
    }}
  </app-dialog-header>

  <mat-dialog-content class="!m-0 !p-6">
    <form [formGroup]="quoteForm">
      <!-- OFFER SELECTION -->
      <div class="flex flex-wrap !w-full">
        <mat-form-field appearance="outline" class="!w-full">
          <mat-label>
            {{ "LABEL.FIND_OFFER" | translate }}
          </mat-label>
          <mat-select
            [placeholder]="'LABEL.FIND_OFFER' | translate"
            formControlName="offer"
            (selectionChange)="selectOffer($event)"
            [compareWith]="compareService"
          >
            <mat-option>
              <ngx-mat-select-search
                [clearSearchInput]="false"
                [placeholderLabel]="'LABEL.FIND_OFFER' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="serviceOffersSearchCtrl"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let serviceOffer of serviceOffers$ | async"
              [value]="serviceOffer"
            >
              ({{ serviceOffer.id }})
              {{ serviceOffer.title }}
              ({{ "GENERALS.WORKFLOW" | translate | lowercase }}
              {{ serviceOffer.workflow_status }}) -
              {{
                serviceOffer.sale_price.price
                  | currency: serviceOffer.sale_price.currency
              }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="offer"> </mat-error>
        </mat-form-field>
      </div>
      <!-- OFFER SELECTION -->
      <div class="flex flex-wrap !w-full">
        <mat-form-field class="!w-1/2 pr-3" appearance="outline">
          <mat-label>
            {{ "GENERALS.PRICE" | translate }}
            ({{ user.entity_details.company.currency_code }})
          </mat-label>
          <input
            matInput
            formControlName="unit_price"
            type="number"
            min="0"
            oninput="this.value = Math.abs(this.value)"
            name="unit_price"
          />
          <mat-error formControlError="unit_price"> </mat-error>
        </mat-form-field>
        <mat-form-field class="!w-1/2" appearance="outline">
          <mat-label>
            {{ "GENERALS.SERVICE-NAME" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            name="service_name"
            formControlName="service_name"
          />
          <mat-error formControlError="service_name"> </mat-error>
        </mat-form-field>
        <div class="w-1/2 pr-3">
          <app-date-range-calendar
            [control]="quoteForm.get('expires_at')"
            (change)="onDateChange($event)"
            [placeholder]="'LABEL.VALID_UNTIL' | translate"
            [minDate]="minDate"
            [singleDate]="quoteForm.get('expires_at').value"
          >
          </app-date-range-calendar>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <div class="dialog-footer">
    <button
      type="button"
      class="btn-primary"
      (click)="save()"
      [disabled]="isLoading"
    >
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
