import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProvidersCommissions } from "src/app/_models/providerscommissions";
import { ServiceCategory } from "src/app/_models/servicecategory";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { ServiceCategoriesService } from "src/app/_services/servicecategories.service";
import { UsersService } from "src/app/_services/users.service";

@Component({
  selector: "app-service-portfolio",
  templateUrl: "./service-portfolio.component.html",
  styleUrls: ["./service-portfolio.component.scss"],
})
export class ServicePortfolioComponent implements OnInit, OnDestroy {
  commissions: ProvidersCommissions;
  categories: ServiceCategory[] = [];
  provider;

  selectedCategories = {};
  countries;
  selectedCountries;
  isLoading = false;

  private _onDestroy = new Subject<void>();

  constructor(
    private service: ProvidersService,
    private alertService: AlertService,
    private userService: UsersService,
    private dictionaryService: DictionariesService,
    private categoryService: ServiceCategoriesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getClientDetails();
  }

  getCountryList() {
    this.dictionaryService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  getClientDetails() {
    this.isLoading = true;

    this.userService.getCurrentUser().subscribe(
      (data) => {
        this.provider = data;
        this.isLoading = false;
        this.getServiceCategories();
        this.getCountryList();
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  getServiceCategories() {
    const params = { status: "active" };
    this.isLoading = true;

    this.categoryService
      .getServiceCategories(params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.categories = data.items;
        this.isLoading = false;
      });
  }

  updateCategorySelection(data) {
    if (data.id) {
      if (this.selectedCategories[data.id]) {
        const idx = this.selectedCategories[data.id].findIndex(
          (f) => f.id === data.selection.id
        );
        if (idx > -1 && !data.update) {
          this.selectedCategories[data.id].splice(idx, 1);
        } else if (data.update) {
          this.selectedCategories[data.id][idx] = data.selection;
        } else {
          this.selectedCategories[data.id].push(data.selection);
        }
      } else {
        this.selectedCategories[data.id] = [];
        this.selectedCategories[data.id].push(data.selection);
      }
      if (
        this.selectedCategories[data.id] &&
        this.selectedCategories[data.id].length === 0
      ) {
        delete this.selectedCategories[data.id];
      }
    }
  }

  addCategories(param?) {
    this.isLoading = true;
    const commissions = new ProvidersCommissions();
    commissions.provider_id = this.provider.entity_id;
    commissions.categories = [];
    const keys = Object.keys(this.selectedCategories);
    keys.forEach((item) => {
      this.selectedCategories[item].forEach((categ) => {
        commissions.categories.push({
          id: categ.id,
          commissions: "",
        });
      });
    });

    let countryCodes;

    if (this.selectedCountries) {
      countryCodes = this.countries.filter((f) =>
        this.selectedCountries.find((name) => name === f.name)
      );
      countryCodes = countryCodes.map((f) => f.country_code);
    } else {
      countryCodes = [];
    }

    const commission$ = this.service.updateCategories(
      commissions,
      this.provider.entity_id
    );
    const countries$ = this.service.setCountryForCommission(
      { countries: countryCodes },
      this.provider.entity_id
    );
    combineLatest([commission$, countries$]).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigate(["/dashboard"]);
      },
      (error) => {
        if (error.error) {
          this.isLoading = false;
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
