export class Billing {
  links: Array<any>;

  constructor(_id?: number, filters?: any) {
    this.links = [
      {
        link: "/billing/plans",
        filters: filters,
        label: "Plans",
        page: "plans",
      },
      {
        link: "/billing/billing-info",
        filters: filters,
        label: "Billing info",
        page: "billing-info",
      },
    ];
  }
}
