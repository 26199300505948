import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { ValueList } from "@modules/shared/models/value-list.model";
import { concat, Observable, of } from "rxjs";
import { map, pairwise } from "rxjs/operators";

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}
  public revenue(params = {}): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "reports/costs", {
      params,
    });
  }

  public monthlyCosts(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "reports/monthly-costs",
      {
        params,
      }
    );
  }

  public openCases(params = {}): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "reports/open-cases", {
        params,
      })
      .pipe(
        map((res) =>
          res.result.map((item) => ({
            x: item.user.first_name + " " + item.user.last_name,
            y: item.number_of_cases,
          }))
        )
      );
  }

  public montlyProfit(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "reports/monthly-profits",
      {
        params,
      }
    );
  }

  public milestoneFilters(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "reports/milestones/filters",
      {
        params,
      }
    );
  }

  public milestones(data: ValueList<any> = {}): Observable<any> {
    const withoutFilters = { ...data };
    const withFilters = {
      ...data,
      include: "filters",
    };

    return concat(
      of({}),
      this.http
        .get<any>(environment.gateway_endpoint + "reports/milestones", {
          params: withoutFilters,
        })
        .pipe(map((data) => data?.result)),
      this.http
        .get<any>(environment.gateway_endpoint + "reports/milestones", {
          params: withFilters,
        })
        .pipe(map((res) => ({ filters: res.result?.filters })))
    ).pipe(
      pairwise(),
      map(([prev, curr]) => ({ ...prev, ...curr })),
      map((response: any) => {
        return response;
      })
    );
  }

  public export(params = {}): Observable<any> {
    return this.http.get(
      `${environment.gateway_endpoint}reports/milestones/export`,
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  public customMilestoneList(params = {}): Observable<any> {
    return this.http.get(
      `${environment.gateway_endpoint}reports/milestones/custom`,
      {
        params: params,
      }
    );
  }

  public customMilestoneView(id, params = {}): Observable<any> {
    return this.http.get(
      `${environment.gateway_endpoint}reports/milestones/custom/` + id,
      {
        params: params,
      }
    );
  }

  public createCustomMilestone(params = {}): Observable<any> {
    return this.http
      .post(environment.gateway_endpoint + `reports/milestones/custom`, params)
      .pipe(map((response: any) => response.result));
  }

  public updateCustomMilestone(id: number, params = {}): Observable<any> {
    return this.http
      .put(
        environment.gateway_endpoint + `reports/milestones/custom/` + id,
        params
      )
      .pipe(map((response: any) => response.result));
  }

  public removeCustomMilestone(id: number): Observable<any> {
    return this.http
      .delete(environment.gateway_endpoint + `reports/milestones/custom/` + id)
      .pipe(map((response: any) => response.success));
  }
}
