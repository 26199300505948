<div class="w-full flex justify-between">
  <div class="dialog-title">
    <ng-content></ng-content>
  </div>
  <div class="dialog-close">
    <button class="btn-outline-none-secondary" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="w-full">
  <ng-content select="[slot='subtitle']"></ng-content>
</div>
