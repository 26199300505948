<!-- Modal header -->
<div class="modal-title-wrapper p-20">
  <div class="modal-title" *ngIf="edit">
    {{ "GENERALS.EDIT-USER-INFO" | translate }}
  </div>
  <div class="modal-title" *ngIf="!edit">
    {{ "GENERALS.ADD-USER" | translate }}
  </div>
</div>

<div class="clear"></div>

<div class="custom-modal-body p-20 m-0 edit-user-modal">
  <!-- Modal body -->

  <div>
    <div class="modal-form" *ngIf="user">
      <div class="row">
        <mat-form-field appearance="outline" class="w-50">
          <mat-label>{{ "LABEL.FIRST-NAME" | translate }} *</mat-label>
          <input
            type="text"
            name="first_name"
            class="form-control"
            [(ngModel)]="user.first_name"
            matInput
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-50">
          <mat-label>{{ "LABEL.LAST-NAME" | translate }} *</mat-label>
          <input
            type="text"
            name="last_name"
            class="form-control"
            [(ngModel)]="user.last_name"
            matInput
          />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field appearance="outline" class="w-50">
          <mat-label>{{ "LABEL.EMAIL" | translate }} *</mat-label>
          <input
            type="text"
            name="email"
            class="form-control"
            [(ngModel)]="user.email"
            matInput
          />
        </mat-form-field>

        <div class="w-50">
          <mat-form-field appearance="outline" class="w-50 p-0">
            <mat-label>{{ "LABEL.SELECT-PHONE-CODE" | translate }} *</mat-label>

            <mat-select
              formPrefix="client"
              #singleSelect
              [(ngModel)]="user.phone_prefix"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="
                    'EXPATS.EXPAT-NAME-EMAIL-ADDRESS' | translate
                  "
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  ngModel
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let item of prefix" [value]="item.value">
                {{ item.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-50 p-r-0">
            <mat-label>{{ "LABEL.COUNTRY-CODE" | translate }} *</mat-label>
            <input
              type="tel"
              class="form-control"
              (keypress)="validateNumericalInput($event)"
              [(ngModel)]="user.phone"
              matInput
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="w-50">
        <mat-label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</mat-label>
        <input
          type="text"
          name="company_position"
          class="form-control"
          [(ngModel)]="user.company_position"
          matInput
        />
      </mat-form-field>

      <mat-form-field class="w-50" appearance="outline">
        <mat-label>{{ "LABEL.ROLE" | translate }}*</mat-label>
        <mat-select #singleSelect [(ngModel)]="user.role_id">
          <mat-option *ngFor="let item of roles" [value]="item.id">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="clear"></div>

<!-- Modal footer -->
<br />
<div class="modal-footer p-20">
  <ng-container *ngIf="!isLoading; else loadingButtons">
    <button type="button" class="btn gray-bg modal-button" mat-dialog-close>
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      type="button"
      class="btn orange-bg modal-button"
      [disabled]="disabled"
      (click)="submit()"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ "LABEL.SAVE" | translate }}
    </button>
  </ng-container>

  <ng-template #loadingButtons>
    <app-loader-spinner-general
      [loadingType]="LoadingType.BUTTONS"
      [repeat]="2"
    ></app-loader-spinner-general>
  </ng-template>
</div>
