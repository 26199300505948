<ng-container *ngIf="this.timelines && this.timelines.length < 1">
  <mat-error>
    {{ "TIMELINE.GENERIC_ERROR" | translate }}
  </mat-error>
</ng-container>

<ng-container *ngIf="timelineService.timelineHeaderDaysPositions.length > 0">
  <div
    class="sidebar position-relative"
    *ngFor="let item of this.timelines; let index = index"
  >
    <div class="sidebar__card col-2 py-3 ps-3 pe-0">
      <div class="sidebar__card-status col-2 ps-0">
        <span class="status {{ item.activeStatus }} timeline-icon">
          <mat-icon>{{ item.statusImage }}</mat-icon>
        </span>
      </div>
      <div
        [class]="'sidebar__card-body day_' + item.id"
        [ngClass]="item.subtasks.length > 1 && item.active ? 'active' : ''"
      >
        <div
          class="sidebar__dates-timeline"
          appSidebarTimeline
          [task]="item"
          [ngClass]="item.activeStatus"
          [class.next-month-overlap]="item.isNextMonthOverlapped"
          [class.previous-month-overlap]="item.isPreviousMonthOverlapped"
        >
          {{ item.completion_rate | percent }}
          <ng-container
            *ngIf="
              item.endDateDay !== 1 && item.startDateDay !== item.endDateDay
            "
          >
            | {{ item.expireIn }} days
            <div
              class="sidebar__dates-timeline__image-holder"
              *ngIf="!item.isNextMonthOverlapped"
            >
              <img
                [src]="
                  item.assignee_picture
                    ? item.assignee_picture
                    : './assets/images/profile-pics-placeholder.jpg'
                "
                alt="Profile Picture"
              />
            </div>
          </ng-container>
        </div>

        <div class="sidebar__card-body__title pe-1">
          <a [routerLink]="['/', 'cases', item.expat_case_id, 'task', item.id]">
            {{ item.name }}
          </a>
        </div>
        <div
          class="sidebar__card-body__subtasks small"
          *ngIf="item.subtasks.length > 0"
          [class.active]="item.active"
        >
          <a href="javascript:void(0)" (click)="timelineService.openTask(item)">
            {{ item.subtasks.length }} {{ "TASKS.SUB_TASKS" | translate }}
            <i
              [class.icon-angle-up]="item.active"
              [class.icon-angle-down]="!item.active"
            ></i>
          </a>
          <br />
          <ng-container *ngIf="item.active">
            <app-timeline-sidebar-subtasks
              [tasks]="item.subtasks"
            ></app-timeline-sidebar-subtasks>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let day of timelineService.currentMonthDays">
      <div
        [class]="
          'sidebar__dates py-3 position-relative task_' +
          item.id +
          '_day_' +
          day.getDate()
        "
      >
        <div class="sidebar__dates-day"></div>
      </div>
    </ng-container>
  </div>
</ng-container>
