import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Expat } from "../../../../../_models/expat";
import { MatDialog } from "@angular/material/dialog";
import { ExpatPersonalInformationComponent } from "../../../components/expat-personal-information/expat-personal-information.component";
import { Country } from "../../../../../_models/country";
import { take } from "rxjs/operators";
import { ExpatsService } from "../../../services/expats.service";

@Component({
  selector: "app-expat-personal-information-card",
  templateUrl: "./expat-personal-information-card.component.html",
  styleUrls: ["./expat-personal-information-card.component.scss"],
})
export class ExpatPersonalInformationCardComponent implements OnInit {
  @Input()
  public expat!: Expat;

  @Input()
  public countries!: Country[];

  @Output()
  public readonly getNewExpat = new EventEmitter();

  public maritalStatuses;
  public educationForms;

  constructor(public dialog: MatDialog, private expatService: ExpatsService) {}

  public ngOnInit() {
    this.getMaritalStatuses();
    this.getEducationForms();
  }

  public handleEdit() {
    const dialogRef = this.dialog.open(ExpatPersonalInformationComponent, {
      data: {
        expat: this.expat,
        educationForms: this.educationForms,
        maritalStatuses: this.maritalStatuses,
        countries: this.countries,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.action === "save") {
          this.saveData(data.expat);
        }
      });
  }

  private saveData(expat: Expat) {
    this.expatService
      .updateExpat(expat)
      .subscribe(() => this.getNewExpat.emit());
  }

  private getMaritalStatuses() {
    this.expatService.getMaritalStatus().subscribe((data) => {
      if (data.result) {
        this.maritalStatuses = data.result;
      }
    });
  }

  private getEducationForms() {
    this.expatService.getEducationForms().subscribe((data) => {
      if (data.result) {
        this.educationForms = data.result;
      }
    });
  }
}
