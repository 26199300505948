<app-dialog-header>
  {{ (edit ? "PORTAL.EDIT-DEPENDENT" : "PORTAL.ADD-DEPENDENT") | translate }}
</app-dialog-header>
<div class="dialog-body">
  <ng-container *ngIf="isLoadingGeneral">
    <app-loader-spinner-general
      [repeat]="4"
      [loadingType]="LoadingType.INPUT"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      [repeat]="2"
      class="d-flex justify-content-end"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
  </ng-container>
  <form
    [formGroup]="form"
    *ngIf="!isLoadingGeneral"
    class="grid gap-x-4 grid-cols-2"
  >
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.FIRST-NAME" | translate }} </mat-label>
      <input
        matInput
        formControlName="first_name"
        [placeholder]="'LABEL.FIRST-NAME' | translate"
      />
      <mat-error *ngIf="form.controls.first_name.errors?.required">
        {{ "GENERALS.FIELD-REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.LAST-NAME" | translate }} </mat-label>
      <input
        matInput
        type="text"
        formControlName="last_name"
        [placeholder]="'LABEL.LAST-NAME' | translate"
      />
      <mat-error *ngIf="form.controls.last_name.errors?.required">
        {{ "GENERALS.FIELD-REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.RELATIONSHIP" | translate }} </mat-label>
      <mat-select
        formControlName="relationship"
        id="relationship"
        [placeholder]="'LABEL.RELATIONSHIP' | translate"
      >
        <mat-option
          [value]="relationship.id"
          *ngFor="let relationship of relationships$ | async"
        >
          {{ relationship.text }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.relationship.errors?.required">
        {{ "GENERALS.FIELD-REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.RESIDENCE-COUNTRY" | translate }}</mat-label>
      <mat-select
        formControlName="residence_country"
        [placeholder]="'LABEL.COUNTRY' | translate"
      >
        <app-country-options></app-country-options>
      </mat-select>
      <mat-error *ngIf="form.controls.residence_country.errors?.required">
        {{ "GENERALS.FIELD-REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "LABEL.PRIMARY-CITIZENSHIP" | translate }}</mat-label>
      <mat-select
        formControlName="primary_citizenship"
        [placeholder]="'LABEL.COUNTRY' | translate"
      >
        <app-country-options></app-country-options>
      </mat-select>
      <mat-error *ngIf="form.controls.primary_citizenship.errors?.required">
        {{ "GENERALS.FIELD-REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>
        {{ "LABEL.BIRTH-DATE" | translate }}
      </mat-label>
      <input
        matInput
        formControlName="birth_date"
        [placeholder]="'LABEL.BIRTH-DATE' | translate"
        [matDatepicker]="picker"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "EXPATS.COUNTRY-OF-BIRTH" | translate }}</mat-label>
      <mat-select
        formControlName="birth_country"
        [placeholder]="'LABEL.COUNTRY' | translate"
      >
        <app-country-options></app-country-options>
      </mat-select>
      <mat-error *ngIf="form.controls.birth_country.errors?.required">
        {{ "GENERALS.FIELD-REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>{{ "EXPATS.CITY-OF-BIRTH" | translate }}</mat-label>
      <input
        matInput
        type="text"
        formControlName="birth_city"
        [placeholder]="'EXPATS.CITY-OF-BIRTH' | translate"
      />
      <mat-error *ngIf="form.controls.birth_city.errors?.required">
        {{ "GENERALS.FIELD-REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <div class="w-50" *ngIf="edit">
      <div class="empty-container"></div>
    </div>
  </form>
</div>

<div class="dialog-footer" *ngIf="!isLoadingGeneral">
  <app-loader-spinner-general
    *ngIf="isLoadingGeneral"
    [loadingType]="LoadingType.BUTTONS"
    [repeat]="2"
  ></app-loader-spinner-general>
  <ng-container *ngIf="!isLoadingGeneral">
    <button class="btn-primary" (click)="submit()">
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </ng-container>

  <div class="clear"></div>
</div>
