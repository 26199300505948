import { Injectable } from "@angular/core";
import { AccountSelectors } from "@api/account";
import { environment } from "@environment/environment";
import { Store } from "@ngrx/store";
import { ProviderStatuses } from "../_models/_statuses/provider-statuses";
import { CurrentUserStatus } from "../_models/current.user.status";
import { UsersService } from "../_services/users.service";

@Injectable({
  providedIn: "root",
})
export class CurrentUserHelper {
  public currentStatus: CurrentUserStatus;
  public notificationBanner: string;

  public user;

  constructor(private userService: UsersService, private store: Store) {
    this.currentStatus = new CurrentUserStatus();
    this.store.select(AccountSelectors.selectUser).subscribe((user) => {
      if (user) {
        this.setCurrentUserStatus();
      } else {
        this.deleteLocalStorageLastStatusCheck();
      }
    });
  }

  setCurrentUserStatus() {
    this.userService.getCurrentUserStatus().subscribe((data) => {
      if (
        this.currentStatus.userStatusId != data.result.user_status_id ||
        this.currentStatus.entityStatusId != data.result.entity_status_id ||
        this.currentStatus.emailConfirmed != data.result.email_user_confirmed
      ) {
        this.currentStatus.setData(data.result);
        this.setBannerMessage();
        this.setLocalStorageLastStatusCheck();
      }
      this.setLocalStorageLastStatusCheck();
    });
  }

  setLocalStorageLastStatusCheck() {
    localStorage.setItem(
      environment.last_status_timestamp,
      new Date().getTime().toString()
    );
  }

  deleteLocalStorageLastStatusCheck() {
    localStorage.removeItem(environment.last_status_timestamp);
  }

  setBannerMessage() {
    switch (this.currentStatus.entityStatusId) {
      case ProviderStatuses.statuses.declined: {
        this.notificationBanner = "STATUS_BANNER.ACCOUNT_DECLINED";
        break;
      }
      case ProviderStatuses.statuses.refused: {
        this.notificationBanner = "STATUS_BANNER.ACCOUNT_REFUSED";
        break;
      }
      case ProviderStatuses.statuses["terms-not-accepted"]: {
        this.notificationBanner = "STATUS_BANNER.TERMS_NOT_ACCEPTED";
        break;
      }

      default: {
        this.notificationBanner = "";
        break;
      }
    }

    return this.notificationBanner;
  }
}
