<app-loader-spinner-general
  *ngIf="isLoadingClient"
  [loadingType]="LoadingType.INPUT"
></app-loader-spinner-general>

<mat-form-field
  appearance="outline"
  *ngIf="!isLoadingClient"
  [formGroup]="caseForm"
>
  <mat-label> {{ "GENERALS.CLIENT" | translate }} </mat-label>
  <mat-select
    [formControl]="clientFormControl"
    [placeholder]="'GENERALS.CLIENT' | translate"
    #singleSelect
    (openedChange)="errors.updateMessage()"
    msInfiniteScroll
    (infiniteScroll)="getNextBatch()"
    [complete]="totalClients === clientsState.length || isLoading"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'GENERALS.CLIENT' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="clientsSearchForm"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let client of filteredClients$ | async"
      [value]="client"
    >
      {{ client.company_name }}
    </mat-option>
    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.INPUT"
    ></app-loader-spinner-general>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="clientFormControl" #errors></app-form-errors>
  </mat-error>
</mat-form-field>
