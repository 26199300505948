import { Component, OnDestroy, OnInit } from "@angular/core";
import { TimelineCaseTask } from "@api/case-tasks/models/timeline-case-task.model";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { TimelineSource } from "@modules/timeline/timeline-source";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-timeline-sidebar",
  templateUrl: "./timeline-sidebar.component.html",
  styleUrls: ["./timeline-sidebar.component.scss"],
})
export class TimelineSidebarComponent implements OnInit, OnDestroy {
  timelines: TimelineCaseTask[];

  private unsubscribeAll$: Subject<void>;

  dataSource: TimelineSource | null;

  constructor(public timelineService: TimelineService) {
    // Set the private defaults
    this.unsubscribeAll$ = new Subject();
  }

  ngOnInit(): void {
    this.dataSource = new TimelineSource(this.timelineService);

    this.timelineService.onTimelineChanged
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe((timelines) => {
        this.timelines = timelines;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
