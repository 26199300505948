import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { Subject, combineLatest, switchMap, take, takeUntil } from "rxjs";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";

@Component({
  selector: "app-timeline-list",
  templateUrl: "./timeline-list.component.html",
  styleUrls: ["./timeline-list.component.scss"],
})
export class TimelineListComponent implements OnInit, OnDestroy {
  @ViewChild("timelineRef", { static: true }) timelineRef: ElementRef;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    public timelineService: TimelineService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.timelineService.isTimelineOpened = true;

    this.route.params
      .pipe(
        switchMap((params) => {
          this.timelineService.params = params;

          if (!params.case) {
            return;
          }

          return combineLatest({
            case: this.timelineService.getCaseDetails(params),
            timelines: this.timelineService.getTimelines(),
          });
        })
      )
      .subscribe();

    this.timelineService.onTimelineWidthChanged
      .pipe(take(1))
      .subscribe((width) => {
        this.timelineService.sideGridTotalWidth = width;
      });

    if (this.timelineRef.nativeElement) {
      setTimeout(() => {
        this.timelineService.onTimelineWidthChanged.next(
          (this.timelineRef.nativeElement as HTMLElement).scrollWidth
        );

        this.timelineService.setEachDaysPositions();
      }, 10);
    }

    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params.case) {
        this.breadcrumbService.changeBreadcrumbs(
          this.breadcrumbService.setForCase(params.case, "Timeline")
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.timelineService.onTimelineWidthChanged.next("");
    this.timelineService.onTimelineCalendarDateChanged.next(false);
    this.timelineService.onTimelineChanged.next([]);
    this.timelineService.onServicesChanged.next([]);

    this.timelineService.isSidebarOpen = false;
    this.timelineService.isTimelineOpened = false;

    this.timelineService.currentMonth = new Date();
    this.timelineService.getAllDaysInMonth();

    this.destroyed$.complete();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
