import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserData } from "@api/account";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { combineLatest, Observable } from "rxjs";
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { filterNullish } from "src/app/lib";
import { Expat } from "src/app/_models/expat";
import { UsersService } from "src/app/_services/users.service";

@Component({
  selector: "app-expat-selector",
  templateUrl: "./expat-selector.component.html",
})
export class ExpatSelectorComponent implements OnInit {
  caseForm: UntypedFormGroup;

  @Input() submitEvent: Observable<void>;

  expatControl: UntypedFormControl;
  expatSearchFrom: UntypedFormControl;
  expats$: Observable<Expat[]>;
  filteredExpats$: Observable<Expat[]>;
  currentUser$: Observable<UserData>;

  constructor(
    private readonly userService: UsersService,
    private controlContainer: ControlContainer,
    public readonly expatService: ExpatsService
  ) {
    this.expatControl = new UntypedFormControl("", Validators.required);
    this.expatSearchFrom = new UntypedFormControl("");
  }

  ngOnInit() {
    this.submitEvent.subscribe(() => {
      if (!this.expatControl.valid) {
        this.expatControl.setErrors({ required: true });
        this.expatControl.markAsTouched();
      }
    });

    this.caseForm = <UntypedFormGroup>this.controlContainer.control;

    this.caseForm.setControl(
      "expat_name",
      new UntypedFormControl("", Validators.required)
    );
    this.expatControl.valueChanges.subscribe((expat: Expat) => {
      this.caseForm.patchValue({
        expat_id: expat.id,
        expat_name: expat.name,
      });
    });

    this.expatControl.disable({ emitEvent: false });
    this.currentUser$ = this.userService.getCurrentUser();

    this.expats$ = combineLatest([
      this.caseForm.get("client_id").valueChanges,
      this.currentUser$,
    ]).pipe(
      tap(() => this.expatControl.disable({ emitEvent: false })),
      switchMap(([clientId, user]) =>
        this.expatService.list(user.entity_id, clientId, { per_page: 500 })
      ),
      map((response) => response.result.items),
      tap(() => this.expatControl.enable({ emitEvent: false })),
      shareReplay(1)
    );
    if (this.caseForm.get("expat_id").value) {
      this.expats$
        .pipe(
          take(1),
          map((expats) =>
            expats.find(({ id }) => id == this.caseForm.get("expat_id").value)
          ),
          filterNullish()
        )
        .subscribe((expat) => {
          this.expatSearchFrom.setValue("");
          this.expatControl.setValue(expat);
        });
    }

    this.filteredExpats$ = combineLatest([
      this.expatSearchFrom.valueChanges.pipe(startWith("")),
      this.expats$,
    ]).pipe(
      map(([searchInput, expats]) => {
        if (!searchInput) {
          return expats;
        }
        const filterValue = searchInput.toLowerCase();
        return expats.filter((expat) =>
          expat.name.toLocaleLowerCase().includes(filterValue)
        );
      }),
      shareReplay(1)
    );
  }
}
