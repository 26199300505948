import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PasswordValidation } from "@modules/shared/models/password-validation";

const passwordValidatorKeys: PasswordValidation = {
  has_upper_case: false,
  has_lower_case: false,
  has_numeric: false,
  has_symbol: false,
  has_min_length: false,
};

export function passwordStrengthValidator(): ValidatorFn {
  const hasUpperCase = /[A-Z]+/;

  const hasLowerCase = /[a-z]+/;

  const hasNumeric = /[0-9]+/;

  const hasSymbol = /[!"#$%&()*+,-.^_{|}~]+/;
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      passwordValidatorKeys.has_upper_case = false;
      passwordValidatorKeys.has_lower_case = false;
      passwordValidatorKeys.has_numeric = false;
      passwordValidatorKeys.has_symbol = false;
      passwordValidatorKeys.has_min_length = false;
      return { hasPassword: passwordValidatorKeys };
    }

    passwordValidatorKeys.has_upper_case = !hasUpperCase.test(value);
    passwordValidatorKeys.has_lower_case = !hasLowerCase.test(value);
    passwordValidatorKeys.has_numeric = !hasNumeric.test(value);
    passwordValidatorKeys.has_symbol = !hasSymbol.test(value);
    passwordValidatorKeys.has_min_length = value.length < 8;

    const errors = Object.values(passwordValidatorKeys).find((hasError) => {
      if (hasError) {
        return true;
      }
    });

    if (errors) {
      return { hasPassword: passwordValidatorKeys };
    }

    return null;
  };
}

export function passwordValidator(password: string): boolean {
  const hasUpperCase = /[A-Z]+/;

  const hasLowerCase = /[a-z]+/;

  const hasNumeric = /[0-9]+/;

  const hasSymbol = /[!"#$%&()*+,-.^_{|}~]+/;
  return (
    !hasUpperCase.test(password) ||
    !hasLowerCase.test(password) ||
    !hasNumeric.test(password) ||
    !hasSymbol.test(password)
  );
}
