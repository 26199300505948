import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { QuoteModel } from "@modules/quotes/models/quote.model";

@Injectable()
export class QuoteMapperService {
  quoteModel: QuoteModel;

  constructor(private http: HttpClient) {}

  provide(input: QuoteModel) {
    return {
      ...input,
    };
  }

  mapOne({ ...input }: any): QuoteModel {
    return {
      ...input,
    };
  }

  mapMany(input: any[]): QuoteModel[] {
    return input.map((i) => this.mapOne(i));
  }
}
