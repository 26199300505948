import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { AuthenticationService } from "@api/authentication";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header-xs",
  templateUrl: "./header-xs.component.html",
  styleUrls: ["./header-xs.component.scss"],
})
export class HeaderXsComponent implements OnInit {
  isUserMenuOpen;
  user;
  @ViewChild("headerRef", { static: true }) headerRef: ElementRef;
  constructor(
    private renderer: Renderer2,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
  }

  fixedHeader(e) {
    const getHeaderHeight = this.headerRef.nativeElement.offsetHeight;

    const scrolled =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentElement ||
            document.body
          ).scrollTop;

    if (scrolled >= 30) {
      this.renderer.addClass(
        this.headerRef.nativeElement,
        "small-fixed-header-portal"
      );
    } else {
      this.renderer.removeClass(
        this.headerRef.nativeElement,
        "small-fixed-header-portal"
      );
    }
  }

  backToAdmin() {
    this.authenticationService.logout("admin").subscribe((data) => {
      window.location.href = environment.admin_portal + "dashboard";
    });
  }
}
