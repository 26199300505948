<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.TABLE"
  ></app-loader-spinner-general>
</ng-container>

<ng-container *ngIf="!isLoading">
  <div class="details-body-wrapper">
    <div class="clear"></div>
    <!-- table -->
    <ng-container *ngIf="!waitingForAdmin && agreements">
      <div class="table-wrapper">
        <ng-container *ngIf="!isXs; else responsiveTable">
          <div class="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>{{ "DOCUMENTS.ID" | translate }}</th>
                  <th>{{ "DOCUMENTS.NAME" | translate }}</th>
                  <th>
                    {{ "DOCUMENTS.SIGNED" | translate }}
                  </th>
                  <th>
                    {{ "DOCUMENTS.SIGNED-ON" | translate }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of agreements
                      | paginate
                        : {
                            itemsPerPage: itemsPage,
                            currentPage: p,
                            totalItems: total
                          };
                    let i = index
                  "
                >
                  <td class="gray-pointer">{{ item.agreement_id }}</td>
                  <td class="gray-pointer">{{ item.agreement_name }}</td>
                  <td class="gray-pointer">
                    {{
                      item.signed
                        ? ("GENERALS.YES" | translate)
                        : ("GENERALS.NO" | translate)
                    }}
                  </td>
                  <td class="gray-pointer">
                    <ng-container *ngIf="item.signed_on; else noSigned">
                      {{ item.signed_on | date: "dd/MM/yyyy hh:mm aaa" }}
                    </ng-container>
                    <ng-template #noSigned>-</ng-template>
                  </td>

                  <td>
                    <!-- table action btn -->
                    <a
                      class="table-action-btn"
                      *ngIf="!item.signed"
                      (click)="openAgreementModal(item)"
                      title="{{ 'DOCUMENTS.SIGN' | translate }}"
                    >
                      <i class="icon-sign-svg4"></i>
                    </a>

                    <a
                      class="table-action-btn"
                      (click)="
                        downloadAgreement(
                          item.agreement_id,
                          item.agreement_name
                        )
                      "
                      title="{{ 'GENERALS.DOWNLOAD-PDF' | translate }}"
                    >
                      <i class="icon-download-cloud"></i>
                    </a>

                    <!-- end table action btn -->
                  </td>
                </tr>

                <tr *ngIf="agreements?.length === 0">
                  <td colspan="6" class="gray">
                    {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-template #responsiveTable>
          <!-- responsive table -->

          <div
            class="resposive-table-wrapper"
            *ngFor="
              let item of agreements
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "DOCUMENTS.ID" | translate }}
              </div>
              <div class="responsive-table-data-item">
                {{ item.agreement_id }}
              </div>
            </div>
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "DOCUMENTS.NAME" | translate }}
              </div>
              <div class="responsive-table-data-item">
                {{ item.agreement_name }}
              </div>
            </div>
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "DOCUMENTS.SIGNED" | translate }}
              </div>
              <div class="responsive-table-data-item">
                {{
                  item.signed
                    ? ("GENERALS.YES" | translate)
                    : ("GENERALS.NO" | translate)
                }}
              </div>
            </div>
            <div *ngIf="item.signed_on" class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "DOCUMENTS.SIGNED-ON" | translate }}
              </div>
              <div *ngIf="item.signed_on" class="responsive-table-data-item">
                {{ item.signed_on | date: "dd/MM/yyyy hh:mm aaa" }}
              </div>
            </div>

            <div *ngIf="!item.signed" class="responsive-table-row">
              <div class="responsive-table-action">
                <span (click)="openAgreementModal(item)">
                  <i class="icon-sign-svg4"></i>
                  {{ "DOCUMENTS.SIGN" | translate }}
                </span>
              </div>
            </div>

            <div class="responsive-table-row">
              <div class="responsive-table-action">
                <span
                  (click)="
                    downloadAgreement(item.agreement_id, item.agreement_name)
                  "
                >
                  <i class="icon-download-cloud"></i>
                  {{ "GENERALS.DOWNLOAD-PDF" | translate }}
                </span>
              </div>
            </div>
          </div>
          <!-- end responsive table -->
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="waitingForAdmin && !agreements">
      <p class="fs-16 fw-500">
        {{ "GENERALS.WAITING-FOR-APPROVE" | translate }}.
      </p>
    </ng-container>

    <!-- end table -->
  </div>
</ng-container>
