<mat-form-field
  appearance="outline"
  class="w-100 country-selector"
  [formGroup]="documentForm"
>
  <mat-label>{{ "LABEL.COUNTRY" | translate }} </mat-label>

  <mat-select
    [placeholder]="'GENERALS.SELECT-COUNTRY' | translate"
    [formControl]="countryControl"
    #singleSelect
  >
    <ng-container *ngIf="countries$ | async as countries">
      <mat-option>
        <ngx-mat-select-search
          [placeholderLabel]="'LABEL.COUNTRY' | translate"
          [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          [formControl]="countrySearchControl"
          [clearSearchInput]="false"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let country of countries"
        [value]="country.country_code"
      >
        {{ country.name }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
