<div class="custom-modal-wrapper add-docs p-0">
  <div>
    <div class="modal-title-custom p-20">
      {{ "LABEL.SHARE-DOCUMENT" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <app-loader-spinner-general *ngIf="isLoading"></app-loader-spinner-general>

  <form [formGroup]="documentForm" *ngIf="!isLoading">
    <div class="modal-body-custom p-20">
      <!-- <div class="w-100"> -->
      <div class="row">
        <div class="w-100">
          <app-user-selector
            [formGroup]="documentForm"
            [id]="data.id"
            [type]="data.type"
          ></app-user-selector>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </form>
  <div class="clear"></div>
  <div class="modal-footer-custom bottom-buttons p-20">
    <app-loader-spinner-general
      *ngIf="isLoading"
      [repeat]="2"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoading">
      <button class="btn gray-bg fl-l modal-button" (click)="close()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <div class="clear"></div>
  </div>
</div>
