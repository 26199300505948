import { Injectable } from "@angular/core";
import { ImgPreviewStrategyComponent } from "../preview-strategies/img-preview-strategy/img-preview-strategy.component";
import { NgxDocViewerStrategyComponent } from "../preview-strategies/ngx-doc-viewer-strategy/ngx-doc-viewer-strategy.component";
import { VideoPreviewStrategyComponent } from "../preview-strategies/video-preview-strategy/video-preview-strategy.component";
import { ExcelPreviewStrategyComponent } from "../preview-strategies/excel-preview-strategy/excel-preview-strategy.component";

@Injectable()
export class PreviewStrategyFactory {
  private strategies = {
    pdf: NgxDocViewerStrategyComponent,
    jpg: ImgPreviewStrategyComponent,
    jpeg: ImgPreviewStrategyComponent,
    png: ImgPreviewStrategyComponent,
    xlsx: ExcelPreviewStrategyComponent,
    xls: ExcelPreviewStrategyComponent,
    txt: NgxDocViewerStrategyComponent,
    mp4: VideoPreviewStrategyComponent,
    docx: NgxDocViewerStrategyComponent,
    doc: NgxDocViewerStrategyComponent,
  };

  public make(type: string) {
    return this.strategies[type];
  }
}
