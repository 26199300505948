import { finalize } from "rxjs";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AgreementsService } from "@api/agreements/services/agreements.service";
import { environment } from "@environment/environment";
import { AlertService } from "../../../../_services/alert.service";
import { CrossNotificationComponent } from "../../../../_services/cross-component-notifications.component";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-terms-and-conditions-modal",
  templateUrl: "./terms-and-conditions-modal.component.html",
  styleUrls: ["./terms-and-conditions-modal.component.scss"],
})
export class TermsAndConditionsModalComponent implements OnInit {
  pdfSrc;
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TermsAndConditionsModalComponent>,
    public service: AgreementsService,
    public alertService: AlertService,
    public notificationsService: CrossNotificationComponent,
    public cookieService: CookieService
  ) {}

  ngOnInit() {
    const session = JSON.parse(
      this.cookieService.get(environment.session_local_storage_key)
    );

    this.pdfSrc = {
      url:
        environment.gateway_endpoint +
        "entities/providers/" +
        this.data.user_entity_id +
        "/agreements/" +
        this.data.agreement.agreement_id +
        "/export-pdf",
      httpHeaders: { Authorization: `Bearer ${session.token}` },
    };
  }

  accept() {
    this.isLoading = true;

    this.service
      .sign(this.data.user_entity_id, this.data.agreement.agreement_id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res) => {
          if (res && res.message) {
            this.alertService.success(res.message);
          }
          this.service.list(this.data.user_entity_id).subscribe((data) => {
            this.service.agreementsList = data;
            this.dialogRef.close();
            this.notificationsService.entityStatusChange.emit();
          });
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }
}
