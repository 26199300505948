import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RFQOverview } from "@api/stats/models";
import { OrdersByStatus } from "@api/stats/models/orders-by-status.model";
import { StatsMapperService } from "@api/stats/services/stats-mapper.service";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { ServicesByStatus } from "@api/stats/models/services-by.status";

@Injectable({ providedIn: "root" })
export class StatsService {
  public rfqOverview$: Observable<RFQOverview>;
  public ordersByStatus$: Observable<OrdersByStatus[]>;
  public servicesByStatus$: Observable<ServicesByStatus[]>;
  public soldServices$: Observable<number>;

  constructor(
    private http: HttpClient,
    private readonly statsMapper: StatsMapperService
  ) {}

  getRFQsOverview(reload = false): Observable<RFQOverview> {
    if (reload || !this.rfqOverview$) {
      this.rfqOverview$ = this.http
        .get(environment.gateway_endpoint + "statistics/rfq/overview")
        .pipe(
          map((response: any) => {
            return this.statsMapper.mapRFQOverview(response.result);
          }),
          shareReplay(1)
        );
    }

    return this.rfqOverview$;
  }

  getOrdersByStatus(reload = false): Observable<OrdersByStatus[]> {
    if (reload || !this.ordersByStatus$) {
      this.ordersByStatus$ = this.http
        .get(environment.gateway_endpoint + "statistics/orders/by-status")
        .pipe(
          map((response: any) => {
            return this.statsMapper.mapManyByStatus(
              response.result
            ) as OrdersByStatus[];
          }),
          shareReplay(1)
        );
    }
    return this.ordersByStatus$;
  }

  getServicesByStatus(reload = false): Observable<ServicesByStatus[]> {
    if (reload || !this.servicesByStatus$) {
      this.servicesByStatus$ = this.http
        .get(environment.gateway_endpoint + "statistics/services/by-status")
        .pipe(
          map((response: any) => {
            return this.statsMapper.mapManyByStatus(
              response.result
            ) as ServicesByStatus[];
          }),
          shareReplay(1)
        );
    }
    return this.servicesByStatus$;
  }

  getSoldServices(reload = false): Observable<number> {
    if (reload || !this.soldServices$) {
      this.soldServices$ = this.http
        .get(environment.gateway_endpoint + "statistics/orders/service-count")
        .pipe(
          map((response: any) => {
            return response.result;
          }),
          shareReplay(1)
        );
    }
    return this.soldServices$;
  }
}
