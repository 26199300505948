import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TemplateListComponent } from "@modules/template/components/template-list/template-list.component";
import { TemplateViewComponent } from "@modules/template/components/template-view/template-view.component";
import { TemplateComponent } from "@modules/template/shared/template/template.component";

const routes: Routes = [
  {
    path: "",
    component: TemplateComponent,
    children: [
      {
        path: "",
        component: TemplateListComponent,
      },
      {
        path: "view/:id",
        component: TemplateViewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TimelineRoutingModule {}
