import { UserData } from "@api/account";
import { CaseService } from "@api/cases/models/case.model";
import { ModelData } from "@api/shared";
export interface TaskTag {
  name: string;
  slug: string;
}
export enum TaskAllowedActions {
  UPDATE = "update",
  UPDATE_ASSIGNEE = "update_assignee",
  UPDATE_CUSTOM_START_DATE = "update_custom_start_date",
  UPDATE_CUSTOM_END_DATE = "update_custom_end_date",
  UPDATE_STATUS = "update_status",
  UPDATE_CUSTOM_DATES = "update_custom_dates",
  ADD_SUBTASK = "add_subtask",
}
export interface CaseTaskData extends ModelData {
  id: number;

  label: string;
  case_service_id: number;
  case_service: CaseService;
  assignee_id: number | null;
  assignee: UserData;
  assignee_picture: string;
  completion: number;
  completion_rate: number;
  expat_case_id: number;
  expat_name: string;
  client_name?: string;
  name: string;
  status: string;
  parent_id: number | null;
  dependent_task_id: number | null;

  start_date: Date | null;
  end_date: Date | null;
  duration: number | null;

  forecast_start_date: Date;
  forecast_end_date: Date;
  forecast_duration: number;

  custom_start_date: Date;
  custom_end_date: Date;

  milestone_name: string;

  subtasks: Array<CaseTaskData>;

  tags: Array<TaskTag>;

  statusImage?: string;
  allowed_actions?: string[];
  status_label?: string;
}

export interface CreateTaskData extends ModelData {
  assignee_id?: number | null;
  expat_case_id: number;
  name: string;
  parent_id?: number | null;
  dependent_task_id: number | null;
  duration: number | null;
}
