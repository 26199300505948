import { Component, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { FileUploadControl } from "@iplab/ngx-file-upload";

@Component({
  selector: "app-file-selector",
  templateUrl: "./file-selector.component.html",
})
export class FileSelectorComponent implements OnInit {
  documentForm: UntypedFormGroup;
  fileControl: UntypedFormControl;

  public fileUploadControl = new FileUploadControl();

  constructor(private controlContainer: ControlContainer) {
    this.fileControl = new UntypedFormControl("");
  }

  ngOnInit() {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("file", this.fileControl);
  }
}
