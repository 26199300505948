<div *ngIf="categories">
  <div class="form-group">
    <label *ngIf="level === 0 && showLabel"
      >{{ "GENERALS.SERVICE-CATEGORY" | translate }}
      <span *ngIf="mandatoryField">*</span>
      <span
        appHoverHelper
        *ngIf="helper && helper.level === 0"
        class="input-helper"
        ><i class="icon-help_comp"></i>
        <span class="helper-description">{{ helper.text | translate }}</span>
      </span>
    </label>
    <label *ngIf="level > 0 && showLabel"
      >{{ "GENERALS.SUBCATEGORY" | translate }}
      <span *ngIf="mandatoryField">*</span>
      <span
        appHoverHelper
        *ngIf="helper && helper.level > 0"
        class="input-helper"
        ><i class="icon-help_comp"></i>
        <span class="helper-description">{{ helper.text | translate }}</span>
      </span>
    </label>

    <ng-select
      class="customiz-select"
      [(ngModel)]="selectedPath"
      (change)="selectCategory($event)"
      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      placeholder="{{ placeholder }}"
      [clearable]="false"
      [id]="'category_' + level"
    >
      <ng-option
        *ngFor="let category of categories | keyvalue"
        [value]="category.key"
        >{{ category.value.label }}</ng-option
      >
    </ng-select>
  </div>
</div>

<ng-container *ngIf="selectedCategory && selectedCategory.children_count">
  <app-category-selection
    [categories]="selectedCategory.children"
    [preselectedPath]="preselectedPath"
    [level]="selectedCategory.level + 1"
    (categorySelected)="forward($event)"
    [clear]="clear"
    [mandatoryField]="mandatoryField"
    [isAd]="isAd"
    (intermediaryCategory)="intermediaryCategory.emit()"
    [showLabel]="showLabel"
    (categoryChange)="onClickChild()"
  ></app-category-selection>
</ng-container>
