<ng-container *ngIf="!data.expat || !data.countries || !data.maritalStatuses">
  <app-loader-spinner-general
    [repeat]="3"
    [loadingType]="LoadingType.INPUT"
  ></app-loader-spinner-general>
  <app-loader-spinner-general
    [repeat]="2"
    class="d-flex justify-content-end"
    [loadingType]="LoadingType.BUTTONS"
  ></app-loader-spinner-general>
</ng-container>

<form
  [formGroup]="form"
  *ngIf="form && data.expat && data.countries && data.maritalStatuses"
>
  <div class="details-2c edit-personal-info-form p-0">
    <div class="white-box-wrapper custom-modal-wrapper p-0">
      <div class="white-box-row">
        <div class="modal-title-custom fl-l p-20">
          {{ "EXPATS.PERSONAL-INFORMATION" | translate }}
        </div>

        <div class="clear"></div>
        <div class="white-box-body p-20">
          <div class="row">
            <div class="w-50">
              <mat-form-field appearance="outline" class="birth_date">
                <mat-label>{{ "EXPATS.BORN-ON" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="birth_date"
                  [matDatepicker]="dpBirthDate_date"
                  name="birth_date"
                  placeholder="DD/MM/YYYY"
                />
                <mat-datepicker-toggle matSuffix [for]="dpBirthDate_date">
                </mat-datepicker-toggle>
                <mat-datepicker #dpBirthDate_date></mat-datepicker>
                <mat-error
                  *ngIf="form.controls.birth_date.errors?.futureDate"
                  class="m-b-10"
                >
                  {{ "GENERALS.FUTURE-DATE" | translate }}
                </mat-error>
                <mat-error *ngIf="form.controls.birth_date.errors?.invalidDate">
                  Invalid date
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{
                  "EXPATS.COUNTRY-OF-BIRTH" | translate
                }}</mat-label>
                <mat-select
                  formControlName="birth_country"
                  [placeholder]="'LABEL.COUNTRY' | translate"
                  id="birth_country"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [placeholderLabel]="'LABEL.COUNTRY' | translate"
                      [noEntriesFoundLabel]="
                        'GENERALS.NO-ITEMS-DISPLAY' | translate
                      "
                      [formControl]="countryFilterControl"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let country of filteredCountries"
                    [value]="country.country_code"
                  >
                    {{ country.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.CITY-OF-BIRTH" | translate }}</mat-label>
                <mat-select
                  formControlName="birth_city"
                  [placeholder]="'EXPATS.CITY-OF-BIRTH' | translate"
                  id="birth_city"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [placeholderLabel]="'EXPATS.CITY-OF-BIRTH' | translate"
                      [noEntriesFoundLabel]="
                        'GENERALS.NO-ITEMS-DISPLAY' | translate
                      "
                      [formControl]="cityFilterControl"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let city of filteredCities$ | async"
                    [value]="city.name"
                  >
                    {{ city.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.LANGUAGE" | translate }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="primary_language"
                  name="primary_language"
                />
              </mat-form-field>
            </div>
            <div class="w-50">
              <mat-form-field appearance="outline">
                <mat-label>{{ "EXPATS.MARITAL-STATUS" | translate }}</mat-label>
                <mat-select
                  formControlName="marital_status_id"
                  id="marital_status"
                >
                  <mat-option
                    [value]="maritalStatus.id"
                    *ngFor="let maritalStatus of data.maritalStatuses"
                  >
                    {{ maritalStatus.text }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="w-50" *ngIf="data.educationForms">
              <mat-form-field appearance="outline">
                <mat-label>{{
                  "EXPATS.HIGHEST-FORM-EDUCATION" | translate
                }}</mat-label>
                <mat-select
                  formControlName="education_form_id"
                  id="education_form"
                >
                  <mat-option
                    [value]="educationForm.id"
                    *ngFor="let educationForm of data.educationForms"
                  >
                    {{ educationForm.text }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="fl-r p-20">
          <button class="btn gray-bg fl-l modal-button" (click)="closeModal()">
            <mat-icon svgIcon="cancel"></mat-icon>
            {{ "GENERALS.CANCEL" | translate }}
          </button>
          <button
            class="btn orange-bg fl-r m-l-20 save-personal-info modal-button"
            (click)="save()"
          >
            <mat-icon svgIcon="save"></mat-icon>
            {{ "GENERALS.SAVE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
