import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Dictionary } from "../../../../../../../../_models/dictionary";
import { startWith } from "rxjs/operators";

@Component({
  selector: "app-multiple-selector",
  templateUrl: "./multiple-selector.component.html",
  styleUrls: ["./multiple-selector.component.scss"],
})
export class MultipleSelectorComponent implements OnInit {
  @Input() formGroup;
  @Input() itemsList;
  @Input() placeholder;
  @Input() label;
  @Input() selectorCtrl;
  @Output() closed = new EventEmitter();

  changed: boolean = false;

  searchControl = new FormControl();
  filteredItems: Dictionary[];
  items = [];

  ngOnInit() {
    this.setShowItems();
    this.selectorCtrl.valueChanges.subscribe(() => (this.changed = true));
  }

  onToppingRemoved(topping) {
    const toppings = this.selectorCtrl.value;
    this.removeFirst(toppings, topping);
    this.selectorCtrl.setValue(toppings);
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  protected filterResults() {
    this.searchControl.valueChanges.pipe(startWith("")).subscribe((value) => {
      if (!value) {
        this.filteredItems = this.itemsList;

        return;
      }

      this.filteredItems = this.itemsList.filter((item: any) =>
        item?.text.toLowerCase().includes(value.toLowerCase())
      );
    });
  }

  private setShowItems() {
    this.itemsList.forEach((item) => {
      this.items[item.id] = item;
    });
    this.filterResults();
    this.updateSelectedValues();
  }

  public closeSelect() {
    if (this.changed) {
      this.closed.emit();
    }
  }

  private updateSelectedValues() {
    if (this.selectorCtrl.getRawValue() !== null) {
      Object.values(this.selectorCtrl.getRawValue()).forEach((key) => {
        if (!this.items[key.toString()]) {
          this.onToppingRemoved(key);
        }
      });
    }
  }
}
