import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class SalesService {
  constructor(private http: HttpClient) {}
  // GET sales list
  getSalesList(params = {}, id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/offers/sales/",
      {
        params: params,
      }
    );
  }

  // GET list of associated offers list
  getAssociatedOffers(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + `offers/offers/${id}/associations`
    );
  }

  // associate provider offer
  associateOffer(params, id): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint + "offers/offers/" + id + "/associate",
      params,
      httpOptions
    );
  }

  // match provider offer
  matchBestPriceOffer(params, id): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        "offers/offers/sales-list/match-price/" +
        id,
      params,
      httpOptions
    );
  }

  /**
   * Export filtered sales to XLS
   * @param params
   */
  exportSales(params = {}) {
    return this.http.get(
      environment.gateway_endpoint + "offers/offers/export/sales/",
      {
        responseType: "blob",
        params: params,
      }
    );
  }
}
