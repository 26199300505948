<div class="tag-wrapper">
  <div class="select-group-block">
    <ng-select
      class="multiple-select"
      [formGroup]="documentForm"
      [addTag]="true"
      [addTagText]="'GENERALS.ADD-TAG' | translate"
      [placeholder]="'GENERALS.SELECT-TAG' | translate"
      [formControl]="tagControl"
      [items]="filteredTags"
      [searchable]="true"
      [clearable]="true"
      [multiple]="true"
      bindLabel="text"
      bindValue="text"
      (search)="onSearch($event)"
      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      (focusout)="focusOut($event)"
      appearance="outline"
    >
    </ng-select>
  </div>
</div>
