<mat-form-field appearance="outline" class="w-full" [formGroup]="caseForm">
  <mat-label> {{ "GENERALS.EXPAT" | translate }} </mat-label>
  <mat-select
    [formControl]="expatControl"
    [placeholder]="'GENERALS.EXPAT' | translate"
    #singleSelect
    (openedChange)="errors.updateMessage()"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'GENERALS.EXPAT' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="expatSearchFrom"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let expat of filteredExpats$ | async" [value]="expat">
      {{ expat.name }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="expatControl" #errors></app-form-errors>
  </mat-error>
</mat-form-field>
