import { CommonModule, TitleCasePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";

import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSortModule } from "@angular/material/sort";
import { RouterModule } from "@angular/router";
import { CategorySelectionComponent } from "@modules/shared/_components/category-selection/category-selection.component";
import { WindowScrollDashboardBodyTopDirective } from "@modules/shared/_directives/window-scroll-dashboardBodyTop";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSmartModalModule } from "ngx-smart-modal";
import { TaskTimelineComponent } from "src/app/components/shared/task-timeline/task-timeline.component";
import { ActivityComponent } from "./_components/activity/activity.component";
import { DetailsNavigationComponent } from "./_components/details-navigation/details-navigation.component";
import { ElementsOnPageComponent } from "./_components/elements-on-page/elements-on-page.component";
import { BulkActionsComponent } from "./_components/items-list/bulk-actions/bulk-actions.component";
import { FiltersComponent } from "./_components/items-list/filters/filters.component";
import { ItemsListComponent } from "./_components/items-list/items-list.component";
import { RatingComponent } from "./_components/rating/rating.component";
import { DateRangeCalendarComponent } from "./_components/date-range-calendar/date-range-calendar.component";
import { HoverHelperDirective } from "./_directives/HoverHelperDirective";
import { LoaderSpinnerGeneralComponent } from "./_directives/loader-spinner-general/loader-spinner-general.component";
import { ToggleCalendarInputDirective } from "./_directives/toggle-calendar-input";
import { ToggleActionTableDirective } from "./_directives/toggle-table-action";
import { StsCalendarHelperDirective } from "./_directives/z-index-helper";
import { KeysPipe } from "./_pipes/keys.pipe";
import { SelectionWorkflowPipe } from "./_pipes/selection-workflow.pipe";
import { SortDatePipe } from "./_pipes/sortDate.pipe";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { UserDetailsActionsPanelComponent } from "@modules/shared/_components/details-action-panel/details-action-panel.component";
import { AddProfilePictureComponent } from "@modules/shared/_components/add-profile-picture/add-profile-picture.component";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { OrderPopupComponent } from "@modules/shared/_components/order-popup/order-popup.component";
import { AddExpatForm } from "@modules/shared/_components/add-expat-form/add-expat-form.component";
import { AlreadySelectedPipe } from "@modules/shared/_pipes/already-selected.pipe";
import { WindowScrollAppNavDirective } from "./_directives/window-scroll-app-nav";
import { WorkflowCopyPopupComponent } from "./_components/workflow-copy-popup/workflow-copy-popup.component";
import { DeleteModalComponent } from "./_components/delete-modal/delete-modal.component";
import { AddDependentForm } from "@modules/shared/_components/add-dependent-form/add-dependent-form.component";
import { ConfirmActionModalComponent } from "./_components/confirm-action-modal/confirm-action-modal.component";
import { FormErrorsComponent } from "./_components/form-errors/form-errors.component";
import {
  MomentDateModule,
  MatMomentDateModule,
} from "@angular/material-moment-adapter";
import { UpdateStatusComponent } from "./_components/update-status/update-status.component";
import { ExpatCardComponent } from "./_components/expat-card/expat-card.component";
import { FollowersComponent } from "./_components/followers/followers.component";
import { OrdinalDatePipe } from "./_pipes/ordinal-date.pipe";
import { SortByPipe } from "./_pipes/sort-by.pipe";
import { UserProfileImageComponent } from "./_components/user-profile-image/user-profile-image.component";
import { EditUserDetailsComponent } from "./_components/edit-user-details/edit-user-details.component";
import { PermissionsComponent } from "./_components/permissions/permissions.component";
import { FormErrorDirective } from "src/app/_directives/form-error.directive";
import { CurrencySelectorComponent } from "./_components/currency-selector/currency-selector.component";
import { NotAvailablePipe } from "./_pipes/not-available.pipe";
import { AuthorizationModule } from "@modules/authorization/authorization.module";
import { AddPermissionFormComponent } from "./_components/add-permission-form/add-permission-form.component";
import { TooltipListPipe } from "./_pipes/tooltip-list.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FileDragAndDropDirective } from "./_directives/file-drag-and-drop.directive";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { CountryPipe } from "./_pipes/country.pipe";
import { IsVisibleDirective } from "./_directives/is-visible.directive";
import { BackButtonComponent } from "./_components/back-button/back-button.component";
import { NotApplicablePipe } from "@modules/shared/_pipes/not-applicable.pipe";
import { TimeRemainingPipe } from "./_pipes/time-remaining.pipe";
import { DatePickerActions } from "src/app/standalone/components/datepicker-actions/datepicker-actions.component";
import { StatusStyleDirective } from "./_directives/status-style.directive";
import { CountryOptionsComponent } from "./_components/country-options/country-options.component";
import { DialogModule } from "./dialog/dialog.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DateModule } from "@modules/util/date/date.module";
import { SecondaryStatusStyleDirective } from "@modules/shared/_directives/secondary-status-style.directive";
import { ColumnSelectionComponent } from "./_components/column-selection/column-selection.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    FileUploadModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatRadioModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatListModule,
    MatExpansionModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    NgSelectModule,
    NgxChartsModule,
    NgxPaginationModule,
    RouterModule,
    TranslateModule,
    NgxSmartModalModule,
    MomentDateModule,
    MatMomentDateModule,
    AuthorizationModule,
    MatChipsModule,
    MatTooltipModule,
    MatSelectInfiniteScrollModule,
    DatePickerActions,
    DialogModule,
    MatProgressSpinnerModule,
    DateModule,
  ],
  exports: [
    ActivityComponent,
    MatListModule,
    RatingComponent,
    LoaderSpinnerGeneralComponent,
    DateRangeCalendarComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FileUploadModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatRadioModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatTabsModule,
    MatMenuModule,
    DateModule,
    MatAutocompleteModule,
    NgSelectModule,
    NgxChartsModule,
    NgxPaginationModule,
    MatSelectInfiniteScrollModule,
    StsCalendarHelperDirective,
    ToggleCalendarInputDirective,
    ToggleActionTableDirective,
    HoverHelperDirective,
    ItemsListComponent,
    ItemsListComponent,
    BulkActionsComponent,
    FiltersComponent,
    TranslateModule,
    ElementsOnPageComponent,
    TaskTimelineComponent,
    DetailsNavigationComponent,
    CategorySelectionComponent,
    UserDetailsActionsPanelComponent,
    AddProfilePictureComponent,
    ConfirmActionModalComponent,
    OrderPopupComponent,
    AddExpatForm,
    AddDependentForm,
    FollowersComponent,
    KeysPipe,
    SortByPipe,
    CountryPipe,
    TimeRemainingPipe,
    AlreadySelectedPipe,
    SelectionWorkflowPipe,
    SortDatePipe,
    OrdinalDatePipe,
    TooltipListPipe,
    WindowScrollDashboardBodyTopDirective,
    WindowScrollAppNavDirective,
    FormErrorsComponent,
    UpdateStatusComponent,
    ExpatCardComponent,
    UserProfileImageComponent,
    EditUserDetailsComponent,
    PermissionsComponent,
    MomentDateModule,
    MatMomentDateModule,
    FormErrorDirective,
    CurrencySelectorComponent,
    MatTooltipModule,
    NotAvailablePipe,
    FileDragAndDropDirective,
    IsVisibleDirective,
    BackButtonComponent,
    NotApplicablePipe,
    DatePickerActions,
    StatusStyleDirective,
    CountryOptionsComponent,
    DialogModule,
    MatProgressSpinnerModule,
    SecondaryStatusStyleDirective,
    ColumnSelectionComponent,
  ],
  declarations: [
    //  pipes
    OrdinalDatePipe,
    KeysPipe,
    SortByPipe,
    SelectionWorkflowPipe,
    SortDatePipe,
    AlreadySelectedPipe,
    TooltipListPipe,
    // components
    TaskTimelineComponent,
    ActivityComponent,
    ElementsOnPageComponent,
    LoaderSpinnerGeneralComponent,
    DateRangeCalendarComponent,
    StsCalendarHelperDirective,
    RatingComponent,
    ItemsListComponent,
    BulkActionsComponent,
    FiltersComponent,
    DetailsNavigationComponent,
    CategorySelectionComponent,
    CountryOptionsComponent,

    // directives
    ToggleCalendarInputDirective,
    ToggleActionTableDirective,
    WindowScrollAppNavDirective,
    HoverHelperDirective,
    WindowScrollDashboardBodyTopDirective,
    UserDetailsActionsPanelComponent,
    AddProfilePictureComponent,
    OrderPopupComponent,
    AddExpatForm,
    WorkflowCopyPopupComponent,
    DeleteModalComponent,
    AddDependentForm,
    ConfirmActionModalComponent,
    FormErrorsComponent,
    UpdateStatusComponent,
    ExpatCardComponent,
    FollowersComponent,
    UserProfileImageComponent,
    EditUserDetailsComponent,
    PermissionsComponent,
    FormErrorDirective,
    CurrencySelectorComponent,
    NotAvailablePipe,
    AddPermissionFormComponent,
    FileDragAndDropDirective,
    CountryPipe,
    TimeRemainingPipe,
    IsVisibleDirective,
    BackButtonComponent,
    NotApplicablePipe,
    StatusStyleDirective,
    SecondaryStatusStyleDirective,
    ColumnSelectionComponent,
  ],
  providers: [TitleCasePipe],
})
export class SharedModule {}
