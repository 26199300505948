import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ActionTypes } from "@api/messages";

@Injectable()
export class WidgetsEffects {
  constructor(private actions$: Actions, private store: Store) {}

  setNotification$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.SET_NOTIFICATION),
      mergeMap((action) => [ActionTypes.SET_NOTIFICATION_SUCCESS()])
    )
  );

  setMessages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.SET_MESSAGES),
      mergeMap((action) => [ActionTypes.SET_MESSAGES_SUCCESS(action.payload)])
    )
  );
}
