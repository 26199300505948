import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { TimelineCaseTask } from "@api/case-tasks/models/timeline-case-task.model";

@Directive({
  selector: "[appSidebarTimeline]",
})
export class SidebarTimelineDirective implements OnInit {
  @Input() task?: TimelineCaseTask;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private timelineService: TimelineService
  ) {}

  ngOnInit(): void {
    const timelinePositions = this.timelineService.formatTimelinePositions(
      this.task
    );

    const startDatePosition =
      this.timelineService.timelineHeaderDaysPositions[
        this.task.startDateDay - 1
      ];
    this.renderer.setStyle(
      this.el.nativeElement,
      "left",
      `${
        startDatePosition.startPosition +
        timelinePositions.positionOfTimelineStartElement
      }px`
    );

    let endDatePosition =
      this.timelineService.timelineHeaderDaysPositions[
        this.task.endDateDay - 1
      ];

    if (this.task.isNextMonthOverlapped && !endDatePosition) {
      endDatePosition = {
        day: 1,
        startPosition: this.timelineService.sideGridTotalWidth,
      };
    }

    if (endDatePosition && startDatePosition) {
      const timelineWidthElement =
        endDatePosition.startPosition -
        startDatePosition.startPosition +
        timelinePositions.totalWidthOfEndPosition;
      this.renderer.setStyle(
        this.el.nativeElement,
        "width",
        `${timelineWidthElement}px`
      );
    }
  }
}
