import { Injectable } from "@angular/core";
import { AddressData } from "../models/address.model";

@Injectable({
  providedIn: "root",
})
export class AddressMapperService {
  mapOne(input: any): AddressData {
    return {
      id: input.id,
      address: input.address,
      city: input.city,
      usage: input.usage,
      countryCode: input.country_code,
      state: input.state,
      postCode: input.post_code,
    };
  }

  mapMany(input: any[]): AddressData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: AddressData) {
    return {
      id: input.id,
      address: input.address,
      city: input.city,
      country_code: input.countryCode,
      state: input.state,
      post_code: input.postCode,
      usage: input.usage,
    };
  }
}
