<ng-container *ngIf="!xs; else xsLayoutAdmin">
  <app-left-navigation></app-left-navigation>
  <div class="dashboard-wrapper" [class.d-flex]="timelineService.isSidebarOpen">
    <app-header></app-header>

    <app-timeline-services-sidebar
      *ngIf="timelineService.isTimelineOpened"
    ></app-timeline-services-sidebar>

    <div class="dashboard-body">
      <div class="notification-banner" *ngIf="statusBanner">
        <span
          *ngIf="
            userHelper.notificationBanner === 'STATUS_BANNER.TERMS_NOT_ACCEPTED'
          "
        >
          {{ this.userHelper.notificationBanner | translate
          }}<a routerLink="/settings/xpath-terms-and-conditions">{{
            "STATUS_BANNER.TERMS_AND_CONDITIONS" | translate
          }}</a
          >.
        </span>
        <span
          *ngIf="
            userHelper.notificationBanner === 'STATUS_BANNER.ACCOUNT_REFUSED'
          "
        >
          {{ this.userHelper.notificationBanner | translate
          }}<a routerLink="/settings">{{
            "STATUS_BANNER.ACCOUNT_SETTINGS" | translate
          }}</a
          >.
        </span>
        <span
          *ngIf="
            !(
              userHelper.notificationBanner ===
                'STATUS_BANNER.ACCOUNT_REFUSED' ||
              userHelper.notificationBanner ===
                'STATUS_BANNER.TERMS_NOT_ACCEPTED'
            )
          "
        >
          {{ this.userHelper.notificationBanner | translate }}
        </span>
      </div>
      <div class="notification-banner" *ngIf="!userIsConfirmed">
        {{ "GENERALS.CONFIRM-EMAIL-ADDRESS" | translate }} Click
        <span (click)="resendConfirmationMail()" class="action-link-white">
          here</span
        >
        to resend it.
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<ng-template #xsLayoutAdmin>
  <div class="dashboard-wrapper">
    <app-header-xs></app-header-xs>

    <app-left-navigation-xs></app-left-navigation-xs>

    <div class="dashboard-body">
      <div
        class="notification-banner"
        *ngIf="statusBanner"
        [innerHTML]="statusBanner | translate"
      ></div>
      <div class="notification-banner" *ngIf="!userIsConfirmed">
        {{ "GENERALS.CONFIRM-EMAIL-ADDRESS" | translate }} Click
        <span (click)="resendConfirmationMail()" class="action-link-white">
          here</span
        >
        to resend it.
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
