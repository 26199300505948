import { Component, OnInit } from "@angular/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AdsService } from "src/app/_services/ads.service";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "../../_services/breadcrumb.service";

@Component({
  selector: "app-ads",
  templateUrl: "./ads.component.html",
  styleUrls: ["./ads.component.scss"],
})
export class AdsComponent implements OnInit {
  isLoading: boolean;
  request_call;
  listAdBanner;
  itemsPage: number;
  p: number;
  total: number;
  isXs: boolean;
  editItem;
  listAdBannersStatusTransitions;
  campaignDetails;
  previewDetails;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private adsService: AdsService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/ads", "Ad campaigns")
    );
  }

  ngOnInit() {
    this.getListAdBannersStatusTransitions();
    this.getListAdBanner(1);
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  addAdBanner() {
    this.ngxSmartModalService.getModal("createAdBanner").open();
  }

  getListAdBanner(page: number) {
    const params = {};
    params["page"] = page;
    this.isLoading = true;
    if (this.request_call) {
      this.request_call.unsubscribe();
    }
    this.request_call = this.adsService
      .getListAdBanner(params)
      .subscribe((data) => {
        this.listAdBanner = data.result.items;
        this.itemsPage = data.result.perPage;
        this.p = data.result.currentPage;
        this.total = data.result.total;
        this.isLoading = false;
      });
  }

  updateAdBanner(sts, id) {
    const status = {};
    status["status"] = sts;

    this.adsService.updateAdBanner(status, id).subscribe((data) => {
      this.getListAdBanner(1);
    });
  }

  getListAdBannersStatusTransitions() {
    this.adsService.getListAdBannersStatusTransitions().subscribe((data) => {
      this.listAdBannersStatusTransitions = data.result;
    });
  }

  editAdBanner(item) {
    this.editItem = item;
    this.ngxSmartModalService.getModal("updateAdBanner").open();
  }

  openCampaignDetails(e) {
    this.campaignDetails = e;
    this.ngxSmartModalService.getModal("detailsCampaign").open();
  }

  previewBanner(e) {
    this.previewDetails = e;
    this.ngxSmartModalService.getModal("previewBanner").open();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
