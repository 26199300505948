<form [formGroup]="caseForm">
  <div class="flex gap-4">
    <div class="w-1/2">
      <app-client-selector
        [required]="true"
        class="w-full"
        [formGroup]="caseForm"
        [submitEvent]="submitForm.asObservable()"
      ></app-client-selector>
    </div>

    <div class="w-1/2">
      <app-expat-selector
        class="w-full"
        [formGroup]="caseForm"
        [submitEvent]="submitForm.asObservable()"
      ></app-expat-selector>
    </div>
  </div>
  <div class="flex gap-4">
    <div class="w-1/2">
      <app-assignment-selector
        class="d-block w-full"
        [formGroup]="caseForm"
        [submitEvent]="submitForm.asObservable()"
      ></app-assignment-selector>
    </div>
    <div class="w-1/2">
      <app-order-selector
        class="d-block w-full"
        [formGroup]="caseForm"
        [submitEvent]="submitForm.asObservable()"
      ></app-order-selector>
    </div>
  </div>
  <app-subject-selector
    class="block w-full"
    [formGroup]="caseForm"
    [submitEvent]="submitForm.asObservable()"
  ></app-subject-selector>
  <app-order-services-selector
    class="block w-full"
    [formGroup]="caseForm"
    [submitEvent]="submitForm.asObservable()"
  ></app-order-services-selector>
</form>
