<div class="card">
  <div class="plan-name">{{ plan.name }}</div>
  <div class="cost">
    {{
      plan.amount | currency: plan.currency.toUpperCase():"symbol":"3.0"
    }}/<span>{{ plan.interval }}</span>
  </div>
  <div class="plan-description">{{ plan.description }}</div>
  <button
    [disabled]="current"
    [ngClass]="{ 'current-plan-button': current }"
    class="select-plan"
    (click)="purchase(plan.id)"
  >
    <ng-container *ngIf="current">{{
      "BILLING.CURRENT-PLAN" | translate
    }}</ng-container>
    <ng-container *ngIf="!current">{{
      "BILLING.SELECT" | translate
    }}</ng-container>
  </button>
  <ul class="benefits">
    <li
      *ngFor="let feature of plan.features"
      [ngClass]="{ disabled: !feature.enabled }"
    >
      <mat-icon *ngIf="feature.enabled">check</mat-icon>
      <mat-icon *ngIf="!feature.enabled">close</mat-icon>
      {{ feature.label }}
    </li>
  </ul>
</div>
