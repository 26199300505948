<div class="custom-modal-wrapper add-expat p-0">
  <div class="modal-title-custom p-20" *ngIf="!edit">
    {{ "PORTAL.ADD-EXPAT" | translate }}
  </div>
  <div class="modal-title-custom p-20" *ngIf="edit">
    {{ "PORTAL.EDIT-EXPAT" | translate }}
  </div>
  <div *ngIf="showExpatAddedMessage" class="inner-notification-msg">
    {{
      "PORTAL.EXPAT-CREATED"
        | translate
          : {
              first_name: recentlyAddedExpat.user.first_name,
              last_name: recentlyAddedExpat.user.last_name
            }
    }}
  </div>
  <ng-container *ngIf="!expat">
    <app-loader-spinner-general
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      [repeat]="2"
      class="d-flex justify-content-end"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
  </ng-container>
  <form [formGroup]="form" *ngIf="form && expat">
    <div class="modal-body-custom p-20 m-0">
      <div class="row">
        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100 p-0">
            <mat-label>{{ "LABEL.FIRST-NAME" | translate }} </mat-label>
            <input matInput formControlName="first_name" name="first_name" />
            <mat-error *ngIf="form.controls.first_name.errors?.required">
              {{ "GENERALS.REQUIRED-FIELD" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100 p-0">
            <mat-label>{{ "LABEL.LAST-NAME" | translate }} </mat-label>
            <input
              matInput
              type="text"
              formControlName="last_name"
              name="last_name"
            />
            <mat-error *ngIf="form.controls.last_name.errors?.required">
              {{ "GENERALS.REQUIRED-FIELD" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100 p-0">
            <mat-label>{{ "LABEL.EMAIL" | translate }} </mat-label>
            <input matInput type="text" formControlName="email" name="email" />
            <mat-error *ngIf="form.controls.email.errors?.required">
              {{ "GENERALS.REQUIRED-FIELD" | translate }}
            </mat-error>
            <mat-error *ngIf="form.controls.email.errors?.email">
              {{ "GENERALS.EMAIL-INVALID" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="w-50">
          <div class="row">
            <div class="w-50 p-0">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label
                  >{{ "GENERALS.SELECT-PHONE-CODE" | translate }}
                </mat-label>
                <mat-select
                  formPrefix="client"
                  #singleSelect
                  formControlName="phone_prefix"
                  id="phone_prefix"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [placeholderLabel]="
                        'GENERALS.SELECT-PHONE-CODE' | translate
                      "
                      [noEntriesFoundLabel]="
                        'GENERALS.NO-ITEMS-DISPLAY' | translate
                      "
                      [formControl]="phonePrefixSearchControl"
                      id="phone_prefix_search"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let prefix of filteredPrefixes$ | async"
                    [value]="prefix.phone_code"
                  >
                    {{ prefix.phone_code }}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="form.controls.phone_prefix.errors?.match">
                  {{ "EXPATS.PHONE-PREFIX-MATCH" | translate }}
                </mat-error>
              </mat-form-field>
              <div class="error-wrapper"></div>
            </div>

            <div class="w-50 expat-phone-number">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "EXPATS.PHONE-NUMBER" | translate }} </mat-label>
                <input matInput formControlName="phone" name="phone" />
                <mat-error *ngIf="form.controls.phone_prefix.errors?.match">
                  {{ "EXPATS.PHONE-PREFIX-MATCH" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="w-50">
          <mat-form-field
            appearance="outline"
            class="w-100 p-0"
            [ngClass]="{ 'm-b-15': !!form.controls.country_code.errors }"
          >
            <mat-label>{{ "LABEL.RESIDENCE-COUNTRY" | translate }} </mat-label>
            <mat-select
              formControlName="country_code"
              id="country_of_residence"
              [placeholder]="'LABEL.COUNTRY' | translate"
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'LABEL.COUNTRY' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="countryFilterControl"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let country of filteredCountries"
                [value]="country.country_code"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="form.controls.country_code.errors?.required">
              {{ "GENERALS.REQUIRED-FIELD" | translate }}
            </mat-error>
            <mat-error
              class="m-b-10"
              *ngIf="form.controls.country_code.errors?.match"
            >
              {{ "EXPATS.COUNTRY-MATCH" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100 p-0">
            <mat-label>{{ "EXPATS.PAYROLL-ID" | translate }} </mat-label>
            <input matInput formControlName="payroll_id" name="payroll_id" />
            <mat-error *ngIf="form.controls.payroll_id.errors?.required">
              {{ "GENERALS.REQUIRED-FIELD" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100 p-0">
            <mat-label>{{ "LABEL.DEPARTMENT" | translate }} </mat-label>
            <input
              matInput
              formControlName="department_name"
              name="department_name"
            />
          </mat-form-field>
        </div>

        <div class="w-50">
          <mat-form-field appearance="outline" class="w-100 p-0">
            <mat-label>
              {{ "LABEL.POSITION-IN-COMPANY" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              formControlName="company_position"
              name="company_position"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div class="clear"></div>

  <div class="modal-footer-custom bottom-buttons p-20 m-0">
    <ng-container *ngIf="!isLoadingGeneral; else loadingButtons">
      <button
        mat-dialog-close
        class="btn gray-bg fl-l modal-button"
        (click)="emitChanges('cancel')"
      >
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button
        class="btn orange-bg fl-r save-button modal-button"
        (click)="emitChanges('save')"
      >
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <ng-template #loadingButtons>
      <app-loader-spinner-general
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
    </ng-template>
    <div class="clear"></div>
  </div>
</div>
