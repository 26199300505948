import { Component, Inject, EventEmitter, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DictionariesService } from "../../../../_services/dictionaries.service";
import { ExpatsService } from "../../../expats/services/expats.service";
import { OrdersService } from "../../../../_services/orders.service";
import { UsersService } from "src/app/_services/users.service";
import { MarketService } from "@modules/shared/services/market.service";
import { UserDetails } from "src/app/_models/user-details";
import { AlertService } from "src/app/_services/alert.service";
import * as moment from "moment";

@Component({
  selector: "app-order-modal",
  templateUrl: "./order-popup.component.html",
  styleUrls: ["./order-popup.component.scss"],
})
export class OrderPopupComponent implements OnInit {
  order;
  offer;
  stage;
  status_id = 1;
  expat;
  expatsList;
  orderParamIDentityNumber;
  user;
  entityInfo = new UserDetails();
  offerID;
  expatsNr = 0;
  selectedExpats = [];
  isAssigned = false;
  expatId;
  totalNoExpats;
  onAddMore = new EventEmitter();
  minDate: Date;
  currentUser;
  clientId: number;

  constructor(
    public dialogRef: MatDialogRef<OrderPopupComponent>,
    private router: Router,
    private dictionariesService: DictionariesService,
    private userService: UsersService,
    private expatsService: ExpatsService,
    private orderService: OrdersService,
    private marketService: MarketService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.stage = data.type;
    this.clientId = parseInt(data.clientId, 10);
    this.expat = data.expat ? data.expat : null;
    this.orderParamIDentityNumber = data.id ? data.id : null;

    if ("data" in data) {
      if (data.data !== undefined) {
        this.order = data.data.order || {};
        this.offer = data.data.offer || {};
      }
    }

    if (this.stage === "assignExpat") {
      this.offerID = data.data.offerID;
      this.totalNoExpats = data.data.len;
      // for (let i = 0; i < data.data.len; i++) {
      //   this.selectedExpats[i] = {
      //     id: null,
      //     arriving_on: null
      //   };
      // }
      if (this.totalNoExpats < 5) {
        for (let i = 0; i < this.totalNoExpats; i++) {
          this.selectedExpats[i] = {
            id: null,
            arriving_on: null,
          };
        }
      } else {
        for (let i = 0; i < 5; i++) {
          this.selectedExpats[i] = {
            id: null,
            arriving_on: null,
          };
        }
      }

      // fixme set loading=true until this finishes
      this.setExpatsList();
    }

    if (this.stage === "contactExpat") {
      this.expatId = data.id;

      this.getExpatById();
    }
  }

  ngOnInit() {
    this.minDate = new Date(this.addDayToDate(new Date(), 1));
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService
      .getCurrentUser()
      .subscribe((user) => (this.currentUser = user));
  }

  async setExpatsList() {
    const params = { status_id: 3 };

    return await this.expatsService
      .list(this.currentUser.entity_id, params)
      .subscribe((res) => {
        this.expatsList = res.result.items.map((exp) => ({
          id: exp.id,
          name: exp.user.name,
        }));
        this.expatsList.unshift({ id: "newExpat", name: "ADD NEW EXPAT" });
      });
  }

  getExpatById() {
    this.expatsService.getExpatById(this.expatId).subscribe((data) => {
      this.expat = data.result;
    });
  }

  checkAction(e, idx) {
    if (!e) {
      // cleared selection
      this.selectedExpats[idx].id = null;
      this.isAssigned = this.hasSelectedExpats();
      return;
    }

    if (e.id === "newExpat") {
      // selected add new expat
      this.stage = e.id;
    } else {
      // selected valid expat
      this.selectedExpats[idx].id = e.id;
      this.isAssigned = this.hasSelectedExpats();
    }

    // trigger change for expats ng-select, so that it triggers the pipe filter again
    this.expatsList = [...this.expatsList];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(e): void {
    const params = { expats: [] };
    this.selectedExpats.forEach((item) => {
      if (item.id) {
        params["expats"].push(item);
      }
    });
    if (params["expats"].length) {
      this.orderService
        .assignOrderExpat(this.order.id, this.offerID, params)
        .subscribe(
          (res) => {
            this.dialogRef.close("Successfully assigned");
          },
          (error) => {
            if (error.error.errors) {
              this.alertService.errors(error.error.errors);
            }
          }
        );
    } else {
      this.onNoClick();
    }
  }

  addMoreExpats() {
    if (this.selectedExpats.length < this.expatsRemaining()) {
      this.selectedExpats.push({
        id: null,
        arriving_on: null,
      });
    }
  }

  async addedExpat(e) {
    if (this.data.openedFrom === "expats") {
      this.dialogRef.close(e.type);

      if (e.id) {
        const path = `/clients/${this.clientId}/expats/${e.id}`;
        await this.router.navigate([path]);
      }
    } else {
      this.stage = "assignExpat";
      await this.setExpatsList();
    }
  }

  navigateToExpatDetails() {
    this.dialogRef.close();
    this.router.navigate(["expats/" + this.expatId]);
  }

  showAlreadyAddedExpats() {
    return (
      typeof this.selectedExpats !== "undefined" &&
      typeof this.order !== "undefined" &&
      this.expatsRemaining() > 0
    );
  }

  expatsRemaining() {
    const expatsOnOrder = this.order.details.reduce(
      (sum, value) => (sum += value.quantity),
      0
    );
    const selectedExpats = this.selectedExpatsCount();
    const expatsCommitedOnOrder = this.order.details.reduce(
      (sum, detail) => (sum += detail.expats.length),
      0
    );

    return expatsOnOrder - selectedExpats - expatsCommitedOnOrder;
  }

  private selectedExpatsCount() {
    return this.selectedExpats.filter((expat) => expat.id !== null).length;
  }

  private hasSelectedExpats() {
    return this.selectedExpatsCount() > 0;
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }
}
