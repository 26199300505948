import { Action, createReducer, on } from "@ngrx/store";
import { ActionTypes } from "./messages.actions";

export const messagesFeatureKey = "messages";

export interface State {
  messagesCount: number;
}

export const initialState: State = {
  messagesCount: 0,
};

export const messagesReducer = createReducer(
  initialState,
  on(ActionTypes.SET_MESSAGES_SUCCESS, (state, { payload }) => {
    return { ...state, messagesCount: payload };
  }),
  on(ActionTypes.SET_MESSAGES_FAILED, (state) => {
    return { ...state };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return messagesReducer(state, action);
}
