<div class="custom-modal-wrapper">
  <div class="modal-title-custom">
    {{ "EXPATS.CONFIRM_DELETE.TITLE" | translate }}
  </div>
  <div class="modal-body-custom">
    <form [formGroup]="deleteExpatForm">
      <div class="row m-b-15">
        <div class="w-100 confirmation-message">
          {{ "EXPATS.CONFIRM_DELETE.MESSAGE" | translate }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.NAME" | translate }} </mat-label>
        <input matInput formControlName="confirm_token" name="confirm_token" />
        <mat-error formControlError="confirm_token"></mat-error>
        <mat-hint>
          {{
            "EXPATS.CONFIRM_DELETE.CONFIRM_TOKEN_HINT"
              | translate: { name: expat.name }
          }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "LABEL.REASON" | translate }} </mat-label>
        <textarea matInput formControlName="reason" name="reason"></textarea>
        <mat-error formControlError="reason"></mat-error>
        <mat-hint>
          {{ "EXPATS.CONFIRM_DELETE.REASON_HINT" | translate }}
        </mat-hint>
      </mat-form-field>
    </form>
  </div>
  <div class="clear"></div>

  <div class="modal-footer-custom">
    <button (click)="cancel()" class="btn gray-bg fl-l">
      {{ "GENERALS.CANCEL" | translate }}
    </button>
    <button
      [disabled]="!this.deleteExpatForm.valid"
      (click)="confirm()"
      class="btn orange-bg fl-r"
    >
      {{ "GENERALS.CONFIRM" | translate }}
    </button>

    <div class="clear"></div>
  </div>
</div>
