import { Injectable } from "@angular/core";
import { CreatedOrderModel } from "@modules/quotes/models/created-order.model";

@Injectable()
export class CreatedOrderMapperService {
  mapOne({ ...input }: any): CreatedOrderModel {
    return {
      ...input,
    };
  }

  mapMany(input: any): CreatedOrderModel[] {
    const orders = Object.values(input);
    return orders.map((i) => this.mapOne(i));
  }
}
