<div
  class="table-wrapper m-t-30 p-b-15 table-overflow p-1"
  *ngIf="maximumDuration > 0 && tasks"
>
  <div class="m-b-15"><h6 class="m-l-15">Timeline</h6></div>
  <span class="m-l-15">Task </span><span class="task-dot"></span>
  <span class="m-l-15">Subtask </span><span class="subtask-dot"></span>
  <div class="horizontal-overflow">
    <table class="p-b-15 p-b-15">
      <thead>
        <tr>
          <th></th>
          <th
            *ngFor="let i of fakeArray(maximumDuration); let counter = index"
            class="text-center"
          >
            {{ counter + 1 }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let task of tasks; trackBy: trackBy">
          <tr class="border-timeline-table">
            <td class="task-name-column border-timeline-table">
              {{ task.name }}
              <i
                class="expandable-icon"
                *ngIf="task.children && task.children.length"
                [ngClass]="
                  task.isSubtasksAvailable ? 'icon-angle-up' : 'icon-angle-down'
                "
                (click)="showSubtasks(task)"
              ></i>
            </td>
            <td
              *ngFor="let j of fakeArray(maximumDuration); let k = index"
              class="row-box border-timeline-table"
            >
              <div
                [ngClass]="
                  getTimelineColor(k + 1, task.start_day, task.end_day, false)
                "
                class="cell"
              ></div>
            </td>
          </tr>

          <ng-container *ngIf="task.isSubtasksAvailable">
            <tr *ngFor="let subtask of task.children; trackBy: trackBy">
              <td class="task-name-column border-timeline-table">
                <span style="margin-left: 10px">{{ subtask.name }}</span>
              </td>
              <td
                *ngFor="let j of fakeArray(maximumDuration); let k = index"
                class="row-box border-timeline-table"
              >
                <div
                  [ngClass]="
                    getTimelineColor(
                      k + 1,
                      subtask.start_day,
                      subtask.end_day,
                      true
                    )
                  "
                  class="cell"
                ></div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
