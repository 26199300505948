<div class="box-actions">
  <button
    mat-icon-button
    #settingsTrigger="matMenuTrigger"
    [matMenuTriggerFor]="settingsMenu"
    class="btn-outline-none-primary items-end"
    (click)="$event.stopPropagation()"
    (menuClosed)="applyChanges()"
  >
    <mat-icon svgIcon="settings" class="btn-icon"></mat-icon>
  </button>
  <mat-menu
    #settingsMenu="matMenu"
    xPosition="before"
    class="column-selection-options"
  >
    <button
      class="mat-menu-item"
      *ngFor="let column of columnList; index as i"
      (click)="$event.stopPropagation()"
    >
      <mat-checkbox
        [(ngModel)]="column.visible"
        [disabled]="column.readonly"
      ></mat-checkbox>
      {{ column.label | translate }}
    </button>
    <button class="btn-primary" (click)="settingsTrigger.closeMenu()">
      {{ "LABEL.APPLY" | translate }}
    </button>
  </mat-menu>
</div>
