import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, timer } from "rxjs";
import { environment } from "../../../../environments/environment";
import { shareReplay, switchMap } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

const marketProviderUrl = "marketplace/providers";

const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 10 * 60 * 1000; // 10 mins

@Injectable({
  providedIn: "root",
})
export class MarketService {
  private cache$: Observable<any>;
  private serviceOffersUrl = "marketplace/offers";

  constructor(private http: HttpClient) {}

  getServiceCategories(): Observable<any> {
    if (!this.cache$) {
      const timer$ = timer(0, REFRESH_INTERVAL);

      // For each tick make an http request to fetch new data
      this.cache$ = timer$.pipe(
        switchMap((_) => this.requestMenu()),
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cache$;
  }

  private requestMenu(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "offers/serviceCategories/forMenu"
    );
  }

  getServiceOfferById(id, isPreview: boolean = false): Observable<any> {
    const params = isPreview ? { preview: "true" } : {};
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceOffersUrl + "/" + id,
      { params }
    );
  }

  // get provider details
  getProviderDetails(id) {
    return this.http.get<any>(
      environment.gateway_endpoint + marketProviderUrl + "/" + id
    );
  }
}
