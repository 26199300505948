import { Component, OnInit } from "@angular/core";
import { User } from "../../../_models/user";
import { DictionariesService } from "../../../_services/dictionaries.service";
import { Dictionary } from "../../../_models/dictionary";
import { AlertService } from "../../../_services/alert.service";
import { Router } from "@angular/router";
import { Country } from "../../../_models/country";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "@api/authentication";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit {
  public user: User;
  public departments: Dictionary[];
  public countries: Country[];
  public isLoading = false;

  constructor(
    private service: AuthenticationService,
    private dictionaryService: DictionariesService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.user = new User();
  }

  ngOnInit() {
    this.getDepartments();
    this.getCountries();
  }

  getDepartments() {
    this.dictionaryService.getDepartments().subscribe((data) => {
      this.departments = data.result;
    });
  }

  getCountries() {
    this.dictionaryService.getCountryList().subscribe((data) => {
      if (data && data.result) {
        this.countries = data.result.map((country) => {
          const prefix = country.phone_code.replaceAll("+", "");
          return {
            name: `(+${prefix}) ${country.name}`,
            phone_code: country.phone_code,
          };
        });
      }
    });
  }

  register() {
    this.isLoading = true;
    this.user.main_register = 1;
    this.service.register(this.user).subscribe(
      (data) => {
        // client successfully register => login and go to step 2
        this.service.login(this.user.email, this.user.password).subscribe(
          (loginResponse) => {
            this.router.navigateByUrl("/create-profile");
          },
          (error) => {
            this.alertService.errors(error.error.errors);
          }
        );
      },
      (error) => {
        this.isLoading = false;
        this.alertService.errors(error.error.errors);
      }
    );
  }

  validateNumericalInput(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setPrefix(event: any) {
    if (event) {
      const code = event.phone_code.match(/\((.*?)\)/);
      this.user.phone_prefix = code[1];
    }
  }

  customSearchFn(term, item) {
    term = term.toLocaleLowerCase();
    const onlyLetters = /^[a-zA-Z]+$/.test(term);
    if (onlyLetters) {
      return item.name.toLocaleLowerCase().indexOf(term) === 0;
    } else {
      return item.phone_code.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  goToPrivacy() {
    window.open(environment.marketplace + "privacy-policy");
  }

  goToTerms() {
    window.open(environment.marketplace + "terms-conditions");
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
