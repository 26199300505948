import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromMessages from "./messages.reducer";

export const selectMessagesState = createFeatureSelector<fromMessages.State>(
  fromMessages.messagesFeatureKey
);

export const getMessagesCount = createSelector(
  selectMessagesState,
  (state: fromMessages.State) => state.messagesCount
);
