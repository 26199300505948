import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";

export class OrderDetails {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/orders/" + _id,
        filters: filters,
        label: "Details",
        page: "details",
        permission: {
          action: PermissionActionEnum.VIEW,
          resource: PermissionResourceEnum.ORDER,
          secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
        },
      },
      {
        link: "/orders/" + _id + "/docs",
        filters: filters,
        label: "Docs",
        page: "docs",
        permission: {
          action: PermissionActionEnum.VIEW,
          resource: PermissionResourceEnum.DOCUMENT,
          secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
        },
      },
      {
        link: "/orders/" + _id + "/invoices",
        filters: filters,
        label: "Invoices",
        page: "invoices",
        permission: {
          action: PermissionActionEnum.VIEW,
          resource: PermissionResourceEnum.ORDER,
          secondaryResource: PermissionSecondaryResourceEnum.FINANCIAL,
        },
      },
      {
        link: "/orders/" + _id + "/quotes",
        filters: filters,
        label: "Quotes",
        page: "quotes",
        permission: {
          action: PermissionActionEnum.VIEW,
          resource: PermissionResourceEnum.ORDER,
          secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
        },
      },
      {
        link: "/orders/" + _id + "/history",
        filters: filters,
        label: "History",
        page: "history",
        permission: {
          action: PermissionActionEnum.VIEW,
          resource: PermissionResourceEnum.ORDER,
          secondaryResource: PermissionSecondaryResourceEnum.ACTIVITY,
        },
      },
    ];
  }
}
