import { Component, OnInit } from "@angular/core";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { Router } from "@angular/router";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { TemplateService } from "@api/templates";
import { QueryParamsService } from "src/app/_services/query-params.service";

@Component({
  selector: "app-template-view",
  templateUrl: "./template-view.component.html",
  styleUrls: ["./template-view.component.scss"],
})
export class TemplateViewComponent implements OnInit {
  constructor(
    public templateService: TemplateService,
    public dialog: MatDialog,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForTemplateView("/template", "Templates")
    );
  }

  openDialog(type: string): void {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          needs_confirmation: true,
          text: `${type} template`,
          hideText: type === "save",
          customText: type === "save" ? "TEMPLATE.CONFIRM-SAVE" : null,
        },
      },
    });

    dialogRef.afterClosed().subscribe((reason: string) => {
      if (reason) {
        this.router
          .navigate(["/", "template"], {
            queryParams: this.queryParamsService.defaultParams,
          })
          .then();
      }
    });
  }

  templatePreview(): void {
    this.templateService.isTemplatePreview =
      !this.templateService.isTemplatePreview;
  }
}
