import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserData } from "@api/account";
import { Order } from "../../../../_models/order";
import { OrdersService } from "../../../../_services/orders.service";
import { UsersService } from "../../../../_services/users.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-order-quotes",
  templateUrl: "./order-quotes.component.html",
  styleUrls: ["./order-quotes.component.scss"],
})
export class OrderQuotesComponent implements OnInit {
  public orderId: number;
  public isLoading = false;
  public user: UserData;
  public order: Order;

  constructor(
    private route: ActivatedRoute,
    private orderService: OrdersService,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.orderId = params.id;
      }
    });

    this.userService.getCurrentUser().subscribe((data) => {
      this.user = data;
      this.getOrderById(this.orderId);
    });
  }

  getOrderById(id) {
    this.isLoading = true;
    const params = {};

    params["entity_id"] = this.user.id;
    this.orderService.getOrderByIdentityNumber(params, id).subscribe(
      (res) => {
        if (res) {
          this.order = res.result;
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
