import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AgreementMapperService} from '@api/agreements/services/agreement-mapper.service';
import {AgreementsService} from '@api/agreements/services/agreements.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [AgreementsService, AgreementMapperService],
})
export class AgreementsModule {}
