import { createFeatureSelector, createSelector } from "@ngrx/store";
import { fromCase } from "../reducers";

export const selectCaseState = createFeatureSelector<fromCase.State>(
  fromCase.casesFeatureKey
);

export const selectCaseIds = createSelector(
  selectCaseState,
  fromCase.selectCaseIds
);
export const selectCaseEntities = createSelector(
  selectCaseState,
  fromCase.selectCaseEntities
);
export const selectAllCases = createSelector(
  selectCaseState,
  fromCase.selectAllCases
);
export const selectCaseTotal = createSelector(
  selectCaseState,
  fromCase.selectCaseTotal
);
export const selectCurrentCaseId = createSelector(
  selectCaseState,
  fromCase.getSelectedCaseId
);

export const selectCurrentCase = createSelector(
  selectCaseEntities,
  selectCurrentCaseId,
  (entities, caseId) => caseId && entities[caseId]
);
export const selectCaseById = (caseId: number) =>
  createSelector(selectCaseEntities, (entities) => entities[caseId]);
