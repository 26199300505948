import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { ActivityService } from "../../../../_services/activity.service";
import { ItemsListComponent } from "../items-list/items-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";

@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy
{
  @Input() model: string;
  @Input() modelId: number;
  @Input() params: any;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ActivityService
  ) {
    super();
  }

  ngOnInit() {
    this.getItems();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getItems(page = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params = { ...this.getParams(page), ...this.params };

    this.isLoading = true;

    this.request_call = this.service
      .list(this.model, this.modelId, params)
      .subscribe({
        next: (data) => {
          this.processResponse(data);
          this.changeDetectorRef.markForCheck();
          window.scrollTo(0, 0);
        },
        error: () => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router
      .navigate([], {
        queryParams: params,
        relativeTo: this.route,
        replaceUrl: true,
      })
      .then();
  }
}
