import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { AuthenticationService } from "src/app/_services/authentication.service";

@Component({
  selector: "app-details-action-panel",
  templateUrl: "./details-action-panel.component.html",
})
export class UserDetailsActionsPanelComponent {
  @Input() user;
  @Input() entity_type;
  @Input() changeStatusPermission = false;
  @Input() readonly;
  @Output() actionChange: EventEmitter<any> = new EventEmitter();
  profilePic;
  constructor(
    private authService: AuthenticationService,
    private alertService: AlertService,
    public ngxSmartModalService: NgxSmartModalService,
    protected dialog: MatDialog
  ) {}

  handleEvent(e) {
    this.actionChange.emit(e);
  }

  resetPassword() {
    this.authService.sendResetPasswordEmail(this.user.email).subscribe(
      (res) => {
        if (res && res.message) {
          this.alertService.success(res.message);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }
  openDocModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").open();
  }
}
