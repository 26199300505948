import {
  EventEmitter,
  Injectable,
  Output,
  Directive,
  OnDestroy,
} from "@angular/core";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { PermissionResourceEnum } from "@modules/shared/_enums/permission-resource.enum";
import { OrdersService } from "./orders.service";
import { Subject } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { RfqActions } from "@api/rfq/actions";
import { takeUntil } from "rxjs/operators";
import { StatsService } from "@api/stats";

@Directive()
@Injectable()
export class MenuBadgesService implements OnDestroy {
  private destroyed$ = new Subject<boolean>();

  @Output() ordersChanged: EventEmitter<any> = new EventEmitter();
  @Output() rfqChanged: EventEmitter<number> = new EventEmitter();

  constructor(
    private ordersService: OrdersService,
    private stats: StatsService,
    private auth: AuthorizationService,
    updates$: Actions
  ) {
    ordersService.statusChange.subscribe((event) => {
      this._getPendingOrders();
    });
    updates$
      .pipe(
        ofType(RfqActions.requestUpdateRfqSuccess),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this._getPendingRFQs();
      });
  }

  /**
   * Get the number of providers pending approval
   *
   * @private
   */
  private _getPendingOrders() {
    this.ordersService.getPendingCount().subscribe((data) => {
      if (data.success) {
        this.ordersChanged.emit(data.result);
      }
    });
  }

  /**
   * Get the number of providers pending approval
   *
   * @private
   */
  private _getPendingRFQs() {
    this.stats.getRFQsOverview().subscribe((data) => {
      this.rfqChanged.emit(data.invited);
    });
  }

  /**
   * Get all available counters
   */
  getAllCounters() {
    this.auth
      .$hasResource(PermissionResourceEnum.ORDER)
      .subscribe((value: boolean) => {
        if (value) {
          this._getPendingOrders();
        }
      });
    this._getPendingRFQs();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
