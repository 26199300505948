<mat-form-field appearance="outline" class="w-100" [formGroup]="expenseForm">
  <mat-label>{{ "EXPENSES.AMOUNT" | translate }}</mat-label>
  <input
    matInput
    [min]="0"
    type="number"
    [formControl]="costControl"
    [ngModel]="value"
  />
  <mat-error>
    <app-form-errors [control]="expenseForm.get('amount')"> </app-form-errors>
  </mat-error>
</mat-form-field>
