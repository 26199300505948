import {
  Directive,
  HostBinding,
  HostListener,
  ElementRef,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[sts-calendar-helper]",
})
export class StsCalendarHelperDirective {
  elParent;
  constructor(private el: ElementRef, public renderer: Renderer2) {}
  @HostBinding("class.zindex-helper") isOpen = false;

  @HostListener("document:click", ["$event"])
  public onClick(event) {
    if (this.el.nativeElement.contains(event.target)) {
      this.elParent =
        this.el.nativeElement.closest(".dashboard-statistics-box") ||
        this.el.nativeElement.closest(".table-header-action-wrapper");

      this.isOpen = !this.isOpen;

      if (this.elParent) {
        if (this.isOpen) {
          this.renderer.setStyle(this.elParent, "z-index", "10");
        } else {
          this.renderer.setStyle(this.elParent, "z-index", "unset");
        }
      }
    } else {
      this.isOpen = false;
      if (this.elParent) {
        this.renderer.setStyle(this.elParent, "z-index", "unset");
      }
    }
  }
}
