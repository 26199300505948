import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { fromMessages } from "./state";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromMessages.messagesFeatureKey,
      fromMessages.reducer
    ),
  ],
})
export class MessagesModule {}
