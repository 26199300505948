<div class="doc-preview">
  <div id="progressBar" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
  </div>

  <ngx-doc-viewer
    [url]="file.file_path"
    viewer="google"
    style="width: 100%"
    (loaded)="loading = false"
    [disableContent]="'popout-hide'"
  ></ngx-doc-viewer>
</div>
