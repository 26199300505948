import { Component, OnInit, Input } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-name-selector",
  templateUrl: "./name-selector.component.html",
})
export class NameSelectorComponent implements OnInit {
  documentForm: UntypedFormGroup;
  nameControl: UntypedFormControl;

  @Input() public value?: string;

  constructor(private controlContainer: ControlContainer) {
    this.nameControl = new UntypedFormControl("", Validators.required);
  }

  ngOnInit() {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("name", this.nameControl);

    if (this.value) {
      this.documentForm.get("name").setValue(this.value);
    }
  }
}
