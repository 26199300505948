import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountSelectors } from "@api/account";
import { Store } from "@ngrx/store";
import { select } from "@ngrx/store";
import { map, switchMap } from "rxjs";
import { filterNullish } from "src/app/lib";
import { XpathState } from "src/app/reducers";
import { CaseStatusEnum } from "../../../../_enums/case-status-enum";

@Component({
  selector: "app-my-cases-button",
  templateUrl: "./my-cases-button.component.html",
  styleUrls: ["./my-cases-button.component.scss"],
})
export class MyCasesButtonComponent implements OnInit {
  myCases: boolean = false;
  currentId: number = null;

  $user: any;

  constructor(
    private store: Store<XpathState>,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.$user = this.store
      .pipe(select(AccountSelectors.selectUser))
      .pipe(filterNullish());

    this.$user.subscribe((user) => {
      this.currentId = user.id;
    });

    this.route.queryParams
      .pipe(
        switchMap((params) => {
          return this.$user.pipe(
            map((user) => params["manager_id"] == user["id"])
          );
        })
      )
      .subscribe((result: any) => {
        this.myCases = result;
      });
  }

  toggleMyCases() {
    this.myCases = !this.myCases;

    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: {
          manager_id: this.myCases ? this.currentId : null,
          status: this.myCases
            ? [CaseStatusEnum.PENDING, CaseStatusEnum.IN_PROGRESS]
            : null,
        },
        queryParamsHandling: "merge",
        replaceUrl: true,
      })
      .then();
  }
}
