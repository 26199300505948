import { AbstractControl, ValidatorFn } from "@angular/forms";

export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const today = control.value;
    let invalid = false;

    if (today) {
      invalid = new Date(today).valueOf() > new Date().valueOf();
    }
    return invalid ? { futureDate: true } : null;
  };
}
