<div class="custom-modal-wrapper">
  <div class="row">
    <div class="w-100">
      <div class="white-box-row">
        <h2 mat-dialog-title>
          {{ "GENERALS.COPY-OFFER" | translate }}
        </h2>
        <mat-dialog-content class="m-t-30"
          ><mat-form-field appearance="outline">
            <mat-select
              [formControl]="offerControl"
              [placeholder]="'LABEL.SELECT-OFFER' | translate"
              #singleSelect
            >
              <mat-option>
                <ngx-mat-select-search
                  [placeholderLabel]="'LABEL.OFFER' | translate"
                  [noEntriesFoundLabel]="
                    'GENERALS.NO-ITEMS-DISPLAY' | translate
                  "
                  [formControl]="offerFilterControl"
                >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let offer of offers"
                [value]="offer.id"
                [title]="offer.title"
              >
                ({{ offer.id }})
                {{ offer.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-dialog-content>

        <div class="m-t-30 fl-r">
          <button class="btn gray-bg fl-l modal-button" (click)="closeModal()">
            <mat-icon svgIcon="cancel"></mat-icon>
            {{ "GENERALS.CANCEL" | translate }}
          </button>
          <button
            [disabled]="!offerControl.value"
            class="btn orange-bg fl-r m-l-20 modal-button"
            (click)="save()"
          >
            <mat-icon svgIcon="save"></mat-icon>
            {{ "GENERALS.SAVE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
