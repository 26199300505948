export enum DocumentActionsEnum {
  ACTIVATE = 1,
  ARCHIVED = 2,

  DELETE = 3,

  IN_RENEWAL = 4,

  REMINDER_SET = 5,

  NOT_TRACKED = 6,

  EXPIRED = 7,

  ALL = 8,
}
