import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BillingInfoComponent } from "./components/billing-info/billing-info.component";
import { BillingComponent } from "./components/billing/billing.component";
import { PlansComponent } from "./components/plans/plans.component";

const routes: Routes = [
  {
    path: "",
    component: BillingComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "plans",
      },
      {
        path: "plans",
        component: PlansComponent,
      },
      {
        path: "billing-info",
        component: BillingInfoComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BillingRoutingModule {}
