import { AbstractControl, ValidationErrors } from "@angular/forms";
import { FileTypeEnum } from "../../../_enums/file-type-enum";

export function fileValidator() {
  return (files: AbstractControl): ValidationErrors | null => {
    let invalid = null;
    try {
      files.value.forEach((file) => {
        // max size 250 mb
        if (file.size > 250000000) {
          invalid = { fileSizeExtension: true };
        }
        const name = file.name.toLowerCase();
        const exp = new RegExp(
          // eslint-disable-next-line no-useless-escape
          `^.*\.(${Object.values(FileTypeEnum).join("|")})$`
        );
        if (!exp.test(name)) {
          invalid = { fileTypeExtension: true };
        }
      });
    } catch (e) {
      return { fileException: true };
    }
    return invalid;
  };
}
