import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { BehaviorSubject, Subject } from "rxjs";
import isEmpty from "lodash/isEmpty";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { TranslateService } from "@ngx-translate/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { FormControl } from "@angular/forms";
import { startWith } from "rxjs/operators";
import { FilterTypesEnum } from "../../../../../../../../_enums/filter-types-enum";
import * as moment from "moment/moment";

export class ItemFilterNode {
  children: ItemFilterNode[];
  id: string | number;
  text: string;
}

/** Flat to-do item node with expandable and level information */
export class ItemFilterFlatNode {
  id: string | number;
  text: string;
  level: number;
  expandable: boolean;
}

export class FilterData {
  id: string | number;
  text: string;
}
@Component({
  selector: "app-report-filters",
  templateUrl: "./report-filters.component.html",
  styleUrls: ["./report-filters.component.scss"],
})
export class ReportFiltersComponent implements OnInit, OnDestroy {
  @Input() availableFilters?: Array<any>;
  @Input() milestoneFilter: boolean = false;
  @Input() milestoneId: string;
  @Input() key: string;
  @Input() type: string;

  @Input() queryParams: any;
  @Output() reportFilters: EventEmitter<any> = new EventEmitter();

  public isLoaded = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public searchControl = new FormControl();
  public startDateControl = new FormControl();
  public endDateControl = new FormControl();

  selected = {
    columns: [],
    orderAsc: false,
    orderDesc: false,
  };

  flatNodeMap = new Map<ItemFilterFlatNode, ItemFilterNode>();

  nestedNodeMap = new Map<ItemFilterNode, ItemFilterFlatNode>();

  treeControl: FlatTreeControl<ItemFilterFlatNode>;

  treeFlattener: MatTreeFlattener<ItemFilterNode, ItemFilterFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemFilterNode, ItemFilterFlatNode>;

  checklistSelection = new SelectionModel<ItemFilterFlatNode>(true);

  dataChange = new BehaviorSubject<ItemFilterNode[]>([]);

  filters: Partial<any> = {};

  filterData: FilterData[];
  dateFilterData;
  searchFilterData: FilterData[];
  label: string;
  get data(): ItemFilterNode[] {
    return this.dataChange.value;
  }

  constructor(private translate: TranslateService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFilterFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    this.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.isLoaded = true;
    this.filters = { ...this.filters, ...this.queryParams };

    this.getFilterData();
    if (this.filterData) {
      this.searchSelectData();
    }
  }

  getLevel = (node: ItemFilterFlatNode) => node.level;

  isExpandable = (node: ItemFilterFlatNode) => node.expandable;

  getChildren = (node: ItemFilterNode): ItemFilterNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFilterFlatNode) => _nodeData.expandable;

  transformer = (node: ItemFilterNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.id === node.id
        ? existingNode
        : new ItemFilterFlatNode();
    flatNode.id = node.id;
    flatNode.text = node.text;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: ItemFilterFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
  }

  descendantsPartiallySelected(node: ItemFilterFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  itemSelectionToggle(node: ItemFilterFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  initialize() {
    const data = this.buildFileTree();

    this.dataChange.next(data);
    this.treeControl.expandAll();
    this.setSelectedColumns();
    this.isLoaded = false;
  }

  buildFileTree(): ItemFilterNode[] {
    const node = new ItemFilterNode();
    node.id = this.key;
    node.text = this.translate.instant("GENERALS.SELECT-ALL");

    node.children = this.searchFilterData.reduce<ItemFilterNode[]>(
      (fields, item) => {
        const children = new ItemFilterNode();
        children.id = item.id;
        children.text = item.text;
        return fields.concat(children);
      },
      []
    );

    return [node];
  }

  getFilterData() {
    if (!this.milestoneFilter) {
      const data = this.availableFilters?.find(
        (element) => element.id == this.key
      );
      this.type = data.field_type;
      this.label = data.label;

      if (this.type == FilterTypesEnum.DATE_RANGE) {
        this.dateFilterData = data.metadata;

        this.dateFilterData.min_date = new Date(data.metadata?.min_date);
        this.dateFilterData.max_date = new Date(data.metadata?.max_date);
        this.setSelectedColumns();
        this.isLoaded = false;
      } else {
        this.filterData = data.data;
      }
    } else {
      this.setSelectedColumns();
    }
  }

  setDateRangeSelectedValues() {
    if (this.queryParams[this.key]) {
      let startDate = "";
      let endDate = "";
      if (this.key === "milestone_date") {
        if (
          this.queryParams[this.key].split("_")[0].toString() !==
          this.milestoneId.toString()
        ) {
          return;
        }

        const date = this.queryParams[this.key].split("_")[1];
        startDate = date.split(";")[0];
        endDate = date.split(";")[1];
      } else {
        startDate = this.queryParams[this.key].split(";")[0];
        endDate = this.queryParams[this.key].split(";")[1];
      }

      this.startDateControl.setValue(
        new Date(moment(startDate).add(1, "day").format("YYYY/MM/DD"))
      );
      this.endDateControl.setValue(
        new Date(moment(endDate).add(1, "day").format("YYYY/MM/DD"))
      );
    }
  }

  setSortingValues() {
    this.selected.orderAsc = false;
    this.selected.orderDesc = false;

    if (this.queryParams["order"] && this.queryParams["order"] === this.key) {
      if (this.queryParams["order_direction"] === "asc") {
        this.selected.orderAsc = true;
      } else {
        this.selected.orderDesc = true;
      }
    }
  }
  setMultiSelectSelectedValues() {
    if (this.queryParams[this.key] && this.queryParams[this.key].length > 0) {
      this.selected.columns = this.queryParams[this.key];

      if (this.selected.columns !== undefined) {
        this.treeControl.dataNodes.forEach((item) => {
          if (this.selected.columns.indexOf(item.id.toString()) !== -1) {
            this.checklistSelection.toggle(item);
            this.treeControl.expand(item);
          }
        });
      }
    }
  }
  setSelectedColumns() {
    switch (this.type) {
      case FilterTypesEnum.DATE_RANGE:
        this.setDateRangeSelectedValues();
        break;
      case FilterTypesEnum.MULTIPLE_SELECT:
        this.setMultiSelectSelectedValues();
        break;
    }

    this.setSortingValues();
    this.isLoaded = false;
  }

  public clearFilters() {
    if (this.type === FilterTypesEnum.DATE_RANGE) {
      delete this.filters[this.key];
      this.reportFilters.emit(this.filters);
    } else {
      this.checklistSelection.clear();
      this.setFilter();
    }
  }

  setFilter() {
    if (this.type === FilterTypesEnum.DATE_RANGE) {
      let date = "";

      if (this.milestoneFilter) {
        date = this.milestoneId + "_";
      }

      date += moment(new Date(this.startDateControl.getRawValue())).format(
        "YYYY/MM/DD"
      );
      if (this.endDateControl.getRawValue()) {
        date +=
          ";" +
          moment(new Date(this.endDateControl.getRawValue())).format(
            "YYYY/MM/DD"
          );
      }

      this.filters[this.key] = date;
      this.reportFilters.emit(this.filters);
    } else {
      const filter = [];
      filter[this.key] = [];
      const selected = this.checklistSelection.selected;

      selected.forEach((item) => {
        filter[this.key].push(item.id);
      });
      this.reportFilters.emit({ ...this.filters, ...filter });
    }
  }

  sortAsc() {
    if (this.selected.orderAsc) {
      delete this.filters.order;
      delete this.filters.order_direction;

      this.reportFilters.emit(this.filters);
    } else {
      const filter = {
        order: this.key,
        order_direction: "asc",
      };
      this.reportFilters.emit({ ...this.filters, ...filter });
    }
  }

  sortDesc() {
    if (this.selected.orderDesc) {
      delete this.filters.order;
      delete this.filters.order_direction;

      this.reportFilters.emit(this.filters);
    } else {
      const filter = {
        order: this.key,
        order_direction: "desc",
      };
      this.reportFilters.emit({ ...this.filters, ...filter });
    }
  }

  protected searchSelectData() {
    this.searchControl.valueChanges.pipe(startWith("")).subscribe((value) => {
      if (!value) {
        this.searchFilterData = this.filterData;
        this.initialize();
        return;
      }

      this.searchFilterData = this.filterData.filter((data: FilterData) =>
        data.text.toLowerCase().includes(value.toLowerCase())
      );
      this.initialize();
    });
  }

  hasActiveFilter() {
    switch (this.type) {
      case FilterTypesEnum.DATE_RANGE:
        if (
          this.startDateControl.getRawValue() ||
          this.startDateControl.getRawValue()
        ) {
          return true;
        }
        break;
      case FilterTypesEnum.MULTIPLE_SELECT:
        if (this.checklistSelection.hasValue()) {
          return true;
        }
        break;
    }

    return this.selected.orderAsc || this.selected.orderDesc;
  }

  hasFilterData() {
    switch (this.type) {
      case FilterTypesEnum.DATE_RANGE:
        if (!isEmpty(this.dateFilterData)) {
          return true;
        }
        break;
      case FilterTypesEnum.MULTIPLE_SELECT:
        if (!isEmpty(this.filterData)) {
          return true;
        }
        break;
    }

    return this.milestoneFilter;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
  get FilterType() {
    return FilterTypesEnum;
  }
}
