<div
  class="portal-details-navigation d-flex align-items-center justify-content-between dashboard-body-top"
>
  <div class="big-title d-flex align-items-center pb-0 mb-0">
    {{ "TEMPLATE.TEMPLATE" | translate }}
  </div>
  <button class="btn orange-bg ml-3 fl-r" (click)="openDialog()">
    {{ "TEMPLATE.ADD" | translate }}
  </button>
</div>

<div class="col-lg-12 mt-0 mb-5 mx-0 px-0 pb-2" *ngIf="isFiltersLoaded">
  <div class="fl-l advanced-search-wrapper w-100 p-0">
    <app-filters
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="TEMPLATE.TEMPLATE"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    >
    </app-filters>
  </div>
</div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<div class="table-wrapper w-100 p-0">
  <ng-container>
    <div class="table-responsive">
      <table matSort class="col-lg-12 white-bg">
        <thead>
          <tr>
            <th class="first-col pl-5">{{ "TEMPLATE.NAME" | translate }}</th>
            <th class="second-col">
              {{ "TEMPLATE.CREATED-UPDATED" | translate }}
            </th>
            <th class="third-col px-5">
              {{ "TEMPLATE.ACTIONS" | translate }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr
            class="cursor-pointer"
            *ngFor="
              let item of items
                | paginate
                  : {
                      itemsPerPage: perPage,
                      currentPage: p,
                      totalItems: total
                    }
                | sortBy: 'desc':'created_at';
              let i = index
            "
          >
            <td
              class="gray-pointer first-col pl-5"
              (click)="editTemplate(item)"
            >
              {{ item.name }}
            </td>
            <td
              class="gray-pointer second-col px-5"
              (click)="editTemplate(item)"
            >
              {{ item.created_at | date: "dd/MM/yyyy" }}
            </td>
            <td class="ta-r third-col">
              <div
                toggle-action-table
                class="table-action-wrapper d-flex justify-content-start"
              >
                <button [routerLink]="['view', item.id]" class="btn">
                  {{ "TEMPLATE.USE-TEMPLATE" | translate }}
                </button>
              </div>
            </td>
            <td class="ta-r">
              <div toggle-action-table class="table-action-wrapper">
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li
                      (click)="openArchiveDialog(item)"
                      *ngIf="item.archived_at === null"
                      class="d-flex justify-content-center"
                    >
                      {{ "GENERALS.ARCHIVE" | translate }}
                    </li>
                    <li
                      (click)="openUnarchivedDialog(item)"
                      *ngIf="item.archived_at !== null"
                      class="d-flex justify-content-center"
                    >
                      {{ "GENERALS.UNARCHIVE" | translate }}
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="py-3 px-2 font-weight-bold text-center"
        *ngIf="items && items.length < 1"
      >
        {{ "TEMPLATE.NOT-FOUND" | translate }}
      </div>
    </div>
  </ng-container>
  <div class="paginator">
    <pagination-controls
      (pageChange)="getItems($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>
</div>
