import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { CaseData, CasesService } from "@api/cases";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { Observable, finalize } from "rxjs";

@Component({
  templateUrl: "./add-follower.component.html",
  styleUrls: ["./add-follower.component.scss"],
})
export class AddFollowerComponent implements OnInit {
  case: CaseData;

  users$: Observable<UserData[]>;

  form = new FormGroup({
    user_id: new UntypedFormControl(null, Validators.required),
    case_id: new UntypedFormControl(null, Validators.required),
  });

  public isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddFollowerComponent>,
    protected readonly caseService: CasesService,
    @Inject(MAT_DIALOG_DATA) protected readonly data: { case: CaseData }
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.case = this.data.case;
    this.form.get("case_id").setValue(this.case.id);
    this.users$ = this.caseService
      .fetchEligibleFollowers(this.case.id)
      .pipe(finalize(() => (this.isLoading = false)));
  }

  submit() {
    this.isLoading = true;
    const data: any = this.form.getRawValue();
    this.caseService.addFollower(data).subscribe({
      next: (followers) => {
        this.dialogRef.close({
          followers,
        });
      },
    });
  }

  close() {
    this.dialogRef.close({});
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
