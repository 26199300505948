<div [formGroup]="quoteForm" class="item">
  <div class="name">
    {{ quote.service_name }}
  </div>
  <div class="price">
    {{ quote.unit_price | currency: quote.currency_code }}
  </div>
  <div class="qty">
    <app-quantity-input formControlName="quantity"></app-quantity-input>
  </div>
  <div class="total">{{ total | currency: quote.currency_code }}</div>
</div>
