<mat-form-field appearance="outline" class="w-full" [formGroup]="caseForm">
  <mat-label> {{ "LABEL.DOCUMENTS" | translate }} </mat-label>
  <mat-select
    [formControl]="docsControl"
    [placeholder]="'LABEL.DOCUMENTS' | translate"
    #singleSelect
    (openedChange)="errors.updateMessage()"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'LABEL.DOCUMENTS' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="docsSearchFrom"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let doc of filteredDocs$ | async" [value]="doc.id">
      {{ doc.name }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="docsControl" #errors></app-form-errors>
  </mat-error>
</mat-form-field>
