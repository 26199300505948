<div class="details-2c m-t-15">
  <div class="details-2c-left" *ngIf="statuses?.length">
    <div class="small-box">
      <div class="small-box-header">
        <div class="medium-title">
          {{ "GENERALS.TAKE_ACTION" | translate }}
        </div>
      </div>

      <div class="small-box-details">
        <div class="small-box-list">
          <ng-container *ngFor="let status of statuses">
            <div class="card-box-action-btn">
              <div
                (click)="openConfirmAction(status)"
                class="btn-small orange-border orange"
              >
                {{ status.text }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
