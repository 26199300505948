import { Injectable } from "@angular/core";
import { ContactData } from "../models/contact.model";
import { AddressMapperService } from "./address-mapper.service";

@Injectable({
  providedIn: "root",
})
export class ContactsMapperService {
  constructor(private addressMapper: AddressMapperService) {}

  mapOne(input: any): ContactData {
    return {
      id: input.id,
      label: input.label,
      name: input.name,
      email: input.email,
      phoneNumber: input.phone_number,
      isEmergency: input.is_emergency,
      isPrimary: input.is_primary,
      address: input.address ? this.addressMapper.mapOne(input.address) : null,
    };
  }

  mapMany(input: any[]): ContactData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepare(input: ContactData) {
    return {
      id: input.id,
      label: input.label,
      name: input.name,
      email: input.email,
      phone_number: input.phoneNumber,
      is_emergency: input.isEmergency,
      is_primary: input.isPrimary,
      address: input.address ? this.addressMapper.prepare(input.address) : null,
    };
  }
}
