import { Component, Input } from "@angular/core";
import { DocumentFile } from "@api/documents/models/document.model";
import { PreviewStrategy } from "../preview-strategy.component";

@Component({
  selector: "app-img-preview-strategy",
  templateUrl: "./img-preview-strategy.component.html",
  styleUrls: ["./img-preview-strategy.component.css"],
})
export class ImgPreviewStrategyComponent implements PreviewStrategy {
  @Input() public file: DocumentFile;
}
