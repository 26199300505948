import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { User } from "src/app/_models/user";
import { AlertService } from "src/app/_services/alert.service";
import { UsersService } from "src/app/_services/users.service";
import { ItemsListComponent } from "src/app/modules/shared/_components/items-list/items-list.component";
import { AuthenticationService } from "../../../../_services/authentication.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { finalize } from "rxjs";
import {
  EditUserDetailsComponent,
  EditUserDetailsComponentInput,
} from "@modules/shared/_components/edit-user-details/edit-user-details.component";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
})
export class UsersListComponent extends ItemsListComponent implements OnInit {
  isFiltersLoaded: boolean = false;
  user: User;
  items: User[];
  isCanceled;
  entityID;
  companyName;
  active = "";
  roles;
  public createUserDetails = {
    action: PermissionActionEnum.CREATE,
    resource: PermissionResourceEnum.USER,
    secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
  };

  public isLoadingModal: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private service: UsersService,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private authService: AuthenticationService,
    private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
    this.user = new User();
    this.service.getCurrentUser().subscribe(
      (res) => {
        this.entityID = res.entity_id;
        this.companyName = res.entity_details.company.company_name;
        this.user.company_name = this.companyName;

        this.route.queryParams.subscribe((query) => {
          if (query && Object.keys(query).length > 0) {
            this.filters = { ...query };
          } else {
            this.clearFilters();
            const params = this.getParams(1);
            this.filters = params;
          }
          if (this.filters["search_text"]) {
            this.search_text = this.filters["search_text"];
          }
          if (this.filters["per_page"]) {
            this.perPage = this.filters["per_page"];
          }
          if (this.filters["order"]) {
            this.active = this.filters["order"];
            this.direction = this.filters["order_direction"];
          } else {
            this.active = "";
            this.direction = "";
          }
          this.getItems(1);
        });
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
    this.showBulkActions = false;
    this.setAvailableFilters();
    this.getStatusTransitions();
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router.navigate([], {
      queryParams: params,
      replaceUrl: true,
    });
  }

  ngOnInit() {
    this.getUserRoles();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/company-users", "company users")
    );
  }

  getUserRoles() {
    this.service.getUserRoles().subscribe((res) => {
      this.roles = res.result;
    });
  }
  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    // const params = this.getParams(page);
    let params = {};
    params = { ...this.filters };
    this.isLoading = true;
    this.request_call = this.service
      .list(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
        },
        (message) => {
          this.alertService.errors(message.error.errors);
        }
      );
  }

  public setAvailableFilters() {
    this.availableFilters = [
      // {
      //   id: 'search_fields',
      //   label: 'FILTERS.SEARCH_BY',
      //   service: this.service,
      //   method: 'getSearchFields'
      // }
    ];
  }

  onChanges(e) {
    switch (e) {
      case "save_account":
        this.addEntityUser();
        break;
      case "save_user":
        this.addEntityUser();
        break;
    }
  }

  public getStatusTransitions() {
    this.service.getStatusTransitions().subscribe((data) => {
      if (data.success) {
        this.actions = data.result;
      }
    });
  }

  protected getService() {
    return this.service;
  }

  openModal() {
    const data: EditUserDetailsComponentInput = {};
    this.dialog
      .open(EditUserDetailsComponent, { data })
      .afterClosed()
      .subscribe((user) => {
        if (!user) {
          return;
        }
        this.navigateByUrl(1);
        this.getItems();
      });
  }

  addEntityUser() {
    this.isLoadingModal = true;

    this.user.name = this.user.first_name + " " + this.user.last_name;

    this.service.createUser(this.entityID, this.user).subscribe(
      (res) => {
        this.getItems(1);
        this.router.navigate(["users/" + res.result.id]);
      },
      (message) => {
        this.isLoadingModal = false;
        this.alertService.errors(message.error.errors);
      }
    );
  }

  resetPassword(_email) {
    this.authService.sendResetPasswordEmail(_email).subscribe(
      (res) => {
        if (res.message) {
          this.alertService.success(res.message);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  checkPrimary(user) {
    if (!user || !user.is_primary) {
      return true;
    }
    const statuses = this.actions[user.status_id];
    let len = 0;
    len = statuses.find((f) => f.id === 8) ? len + 1 : len;
    len = statuses.find((f) => f.id === 7) ? len + 1 : len;
    if (statuses.length === len) {
      return false;
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
