<ng-container *ngIf="passwordErrors; else responsiveTable">
  <div class="password_validate_popup">
    <img
      class="lock_icon_validate"
      [src]="'assets/icons/lock-svg-validate.svg'"
      alt=""
    />
    <div>
      <div class="title_validate_popup">
        {{ "VALIDATIONS.PASSWORD_VALIDATION_TEXT" | translate }}
      </div>
      <div class="list_error">
        <div class="item-error error-{{ !passwordErrors.has_upper_case }}">
          <i class="fa fa-check"></i>
          {{ "VALIDATIONS.UPPERCASE" | translate }}
        </div>
        <div class="item-error error-{{ !passwordErrors.has_lower_case }}">
          <i class="fa fa-check"></i>
          {{ "VALIDATIONS.LOWERCASE" | translate }}
        </div>
        <div class="item-error error-{{ !passwordErrors.has_numeric }}">
          <i class="fa fa-check"></i>
          {{ "VALIDATIONS.NUMERIC" | translate }}
        </div>
        <div class="item-error error-{{ !passwordErrors.has_symbol }}">
          <i class="fa fa-check"></i>
          {{ "VALIDATIONS.SYMBOL" | translate }}
        </div>
        <div class="item-error error-{{ !passwordErrors.has_min_length }}">
          <i class="fa fa-close" aria-hidden="true"></i>
          {{ "VALIDATIONS.MINLENGTH" | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #responsiveTable>
  <ng-container *ngIf="message">
    <p class="error-text">{{ message | translate }}</p>
  </ng-container>
</ng-template>
