import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "@api/authentication";
import { environment } from "@environment/environment";
import { CurrentUserHelper } from "@helpers/current.user.helper";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { ProviderStatuses } from "src/app/_models/_statuses/provider-statuses";
import { ProvidersService } from "src/app/_services/providers.service";
import { User } from "../../../_models/user";
import { AlertService } from "../../../_services/alert.service";
import { passwordValidator } from "@modules/shared/_validators/password-strength-validator";
import { MatDialog } from "@angular/material/dialog";
import { ChangePasswordDialogComponent } from "@modules/account";

@Component({
  selector: "app-login-form",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [],
})
export class LoginComponent implements OnInit {
  user: User;
  returnUrl: string;
  isLoading: boolean = false;
  hide = true;
  recaptchaHide = true;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private providerService: ProvidersService,
    private currentUserHelper: CurrentUserHelper,
    private dialog: MatDialog
  ) {
    this.user = new User();
  }

  ngOnInit() {
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/dashboard";
  }

  login() {
    this.isLoading = true;
    this.authenticationService
      .login(this.user.username, this.user.password)
      .subscribe(
        (_data) => {
          this.isLoading = false;
          const localUser = JSON.parse(
            localStorage.getItem(environment.user_local_storage_key)
          );
          this.currentUserHelper.user = localUser;
          this.providerService.getProvider(localUser.entity_id).subscribe(
            (res) => {
              const provider = res.result;
              localStorage.setItem(
                environment.entity_local_storage_key,
                JSON.stringify(provider)
              );
              this.currentUserHelper.user = res.result;

              if (passwordValidator(this.user.password)) {
                this.dialog.open(ChangePasswordDialogComponent);
              }

              if (provider.status_id === ProviderStatuses.statuses.incomplete) {
                this.router.navigateByUrl("/create-profile").then();
                return false;
              }

              if (
                provider.status_id ===
                ProviderStatuses.statuses["no-categories"]
              ) {
                this.router.navigateByUrl("/service-portfolio").then();
              }

              if (provider.status_id !== ProviderStatuses.statuses.active) {
                this.router.navigateByUrl("/settings").then();
                return false;
              }

              this.router.navigateByUrl(this.returnUrl).then();
              return true;
            },
            (_error) => {
              return false;
            }
          );
        },
        (error) => {
          this.recaptchaHide = false;
          this.isLoading = false;
          this.alertService.errors(error.error.errors);
        }
      );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
