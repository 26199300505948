import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { CompanySettingsDialogComponent } from "./dialogs/company-settings-dialog/company-settings-dialog.component";

@NgModule({
  declarations: [CompanySettingsDialogComponent],
  exports: [CompanySettingsDialogComponent],
  providers: [],
  imports: [CommonModule, SharedModule],
})
export class CompanyModule {}
