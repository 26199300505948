<div class="custom-modal-wrapper p-0">
  <div class="modal-title-custom p-20">
    {{ "EXPENSES.EDIT" | translate }}
  </div>

  <div class="clear"></div>

  <app-loader-spinner-general
    *ngIf="isLoading"
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>

  <form [formGroup]="expenseForm" *ngIf="!isLoading">
    <div class="modal-body-custom p-20">
      <!-- Title, Cost Category -->
      <div class="row">
        <div class="form-group w-50">
          <app-title-selector
            [value]="expenseItem.title"
            [formGroup]="expenseForm"
          ></app-title-selector>
        </div>

        <div class="form-group w-50">
          <app-cost-category-selector
            [value]="expenseItem.cost_category_id"
            [formGroup]="expenseForm"
          ></app-cost-category-selector>
        </div>
      </div>

      <!-- Amount & Currency + Third Party Supplier -->
      <div class="row">
        <div class="form-group w-50">
          <app-cost-selector
            [value]="expenseItem.amount"
            [formGroup]="expenseForm"
          ></app-cost-selector>
        </div>

        <div class="form-group w-50">
          <app-currency-selector
            [useCode]="true"
            [formGroup]="expenseForm"
          ></app-currency-selector>
        </div>
      </div>

      <!-- Expense date, Covered by & Assigned to task -->
      <div class="row">
        <div class="form-group w-50">
          <app-covered-by-selector
            [value]="expenseItem.covered_by_id"
            [caseId]="data.entityId"
            [formGroup]="expenseForm"
          ></app-covered-by-selector>
        </div>

        <div class="w-50">
          <app-date-range-calendar
            [placeholder]="'EXPENSES.DATE' | translate"
            [singleDate]="expenseForm.get('incurred_at').value"
            (change)="setDate($event)"
          ></app-date-range-calendar>
        </div>
      </div>

      <div [ngClass]="{ 'd-none': !fileRemoved }">
        <!-- Upload doc -->
        <div class="row">
          <div class="form-group w-100 file-wrapper">
            <file-upload
              class="w-100"
              [formGroup]="expenseForm"
              formControlName="file"
              [control]="fileUploadControl"
              multiple="false"
            >
            </file-upload>
            <div class="error-wrapper">
              <app-form-errors [control]="expenseForm.get('file')">
              </app-form-errors>
            </div>
            <div class="clear"></div>
          </div>
        </div>
        <!-- Accepted doc formats -->
        <div class="row">
          <div class="w-100 gray fs-12 fw-500 file-wrapper">
            {{ "GENERALS.DOC-TYPE" | translate }}.
          </div>
        </div>
      </div>

      <!-- remove-file-button -->
      <div class="row" [ngClass]="{ 'd-none': fileRemoved }">
        <div class="remove-file-wrapper">
          <img
            class="doc-type-icon"
            [src]="
              'assets/images/doc-type-' + expenseItem.file.file_type + '.svg'
            "
            alt=""
          />
          {{ expenseItem.file.name }}
          <span (click)="removeFile()" class="orange">Remove file</span>
        </div>
      </div>
    </div>
  </form>
  <div class="clear"></div>
  <div class="modal-footer-custom bottom-buttons p-20">
    <app-loader-spinner-general
      *ngIf="isLoading"
      [repeat]="2"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoading">
      <button class="btn gray-bg fl-l modal-button" (click)="close()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <div class="clear"></div>
  </div>
</div>
