import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ContactsService } from "@modules/contacts/services/contacts.service";
import { ModelData } from "@modules/shared/models/model";
import { combineLatest, merge, Observable, Subject } from "rxjs";
import { finalize, map, shareReplay, startWith } from "rxjs/operators";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { ContactData } from "../../models/contact.model";

@Component({
  selector: "app-contact-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditComponent implements OnInit {
  @Input()
  contactable: ModelData;

  @Input()
  contact?: ContactData;

  @Output()
  changes = new EventEmitter<any>();

  type: "create" | "update" = "create";

  contactFrom = new UntypedFormGroup({
    label: new UntypedFormControl(""),
    name: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", [Validators.required, Validators.email]),
    phoneNumber: new UntypedFormControl("", []),
    isEmergency: new UntypedFormControl(false, Validators.required),
    isPrimary: new UntypedFormControl(false, Validators.required),
    hasAddress: new UntypedFormControl(false),
  });

  addressForm = new UntypedFormGroup({
    usage: new UntypedFormControl(""),
    countryCode: new UntypedFormControl("", Validators.required),
    state: new UntypedFormControl("", Validators.required),
    city: new UntypedFormControl("", Validators.required),
    address: new UntypedFormControl("", Validators.required),
    postCode: new UntypedFormControl("", Validators.required),
  });

  countries$: Observable<any[]>;
  countryFilterControl = new UntypedFormControl();
  filteredCountries$: Observable<any[]>;
  loading$ = new Subject<boolean>();

  constructor(
    private contactService: ContactsService,
    private dictionariesService: DictionariesService
  ) {
    this.countries$ = this.dictionariesService.getCountryList().pipe(
      map((data) => data.result),
      shareReplay(1)
    );
    this.loading$.subscribe((loading) => {
      if (loading) {
        this.contactFrom.disable({ emitEvent: false });
        this.addressForm.disable({ emitEvent: false });
      } else {
        this.contactFrom.enable({ emitEvent: false });
        this.addressForm.enable({ emitEvent: false });
      }
    });

    this.filteredCountries$ = combineLatest([
      this.countryFilterControl.valueChanges.pipe(startWith("")),
      this.countries$,
    ]).pipe(
      map(([searchInput, countries]) => {
        if (!searchInput) {
          return countries;
        }
        const filterValue = searchInput.toLowerCase();
        return countries.filter((country) =>
          country.name.toLowerCase().includes(filterValue)
        );
      }),
      shareReplay(1)
    );
  }

  ngOnInit() {
    merge(
      this.contactFrom.valueChanges,
      this.addressForm.valueChanges
    ).subscribe((data) => this.changes.emit(data));
    if (this.contact) {
      this.type = "update";
      this.contactFrom.patchValue(this.contact);
      if (this.contact.address) {
        this.contactFrom.get("hasAddress").setValue(true);
        this.addressForm.patchValue(this.contact.address);
      }
    }
  }

  canSubmit() {
    if (!this.contactFrom.valid) {
      return false;
    }
    if (!!this.contactFrom.get("hasAddress").value && !this.addressForm.valid) {
      return false;
    }
    return true;
  }

  submit() {
    this.contactFrom.markAllAsTouched();

    if (!this.contactFrom.valid) {
      return;
    }

    if (!this.canSubmit()) {
      throw new Error("form has errors");
    }
    this.loading$.next(true);
    return this.createOrUpdate().pipe(
      finalize(() => {
        this.loading$.next(false);
      })
    );
  }

  private prepare() {
    const contact: ContactData = { ...this.contact, ...this.contactFrom.value };
    if (this.contactFrom.value.hasAddress) {
      contact.address = this.addressForm.value;
    } else {
      contact.address = null;
    }
    return contact;
  }

  private createOrUpdate(): Observable<any> {
    const data = this.prepare();
    if (this.type === "update") {
      return this.contactService.update(this.contactable, data);
    }
    return this.contactService.create(this.contactable, data);
  }
}
