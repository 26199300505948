export class ProviderStatuses {
  static statuses = {
    active: 3,
    "pending-approval": 4,
    invited: 5,
    "pending-request": 6,
    deleted: 7,
    suspended: 8,
    draft: 9,
    incomplete: 10,
    declined: 17,
    refused: 50,
    "terms-not-accepted": 200,
    "no-categories": 201,
  };
}
