import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { ValueList } from "@modules/shared/models/value-list.model";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";

declare let $: any;
interface FieldErrorResponse {
  message: string;
  errors: ValueList<string[]>;
}
@Injectable()
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {
    // clear alert message on route change
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next(null);
        }
      }
    });
  }

  clearErrorMsg() {
    this.subject.next(null);
  }

  saved() {
    this.success(this.translateService.instant("GENERALS.SAVED"));
  }

  success(message, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "success", text: message });
  }

  error(message, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "error", text: message });
  }

  stringError(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: "string-error", text: message });
  }
  fieldErrors(error: FieldErrorResponse) {
    let messages = [];
    if (typeof error?.message === "string" && error.message.length) {
      messages.push(error.message);
    }
    for (const fieldKey in error.errors) {
      const fieldError = error.errors[fieldKey] as string[];
      messages = [...messages, ...fieldError];
    }
    this.subject.next({ type: "error", text: messages });
  }
  errors(messages, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    const message = [];
    if (!Array.isArray(messages)) {
      this.fieldErrors({
        message: "",
        errors: messages,
      });
      return;
    }
    for (let i = 0; i < (messages?.length || 0); i++) {
      message.push(messages[i].message);
    }
    this.subject.next({ type: "error", text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  closeErrNotification(resp) {
    this.subject.next(resp);
  }

  isCloseErrNotifObs(): Observable<any> {
    return this.subject.asObservable();
  }

  formatLongDeleteError(error, attr): any[] {
    return this.formatLongDeleteError2(error.error.result, attr);
  }

  formatLongDeleteError2(error, attr): any[] {
    const text = [];
    $.each(error, function (i, val) {
      text.push(val[attr]);
    });
    return text;
  }
}
