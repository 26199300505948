import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-cost-selector",
  templateUrl: "./cost-selector.component.html",
})
export class CostSelectorComponent implements OnInit {
  @Input() value;
  expenseForm: UntypedFormGroup;
  costControl: UntypedFormControl;

  constructor(private controlContainer: ControlContainer) {
    this.costControl = new UntypedFormControl(this.value ? this.value : "", [
      Validators.required,
      Validators.min(0),
    ]);
  }

  ngOnInit() {
    this.expenseForm = <UntypedFormGroup>this.controlContainer.control;
    this.expenseForm.setControl("amount", this.costControl);

    this.costControl.valueChanges.subscribe((val) => {
      if (+val < 0) {
        this.costControl.setValue(-val);
      }
    });
  }
}
