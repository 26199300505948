import { Component, Input, OnInit } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { take } from "rxjs/operators";
import { DocumentService } from "@api/documents/services/documents.service";
import { Subject } from "rxjs";

@Component({
  selector: "app-type-selector",
  templateUrl: "./type-selector.component.html",
  styleUrls: ["./type-selector.component.scss"],
})
export class TypeSelectorComponent implements OnInit {
  documentForm: UntypedFormGroup;
  typeControl: UntypedFormControl;
  isLoading = false;
  types;

  @Input() entityType;
  @Input() public value?: number;

  private unsubscribe$: Subject<void>;

  public docType: string;

  handleDocumentType(event: MatSelectChange) {
    let words = event.source.triggerValue.split(" ");

    words = words.map((word) => {
      if (word.includes("(")) {
        return word.charAt(0) + word.charAt(1).toUpperCase() + word.slice(2);
      }

      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    this.docType = words.join(" ");
  }

  constructor(
    private controlContainer: ControlContainer,
    private documentService: DocumentService
  ) {
    this.typeControl = new UntypedFormControl("", Validators.required);
  }

  ngOnInit() {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("document_type_id", this.typeControl);

    this.unsubscribe$ = new Subject();

    if (this.value) {
      this.documentForm.get("document_type_id").setValue(this.value);
    }

    this.documentService
      .types(this.entityType)
      .pipe(take(1))
      .subscribe((type) => {
        this.types = type["result"];
        this.isLoading = false;
      });
  }
}
