import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimelineRoutingModule } from "@modules/timeline/timeline-routing.module";
import { TimelineListComponent } from "./timeline-shared/timeline-list/timeline-list.component";
import { TimelineHeaderComponent } from "./components/timeline-header/timeline-header.component";
import { TimelineSidebarComponent } from "./components/timeline-sidebar/timeline-sidebar.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TimelineSidebarSubtasksComponent } from "./components/timeline-sidebar-subtasks/timeline-sidebar-subtasks.component";
import { TaskStatusDirective } from "./directives/task-status.directive";
import { SidebarWidthDirective } from "./directives/sidebar-width.directive";
import { SidebarTimelineDirective } from "./directives/sidebar-timeline.directive";
import { TimelineNavigationComponent } from "./components/timeline-navigation/timeline-navigation.component";
import { SharedModule } from "@modules/shared/shared.module";
import { TimelineServicesSidebarComponent } from "./components/timeline-services-sidebar/timeline-services-sidebar.component";
import { CaseTaskMapperService } from "@api/case-tasks";

@NgModule({
  declarations: [
    TimelineListComponent,
    TimelineHeaderComponent,
    TimelineSidebarComponent,
    TimelineSidebarSubtasksComponent,
    TaskStatusDirective,
    SidebarWidthDirective,
    SidebarTimelineDirective,
    TimelineNavigationComponent,
    TimelineServicesSidebarComponent,
  ],
  imports: [
    CommonModule,
    TimelineRoutingModule,
    MatTooltipModule,
    SharedModule,
  ],
  exports: [TimelineServicesSidebarComponent],
  providers: [CaseTaskMapperService],
})
export class TimelineModule {}
