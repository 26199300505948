import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlanCardComponent } from "./components/plan-card/plan-card.component";
import { SharedModule } from "@modules/shared/shared.module";

@NgModule({
  exports: [PlanCardComponent],
  declarations: [PlanCardComponent],
  imports: [CommonModule, SharedModule],
})
export class BillingSharedModule {}
