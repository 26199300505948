<app-dialog-header>
  {{ "MILESTONES.SAVE_MILESTONE_REPORT" | translate }}
</app-dialog-header>
<div class="modal-body-custom p-20">
  <mat-form-field
    [formGroup]="customReportForm"
    appearance="outline"
    class="w-full"
  >
    <mat-label>{{ "LABEL.NAME" | translate }}</mat-label>
    <input matInput formControlName="name" />
    <mat-error formControlError="name"></mat-error>
  </mat-form-field>
</div>
<div class="clear"></div>

<div class="dialog-footer">
  <button class="btn-primary" (click)="submit()" [disabled]="isLoading">
    <mat-icon svgIcon="save"></mat-icon>
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>
