import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AlertService } from "../../../../_services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { finalize } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { fileValidator } from "@modules/shared/_validators/file-extension.validator";
import { ExpensesService } from "@api/expenses/services/expenses.service";
import { ExpenseData } from "@api/expenses/models/expenses.model";
import { FileUploadControl } from "@iplab/ngx-file-upload";
import moment from "moment";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-edit-expense",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditExpenseComponent implements OnInit {
  expenseForm: UntypedFormGroup;
  isLoading = true;
  expenseItem: ExpenseData;
  public fileUploadControl = new FileUploadControl();

  fileRemoved = false;

  taskId?: number;

  constructor(
    private expensesService: ExpensesService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data,
    protected dialogRef: MatDialogRef<EditExpenseComponent>,
    public translate: TranslateService
  ) {
    if (data.expense.title) {
      this.expenseItem = data.expense;
    }

    this.taskId = data.expense.task_id;

    this.expenseForm = new UntypedFormGroup({
      file: new UntypedFormControl([], [Validators.required, fileValidator()]),
      incurred_at: new UntypedFormControl(this.expenseItem.incurred_at, [
        Validators.required,
      ]),
      currency_code: new UntypedFormControl(
        this.expenseItem.currency_code,
        Validators.required
      ),
    });

    // this.fileUploadControl.setValue([this.expenseItem.file]);

    this.expenseForm.get("file").valueChanges.subscribe(() => {
      if (this.expenseForm.get("file").hasError("fileExtension")) {
        this.alertService.error([
          this.translate.instant("VALIDATIONS.FILE_EXTENSION_TYPE"),
        ]);
      }
    });
  }

  removeFile() {
    this.fileRemoved = true;
  }

  ngOnInit() {
    this.isLoading = false;
  }

  submit() {
    const data = this.expenseForm.getRawValue();

    data.task_id = this.taskId;

    if (!this.fileRemoved) {
      data.file = [this.expenseItem.file];
      this.expenseForm.get("file").clearValidators();
      this.expenseForm.get("file").updateValueAndValidity();
    }

    if (this.fileRemoved) {
      this.expenseForm.get("file").clearValidators();
      this.expenseForm
        .get("file")
        .setValidators([Validators.required, fileValidator()]);

      this.expenseForm.get("file").updateValueAndValidity();
    }

    this.expenseForm.markAllAsTouched();

    if (!this.expenseForm.valid) {
      return;
    }

    data.expat_case_id = this.expenseItem.expat_case_id;
    this.isLoading = true;

    this.expensesService
      .editExpense(
        this.data.entityType,
        this.data.entityId,
        this.expenseItem.id,
        data
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((documentData) => {
        this.dialogRef.close({
          type: "saved",
          documentData,
        });
      });
  }

  close() {
    this.dialogRef.close();
  }

  setDate(date: any): void {
    if (!moment(date).isValid()) {
      return;
    }

    this.expenseForm.get("incurred_at").setValue(moment(date));
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
