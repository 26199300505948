import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CaseFollower, CasesService } from "@api/cases";
import { Subject, pipe, switchMap, takeUntil } from "rxjs";
import { filterNullish } from "src/app/lib";
import { ConfirmActionModalComponent } from "../confirm-action-modal/confirm-action-modal.component";

@Component({
  selector: "app-followers",
  templateUrl: "./followers.component.html",
  styleUrls: ["./followers.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowersComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  resourceId: number;
  @Input()
  resourceType: string;
  @Input()
  followers: any[];

  @Output()
  updated = new EventEmitter<CaseFollower[]>();

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected readonly dialog: MatDialog,
    protected readonly caseService: CasesService,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges() {
    this.mapFollowers();
  }

  ngOnInit() {
    this.mapFollowers();
  }

  mapFollowers() {
    if (this.followers) {
      this.followers = this.followers.map((follower) => ({
        ...follower,
        avatar:
          "https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=" +
          follower.user_name,
      }));
    }
    this.changeDetectorRef.markForCheck();
  }

  removeFollower(user) {
    const action = {
      needs_confirmation: false,
      customTitle: "GENERALS.DELETE-FOLLOWER",
      customText: "GENERALS.DELETE-FOLLOWER-CONFIRM",
    };

    this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
        filterNullish(),
        pipe(
          switchMap(() =>
            this.caseService.removeFollower({
              case_id: this.resourceId,
              user_id: user.user_id,
            })
          )
        )
      )
      .subscribe((result) => {
        this.updated.next(result);
        this.changeDetectorRef.markForCheck();
      });
  }
}
