import { AbstractControl, ValidatorFn } from "@angular/forms";

export function dependentFieldsValidator(
  firstDependent: string,
  secondDependent: string
): ValidatorFn {
  return (controls: AbstractControl) => {
    const firstDependentValue = controls.get(firstDependent).value;
    const secondDependentValue = controls.get(secondDependent).value;
    if (
      (!!firstDependentValue && !secondDependentValue) ||
      (!firstDependentValue && !!secondDependentValue)
    ) {
      return { isDependent: true };
    }
    return null;
  };
}
