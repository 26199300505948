import { Component, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { CurrencyData } from "@api/expenses/models/currency.model";
import { DictionariesService } from "../../../../_services/dictionaries.service";

@Component({
  selector: "app-currency-selector",
  templateUrl: "./currency-selector.component.html",
})
export class CurrencySelectorComponent implements OnInit {
  form: FormGroup;
  currencies: CurrencyData[];

  @Input()
  useCode: boolean = false;

  constructor(
    private controlContainer: ControlContainer,
    private dictionaryService: DictionariesService
  ) {}

  ngOnInit(): void {
    this.form = <FormGroup>this.controlContainer.control;
    this.getCurrencies();
  }

  getCurrencies() {
    this.dictionaryService.getCurrencies().subscribe((data) => {
      this.currencies = data;
    });
  }
}
