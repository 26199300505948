import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { WorkflowStatus } from "src/app/_models/workflow-status";
import { AlertService } from "src/app/_services/alert.service";
import { WorkflowService } from "src/app/_services/workflow.service";

@Component({
  selector: "app-service-selector-item",
  templateUrl: "./service-selector-item.component.html",
})
export class ServiceSelectorItemComponent implements OnInit {
  @Input()
  serviceControl: UntypedFormGroup;

  constructor(
    private workflowService: WorkflowService,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const { remaining } = this.serviceControl.getRawValue();
    if (remaining === 0) {
      this.serviceControl.disable();
    }
    this.serviceControl.valueChanges
      .pipe(
        distinctUntilChanged((x, y) => x.enabled === y.enabled),
        filter(({ enabled }) => enabled),
        switchMap((value) => {
          return this.workflowService
            .getWorkflows(value.service_id, {
              workflow_status: WorkflowStatus.PUBLISHED,
            })
            .pipe(
              map((response) => {
                if (response.result.length > 0) {
                  return {};
                }
                this.alertService.stringError(
                  this.translate.instant("ERROR.SERVICE_NO_WORKFLOWS")
                );

                return { enabled: false };
              }),
              catchError((data) => {
                return of({ enabled: false });
              })
            );
        })
      )
      .subscribe((data) => {
        this.serviceControl.patchValue(data);
      });
  }
}
