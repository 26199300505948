<form class="services" [formGroup]="serviceControl">
  <mat-slide-toggle
    color="primary"
    class="create-case-slide-inner"
    formControlName="enabled"
  >
  </mat-slide-toggle>
  {{ serviceControl.get("service_name").value }}
  <span *ngIf="serviceControl.get('remaining')">
    ({{ serviceControl.get("remaining").value }} remaining)
  </span>
</form>
