<form [formGroup]="form" *ngIf="form">
  <div class="details-2c m-t-15">
    <div class="white-box-wrapper">
      <div class="white-box-row">
        <div class="white-box-header">
          <div class="medium-title fl-l">
            {{ "EXPATS.OFFICE" | translate }}
          </div>
        </div>
        <div class="clear"></div>

        <div class="white-box-body">
          <div class="row">
            <div class="w-50">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "GENERALS.COUNTRY" | translate }}</mat-label>
                <mat-select
                  formControlName="countryCode"
                  [placeholder]="'LABEL.COUNTRY' | translate"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [placeholderLabel]="'LABEL.COUNTRY' | translate"
                      [noEntriesFoundLabel]="
                        'GENERALS.NO-ITEMS-DISPLAY' | translate
                      "
                      [formControl]="countryFilterControl"
                      [clearSearchInput]="false"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let country of filteredCountries"
                    [value]="country.country_code"
                  >
                    {{ country.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    form.controls.countryCode.errors?.required &&
                    form.controls.countryCode.touched
                  "
                >
                  {{ "GENERALS.FIELD-REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="w-50">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "GENERALS.CITY" | translate }}</mat-label>
                <mat-select
                  formControlName="city"
                  [placeholder]="'LABEL.CITY' | translate"
                  #singleSelect
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [placeholderLabel]="'LABEL.CITY' | translate"
                      [noEntriesFoundLabel]="
                        'GENERALS.NO-ITEMS-DISPLAY' | translate
                      "
                      [formControl]="cityFilterControl"
                      [clearSearchInput]="false"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let city of cities$ | async"
                    [value]="city.name"
                  >
                    {{ city.name }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    form.controls.city.errors?.required &&
                    form.controls.city.touched
                  "
                >
                  {{ "GENERALS.FIELD-REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="w-50">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "LABEL.COUNTY-STATE" | translate }}</mat-label>
                <input matInput formControlName="county" />
                <mat-error *ngIf="form.controls.county?.errors?.minlength">
                  {{ "GENERALS.MIN-CHARACTERS" | translate }}
                  {{ form.controls.county.errors?.minlength?.requiredLength }}
                  {{ "GENERALS.MIN-CHARACTERS2" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="w-50">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "LABEL.ADDRESS" | translate }}</mat-label>
                <input matInput formControlName="address" />
                <mat-error *ngIf="form.controls.address?.errors?.minlength">
                  {{ "GENERALS.MIN-CHARACTERS" | translate }}
                  {{ form.controls.address.errors?.minlength?.requiredLength }}
                  {{ "GENERALS.MIN-CHARACTERS2" | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="w-50">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "LABEL.ZIP_CODE" | translate }}</mat-label>
                <input matInput formControlName="postalCode" />
              </mat-form-field>
            </div>

            <div class="w-50">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "LABEL.LABEL" | translate }}</mat-label>
                <input matInput formControlName="label" />
                <mat-error
                  *ngIf="
                    form.controls.label.errors?.required &&
                    form.controls.label.touched
                  "
                >
                  {{ "GENERALS.FIELD-REQUIRED" | translate }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="modal-footer-custom fl-r">
          <button class="btn gray-bg fl-l modal-button" (click)="closeModal()">
            <mat-icon svgIcon="cancel"></mat-icon>
            {{ "GENERALS.CANCEL" | translate }}
          </button>
          <button
            class="btn orange-bg fl-r m-l-20 modal-button"
            (click)="save()"
          >
            <mat-icon svgIcon="save"></mat-icon>
            {{ "GENERALS.SAVE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
