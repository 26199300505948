import { createAction, props } from "@ngrx/store";

export const ActionTypes = {
  SET_NOTIFICATION: createAction("[Notification] Set Notification"),
  SET_NOTIFICATION_SUCCESS: createAction("[Notification] Save Success"),
  SET_NOTIFICATION_FAILED: createAction("[Notification] Save Failed"),
  SET_MESSAGES: createAction(
    "[Messages] Set Messages",
    props<{ payload: any }>()
  ),
  SET_MESSAGES_SUCCESS: createAction(
    "[Messages] Save Success",
    props<{ payload: any }>()
  ),
  SET_MESSAGES_FAILED: createAction("[Messages] Save Failed"),
};
