import { Component, Input } from "@angular/core";
import { PreviewStrategy } from "../preview-strategy.component";
import { DocumentFile } from "@api/documents/models/document.model";

@Component({
  selector: "app-excel-preview-strategy",
  templateUrl: "./excel-preview-strategy.component.html",
  styleUrls: ["./excel-preview-strategy.component.scss"],
})
export class ExcelPreviewStrategyComponent implements PreviewStrategy {
  @Input() file: DocumentFile;

  public loading: boolean;
}
