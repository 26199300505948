import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PageData } from "@modules/shared/models/page.model";
import { Observable, merge, of } from "rxjs";
import { map, pairwise, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { ServiceOffer } from "../../../_models/service-offer";
import { BulkOfferUpdateData } from "../models/bulk-offer-update.data";
import { Answer, Question } from "../models/question.model";
import { WorkflowModel } from "src/app/_models/workflow";

@Injectable()
export class ServiceOffersService {
  private serviceOffersUrl = "offers/offers";
  private offProvider = "offers/provider";
  private offerHistory = "history/offer";

  offerDetails;

  constructor(private http: HttpClient) {}

  getServiceOffers(
    params: any = {}
  ): Observable<{ result: PageData<ServiceOffer> }> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.serviceOffersUrl,
      {
        params: {
          ...params,
          exclude_id: params.exclude_id ? params.exclude_id : ";",
        },
      }
    );
  }

  getServicesList(params: any = {}): Observable<any> {
    return merge(
      of({ filters: [] }),
      this.http
        .get<any>(environment.gateway_endpoint + this.serviceOffersUrl, {
          params: {
            ...params,
            exclude_id: params.exclude_id ? params.exclude_id : ";",
          },
        })
        .pipe(
          map((response) => {
            delete response.result.filters;
            return response.result;
          })
        ),
      this.http
        .get<any>(environment.gateway_endpoint + this.serviceOffersUrl, {
          params: {
            ...params,
            include: "filters",
            per_page: 1,
            exclude_id: params.exclude_id ? params.exclude_id : ";",
          },
        })
        .pipe(map((response) => ({ filters: response.result.filters })))
    ).pipe(
      pairwise(),
      map(([prev, curr]) => ({ ...prev, ...curr }))
    );
  }

  getServiceOfferById(id): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + this.serviceOffersUrl + "/" + id)
      .pipe(
        tap((data) => {
          this.offerDetails = data.result;
        })
      );
  }

  createServiceOffers(offer: Partial<ServiceOffer>): Observable<any> {
    return this.http
      .post<any>(environment.gateway_endpoint + this.serviceOffersUrl, offer)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  copyWorkflow(offerId, sourceOfferId: number): Observable<WorkflowModel[]> {
    return this.http
      .post<{ result: WorkflowModel[] }>(
        environment.gateway_endpoint +
          this.serviceOffersUrl +
          `/${sourceOfferId}/copy-workflow`,
        offerId
      )
      .pipe(map(({ result }) => result));
  }

  updateServiceOffers(offer: Partial<ServiceOffer>, id): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.serviceOffersUrl + "/" + id,
        offer
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Bulk
  updateBulk(update: BulkOfferUpdateData): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + this.serviceOffersUrl + "/bulk",
        update
      )
      .pipe(map((response) => response.result));
  }

  // Update Status Bulk
  updateStatusBulk(status_id, offer_ids): Observable<any> {
    return this.http.patch<any>(
      environment.gateway_endpoint + this.serviceOffersUrl,
      { status_id: status_id, offer_ids: offer_ids }
    );
  }

  // Copy offer
  copyOffer(offerId): Observable<any> {
    return this.http.post<any>(
      environment.gateway_endpoint +
        this.serviceOffersUrl +
        "/" +
        offerId +
        "/duplicate",
      {}
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.serviceOffersUrl +
        "/status-transitions"
    );
  }

  // Upload by doc type
  uploadImage(id, files): Observable<any> {
    const formData: FormData = new FormData();
    if (files) {
      formData.append("file", files[0], files[0].name);
    }
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "offers/offers/" +
          id +
          "/setPrimaryImage",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Upload by doc type
  uploadBulkOffers(provider_id, category_id, obj): Observable<any> {
    const formData: FormData = new FormData();

    if (obj.file) {
      formData.append("file", obj.file[0], obj.file[0].name);
    }
    formData.append("provider_id", provider_id);
    formData.append("category_id", category_id);

    return this.http
      .post<any>(environment.gateway_endpoint + "offers/import", formData)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  downloadExcel(provider_id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.offProvider +
        "/" +
        provider_id +
        "/download-offers-zip",
      { responseType: "blob" as const }
    );
  }

  getBundleServiceOffersById(id): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "bundles/" + id);
  }

  createBundleServiceOffers(offer: Partial<ServiceOffer>): Observable<any> {
    return this.http
      .post<any>(environment.gateway_endpoint + "bundles", offer)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getOffersReviews(id, params): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "offers/" + id + "/reviews",
      {
        params: params,
      }
    );
  }

  addOfferReview(id, params) {
    return this.http
      .post<any>(
        environment.gateway_endpoint + "offers/" + id + "/reviews",
        params
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // GET Offer history
  getOfferHistory(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.offerHistory + "/" + id,
      {
        params: params,
      }
    );
  }

  calculateDiscount(oldP, newP) {
    return Math.round(((oldP - newP) / oldP) * 100);
  }

  /**
   * Export filtered offers to CSV
   * @param params
   */
  export(params = {}) {
    return this.http.get(
      environment.gateway_endpoint + this.serviceOffersUrl + "/export",
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  uploadDocument(offerId, file): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.http
      .post<any>(
        environment.gateway_endpoint + "offers/offers/" + offerId + "/docs",
        formData
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getQuestions(offerId: string | number): Observable<Question[]> {
    return this.http
      .get<{ result: PageData<Question> }>(
        environment.gateway_endpoint + "offers/offers/" + offerId + "/questions"
      )
      .pipe(map((data) => data.result.items));
  }

  uploadQuestions(offerId: string, questions: string[]): Observable<any> {
    return this.http.post(
      environment.gateway_endpoint + "offers/offers/" + offerId + "/questions",
      { text: questions }
    );
  }

  getAnswers(
    offerId: string | number,
    orderId: string | number
  ): Observable<Answer[]> {
    return this.http
      .get<{ result: Answer[] }>(
        environment.gateway_endpoint + "billing/orders/answers",
        {
          params: {
            offer_id: offerId,
            order_id: orderId,
          },
        }
      )
      .pipe(map((data) => data.result));
  }
}
