import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  OnInit,
} from "@angular/core";

@Directive({
  selector: "[windowScrollAppNav]",
})
export class WindowScrollAppNavDirective implements OnInit {
  elemPosition;
  innerWidth;
  xs;

  constructor(public el: ElementRef, public renderer: Renderer2) {
    this.innerWidth = window.innerWidth;
    this.xs = innerWidth <= 992;
  }

  ngOnInit() {
    // add position fixed helper
    const helperElem = document.createElement("div");
    helperElem.classList.add("small-app-nav-helper");
    this.el.nativeElement.before(helperElem);
    // 15 = margin bottom for .dashboard-body-top
    this.renderer.setStyle(
      helperElem,
      "height",
      this.el.nativeElement.clientHeight + "px"
    );
    this.renderer.setStyle(
      document.querySelector(".small-app-nav-helper"),
      "display",
      "none"
    );

    this.elemPosition = this.el.nativeElement.offsetTop;
  }

  @HostListener("window:scroll", ["$event"])
  @HostListener("window:resize", ["$event"])
  onScroll() {
    this.innerWidth = window.innerWidth;

    this.xs = innerWidth <= 992;

    if (!this.xs) {
      const scrolled =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentElement ||
              document.body
            ).scrollTop;

      // 51 = small header height;
      if (scrolled >= this.elemPosition - 51) {
        this.renderer.addClass(this.el.nativeElement, "small-app-nav");
        this.renderer.setStyle(
          document.querySelector(".small-app-nav-helper"),
          "display",
          "inline-block"
        );
      } else {
        this.renderer.removeClass(this.el.nativeElement, "small-app-nav");
        this.renderer.setStyle(
          document.querySelector(".small-app-nav-helper"),
          "display",
          "none"
        );
      }
    }
  }
}
