<div class="billing-details">
  <div class="heading d-flex justify-content-between align-items-center">
    <b>{{ "BILLING.DETAILS" | translate }}</b>
    <a class="btn orange-bg" (click)="manageBilling()">Manage Billing</a>
  </div>
  <div class="details-actions" [ngClass]="{ 'no-plan': !currentPlan }">
    <ng-container *ngIf="loaded">
      <ng-container *ngIf="currentPlan">
        <div class="details-actions-description">
          Your <b>{{ currentPlan?.name }}</b> billing plan will renew on the
          <b>{{ currentSubscription?.current_period_end | ordinalDate }}</b>
        </div>
        <div class="buttons">
          <a
            class="billing-info-button change-plan"
            routerLink="/billing/plans"
          >
            {{ "BILLING.CHANGE-PLAN" | translate }}
          </a>
          <button class="billing-info-button" (click)="cancel()">
            {{ "BILLING.CANCEL" | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!currentPlan">
        <div class="details-actions-description">
          {{ "BILLING.NO-PLAN" | translate }}
        </div>
        <button class="billing-info-button">
          {{ "BILLING.SELECT-PLAN" | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="invoices?.length">
  <div class="billing-name">
    <table>
      <thead>
        <tr>
          <th class="name">{{ "BILLING.PLAN-NAME" | translate }}</th>
          <th>{{ "BILLING.INVOICE" | translate }}</th>
          <th>{{ "BILLING.DATE" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of invoices">
          <td>{{ invoice.lines.data[0].description }}</td>
          <td>
            <a
              target="_blank"
              [href]="invoice.invoice_pdf"
              class="download-button"
              >{{ "BILLING.DOWNLOAD" | translate }}</a
            >
          </td>
          <td class="invoice-date">
            {{ invoice.created * 1000 | date: "dd/MM/yyyy" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
