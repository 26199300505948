import { Component, OnInit } from "@angular/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { ReportsService } from "@api/reports/services/reports.service";

@Component({
  selector: "app-cases-report-card",
  templateUrl: "./cases-report-card.component.html",
  styleUrls: ["./cases-report-card.component.scss"],
})
export class CasesReportCardComponent implements OnInit {
  public chartOptions;
  private chartMaxValue = 0;
  private chartMinValue = 0;

  constructor(private reports: ReportsService) {}

  getPercent(min: number, max: number, value: number): number {
    return (100 * (value - min)) / (max - min);
  }

  ngOnInit() {
    this.reports.openCases({}).subscribe((data) => {
      data.forEach((item: any) => {
        if (this.chartMaxValue < item.y) {
          this.chartMaxValue = item.y;
        }

        if (this.chartMinValue > item.y) {
          this.chartMinValue = item.y;
        }
      });

      this.chartOptions = {
        series: [
          {
            name: "Actual",
            data,
          },
        ],
        chart: {
          height: 600,
          toolbar: {
            show: false,
          },
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: 30,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: ["#f96332"],
        dataLabels: {
          offsetX: 10,
          formatter: (value, opt) => {
            opt.w.config.dataLabels.offsetX = 10;
            opt.w.config.dataLabels.textAnchor = "start";

            return value + " cases";
          },
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: ["#333333"],
          },
        },
        xaxis: {
          labels: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          tickAmount: 6,
        },
        yaxis: {
          tickAmount: 0,
          labels: {
            style: {
              colors: [],
              fontSize: "14px",
              fontWeight: 700,
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        legend: {
          show: false,
        },
      };
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
