<ng-template #loading>
  <div class="w-100 m-fix">
    <app-loader-spinner-general
      class="w-100 m-t-15"
      [repeat]="2"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>
  </div>
  <div class="w-100">
    <app-loader-spinner-general
      class="w-50"
      [loadingType]="LoadingType.PROFILE"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      class="w-50"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </div>

  <div class="w-100">
    <app-loader-spinner-general
      class="w-50"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
    <app-loader-spinner-general
      class="w-50"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
  </div>
</ng-template>

<div
  class="container-fluid m-fix"
  *ngIf="document$ | async as document; else loading"
>
  <div class="row">
    <div class="w-100">
      <div class="action-link-wrapper m-b-15">
        <ng-container *ngFor="let status of actions[document.status_id]">
          <div
            class="action-link btn white-bg-border"
            *ngIf="
              status.id === DocumentType.DELETE
                ? document.can_delete
                : hasPermission(document, status.id)
            "
            (click)="
              status.id === DocumentType.DELETE
                ? delete()
                : changeStatus(status, document)
            "
          >
            <span>{{ status.text | translate }}</span>
          </div>
        </ng-container>
        <div class="action-link btn white-bg-border" (click)="share()">
          <span>{{ "GENERALS.SHARE" | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="w-50">
      <div class="preview-doc">
        <div class="white-box-wrapper m-0">
          <app-document-preview [document]="document"></app-document-preview>
          <div
            class="mt-4 d-flex align-items-center justify-content-between px-2"
          >
            <div class="preview-title medium-title w-50 text-start p-l-0">
              {{ "GENERALS.DOCUMENT-PREVIEW" | translate }}
            </div>
            <div
              class="w-50 cursor-pointer document-status-label-{{
                document.status_id
              }}  text-center"
              [matMenuTriggerFor]="info"
              #infoTrigger="matMenuTrigger"
              (mouseover)="infoTrigger.openMenu()"
            >
              {{ document.status_label | translate | uppercase }}
            </div>
            <mat-menu
              class="{{
                document.status_id === DocumentType.IN_RENEWAL ||
                document.status_id === DocumentType.REMINDER_SET
                  ? ''
                  : ' d-none'
              }}"
              #info="matMenu"
              [overlapTrigger]="false"
            >
              <span (mouseleave)="infoTrigger.closeMenu()">
                <span
                  class="px-2"
                  *ngIf="document.status_id === DocumentType.REMINDER_SET"
                >
                  {{ document.reminder_date | date: "dd/MM/yyyy hh:mm aaa" }}
                </span>
                <span
                  class="d-flex align-items-center px-2 font-weight-600"
                  *ngIf="document.status_id === DocumentType.IN_RENEWAL"
                >
                  {{ "LEFT-NAVIGATION.CASE" | translate }}:
                  <a
                    routerLink="/cases/{{ document.entity_id }}"
                    routerLinkActive="active"
                    target="_blank"
                    class="orange text-decoration-underline cursor-pointer"
                    mat-menu-item
                  >
                    #{{ document.entity_id }}
                  </a>
                </span>
              </span>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
    <div class="w-50">
      <ng-container>
        <div class="white-box-wrapper m-0">
          <div class="white-box-row">
            <div class="white-box-header">
              <div class="medium-title fl-l">
                {{ "GENERALS.DOCUMENT-INFORMATION" | translate }}
              </div>
              <div
                (click)="openEditDialog()"
                class="white-box-header-action fl-r add orange"
              >
                <b
                  ><i class="icon-edit"></i>
                  {{ "GENERALS.EDIT" | translate }}</b
                >
              </div>
            </div>
            <div class="white-box-body row">
              <div class="w-100">
                <div class="m-b-10">Document name</div>
                <div class="case-label">
                  {{ document.name | notAvailable }}
                </div>
              </div>
              <div class="w-100">
                <div class="m-b-10">Document type</div>
                <div class="case-label">
                  {{ document.document_type.text | notAvailable }}
                </div>
              </div>
              <div class="w-100">
                <div class="m-b-10">Document number</div>
                <div class="case-label">
                  {{ document.number | notAvailable }}
                </div>
              </div>
              <div class="w-50">
                <div class="m-b-10">Document expires at</div>
                <div class="case-label">
                  {{ document.expiration_date | notAvailable }}
                </div>
              </div>
              <div class="w-50">
                <div class="m-b-10">Document uploaded at</div>
                <div class="case-label">
                  {{ document.created_at | notAvailable }}
                </div>
              </div>
              <div class="w-50">
                <div class="m-b-10">Country</div>
                <div class="case-label">
                  {{ document.country?.name | notAvailable }}
                </div>
              </div>
              <div class="w-50">
                <div class="m-b-10">
                  {{ "GENERALS.DOCUMENT-SERVICE-CATEGORY" | translate }}
                </div>
                <div class="case-label">
                  {{ document.service_category?.name | notAvailable }}
                </div>
              </div>
              <div class="w-100">
                <div class="m-b-10">
                  {{ "GENERALS.DOCUMENT-FOLDERS" | translate }}
                </div>
                <div class="case-label">
                  <ng-container *ngIf="!document.tags?.length">
                    N/A
                  </ng-container>
                  <ng-container *ngFor="let tag of document.tags">
                    #{{ tag.name | notAvailable }}
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="w-50">
      <div class="w-100 p-0 m-t-24">
        <app-document-files
          [documentData]="document"
          (filesUpdated)="onFilesUpdated()"
        ></app-document-files>
      </div>
      <div class="w-100 p-0">
        <app-document-shared-with
          [document]="document"
          [docId$]="docId$"
          [entityId$]="entityId$"
          [entityType$]="entityType$"
        ></app-document-shared-with>
      </div>
    </div>

    <div class="w-50 doc-reminders m-t-24">
      <div class="w-100 p-0">
        <app-document-linked-with
          [docId]="document.id"
          [entityType$]="entityType$"
          [entityId$]="entityId$"
        ></app-document-linked-with>
      </div>
      <div class="w-100 p-0 m-t-24">
        <app-reminders-list
          *ngIf="docId$ | async as docId"
          [subjectType]="'doc'"
          [subjectId]="docId"
          [eligibleUsersEntity]="docId"
          (addReminder)="reloadPage()"
        ></app-reminders-list>
      </div>
    </div>
  </div>
</div>
