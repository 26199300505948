import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { Plan } from "../models/plan.model";
import { PageData } from "@modules/shared/models/page.model";
import { ValueList } from "@modules/shared/models/value-list.model";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  constructor(private http: HttpClient) {}

  public listPlans(filters: ValueList<string>): Observable<PageData<Plan>> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}/subscriptions/plans`, {
        params: filters,
      })
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.plans;

          return {
            ...pageData,
            items,
          } as PageData<Plan>;
        })
      );
  }

  public currentSubscription(): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}/subscriptions/current`
    );
  }

  public purchaseSubscription(planId: string) {
    return this.http.post(
      `${environment.gateway_endpoint}/subscriptions/purchase`,
      { plan_id: planId }
    );
  }

  public cancelSubscription(subscriptionId: number) {
    return this.http.post(
      `${environment.gateway_endpoint}/subscriptions/cancel`,
      { subscription_id: subscriptionId }
    );
  }

  public getInvoices() {
    return this.http.get<any>(
      `${environment.gateway_endpoint}/customers/invoices`
    );
  }
}
