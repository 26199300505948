import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { AdsService } from "src/app/_services/ads.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Ads } from "src/app/_models/ads";
import { AlertService } from "src/app/_services/alert.service";
import { environment } from "../../../../environments/environment";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { ServiceCategoriesService } from "src/app/_services/servicecategories.service";
import { ServiceCategory } from "src/app/_models/servicecategory";
import * as moment from "moment";

@Component({
  selector: "app-create-ad-banner",
  templateUrl: "./create-ad-banner.component.html",
  styleUrls: ["./create-ad-banner.component.scss"],
})
export class CreateAdBannerComponent implements OnInit {
  request_call;
  listBannerType;
  performanceType = [{ value: "cpm" }, { value: "cpc" }];
  message: string;
  imgURL: any;
  item: Ads;
  @Output() getItem: EventEmitter<any> = new EventEmitter();
  @Input() newInit;
  isLoading: boolean;
  countries;
  categories;
  bannerType;
  minStartDate: Date;
  minEndDate: Date;

  constructor(
    private adsService: AdsService,
    private translate: TranslateService,
    public ngxSmartModalService: NgxSmartModalService,
    private alertService: AlertService,
    private dictionaryService: DictionariesService,
    private serviceCategoriesService: ServiceCategoriesService
  ) {
    this.item = new Ads();
    this.item.starts_at = null;
    this.item.ends_at = null;

    const entity_local_storage = JSON.parse(
      localStorage.getItem(environment.entity_local_storage_key)
    );
    this.item.entity_id = entity_local_storage["id"];
  }

  ngOnInit() {
    this.minStartDate = new Date();
    this.minEndDate = new Date();
    this.getListBannerType();
    this.getCountryList();
    this.getCategories();
  }

  getListBannerType() {
    this.adsService.getListBannerType().subscribe((data) => {
      this.listBannerType = data.result;
    });
  }

  createAdBanenr() {
    this.isLoading = true;
    if (this.item) {
      this.adsService.createAdBanenr(this.item).subscribe(
        (data) => {
          this.getItem.emit();
          this.ngxSmartModalService.closeLatestModal();
          this.isLoading = false;
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
            this.isLoading = false;
          }
        }
      );
    }
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    if (files) {
      this.item.image = files[0];
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.translate.get("GENERALS.ONLY-IMG").subscribe((data) => {
        this.message = data;
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  removeUploaded() {
    this.imgURL = "";
    this.message = "";
  }

  close() {
    this.ngxSmartModalService.closeLatestModal();
  }

  setDate(e, type) {
    if (type === "start_date") {
      this.item.starts_at = e;
    } else if (type === "end_date") {
      this.item.ends_at = e;
    }
  }

  isOpen() {
    this.item = new Ads();
    this.removeUploaded();
    this.item.starts_at = null;
    this.item.ends_at = null;
    this.bannerType = null;
  }

  getCountryList() {
    this.dictionaryService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  getCategories() {
    this.serviceCategoriesService
      .getCategoryTree(this.item.entity_id)
      .subscribe(
        (data) => {
          if (data.success) {
            this.categories = data.result;
          }

          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  selectCategory(category: ServiceCategory) {
    if (category) {
      this.item.category_id = category.id;
    }
  }

  changeBannerType(e) {
    this.item.key = e.key;
    this.bannerType = e;
  }

  addDayToDate(date, val) {
    return moment(date).add(val, "days").format();
  }
}
