export class ProvidersCommissions {
  provider_id: number;
  categories: Array<any>;
  values: Array<any>;
}

export class Commissions {
  commissions: Array<Commission>;
}

export class Commission {
  commission_type_id: number;
  commission: number;
  start_date: string;
  end_date: string;
}
