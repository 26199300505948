<div class="reminders-wrapper">
  <div class="white-box-wrapper p-20">
    <div class="white-box-row">
      <div class="white-box-header">
        <div class="medium-title fl-l">
          {{ "LEFT-NAVIGATION.REMINDERS" | translate }}
        </div>
        <div
          *ngIf="showCreateButton"
          class="edit-info white-box-header-action fl-r d-flex align-items-end"
          (click)="openDialog()"
        >
          <mat-icon svgIcon="plus-circle" class="plus-circle"></mat-icon
          >{{ "REMINDERS.ADD" | translate }}
        </div>
      </div>
      <div class="white-box-body">
        <app-loader-spinner-general
          *ngIf="isLoading"
          [loadingType]="LoadingType.INFO"
        ></app-loader-spinner-general>
        <ng-container *ngIf="!isLoading">
          <ng-container
            *ngFor="
              let item of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    };
              let i = index
            "
          >
            <div class="reminder-item row">
              <div class="w-50 name">
                <img
                  [src]="
                    'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                    item.author.first_name +
                    ' ' +
                    item.author.last_name
                  "
                />
                {{ item.author.first_name }}
                {{ item.author.last_name }}
              </div>
              <div class="w-50 d-flex justify-content-end">
                <div class="set-date">
                  <label class="m-b-10"> Reminder set for:</label>
                  <div>
                    {{ item.remind_at | date: "dd/MM/yyyy" }}
                  </div>
                </div>
              </div>
              <div class="w-100">
                <div class="text">
                  {{ item.text }}
                </div>
              </div>
              <div class="w-50">
                <div class="shared-with">
                  <label>Shared with: </label>
                  <span class="orange"
                    ><ng-container
                      *ngFor="
                        let subscriber of item.subscribers;
                        let last = last
                      "
                    >
                      {{ subscriber.first_name }}
                      <ng-container *ngIf="!last">, </ng-container
                      >{{ subscriber.last_name }}
                    </ng-container></span
                  >
                </div>
              </div>
              <div class="w-50 d-flex justify-content-end">
                <div class="action d-flex">
                  <ng-container *ngIf="canEdit(item)">
                    <div
                      class="white-box-header-action edit-info"
                      (click)="onEdit(item)"
                    >
                      <i class="icon-edit"></i>
                      {{ "GENERALS.EDIT" | translate }}
                    </div>
                  </ng-container>

                  <div
                    class="white-box-header-action edit-info m-l-10"
                    (click)="onDelete(item.id)"
                  >
                    <i class="icon-trash"></i>
                    {{ "REMINDERS.DELETE" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="gray" *ngIf="items?.length === 0">
            {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
          </div>
        </ng-container>
      </div>
      <!-- paginator -->
      <div class="paginator p-0 d-none">
        <pagination-controls
          (pageChange)="navigateByUrl($event)"
          previousLabel=""
          nextLabel=""
        >
        </pagination-controls>
      </div>

      <!-- end paginator -->
    </div>
  </div>
  <div class="clear"></div>

  <!-- table -->
  <div class="table-wrapper d-none">
    <ng-container *ngIf="!isXs">
      <div class="table-responsive">
        <table
          matSort
          (matSortChange)="sortData($event)"
          [matSortActive]="active"
          [matSortDirection]="direction"
          class="doc-list-table"
        >
          <thead>
            <tr>
              <th width="11%">
                {{ "REMINDERS.AUTHOR" | translate }}
              </th>
              <th width="58%">
                {{ "REMINDERS.REMINDER-TEXT" | translate }}
              </th>
              <th width="11%">
                {{ "REMINDERS.REMIND-AT" | translate }}
              </th>
              <th width="11%">
                {{ "REMINDERS.REMINDED-AT" | translate }}
              </th>
              <th width="11%">
                {{ "REMINDERS.SUBJECT-TYPE" | translate }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="isLoading">
            <tr>
              <td colspan="100">
                <app-loader-spinner-general
                  [loadingType]="LoadingType.INFO"
                ></app-loader-spinner-general>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr
              *ngFor="
                let item of items
                  | paginate
                    : {
                        itemsPerPage: itemsPage,
                        currentPage: p,
                        totalItems: total
                      };
                let i = index
              "
            >
              <td class="gray-pointer">
                {{ item.author.first_name }}
                {{ item.author.last_name }}
              </td>

              <td class="gray-pointer word-wrap">
                {{ item.text }}
              </td>

              <td class="gray-pointer">
                {{ item.remind_at | date: "dd/MM/yyyy" }}
              </td>

              <td class="gray-pointer">
                <ng-container *ngIf="item.reminded_at; else empty">
                  {{ item.reminded_at | date: "dd/MM/yyyy" }}
                </ng-container>

                <ng-template #empty> - </ng-template>
              </td>

              <td class="gray-pointer">
                {{ item.subject_type }}
              </td>

              <!-- table cel with action -->
              <td class="ta-r" width="5%">
                <!-- table action btn -->
                <!-- table action with overlay -->
                <div toggle-action-table class="table-action-wrapper">
                  <i class="icon-dot-3"></i>

                  <div class="table-action">
                    <ul *ngIf="canEdit(item)">
                      <li (click)="onEdit(item)">
                        {{ "REMINDERS.EDIT" | translate }}
                      </li>
                    </ul>
                    <ul>
                      <li (click)="onDelete(item.id)">
                        {{ "REMINDERS.DELETE" | translate }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- end table action with overlay -->
              </td>
            </tr>

            <tr *ngIf="items?.length === 0">
              <td colspan="6" class="gray">
                {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <!-- paginator -->
    <!-- <div class="paginator ">
      <pagination-controls
        (pageChange)="navigateByUrl($event)"
        previousLabel=""
        nextLabel=""
      >
      </pagination-controls>
    </div> -->

    <!-- end paginator -->
  </div>
  <!-- end table -->
</div>
