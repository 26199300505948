import { Component, OnInit } from "@angular/core";
import { finalize } from "rxjs";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  MilestoneData,
  MilestoneListingFilters,
  MilestonesService,
} from "@api/milestones";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "../../../_services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";

@Component({
  selector: "app-company-milestones",
  templateUrl: "./company-milestones.component.html",
  styleUrls: ["./company-milestones.component.scss"],
})
export class CompanyMilestonesComponent implements OnInit {
  public isLoading = false;

  public milestoneFilter: Partial<MilestoneListingFilters> = {};
  public milestones: MilestoneData[] = [];
  filterMilestoneList: MilestoneData[] = [];
  filter: string;
  isEditEnable: number = 0;
  isAddEnable: boolean = false;
  milestoneName: string;

  editMilestoneName: string;

  public canCreate = {
    action: PermissionActionEnum.CREATE,
    resource: PermissionResourceEnum.MILESTONE,
    secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
  };

  public canUpdate = {
    action: PermissionActionEnum.UPDATE,
    resource: PermissionResourceEnum.MILESTONE,
    secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
  };

  public canDelete = {
    action: PermissionActionEnum.DELETE,
    resource: PermissionResourceEnum.MILESTONE,
    secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
  };

  constructor(
    private milestoneService: MilestonesService,
    private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setCompanyMilestones()
    );
    this.getCompanyMilestones();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  private getCompanyMilestones() {
    this.isLoading = true;

    this.milestoneService.list(this.milestoneFilter).subscribe({
      next: (res) => {
        if (res) {
          this.milestones = res.items;
          this.filterMilestoneList = this.milestones;
        }
        this.isLoading = false;
      },
    });
  }

  protected filterResults(text) {
    if (!text) {
      this.filterMilestoneList = this.milestones;
      return;
    }

    this.filterMilestoneList = this.milestones.filter((milestone) =>
      milestone?.name.toLowerCase().includes(text.toLowerCase())
    );
  }

  protected onAdd() {
    this.milestoneName = null;
    this.isAddEnable = !this.isAddEnable;
    this.isEditEnable = 0;
  }

  hideAddButton() {
    this.milestoneName = null;
    this.isAddEnable = !this.isAddEnable;
  }

  protected onEdit(isEditEnable: number, milestone: MilestoneData) {
    this.milestoneName = null;
    this.editMilestoneName = milestone.name;
    this.isAddEnable = false;

    if (isEditEnable === milestone.id) {
      this.isEditEnable = 0;
    }
    this.isEditEnable = milestone.id;
  }

  protected onClose() {
    this.isEditEnable = 0;
  }

  protected create(milestone: string) {
    if (!milestone) {
      return;
    }
    this.isLoading = true;

    this.milestoneService.create({ name: milestone }).subscribe({
      next: () => {
        this.alertService.success(
          this.translate.instant("GENERALS.SUCCESSFULLY-ADDED")
        );
        this.milestoneName = null;
        this.isAddEnable = false;
        this.getCompanyMilestones();
      },
      error: (err) => {
        this.isLoading = false;
        if (err.error.message) {
          this.alertService.stringError(err.error.message);
        } else {
          this.alertService.errors(
            this.translate.instant("GENERALS.GENERIC_ERROR")
          );
        }
      },
    });
  }

  protected update(milestone: MilestoneData) {
    if (!milestone) {
      return;
    }
    this.isLoading = true;

    milestone.name = this.editMilestoneName;

    this.milestoneService
      .update(milestone)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translate.instant("GENERALS.SUCCESSFULLY-UPDATED")
          );
          this.isEditEnable = 0;
        },
        error: (err) => {
          if (err.error.message) {
            this.alertService.stringError(err.error.message);
          } else {
            this.alertService.errors(
              this.translate.instant("GENERALS.GENERIC_ERROR")
            );
          }
        },
      });
  }

  protected delete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          needs_confirmation: true,
          text: `delete milestone`,
        },
      },
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.isLoading = true;
        this.milestoneService.delete(id).subscribe({
          next: () => {
            this.alertService.success(
              this.translate.instant("GENERALS.SUCCESSFULLY-DELETED")
            );
            this.getCompanyMilestones();
          },
          error: (err) => {
            this.isLoading = false;
            if (err.error.message) {
              this.alertService.stringError(err.error.message);
            } else {
              this.alertService.errors(
                this.translate.instant("GENERALS.GENERIC_ERROR")
              );
            }
          },
        });
      }
    });
  }
}
