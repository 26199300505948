import { Component, OnInit } from "@angular/core";
import {
  AsideNavItemData,
  AsideNavListData,
} from "src/app/_services/aside-navigation.service";
import { LeftNavigationComponent } from "../left-navigation/left-navigation.component";

@Component({
  selector: "app-left-navigation-xs",
  templateUrl: "./left-navigation-xs.component.html",
})
export class LeftNavigationXsComponent
  extends LeftNavigationComponent
  implements OnInit
{
  urlMarketplace: string;
  showpPrereleaseFeatures;
  public menuList: AsideNavListData;

  openMobileNav() {
    document.querySelector("body").classList.add("lock-scroll");
    document
      .querySelector(".mobile-navigation-wrapper")
      .classList.add("open-mobile-nav");
  }

  closeMobileNav() {
    document.querySelector("body").classList.remove("lock-scroll");
    document
      .querySelector(".mobile-navigation-wrapper")
      .classList.remove("open-mobile-nav");
  }

  navigateTo(item: AsideNavItemData) {
    if (item.children && item.children.length) {
      return;
    }
    this.closeMobileNav();
    this.router.navigate(item.routerLink, { relativeTo: this.route });
  }
}
