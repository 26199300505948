import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Host,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { ExpatDependent } from "@modules/expats/models/expat-dependents.model";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { AddDependentForm } from "@modules/shared/_components/add-dependent-form/add-dependent-form.component";
import { AbstractEmbeddedOptionsDirective } from "@modules/shared/_directives/abstract-embedded-options.directive";
import {
  Observable,
  of,
  Subject,
  combineLatest,
  startWith,
  debounceTime,
  take,
  switchMap,
  map,
  catchError,
  takeUntil,
  tap,
  shareReplay,
} from "rxjs";

@Component({
  selector: "app-dependent-options",
  templateUrl: "./dependent-options.component.html",
  styleUrls: ["./dependent-options.component.scss"],
})
export class DependentOptionsComponent
  extends AbstractEmbeddedOptionsDirective
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input()
  actualOption: ExpatDependent;

  @Input()
  exclude: number[] = [];

  @Input()
  expatId: number;

  @ViewChild("actualOptionElement", { static: false })
  actualOptionElement: MatOption;

  params$ = new Subject<Record<string, any>>();

  dependents$: Observable<ExpatDependent[]> = of([]);

  changeDetectionRef = inject(ChangeDetectorRef);

  searchControl = new FormControl("");

  opened$: Subject<void> = new Subject();
  refresh$: Subject<void> = new Subject();
  destroyed$ = new Subject<void>();
  isLoading: boolean = false;

  constructor(
    @Host() select: MatSelect,
    private expatService: ExpatsService,
    private matDialog: MatDialog
  ) {
    super(select);

    this.dependents$ = combineLatest([
      this.params$,
      this.searchControl.valueChanges.pipe(startWith(""), debounceTime(300)),
      this.refresh$.pipe(startWith<void>(undefined)),
      this.opened$.pipe(take(1)),
    ]).pipe(
      takeUntil(this.destroyed$),
      tap(() => (this.isLoading = true)),
      switchMap(([params, search_text]) =>
        this.expatService.getExpatDependents(params.expatId, {
          search_text,
          exclude: params.exclude,
        })
      ),
      map((result) => {
        const items = result.filter((item) => !this.exclude.includes(item.id));
        if (this.actualOption) {
          return items.filter((item) => item.id !== this.actualOption.id);
        }
        return items;
      }),
      catchError(() => of([])),
      tap(() => {
        this.isLoading = false;
        this.changeDetectionRef.detectChanges();
      }),
      shareReplay(1)
    );
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();

    this.opened$.complete();
    this.refresh$.complete();
  }
  ngAfterViewInit(): void {
    this.initOptions();
  }

  ngOnChanges(): void {
    this.params$.next({ expatId: this.expatId, exclude: this.exclude });
  }

  opened() {
    this.opened$.next();
  }

  addDependent() {
    this.matDialog
      .open(AddDependentForm, {
        data: {
          expat_id: this.expatId,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response?.dependent) {
          return;
        }
        if (this.optionParent instanceof MatSelect) {
          this.actualOption = response.dependent;
          this.changeDetectionRef.detectChanges();
          this.actualOptionElement.select();
          this.selectOption(this.optionParent, response.dependent.id, true);
        }
        this.refresh$.next();
      });
  }
}
