export class Document {
  links: Array<any>;

  public static resolve(resource, params, filters) {
    const urls = {
      clients: {
        value: "/clients/" + params["clientId"] + "/docs/" + params["doc"],
      },
      tasks: {
        value:
          "/cases/" +
          params["case"] +
          "/task/" +
          params["task"] +
          "/docs/" +
          params["doc"],
      },
      expats: {
        value:
          "/clients/" +
          params["clientId"] +
          "/expats/" +
          params["expatId"] +
          "/docs/" +
          params["doc"],
      },
      cases: {
        value: "/cases/" + params["case"] + "/docs/" + params["doc"],
      },
      orders: {
        value: "/orders/" + params["id"] + "/docs/" + params["doc"],
      },
      dashboardDocuments: {
        value: "/docs/" + params["doc"],
      },
      documents: {
        value: "/documents/" + params["doc"],
      },
    };
    if (!resource) {
      resource = "documents";
    }
    const menu = [
      {
        link: urls[resource].value,
        filters: filters,
        label: "Details",
        page: "details",
      },
      {
        link: urls[resource].value + "/history",
        filters: filters,
        label: "History",
        page: "history",
      },
    ];

    return new Document(menu);
  }

  constructor(links: Array<any>) {
    this.links = links;
  }
}
