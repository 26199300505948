<div class="custom-modal-wrapper">
  <div class="modal-title-custom">
    {{ "GENERALS.ACCOUNT-INFO" | translate }}
  </div>

  <div class="modal-body-custom">
    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.FIRST-NAME" | translate }} *</label>
        <input
          type="text"
          name="first_name"
          class="form-control"
          [(ngModel)]="user.first_name"
        />
      </div>
      <div class="form-group w-50">
        <label>{{ "LABEL.LAST-NAME" | translate }} *</label>
        <input
          type="text"
          name="last_name"
          class="form-control"
          [(ngModel)]="user.last_name"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.EMAIL" | translate }} *</label>
        <input
          type="text"
          name="email"
          class="form-control"
          [(ngModel)]="user.email"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "LABEL.PHONE-NUMBER" | translate }} *</label>
        <div class="row">
          <div class="form-group w-50">
            <ng-select
              (change)="setPrefix($event)"
              class="customiz-select"
              [items]="prefix"
              bindLabel="phone_code"
              bindValue="phone_code"
              [(ngModel)]="user.phone_prefix"
              placeholder="{{ 'GENERALS.SELECT-PHONE-CODE' | translate }}"
              notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
            >
            </ng-select>
          </div>
          <div class="form-group w-50">
            <input
              type="tel"
              class="form-control"
              (keypress)="validateNumericalInput($event)"
              [(ngModel)]="user.phone"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "LABEL.POSITION-IN-COMPANY" | translate }} *</label>
        <input
          type="text"
          name="company_position"
          class="form-control"
          [(ngModel)]="user.company_position"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer-custom">
    <button
      type="button"
      class="btn gray-bg fl-l modal-button"
      mat-dialog-close
    >
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      type="button"
      class="btn orange-bg fl-r modal-button"
      (click)="submit()"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
