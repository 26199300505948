<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>
</ng-container>

<div class="details-wrapper" *ngIf="!isLoading">
  <div class="breadcrumbs">
    <div *ngFor="let bd of breadcrumbs; let i = index" class="bd-inactive">
      <span [class.bd-active]="i === breadcrumbs.length - 1"> {{ bd }} /</span>
    </div>
  </div>

  <div class="content">
    <div class="fx-column w40">
      <mat-card>
        <img
          mat-card-image
          src="../../../assets/images/slider-1.jpg"
          alt="img"
        />
        <div class="service-offer">
          <span id="text-background"></span>
          <span id="service-offer-text">SERVICE OFFER</span>
        </div>
        <mat-card-subtitle class="card-title">
          {{ offer.title }}</mat-card-subtitle
        >
        <hr />
        <div class="status" [ngClass]="offer.status_label.toLowerCase()">
          {{ offer.status_label }}
        </div>
        <h2>Categorisation</h2>
        <div class="categorisation-tags">
          <div class="tag">Realocation</div>
          <div class="tag">House hunting</div>
        </div>
        <h2>Offered by</h2>

        <div class="sponsor w100">
          <img
            src="../../../assets/images/slider-1.jpg"
            class="w40 h100"
            alt="img"
          />
          <span class="sponsor-name">Acme INC.</span>
        </div>
        <h2>Availability</h2>
        <span> Start: 06-05-2018</span> <br />
        <span> End: 06-05-2018</span>

        <div class="costs">
          <h2>${{ offer.current_price.price }}</h2>
          <span
            >>{{ "GENERALS.STOCK" | translate }}:{{
              offer.current_price.quantity
            }}</span
          >
        </div>
      </mat-card>
    </div>

    <mat-card class="h100 w60">
      <mat-card-title class="card-title-c2">
        <mat-icon>edit</mat-icon>
        <button
          class="c2-buttons"
          [routerLink]="['/service-offers/edit/' + id]"
        >
          EDIT
        </button>
        <button class="c2-buttons">
          {{ "GENERALS.PREVIEW-IN-MARKETPLACE" | translate }}
        </button>
      </mat-card-title>
      <hr />
      <h2>Location</h2>
      <span class="details-text">
        <mat-icon class="gray">place</mat-icon>{{ offer.country }},
        {{ offer.city }}</span
      >

      <div *ngFor="let key of keys">
        <h2>{{ "CHARACTERISTICS." + key | translate }}</h2>
        <span class="details-text">{{ characteristics[key] }}</span>
        <br /><br />
      </div>
    </mat-card>
  </div>
</div>
