import { DataSource } from "@angular/cdk/collections";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { Observable } from "rxjs";

export class TimelineSource extends DataSource<TimelineService | null> {
  /**
   * Constructor
   *
   * @param {TimelineService} timeline
   */
  constructor(private timeline: TimelineService) {
    super();
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    return this.timeline.onTimelineChanged;
  }

  /**
   * Disconnect
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}
}
