import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[windowResize]",
})
export class WindowResizeDirective {
  innerWidth;

  constructor() {
    this.innerWidth = window.innerWidth;
    if (innerWidth <= 992) {
      document.querySelector("body").classList.add("mobile");
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.innerWidth = window.innerWidth;

    if (innerWidth <= 992) {
      document.querySelector("body").classList.add("mobile");
    } else {
      document.querySelector("body").classList.remove("mobile");
    }
  }
}
