<div class="tag-wrapper">
  <div class="select-group-block">
    <mat-label> {{ "LABEL.SELECT-PERMISSIONS" | translate }} </mat-label>
    <ng-select
      class="multiple-select permission-select"
      [formGroup]="documentForm"
      [addTag]="false"
      [placeholder]="'GENERALS.SELECT-PERMISSIONS' | translate"
      [formControl]="permissionsControl"
      [items]="options"
      [clearable]="true"
      [multiple]="true"
      bindLabel="label"
      bindValue="value"
      appendTo="body"
      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      appearance="outline"
    >
    </ng-select>
  </div>
</div>
