import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaseData, CaseType } from "@api/cases";
import { CasesService } from "@api/cases/services/cases.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreateComponent implements OnInit {
  caseTypeOptions = [
    {
      label: "Expat Case",
      value: CaseType.EXPAT,
    },
    {
      label: "Admin Case",
      value: CaseType.ADMIN,
    },
    {
      label: "Document Renewal",
      value: CaseType.DOCUMENT,
    },
  ];

  caseTypeFormControl = new FormControl(CaseType.EXPAT, Validators.required);

  caseForm: UntypedFormGroup;
  isLoading = false;

  submitForm: Subject<void> = new Subject<void>();

  constructor(
    private caseService: CasesService,
    protected dialogRef: MatDialogRef<CreateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: { case: Partial<CaseData> },
    private alert: AlertService,
    private translate: TranslateService
  ) {}

  get caseType() {
    return CaseType;
  }
  ngOnInit() {
    if (this.inputData?.case) {
      this.caseTypeFormControl.setValue(
        this.inputData.case.type ?? CaseType.EXPAT
      );
      this.caseTypeOptions = [
        {
          label: "Expat Case",
          value: CaseType.EXPAT,
        },
        {
          label: "Document Renewal",
          value: CaseType.DOCUMENT,
        },
      ];
    }
  }

  submit() {
    this.submitForm.next();
    this.caseForm.markAllAsTouched();

    if (!this.caseForm.valid) {
      return;
    }

    const data = {
      ...this.caseForm.getRawValue(),
      type: this.caseTypeFormControl.value,
    };

    this.isLoading = true;

    this.caseService
      .create(data)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (caseData) => {
          this.dialogRef.close({
            type: "saved",
            caseData,
          });
        },
        error: (error) => {
          if (error.error.message) {
            this.alert.stringError(error.error.message);
          } else {
            this.alert.error([
              this.translate.instant("GENERALS.GENERIC_ERROR"),
            ]);
          }
        },
      });
  }

  close() {
    this.dialogRef.close();
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  onFormInitialized(data: any) {
    this.caseForm = data.form;
  }
}
