<div
  *ngIf="services.length > 0"
  class="services-sidebar__navigation"
  [class.open]="timelineService.isSidebarOpen"
  [class.closed]="!timelineService.isSidebarOpen"
  (click)="timelineService.toggleSidebar()"
>
  <i
    class="small"
    [class.icon-angle-right]="!timelineService.isSidebarOpen"
    [class.icon-angle-left]="timelineService.isSidebarOpen"
  >
  </i>
</div>

<div
  class="services-sidebar position-relative"
  *ngIf="timelineService.isSidebarOpen"
>
  <div class="services-sidebar__header" *ngIf="timelineService.case">
    <div class="services-sidebar__header-id">
      #{{ timelineService.case.order_number }}
    </div>
    <div class="services-sidebar__header-title">
      {{ timelineService.case.case_manager_name }}
    </div>
    <div class="services-sidebar__header-body">
      {{ "TASKS.FORECAST_END_DATE_SIDEBAR" | translate }}
      <span class="date">{{
        timelineService.case.forecast_end_date | date
      }}</span>
    </div>
  </div>

  <ul class="list-style-none" *ngIf="services">
    <li>
      <a
        href="javascript:void(0)"
        class="d-flex px-0 mx-0"
        (click)="timelineService.filterTimelinesPerService()"
        [class.active]="timelineService.isAllServicesActive"
      >
        <div class="services-sidebar__status col-2">
          <span class="status d-none">
            <i class="icon-ok small"></i>
          </span>
        </div>
        <div class="services-sidebar__body col-sm">
          {{ "GENERALS.ALL-SERVICES" | translate }}
        </div>
      </a>
    </li>
    <li *ngFor="let item of services">
      <a
        href="javascript:void(0)"
        class="d-flex px-0 mx-0"
        (click)="timelineService.filterTimelinesPerService(item)"
        [class.active]="item.active"
      >
        <div class="services-sidebar__status col-2">
          <span class="status" appTaskStatus [status]="item.status">
            <i class="icon-ok small"></i>
          </span>
        </div>
        <div class="services-sidebar__body col-sm">
          {{ item.service_name }}
          <div class="small text-muted">
            {{ item.activeStatus.label }} - {{ item.completion_rate | percent }}
          </div>
        </div>
      </a>
    </li>
  </ul>
</div>
