import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BillingService } from "@api/billing/services/billing.service";
import { environment } from "@environment/environment";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";

@Component({
  selector: "app-billing-info",
  templateUrl: "./billing-info.component.html",
  styleUrls: ["./billing-info.component.scss"],
})
export class BillingInfoComponent implements OnInit {
  constructor(
    private breadcrumbService: BreadcrumbService,
    private billingService: BillingService,
    private router: Router
  ) {}

  public invoices;
  public currentPlan;
  public currentSubscription;
  public loaded = false;

  manageBilling() {
    window.open(environment.stripe_portal_login);
  }

  ngOnInit() {
    this.billingService.getInvoices().subscribe(({ invoices }) => {
      this.invoices = invoices;
    });

    this.billingService.currentSubscription().subscribe((res) => {
      this.currentPlan = res.plan;
      this.currentSubscription = res.subscription;

      this.loaded = true;
    });

    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForBillingInfo()
    );
  }

  cancel() {
    this.billingService
      .cancelSubscription(this.currentPlan.stripe_product_id)
      .subscribe((res) => {
        this.currentPlan = null;
        this.currentSubscription = null;
      });
  }
}
