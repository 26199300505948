export class Ads {
  key: string;
  performance_type: string;
  entity_id: string;
  max_cost: string;
  action_url: string;
  starts_at: string;
  ends_at: string;
  image: any;
  id: number;
  category_id: any;
  country: string;
}
