<mat-form-field appearance="outline" class="w-full" [formGroup]="formGroup">
  <mat-label> {{ label }} </mat-label>
  <mat-select
    (closed)="closeSelect()"
    [formControl]="selectorCtrl"
    multiple
    [placeholder]="placeholder"
  >
    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip
          *ngFor="let topping of selectorCtrl.value"
          [removable]="true"
          (removed)="onToppingRemoved(topping); closed.emit()"
        >
          {{ items[topping].text }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="label"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="searchControl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let topping of filteredItems" [value]="topping.id">
      {{ topping.text }}
    </mat-option>
  </mat-select>
</mat-form-field>
