import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { ValueList } from "@modules/shared/models/value-list.model";

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient) {}

  // GET activity
  list(model, modelId, params: ValueList = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + model + "/" + modelId + "/activity",
      { params }
    );
  }
}
