import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { AuthenticationService } from "@api/authentication";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // redirect to portal

              if (this.router.url.indexOf("login") === -1) {
                this.auth.logout(err.status).subscribe();
              }
              // } else if (err.status === 403) {
              //   this.router.navigate(['/access-denied']);
            } else if (err.status === 404 && environment.production) {
              this.router.navigate(["/not-found"]);
            }
          }
        }
      )
    );
  }
}
