import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "@environment/environment";
import { AssignmentMapperService } from "./assignment-mapper.service";
import { Dictionary } from "../../../_models/dictionary";
import { AssignmentData } from "../models/assignment.model";
import { PageData } from "@modules/shared/models/page.model";
import { DictionaryMapperService } from "@api/shared/services/dictionary-mapper.service";

@Injectable({
  providedIn: "root",
})
export class AssignmentsService {
  private url = "cases/assignments";
  private statusTransitionsUrl =
    "cases/directories/assignments/status-transitions";
  private subject = new BehaviorSubject<AssignmentData>(null);
  public assignment$ = this.subject.asObservable();

  constructor(
    private http: HttpClient,
    private mapperService: AssignmentMapperService,
    private dictionaryMapper: DictionaryMapperService
  ) {}

  /**
   * List assignments
   * @param params
   */
  public list(params = {}): Observable<PageData<AssignmentData>> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}${this.url}`, { params })
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.result;
          return {
            ...pageData,
            items: this.mapperService.mapMany(items),
          } as PageData<AssignmentData>;
        })
      );
  }

  /**
   * Get the details of an assignment
   * @param assignmentId
   */
  public show(
    assignmentId: number,
    context = new HttpContext()
  ): Observable<AssignmentData> {
    return this.http
      .get(`${environment.gateway_endpoint}${this.url}/${assignmentId}`, {
        context,
      })
      .pipe(map((data: any) => this.mapperService.mapOne(data.result)))
      .pipe(tap((data) => this.subject.next(data)));
  }

  /**
   * Create a new assignment
   * @param assignment
   */
  public store(
    assignment: Partial<AssignmentData>
  ): Observable<AssignmentData> {
    return this.http
      .post(`${environment.gateway_endpoint}${this.url}`, assignment)
      .pipe(map((data: any) => this.mapperService.mapOne(data.result)))
      .pipe(tap((data) => this.subject.next(data)));
  }

  /**
   * Update an existing assignment
   * @param assignment
   */
  public update(
    assignment: Partial<AssignmentData>
  ): Observable<AssignmentData> {
    return this.http
      .post(
        `${environment.gateway_endpoint}${this.url}/${assignment.id}`,
        assignment
      )
      .pipe(map((data: any) => this.mapperService.mapOne(data.result)))
      .pipe(tap((data) => this.subject.next(data)));
  }

  // /**
  //  * Get assignment types
  //  */
  // public types(): Observable<Dictionary[]> {
  //   return this.http.get<any>(`${environment.gateway_endpoint}cases/directories/assignments/types`)
  //     .pipe(map((response) => this.dictionaryMapper.mapMany(response.result)));
  // }

  public statusTransitions(params = {}): Observable<any> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}${this.statusTransitionsUrl}`, {
        params,
      })
      .pipe(map((data) => data.result));
  }

  public updateStatus(ids, status, notes?, params = {}) {
    const body = { status: status, assignment_ids: ids, notes: notes };
    return this.http
      .post<any>(`${environment.gateway_endpoint}${this.url}/status`, {
        ...body,
      })
      .pipe(map((data) => data.result));
  }

  /**
   * Get assignment types
   */
  public types(): Observable<Dictionary[]> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}cases/directories/assignments/types`
      )
      .pipe(map((response) => this.dictionaryMapper.mapMany(response.result)));
  }
}
