import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { UserData } from "@api/account";
import { PageData } from "@modules/shared/models/page.model";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Client } from "src/app/_models/client";
import { environment } from "@environment/environment";
import { ClientData } from "@modules/clients/models/client.model";
import { UsersService } from "../../../_services/users.service";
import { ClientMapperService } from "./client-mapper.service";
import { BillingDetails } from "../models/billing-details.models";

@Injectable()
export class ClientsService {
  private user: UserData;
  clientModel: Client;

  constructor(
    private http: HttpClient,
    private clientMapper: ClientMapperService
  ) {
    inject(UsersService)
      .getCurrentUser()
      .subscribe((user) => {
        this.user = user;
      });
  }

  /**
   * List the provider's clients
   * @param providerId
   * @param params
   */
  public list(providerId, params = {}): Observable<PageData<ClientData>> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/clients`,
        { params }
      )
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.result;
          return {
            ...pageData,
            items: this.clientMapper.mapMany(items),
          } as PageData<ClientData>;
        })
      );
  }

  public show(providerId, clientId): Observable<ClientData> {
    return this.http
      .get(
        `${environment.gateway_endpoint}entities/providers/${providerId}/clients/${clientId}`
      )
      .pipe(
        map((response) => {
          return response["result"] as ClientData;
        })
      );
  }
  public fetchUsers(providerId, clientId): Observable<UserData[]> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/clients/${clientId}/users`
      )
      .pipe(map((response) => response.items));
  }

  public getUsers(clientId): Observable<any> {
    return this.fetchUsers(this.user.entity_id, clientId);
  }
  // GET Client
  public getClient(id): Observable<any> {
    return this.http
      .get<any>(environment.gateway_endpoint + "marketplace/clients/" + id)
      .pipe(
        tap((data) => {
          this.clientModel = data;
        })
      );
  }

  /**
   * Export filtered clients to CSV
   * @param providerId
   * @param params
   */
  export(params, providerId) {
    return this.http.get(
      `${environment.gateway_endpoint}entities/providers/${providerId}/clients/export`,
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  // POST Create Client step 1
  createClient(providerId: number, client: Client): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/clients`,
        client
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // POST Create Client step 2
  updateClient(providerId: number, client: ClientData): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/clients/${client.id}`,
        client
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public statusTransitions(params = {}): Observable<any> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}entities/providers/${this.user.entity_id}/clients/status-transitions`,
        { params }
      )
      .pipe(map((data) => data.result));
  }

  public updateStatus(ids, status, notes?, params = {}) {
    const body = { status: status, client_ids: ids, notes: notes };
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${this.user.entity_id}/clients/status`,
        {
          ...body,
        }
      )
      .pipe(map((data) => data.result));
  }
  public listBillingAddress(entityId: number): Observable<BillingDetails[]> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}entities/${entityId}/billing-details`
      )
      .pipe(map((data) => data.result));
  }
  public addBillingAddress(entityId: number, body: BillingDetails) {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/${entityId}/billing-details`,
        {
          ...body,
        }
      )
      .pipe(map((data) => data.result));
  }

  public updateBillingAddress(entityId: number, body: BillingDetails) {
    const billingDetailsId = body.id;
    return this.http
      .put<any>(
        `${environment.gateway_endpoint}entities/${entityId}/billing-details/${billingDetailsId}`,
        {
          ...body,
        }
      )
      .pipe(map((data) => data.result));
  }

  public deleteBillingAddress(entityId, billingDetailsId) {
    return this.http
      .delete<any>(
        `${environment.gateway_endpoint}entities/${entityId}/billing-details/${billingDetailsId}`
      )
      .pipe(map((data) => data.result));
  }
}
