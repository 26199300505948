import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ContactData } from "@modules/contacts/models/contact.model";
import { ModelData } from "@modules/shared/models/model";
import { Subject, takeUntil } from "rxjs";
import { EditComponent } from "../edit/edit.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-edit-dialog",
  templateUrl: "./edit-dialog.component.html",
  styleUrls: ["./edit-dialog.component.scss"],
})
export class EditDialogComponent implements OnInit, OnDestroy {
  @Input()
  contactable?: ModelData;

  @Input()
  contact?: ContactData;

  @ViewChild(EditComponent, { static: true })
  form: EditComponent;

  canSubmit$: Subject<boolean> = new Subject();

  contactSubmitted = new EventEmitter<ContactData | null>();

  public isLoading: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<EditDialogComponent>) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.form.loading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: boolean) => (this.isLoading = value));
  }

  onChange(event: any) {
    this.canSubmit$.next(this.form.canSubmit());
  }

  cancel() {
    this.contactSubmitted.emit(null);

    this.dialogRef.close();
  }

  submit() {
    this.form.submit().subscribe((contact) => {
      /**
       * @todo emit event
       */
      this.contactSubmitted.emit(contact);
      this.dialogRef.close();
    });
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
