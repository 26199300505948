import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  Question,
  QuestionsWithAnswers,
} from "@modules/service-offers/models/question.model";
import { Observable } from "rxjs";

@Component({
  selector: "app-order-questions",
  templateUrl: "./order-questions.component.html",
  styleUrls: ["./order-questions.component.scss"],
})
export class OrderQuestionsComponent {
  questions$: Observable<Question[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: QuestionsWithAnswers[]) {}
}
