import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { AuthorizationService } from "@api/users/services/authorization.service";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Subscription } from "rxjs";
import { AlertService } from "src/app/_services/alert.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { ProvidersService } from "src/app/_services/providers.service";
import { UsersService } from "src/app/_services/users.service";
import { ProviderData } from "../../_models/provider";
import { BreadcrumbService } from "../../_services/breadcrumb.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  CompanySettingsDialogComponent,
  CompanySettingsDialogComponentInput,
} from "@modules/company/dialogs/company-settings-dialog/company-settings-dialog.component";

@Component({
  selector: "app-account-settings-page",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  public updateProviderDetails = {
    action: PermissionActionEnum.UPDATE,
    resource: PermissionResourceEnum.PROVIDER,
    secondaryResource: PermissionSecondaryResourceEnum.DETAILS,
  };
  isLoading;
  about;
  userInfo: UserData;
  provider: ProviderData;
  edit = false;
  statusOptions = [];
  private subscription: Subscription = new Subscription();
  title: string;
  public isLoadingModal: boolean = false;

  constructor(
    private providerService: ProvidersService,
    public ngxSmartModalService: NgxSmartModalService,
    public dialog: MatDialog,
    private alertService: AlertService,
    public authService: AuthenticationService,
    public auth: AuthorizationService,
    public userService: UsersService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.title = "Company Settings";

    this.getCurrentUser();
    this.checkStripeReturn();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setCompanySettings()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkStripeReturn() {
    const stripeAuthorizationCode =
      this.providerService.getStripeAuthorizationCode();
    if (stripeAuthorizationCode) {
      this.providerService.setStripeAccount().subscribe((response) => {
        if (response.success && response.result.id) {
          this.userInfo.stripe_account = response.result;
        }
      });
    }
  }

  getCurrentUser() {
    this.subscription.add(
      this.userService.getCurrentUser().subscribe((user) => {
        this.userInfo = { ...user };
        this.provider = { ...user.entity_details.company };
        this.getProviderDetails();
      })
    );
  }

  getProviderDetails() {
    this.setPrimaryAccount(this.provider.users);

    if (this.provider.rating && this.provider.rating.length) {
      let calculatedRating: number[] = [];

      calculatedRating = this.provider.rating.map((f) => f["rating"]);
      this.provider.calculatedRating =
        calculatedRating.reduce((a, b) => a + b) /
        this.provider?.rating?.length;
      this.provider.calculatedRating =
        +this.provider.calculatedRating.toFixed(2);
    }

    this.subscription.add(
      this.providerService.getStatusTransitions().subscribe((response) => {
        if (response.success) {
          this.statusOptions = response.result[this.provider.status_id];
        }
      })
    );
  }

  setPrimaryAccount(data) {
    this.userInfo = {
      ...data.filter((f) => f.is_primary === true)[0],
      entity_id: this.userInfo.entity_id,
      company_position: this.userInfo.company_position,
    };

    const props = Object.keys(this.provider);
    props.forEach((p) => {
      if (!this.userInfo[p] && p !== "users") {
        this.userInfo[p] = this.provider[p];
      }
    });
    // this.userInfo.country_code = this.userInfo.country_code.toUpperCase();
  }

  openEditModal() {
    const data: CompanySettingsDialogComponentInput = {
      provider: this.provider,
    };
    this.dialog
      .open(CompanySettingsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe(() => {
        //TODO: update the component
      });
  }

  cancelEditModal() {
    this.setPrimaryAccount(this.provider.users);
    this.ngxSmartModalService.getModal("userFormWrapper").close();
  }

  openConfirmAction(action) {
    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result !== undefined) {
          this.providerService
            .updateStatus(action.id, this.provider.id, result)
            .subscribe(
              (data) => {
                this.getProviderDetails();
              },
              (error) => {
                if (error.error.errors) {
                  this.alertService.errors(error.error.errors);
                }
              }
            );
        }
      });
    } else {
      this.providerService
        .updateStatus(action.id, this.provider.id, null)
        .subscribe((data) => {
          this.getProviderDetails();
        });
    }
  }

  openDocModal() {
    this.ngxSmartModalService.getModal("addProfilePicture").open();
  }

  goToStripeOnBoarding() {
    this.isLoading = true;

    this.providerService.getStripeOnBoardingUrl().subscribe(
      (response) => {
        if (response.success) {
          window.location.replace(response.result.url);
        } else {
          this.alertService.errors(response.error.errors);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  goToStripeDashboard() {
    this.isLoading = true;

    this.providerService.getStripeDashboardUrl().subscribe(
      (response) => {
        if (response.success) {
          this.isLoading = false;
          window.open(response.result.url, "_blank");
        } else {
          this.alertService.errors(response.error.errors);
        }
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
