<div class="small-box-header-img" (click)="openDocModal()">
  <img
    [src]="
      user.primary_image_path
        ? user.primary_image_path
        : '../assets/images/profile-pics-placeholder.jpg'
    "
    (error)="onProfileImageError($event)"
    alt="img"
    class="profile-pic"
  />
  <div class="edit-trigger">{{ "PORTAL.CHANGE-PIC" | translate }}</div>
</div>

<app-add-profile-picture
  [entityType]="'user'"
  [entityId]="user?.id"
  [title]="'GENERALS.UPLOAD-PIC'"
  (change)="onChange($event)"
></app-add-profile-picture>
