import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-datepicker-actions",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatButtonModule,
  ],
  providers: [],
  template: `
    <mat-datepicker-actions>
      <button mat-button class="gray-bg datepicker-button" matDatepickerCancel>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="orange-bg datepicker-button m-l-10" matDatepickerApply>
        {{ "LABEL.APPLY" | translate }}
      </button>
    </mat-datepicker-actions>
  `,
})
export class DatePickerActions {}
