<div
  class="tree-row-wrapper"
  *ngFor="let category of serviceCategories; trackBy: trackById"
>
  <div
    toggle-row
    class="toggle-row-action fl-l"
    *ngIf="category.children_count"
  >
    <i class="icon-plus-1"></i>
  </div>
  <div class="tree-row">
    <div class="tree-check fl-l">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? changeSelection(category) : null"
        [checked]="selection.isSelected(category)"
        [disableRipple]="true"
      >
      </mat-checkbox>
    </div>

    <div class="tree-label fl-l">
      <span>{{ category.label }}</span>
      <!-- <span *ngIf="category.children_count"
        >({{ category.children_count }})</span -->
      >
    </div>

    <div class="tree-row-details" *ngIf="category.children_count === 0">
      <span class="orange fl-l fw-600">{{ category.commission }}</span>
      <span class="fl-l fw-600">% {{ "GENERALS.COMMISSION" | translate }}</span>

      <div class="fl-l tree-row-date" *ngIf="category.start_date">
        ({{ category.start_date | date: "dd/MM/yyyy" }} >
        {{ category.end_date | date: "dd/MM/yyyy" }})
      </div>
    </div>
  </div>
  <div class="toggle-tree" *ngIf="category.children_count">
    <app-add-commissions
      [level]="category.level + 1"
      [serviceCategories]="category.children"
      [selectedServiceCategories]="parseSelectedCategory(category.path)"
      [parentIsSelected]="selection.isSelected(category)"
      (onselect)="onselect.emit($event)"
    ></app-add-commissions>
  </div>

  <div class="clear"></div>
</div>
