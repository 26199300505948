<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div class="dashboard-body-top">
  <!-- title -->
  <div class="float-left big-title">
    {{ "LABEL.COMPANY-USERS" | translate }}
  </div>

  <!-- right -->
  <div class="button-bar">
    <button
      class="btn-primary"
      (click)="openModal()"
      *canView="createUserDetails"
    >
      <mat-icon svgIcon="user-plus" class="btn-icon"></mat-icon>
      {{ "GENERALS.ADD-USER" | translate }}
    </button>
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="float-left advanced-search-wrapper w-full p-0">
    <app-filters
      [availableFilters]="availableFilters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="LABEL.COMPANY-USERS"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<!-- table bulk action -->
<app-bulk-actions
  *ngIf="showBulkActions"
  (selectionCleared)="showBulkActions = !showBulkActions"
  (onClick)="bulkAction($event)"
  [selection]="selection"
  [actions]="actions"
></app-bulk-actions>
<!-- end table bulk action -->

<!-- table -->
<div class="table-wrapper">
  <div class="table-responsive">
    <table
      matSort
      (matSortChange)="sortData($event)"
      [matSortActive]="active"
      [matSortDirection]="direction"
    >
      <thead>
        <tr>
          <ng-container matColumnDef="select">
            <th class="w-1">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </th>
          </ng-container>
          <th mat-sort-header="id" class="text-right border-r w-1">
            {{ "GENERALS.ID" | translate }}
          </th>
          <th mat-sort-header="first_name" class="text-left">
            {{ "LABEL.FIRST-NAME" | translate }}
          </th>
          <th mat-sort-header="last_name" class="text-left">
            {{ "LABEL.LAST-NAME" | translate }}
          </th>
          <th mat-sort-header="role">
            {{ "LABEL.ROLE" | translate }}
          </th>
          <th mat-sort-header="email">{{ "LABEL.EMAIL" | translate }}</th>
          <th mat-sort-header="phone_prefix" class="text-right">
            {{ "LABEL.PHONE-NUMBER" | translate }}
          </th>
          <th mat-sort-header="is_primary">
            {{ "GENERALS.PRIMARY" | translate }}
          </th>
          <th mat-sort-header="status">{{ "GENERALS.STATUS" | translate }}</th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr>
          <td colspan="100">
            <app-loader-spinner-general
              [loadingType]="LoadingType.TABLE"
            ></app-loader-spinner-general>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading">
        <tr
          class="cursor-pointer"
          *ngFor="
            let user of items
              | paginate
                : {
                    itemsPerPage: itemsPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <td>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (keyup)="$event.stopPropagation()"
              (change)="$event ? changeSelection(user) : null"
              [checked]="selection.isSelected(user)"
              [disableRipple]="true"
            >
            </mat-checkbox>
          </td>
          <td
            class="text-right border-r"
            [routerLink]="['/users', user.id]"
            queryParamsHandling="merge"
          >
            <a
              [routerLink]="['/users', user.id]"
              class="text-secondary font-semibold"
            >
              #{{ user.id }}
            </a>
          </td>
          <td [routerLink]="['/users', user.id]" queryParamsHandling="merge">
            {{ user.first_name }}
          </td>
          <td [routerLink]="['/users', user.id]" queryParamsHandling="merge">
            {{ user.last_name }}
          </td>
          <td [routerLink]="['/users', user.id]" queryParamsHandling="merge">
            {{ user.role_name }}
          </td>
          <td [routerLink]="['/users', user.id]" queryParamsHandling="merge">
            {{ user.email }}
          </td>
          <td
            class="text-right"
            [routerLink]="['/users', user.id]"
            queryParamsHandling="merge"
          >
            {{ user.phone_prefix }}{{ user.phone }}
          </td>
          <td [routerLink]="['/users', user.id]" queryParamsHandling="merge">
            <mat-icon
              class="text-primary"
              svgIcon="save-outline"
              *ngIf="user.is_primary"
            ></mat-icon>
            <mat-icon
              class="text-secondary"
              svgIcon="cancel"
              *ngIf="!user.is_primary"
            ></mat-icon>
          </td>
          <td [routerLink]="['/users', user.id]" queryParamsHandling="merge">
            <div class="status" [appStatusStyle]="user.status_name | lowercase">
              {{ user.status_name }}
            </div>
          </td>

          <td class="text-right">
            <div class="box-actions">
              <button
                mat-icon-button
                [matMenuTriggerFor]="userMenu"
                class="btn-outline-none-primary items-end"
                *ngIf="actions && checkPrimary(user)"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #userMenu="matMenu" xPosition="before">
                <button
                  mat-menu-item
                  class="uppercase"
                  *ngFor="let action of actions[user.status_id]"
                  (click)="changeStatus(action, user)"
                >
                  {{ action.text | translate }}
                </button>
              </mat-menu>
            </div>
          </td>
        </tr>
        <tr *ngIf="items?.length === 0">
          <td colspan="100" class="gray">
            <div class="notification_row bg_light_blue my-2.5 p-3">
              {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="navigateByUrl($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->
