import { Component, Input } from "@angular/core";
import { DocumentData } from "@api/documents/models/document.model";

@Component({
  selector: "app-document-preview",
  templateUrl: "./document-preview.component.html",
  styleUrls: ["./document-preview.component.scss"],
})
export class DocumentPreviewComponent {
  options = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    items: 1,
    responsive: {},
    nav: false,
  };

  @Input() public document: DocumentData;
}
