import { Injectable } from "@angular/core";
import { OfficeModel } from "../../../_models/office";

@Injectable({
  providedIn: "root",
})
export class OfficeMapperService {
  mapOne(input: any) {
    return {
      id: input.id,
      entityId: input.entity_id,
      label: input.label,
      countryCode: input.country_code,
      city: input.city,
      county: input.county,
      address: input.address,
      postalCode: input.postal_code,
    };
  }

  mapMany(input: any[]): OfficeModel[] {
    return input.map((i) => {
      return this.mapOne(i);
    });
  }

  prepare(input: OfficeModel) {
    return {
      id: input.id,
      entity_id: input.entityId,
      label: input.label,
      country_code: input.countryCode,
      city: input.city,
      county: input.county,
      address: input.address,
      postal_code: input.postalCode,
    };
  }
}
