import { Component, OnInit } from "@angular/core";
import { Plan } from "@api/billing/models/plan.model";
import { BillingService } from "@api/billing/services/billing.service";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";

@Component({
  selector: "app-plans",
  templateUrl: "./plans.component.html",
  styleUrls: ["./plans.component.scss"],
})
export class PlansComponent implements OnInit {
  public plans: Plan[];
  public currentPlan;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private billingService: BillingService
  ) {}

  ngOnInit() {
    this.billingService
      .listPlans({
        entity_type: "provider",
      })
      .subscribe((res) => {
        this.plans = res.items;
      });

    this.billingService.currentSubscription().subscribe((res) => {
      this.currentPlan = res.plan;
    });

    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForPlans()
    );
  }

  purchasePlan(planId: string) {
    if (this.currentPlan) {
      this.billingService
        .cancelSubscription(this.currentPlan.stripe_product_id)
        .subscribe((res) => {
          this.makePurchase(planId);
        });

      return;
    }

    this.makePurchase(planId);
  }

  makePurchase(planId: string) {
    this.billingService
      .purchaseSubscription(planId)
      .subscribe((response: any) => {
        location.href = response.redirect_url;
      });
  }
}
