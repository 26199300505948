<!-- details navigation row (left title - right navigation) -->
<div windowScrollAppNav class="details-navigation" *ngIf="hasShow">
  <!-- title -->
  <div class="fl-l big-title">
    <div class="fl-l big-title">
      {{ "LEFT-NAVIGATION.REPORTS" | translate }}
    </div>
  </div>
  <!-- end title -->

  <!-- navigation -->
  <div class="fl-r">
    <app-details-navigation [menu]="menu"></app-details-navigation>
  </div>
  <!-- end navigation -->
</div>
<!-- end details navigation row (left title - right navigation) -->
<div class="clear"></div>

<router-outlet></router-outlet>
