<div class="editable-input-calendar-wrapper">
  <mat-form-field
    appearance="outline"
    *ngIf="!multiple"
    data-title="DD/MM/YYYY"
    class="!w-full"
  >
    <mat-label *ngIf="showLabel">{{ placeholder }}</mat-label>
    <input
      #single
      matInput
      [matDatepicker]="datepicker"
      [max]="maxDate"
      [min]="minDate"
      [formControl]="dateControl"
      [disabled]="isDisabled"
      (keyup)="validateInput(single.value, 'single')"
      [(ngModel)]="singleDate"
      placeholder="DD/MM/YYYY"
    />

    <mat-datepicker-toggle
      matIconSuffix
      [for]="datepicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #datepicker>
      <mat-datepicker-actions>
        <button
          mat-button
          class="gray-bg datepicker-button"
          matDatepickerCancel
        >
          {{ "GENERALS.CANCEL" | translate }}
        </button>
        <button
          class="orange-bg datepicker-button m-l-10"
          matDatepickerApply
          (click)="validateInput(single.value, 'single')"
        >
          {{ "LABEL.APPLY" | translate }}
        </button>
      </mat-datepicker-actions>
    </mat-datepicker>
    <mat-error [formControlError]="control"> </mat-error>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    *ngIf="multiple"
    data-title="DD/MM/YYYY – DD/MM/YYYY"
    class="w-full"
  >
    <mat-label *ngIf="showLabel">{{ placeholder }}</mat-label>
    <mat-date-range-input
      [rangePicker]="rangePicker"
      [max]="maxDate"
      [min]="minDate"
      class="d-flex"
    >
      <input
        #start
        [disabled]="isDisabled"
        matStartDate
        [placeholder]="'GENERALS.START-DATE' | translate"
        [(ngModel)]="dateRangeMat.start_date"
        (keyup)="validateInput(start.value, 'start')"
      />
      <input
        #end
        [disabled]="isDisabled"
        matEndDate
        [placeholder]="'GENERALS.END-DATE' | translate"
        (keyup)="validateInput(end.value, 'end')"
        [(ngModel)]="dateRangeMat.end_date"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      matIconSuffix
      [for]="rangePicker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <button
          mat-button
          matDateRangePickerCancel
          class="gray-bg datepicker-button"
        >
          {{ "GENERALS.CANCEL" | translate }}
        </button>
        <button
          class="orange-bg datepicker-button m-l-10"
          matDateRangePickerApply
          (click)="
            validateInput(start.value, 'start'); validateInput(end.value, 'end')
          "
        >
          {{ "LABEL.APPLY" | translate }}
        </button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
    <mat-error [formControlError]="control"> </mat-error>
  </mat-form-field>
</div>
