import { UntypedFormGroup, ValidatorFn } from "@angular/forms";

export function passwordMatch(): ValidatorFn {
  return (registrationFormGroup: UntypedFormGroup) => {
    const password = registrationFormGroup.controls.password.value;
    const repeatPassword = registrationFormGroup.controls.repeatPassword.value;

    if (!repeatPassword || repeatPassword.length <= 0) {
      return null;
    }

    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true,
      };
    }

    return null;
  };
}
