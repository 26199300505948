import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from "@angular/core";

export interface StarTemplateContext {
  /**
   * The star fill percentage, an integer in the `[0, 100]` range.
   */
  fill: number;

  /**
   * Index of the star, starts with `0`.
   */
  index: number;
}

@Component({
  selector: "app-rating",
  template: `
    <div class="d-flex">
      <ng-container *ngFor="let context of contexts; let i = index">
        <span class="visually-hidden">({{ i < nextRate ? "*" : " " }})</span>
        <span [style.cursor]="'default'">
          <span class="star" [class.full]="context.fill === 100">
            <span class="half" [style.width.%]="context.fill">&#9733;</span
            >&#9733;
          </span>
        </span>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent implements OnInit, OnChanges {
  nextRate: number;

  // rate = 3.5;
  color = "red";
  private _changeDetectorRef = inject(ChangeDetectorRef);

  contexts: StarTemplateContext[] = [];
  /**
   * If `true`, the rating can't be changed or focused.
   */
  @Input() disabled = false;

  /**
   * The maximal rating that can be given.
   */
  @Input() max = 5;

  /**
   * The current rating. Could be a decimal value like `3.75`.
   */
  @Input() rate: number;

  /**
   * Allows to provide a function to set a custom aria-valuetext
   *
   * @since 14.1.0
   */
  @Input() ariaValueText(current: number, max: number) {
    return `${current} out of ${max}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["rate"]) {
      this.update(this.rate);
    }
    if (changes["max"]) {
      this._updateMax();
    }
  }

  ngOnInit(): void {
    this._setupContexts();
    this._updateState(this.rate);
  }

  reset(): void {
    this._updateState(this.rate);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  getValueInRange(value: number, max: number, min = 0): number {
    return Math.max(Math.min(value, max), min);
  }

  update(value: number): void {
    const newRate = this.getValueInRange(value, this.max, 0);
    if (this.rate !== newRate) {
      this.rate = newRate;
    }
    this._updateState(this.rate);
  }

  writeValue(value) {
    this.update(value);
    this._changeDetectorRef.markForCheck();
  }

  private _updateState(nextValue: number) {
    this.nextRate = nextValue;
    this.contexts.forEach(
      (context, index) =>
        (context.fill = Math.round(
          this.getValueInRange(nextValue - index, 1, 0) * 100
        ))
    );
  }

  private _updateMax() {
    if (this.max > 0) {
      this._setupContexts();
      this.update(this.rate);
    }
  }

  private _setupContexts() {
    this.contexts = Array.from({ length: this.max }, (v, k) => ({
      fill: 0,
      index: k,
    }));
  }
}
