import { Location } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent implements OnInit {
  @Input()
  fallback: Array<string> = ["/dashboard"];

  hasHistory: boolean = false;

  constructor(private location: Location, private router: Router) {}
  ngOnInit(): void {
    this.hasHistory = (this.location.getState() as any)?.navigationId != 1;
  }

  public navigateBack(): void {
    if (this.hasHistory) {
      this.location.back();
    } else {
      this.router.navigate(this.fallback).then();
    }
  }
}
