import {
  AfterViewInit,
  Component,
  Host,
  Input,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { AbstractEmbeddedOptionsDirective } from "@modules/shared/_directives/abstract-embedded-options.directive";
import {
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { Country } from "src/app/_models/country";
import { DictionariesService } from "src/app/_services/dictionaries.service";

@Component({
  selector: "app-country-options",
  templateUrl: "./country-options.component.html",
  styleUrls: ["./country-options.component.scss"],
})
export class CountryOptionsComponent
  extends AbstractEmbeddedOptionsDirective
  implements OnDestroy, AfterViewInit
{
  @Input()
  actualOption: Country;

  @Input()
  exclude: string[] = [];

  params$ = new Subject<Record<string, any>>();

  countries$: Observable<Country[]> = of([]);

  searchControl = new FormControl("");

  opened$: Subject<void> = new Subject();
  destroyed$ = new Subject<void>();

  constructor(
    @Host() select: MatSelect,
    private dictionariesService: DictionariesService,
    private matDialog: MatDialog
  ) {
    super(select);
    this.countries$ = this.searchControl.valueChanges.pipe(
      startWith(""),
      takeUntil(this.destroyed$),
      switchMap((value: string) =>
        this.dictionariesService
          .getCountryList()
          .pipe(
            map((data) =>
              data.result.filter((country: Country) =>
                country.name
                  .toLocaleLowerCase()
                  .includes(value.toLocaleLowerCase())
              )
            )
          )
      )
    );
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.opened$.complete();
  }

  ngAfterViewInit(): void {
    this.initOptions();
  }

  opened() {
    this.opened$.next();
  }
}
