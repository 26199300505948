import { HttpContext } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AssignmentData } from "@api/assignments/models/assignment.model";
import { AssignmentsService } from "@api/assignments/services/assignments.service";
import {
  OrderData,
  OrderServiceData,
  OrderServiceExpatData,
} from "@api/billing";
import { DISPLAY_API_ERRORS } from "@helpers/error.interceptor";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  Observable,
  Subject,
  catchError,
  map,
  of,
  shareReplay,
  takeUntil,
} from "rxjs";
import { AlertService } from "src/app/_services/alert.service";
import { OrdersService } from "src/app/_services/orders.service";
import { CaseStatusEnum } from "../../../../_enums/case-status-enum";

@Component({
  selector: "app-order-assignment-details",
  templateUrl: "./order-assignment-details.component.html",
  styleUrls: ["./order-assignment-details.component.scss"],
})
export class OrderAssignmentDetailsComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private assignments: AssignmentsService,
    private orderService: OrdersService,
    private alertService: AlertService
  ) {}

  @Input()
  order: OrderData;

  @Input()
  orderItem: OrderServiceData;

  @Input()
  orderExpat: OrderServiceExpatData;

  @Output()
  expatDeleted: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  caseCreated: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  isFirst;

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  status?: string = null;

  caseId: number;

  assignment$: Observable<{
    assignment: AssignmentData;
    error: string;
    loaded: boolean;
  }>;

  ngOnInit() {
    if (this.orderExpat.assignment_id) {
      this.assignment$ = this.assignments
        .show(
          this.orderExpat.assignment_id,
          new HttpContext().set(DISPLAY_API_ERRORS, false)
        )
        .pipe(
          map((assignment) => ({ assignment, loaded: true, error: null })),
          catchError((error) =>
            of({ assignment: null, error: error, loaded: true })
          ),
          shareReplay(1)
        );
    } else {
      this.assignment$ = of({
        assignment: null,
        error: "Assignment not found!",
        loaded: true,
      });
    }
  }

  public handleCaseCreated(): void {
    this.caseCreated.emit();
  }

  deleteExpat() {
    this.orderService
      .deleteAssignedExpat(this.order.id, this.orderItem.id, this.orderExpat.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          }
          this.expatDeleted.emit(this.order.identity_number.toString());
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  protected readonly CaseStatusEnum = CaseStatusEnum;
}
