import { Component } from "@angular/core";
import { Billing } from "src/app/_models/_menu/billing";

@Component({
  templateUrl: "./billing.component.html",
  styleUrls: ["./billing.component.scss"],
})
export class BillingComponent {
  public menu: Billing;

  constructor() {
    this.menu = new Billing();
  }
}
