<div class="custom-modal-wrapper reminder">
  <h1 class="modal-title-custom p-20" mat-dialog-title>
    <ng-container *ngIf="data.edit">
      {{ "REMINDERS.EDIT-REMINDER" | translate }}
    </ng-container>

    <ng-container *ngIf="!data.edit">
      {{ "REMINDERS.ADD-REMINDER" | translate }}
    </ng-container>
  </h1>
  <div mat-dialog-content>
    <form [formGroup]="remindersForm">
      <!-- date -->
      <div class="row">
        <mat-form-field class="w-50">
          <mat-label>
            {{ "REMINDERS.REMIND-AT" | translate }}
          </mat-label>
          <input
            matInput
            [ngxMatDatetimePicker]="picker"
            [placeholder]="'GENERALS.SELECT-DATE' | translate"
            formControlName="remind_at"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(picker)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker
            [showSpinners]="true"
            [showSeconds]="false"
            [stepHour]="1"
            [stepMinute]="15"
            [enableMeridian]="false"
            [hideTime]="false"
            [stepSecond]="0"
          >
          </ngx-mat-datetime-picker>
        </mat-form-field>
        <!-- subscribers -->
        <div class="w-50 d-flex align-items-center recipients">
          <mat-form-field
            appearance="outline"
            [formGroup]="remindersForm"
            class="w-100"
          >
            <mat-label> {{ "LABEL.RECIPIENTS" | translate }} </mat-label>
            <mat-select
              #select
              class="chips-input subscribers"
              multiple
              formControlName="subscribers"
            >
              <mat-select-trigger>
                <span *ngFor="let user of selectedUsers">
                  {{ user.first_name }} {{ user.last_name }}
                  <span
                    class="delete-item"
                    (click)="removeSelectedUser($event, user)"
                    >X</span
                  >
                </span>
              </mat-select-trigger>

              <mat-option
                class="chip-option"
                *ngFor="let user of users"
                [value]="user.id"
              >
                {{ user.first_name }}
                {{ user.last_name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="error-wrapper">
            <app-form-errors
              [control]="remindersForm.get('subscribers')"
              #errors
            ></app-form-errors>
          </div>
        </div>

        <!-- end-subscribers -->
      </div>
      <!-- end-date -->

      <div class="row mb-3">
        <div class="w-100 mb-3 p-0">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ "NOTES.NOTE" | translate }}</mat-label>
            <textarea
              class="pb-0"
              matInput
              formControlName="text"
              rows="15"
              placeholder="{{ 'NOTES.ENTER-TEXT' | translate }}"
            >
            </textarea>
            <mat-error formControlError="text"> </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="modal-footer-custom bottom-buttons">
    <ng-container *ngIf="!isLoading; else loadingButtons">
      <button class="btn gray-bg modal-button" mat-dialog-close>
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CLOSE" | translate }}
      </button>
      <button class="btn orange-bg modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <ng-template #loadingButtons>
      <app-loader-spinner-general
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
    </ng-template>
  </div>
</div>
