import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AgreementData } from "@api/agreements/models/agreement.model";
import { AgreementMapperService } from "@api/agreements/services/agreement-mapper.service";
import { environment } from "@environment/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class AgreementsService {
  public agreementsList: AgreementData[] = [];

  constructor(
    private http: HttpClient,
    private readonly mapperService: AgreementMapperService
  ) {}

  public list(entityId): Observable<AgreementData[]> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}entities/providers/${entityId}/agreements/list`
      )
      .pipe(
        map((response) => {
          return this.mapperService.mapMany(response.result);
        })
      );
  }

  sign(provider_id, agreement_id, params = {}): Observable<any> {
    return this.http.post<any>(
      `${environment.gateway_endpoint}entities/providers/${provider_id}/agreements/${agreement_id}`,
      params
    );
  }

  download(provider_id, agreement_id) {
    const headers = new HttpHeaders({
      Accept: "application/pdf",
    });
    return this.http.get(
      environment.gateway_endpoint +
        "entities/providers/" +
        provider_id +
        "/agreements/" +
        agreement_id +
        "/export-pdf",
      {
        headers: headers,
        responseType: "blob",
      }
    );
  }

  isDone() {
    if (!this.agreementsList.length) {
      return false;
    }

    return this.agreementsList.filter((item) => !item.signed).length === 0;
  }
}
