import { finalize } from "rxjs";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { UserData } from "@api/account";
import { environment } from "@environment/environment";
import { CalendarHelper } from "@helpers/calendar.helper";
import { QuoteModel } from "@modules/quotes/models/quote.model";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { AlertService } from "../../../../_services/alert.service";
import { UsersService } from "../../../../_services/users.service";
import { WindowResizeHelperService } from "../../../../_services/window-resize-helper.service";
import { CreateOrderComponent } from "../create-order/create-order.component";
import { CreateQuoteComponent } from "../create-quote/create-quote.component";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-quotes",
  templateUrl: "./quotes.component.html",
  styleUrls: ["./quotes.component.scss"],
})
export class QuotesComponent extends ItemsListComponent implements OnInit {
  @Input() public clientId: number;
  @Input() public orderId: number;
  @Input() public clientName: string;

  public isMobileDevice = false;
  public items: QuoteModel[];
  public user: UserData;
  public hasCrm = environment.has_crm;

  actions = [
    {
      name: "generate_order",
      text: "Place Order",
      action: "generateOrder",
      display: true,
    },
  ];

  constructor(
    private service: QuotesService,
    private windowResizeHelper: WindowResizeHelperService,
    private userService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private alert: AlertService,
    public calendar: CalendarHelper,
    private matDialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isMobileDevice = data.responsiveStatus;
    });

    this.userService.getCurrentUser().subscribe((data) => {
      this.user = data;

      this.route.queryParams.subscribe((query) => {
        this.watchQueryParams(query);
      });
    });
  }

  getItems() {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    const params: any = { ...this.filters };
    if (this.orderId) {
      params.order_id = this.orderId;
    }

    this.isLoading = true;

    this.request_call = this.service
      .list(this.user.entity_id, this.clientId, params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.processResponse({ result: data });
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  remove(quote: QuoteModel) {
    this.service
      .delete(this.user.entity_id, this.clientId, quote)
      .subscribe((data) => {
        this.alert.success(data.message);
        this.getItems();
      });
  }

  createOrEdit(quote = null) {
    this.matDialog
      .open(CreateQuoteComponent, {
        data: {
          quote,
          clientId: this.clientId,
          orderId: this.orderId,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result?.quote) {
          return;
        }
        this.getItems();
      });
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router
      .navigate([], {
        queryParams: params,
        relativeTo: this.route,
        replaceUrl: true,
      })
      .then();
  }

  execBulkAction(bulkEvent) {
    switch (bulkEvent.action) {
      case "generateOrder": {
        const expiredExists = this.selection.selected.some((item) =>
          moment(item.expires_at).isBefore(moment())
        );

        if (expiredExists) {
          this.alert.stringError(
            this.translateService.instant("GENERALS.INVALID-PLACE-ORDER")
          );

          return;
        }

        this.openOrderModal(this.selection.selected);
        break;
      }
      default:
        console.log(bulkEvent);
    }
  }

  openOrderModal(quotes: QuoteModel[]) {
    this.matDialog.open(CreateOrderComponent, {
      data: {
        quotes,
        client_id: this.clientId,
      },
    });
  }

  export() {
    this.filters["provider_id"] = this.user.entity_id;
    this.filters["client_id"] = this.clientId;
    this.exportItems(this.clientName + " Service Quotes");
  }

  protected getService() {
    return this.service;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
