import { Component, OnInit } from "@angular/core";
import { AgreementData } from "@api/agreements/models/agreement.model";
import { AgreementsService } from "@api/agreements/services/agreements.service";
// tslint:disable-next-line:max-line-length
import { CurrentUserHelper } from "@helpers/current.user.helper";
import { TermsAndConditionsModalComponent } from "@modules/onboarding/terms-and-conditions/terms-and-conditions-modal/terms-and-conditions-modal.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { saveAs } from "file-saver";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { AccountSelectors, UserData } from "@api/account";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { filterNullish } from "src/app/lib";

@Component({
  selector: "app-account-documents",
  templateUrl: "./account-documents.component.html",
  styleUrls: ["./account-documents.component.scss"],
})
export class AccountDocumentsComponent
  extends ItemsListComponent
  implements OnInit
{
  public id: number;

  public user$: Observable<UserData>;

  agreements: AgreementData[];
  isLoading = false;
  selectedAgreement;
  isXs: boolean;
  waitingForAdmin = false;
  title: string;

  constructor(
    protected service: AgreementsService,
    private windowResizeHelper: WindowResizeHelperService,
    private userHelper: CurrentUserHelper,
    private store: Store,
    private breadcrumbService: BreadcrumbService
  ) {
    super();
  }

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setCompanyAgreements()
    );
    this.title = "xpath T&Cs";
    this.user$ = this.store
      .select(AccountSelectors.selectUser)
      .pipe(filterNullish());

    this.user$.subscribe((user) => {
      if (user) {
        this.id = user.entity_id;
        this.getAgreements();

        this.windowResizeHelper.getData().subscribe((data) => {
          this.isXs = data.responsiveStatus;
        });
      }
    });
  }

  getAgreements() {
    this.isLoading = true;
    this.service.list(this.id).subscribe(
      (res) => {
        this.agreements = res;
        this.userHelper.setCurrentUserStatus();
        this.isLoading = false;
      },
      (_error) => {
        this.isLoading = false;
        this.waitingForAdmin = true;
      }
    );
  }

  downloadAgreement(agreement_id, name) {
    this.service.download(this.id, agreement_id).subscribe((res) => {
      const blob = new Blob([res], { type: "application/pdf" });
      saveAs(blob, name + ".pdf");
    });
  }

  openAgreementModal(agreement) {
    this.selectedAgreement = agreement;
    this.dialog.open(TermsAndConditionsModalComponent, {
      height: "570px",
      data: { user_entity_id: this.id, agreement: this.selectedAgreement },
    });

    this.dialog.afterAllClosed.subscribe(() => {
      this.getAgreements();
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
