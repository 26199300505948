<button
  *ngIf="!isLoaded"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  [class]="hasActiveFilter() ? 'orange' : ''"
  [disabled]="!hasFilterData() && !hasActiveFilter()"
>
  <mat-icon>keyboard_arrow_down</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <div class="menu">
    <!--   sorting start   -->
    <button mat-menu-item *ngIf="!milestoneFilter" (click)="sortDesc()">
      <mat-icon
        svgIcon="sort-down"
        class="text-icon {{ selected.orderDesc ? 'orange' : '' }}"
      ></mat-icon>
      <span>{{ "LABEL.SORT-Z-A" | translate }}</span>
    </button>
    <button mat-menu-item *ngIf="!milestoneFilter" (click)="sortAsc()">
      <mat-icon
        svgIcon="sort-up"
        class="text-icon {{ selected.orderAsc ? 'orange' : '' }}"
      ></mat-icon>
      <span>{{ "LABEL.SORT-A-Z" | translate }}</span>
    </button>
    <!--   sorting end   -->

    <!--   clear filters start   -->
    <button mat-menu-item (click)="clearFilters()">
      <mat-icon svgIcon="filter" class="text-icon"></mat-icon>
      <span>{{ "LABEL.CLEAR-FILTERS" | translate }}</span>
    </button>
    <!--   clear filters end   -->

    <!--   filter by date start   -->
    <mat-form-field
      *ngIf="type === FilterType.DATE_RANGE"
      appearance="outline"
      class="w-full pr-3.5 pl-3.5 mat-form-date-range tasks-filter-date"
    >
      <mat-date-range-input
        [rangePicker]="picker"
        [min]="dateFilterData?.min_date"
        [max]="dateFilterData?.max_date"
      >
        <input
          matStartDate
          [formControl]="startDateControl"
          placeholder="dd/mm/yyyy "
        />

        <input
          matEndDate
          [formControl]="endDateControl"
          placeholder="dd/mm/yyyy"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <!--   filter by date end   -->

    <!--   search start   -->
    <mat-form-field
      *ngIf="type !== FilterType.DATE_RANGE"
      appearance="outline"
      class="w-full mat-search"
      (click)="$event.stopPropagation()"
    >
      <input class="search-input" matInput [formControl]="searchControl" />
      <mat-icon class="search-input-icon" svgIcon="search"></mat-icon>
    </mat-form-field>
    <!--   search end   -->

    <!--   multiselect filter start  -->
    <mat-tree
      *ngIf="type !== FilterType.DATE_RANGE"
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      (click)="$event.stopPropagation()"
    >
      <mat-tree-node
        class="node-border"
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
      >
        <mat-checkbox
          class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(node)"
          (change)="checklistSelection.toggle(node)"
        >
          {{ node.text | titlecase }}
        </mat-checkbox>
      </mat-tree-node>
      <mat-tree-node
        class="tree-border"
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
      >
        <mat-progress-bar
          *ngIf="node.isLoading"
          mode="indeterminate"
          class="example-tree-progress-bar"
        ></mat-progress-bar>
        <mat-checkbox
          [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="itemSelectionToggle(node)"
          >{{ node.text | titlecase }}
        </mat-checkbox>
      </mat-tree-node>
    </mat-tree>
    <!--   multiselect filter end  -->
  </div>
  <button class="apply-btn btn orange-bg w-full" (click)="setFilter()">
    {{ "LABEL.APPLY" | translate }}
  </button>
</mat-menu>
