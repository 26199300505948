<div
  #headerRef
  id="header-wrapper-portal"
  class="header-wrapper-no-bg"
  (window:scroll)="fixedHeader($event)"
>
  <div class="header-top">
    <!-- breadcrumbs - header left section -->

    <div class="header-breadcrumbs">
      <app-portal-breadcrumbs></app-portal-breadcrumbs>
    </div>

    <!-- end breadcrumbs - header left section -->

    <div class="header-top-action-wrapper fl-r">
      <!-- widgets -->
      <app-top-menu></app-top-menu>

      <!-- language selection -->
      <div class="header-top-lang fl-l">
        <app-translate></app-translate>
      </div>
    </div>

    <div *ngIf="user.admin_access" class="admin-notif fl-r">
      <div class="fw-500">
        {{ "GENERALS.LOGGED-AS-PROVIDER" | translate }}
      </div>
      <div class="admin-return">
        <a (click)="backToAdmin()" class="orange-bg pointer">{{
          "GENERALS.BACK-TO-ADMIN" | translate
        }}</a>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>

<div class="clear"></div>
