/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, HostBinding, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-quantity-input",
  templateUrl: "./quantity-input.component.html",
  styleUrls: ["./quantity-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: QuantityInputComponent,
    },
  ],
})
export class QuantityInputComponent implements ControlValueAccessor {
  _value: number = 0;
  _step: number = 1;
  _min: number = 0;
  _max: number = Infinity;

  @Input()
  disabled: boolean = false;

  @HostBinding("style.opacity")
  get opacity() {
    return this.disabled ? 0.25 : 1;
  }

  touched: boolean = false;

  onChange = (quantity) => {};

  onTouched = () => {};

  @Input()
  color: string = "default";

  @Input()
  set step(_step: number) {
    this._step = +_step;
  }

  @Input()
  set min(_min: number) {
    this._min = +_min;
  }

  @Input()
  set max(_max: number) {
    this._max = +_max;
  }

  shouldDisableDecrement(inputValue: number): boolean {
    return this.disabled || (!this._min && inputValue <= this._min);
  }

  shouldDisableIncrement(inputValue: number): boolean {
    return this.disabled || (!this._max && inputValue >= this._max);
  }

  writeValue(obj: number): void {
    this._value = obj;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  setColor(color: string): void {
    this.color = color;
  }

  getColor(): string {
    return this.color;
  }

  incrementValue(step: number = 1): void {
    if (this.disabled) {
      return;
    }
    const inputValue = this._value + step;
    this._value = inputValue;
    this.onChange(this._value);
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
