import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { Observable } from "rxjs";
import { DocumentCategory } from "@api/documents/models/document-category.model";
import { DocumentService } from "@api/documents/services/documents.service";

@Component({
  selector: "app-service-category-selector",
  templateUrl: "./service-category-selector.component.html",
  styleUrls: ["./service-category-selector.component.scss"],
})
export class ServiceCategorySelectorComponent implements OnInit {
  public documentForm: UntypedFormGroup;
  public categoryControl: UntypedFormControl;

  public categories$: Observable<DocumentCategory[]>;

  @Input() public value?: number;

  constructor(
    private controlContainer: ControlContainer,
    private service: DocumentService
  ) {
    this.categoryControl = new UntypedFormControl("");
  }

  public ngOnInit(): void {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("service_category_id", this.categoryControl);

    if (this.value) {
      this.documentForm.get("service_category_id").setValue(this.value);
    }

    this.categories$ = this.service.categories();
  }
}
