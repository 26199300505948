<div class="custom-modal-wrapper p-0">
  <h2 mat-dialog-title class="modal-title-custom p-20 m-0">
    {{ (contact?.id ? "CONTACT.EDIT" : "CONTACT.CREATE") | translate }}
  </h2>
  <mat-dialog-content class="p-0 m-0">
    <app-contact-edit
      [contact]="contact"
      [contactable]="contactable"
      (changes)="onChange($event)"
    ></app-contact-edit>
  </mat-dialog-content>
  <div class="modal-footer-custom bottom-buttons p-20">
    <ng-container *ngIf="!isLoading; else loadingButtons">
      <button mat-dialog-close mat-button class="gray-bg" (click)="cancel()">
        <span class="d-flex align-items-center justify-content-center">
          <mat-icon class="d-flex" svgIcon="cancel"></mat-icon>
          {{ "GENERALS.CANCEL" | translate }}
        </span>
      </button>
      <button
        mat-raised-button
        mat-button
        [disabled]="(canSubmit$ | async) === false"
        color="primary"
        (click)="submit()"
        class="orange-bg"
      >
        <span class="d-flex align-items-center justify-content-center">
          <mat-icon class="d-flex" svgIcon="save"></mat-icon>
          {{ "GENERALS.SAVE" | translate }}
        </span>
      </button>
    </ng-container>
    <ng-template #loadingButtons>
      <app-loader-spinner-general
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
    </ng-template>
  </div>
</div>
