import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-account-activity",
  templateUrl: "./account-activity.component.html",
  styleUrls: ["./account-activity.component.scss"],
})
export class AccountActivityComponent implements OnInit {
  public id;

  ngOnInit() {
    const user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
    this.id = user["entity_id"];
  }
}
