import { Component } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { ServiceOffersService } from "../../../modules/service-offers/services/service-offers.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-details-page",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent {
  id;
  breadcrumbs = [
    "Home",
    "Realocation",
    "House Hunting",
    "House tours around the city centre",
  ];

  offer;
  characteristics = [];
  keys;
  isLoading;
  constructor(
    private route: ActivatedRoute,
    public serviceOffers: ServiceOffersService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
        this.isLoading = true;

        this.getServiceOfferById(params.id);
      }
    });
  }

  setCharacteristics() {
    this.offer.characteristics.forEach((ch) => {
      const objFields = JSON.parse(ch.data);
      this.characteristics = Object.assign({}, this.characteristics, objFields);
    });
    this.keys = Object.keys(this.characteristics);
    this.isLoading = false;
  }

  getServiceOfferById(id) {
    this.serviceOffers.getServiceOfferById(id).subscribe((data) => {
      console.warn("Data", data);
      this.offer = data.result;
      if (data.result.characteristics && data.result.characteristics.length) {
        this.setCharacteristics();
      } else {
        this.isLoading = false;
      }
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
