import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { TimelineService } from "@modules/timeline/services/timeline.service";

@Directive({
  selector: "[appSidebarWidth]",
})
export class SidebarWidthDirective implements OnChanges {
  @Input() currentMonthDays = 0;
  constructor(
    private timeline: TimelineService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.updateWidth(changes);
  }

  private updateWidth(changes: SimpleChanges): void {
    let totalWidthAdditionalCount = 145;
    if (changes.currentMonthDays.currentValue === 30) {
      totalWidthAdditionalCount = -135;
    } else if (changes.currentMonthDays.currentValue === 28) {
      totalWidthAdditionalCount = -280;
    }

    if (this.el.nativeElement && this.timeline.sideGridTotalWidth) {
      const totalWidth =
        Number(this.timeline.sideGridTotalWidth as number) +
        totalWidthAdditionalCount;
      this.renderer.setStyle(this.el.nativeElement, "width", `${totalWidth}px`);
    }
  }
}
