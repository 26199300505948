import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthorizationService } from "@api/users/services/authorization.service";
import {
  PermissionActionEnum,
  PermissionResourceEnum,
  PermissionSecondaryResourceEnum,
} from "@modules/shared/_enums/permission-resource.enum";
import { TranslateService } from "@ngx-translate/core";
import { ServiceCategoriesService } from "../../../_services/servicecategories.service";

@Component({
  selector: "app-categories-tree",
  templateUrl: "./categories-tree.component.html",
})
export class CategoriesTreeComponent {
  @Input() categories: any[];
  @Output() onchange: EventEmitter<any> = new EventEmitter();

  public permissionResources: typeof PermissionResourceEnum =
    PermissionResourceEnum;
  public permissionSecondaryResources: typeof PermissionSecondaryResourceEnum =
    PermissionSecondaryResourceEnum;
  public permissionActions: typeof PermissionActionEnum = PermissionActionEnum;

  constructor(
    private service: ServiceCategoriesService,
    private translate: TranslateService,
    public auth: AuthorizationService
  ) {}

  downloadTemplate(service_category) {
    this.service.downloadTemplate(service_category.id).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = service_category.label + ".xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove(); // remove the element
    });
  }

  removeCategory(category) {
    if (!confirm(this.translate.instant("CONFIRMATIONS.REMOVE_CATEGORY"))) {
      return;
    }

    this.onchange.emit(category);
  }
}
