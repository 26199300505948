import { Component, OnInit } from "@angular/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { CardType } from "src/app/_enums/report-card-type-enum";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";

@Component({
  selector: "app-financial",
  templateUrl: "./financial.component.html",
  styleUrls: ["./financial.component.scss"],
})
export class FinancialComponent implements OnInit {
  isLoading: {
    [CardType.REVENUE]: boolean;
    [CardType.COSTS]: boolean;
    [CardType.PROFIT]: boolean;
  };

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForFinancialReports()
    );

    this.isLoading = {
      [CardType.REVENUE]: false,
      [CardType.COSTS]: false,
      [CardType.PROFIT]: false,
    };
  }

  setLoading(key: string, isLoading: boolean): void {
    this.isLoading[key] = isLoading;
  }

  get CardType() {
    return CardType;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
