import { Injectable } from "@angular/core";
import { ReminderData } from "../models/reminder.model";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class RemindersMapperService {
  mapOne(input: any): ReminderData {
    return {
      ...input,
      remind_at: moment(input.remind_at).local(),
    };
  }

  mapMany(input: any[]): ReminderData[] {
    return input.map((i) => this.mapOne(i));
  }
}
