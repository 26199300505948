<div class="header">
  <div class="header__column col-2">
    <div class="col-sm">status</div>
  </div>
  <ng-container *ngFor="let day of timelineService.currentMonthDays">
    <div [class]="'header__dates header__dates_' + day.getDate()">
      <div
        class="header__dates-day"
        [class.active]="
          day.getDate() === timelineService.currentMonthHolder.getDate() &&
          timelineService.currentMonthHolder.getMonth() === day.getMonth()
        "
        [class.shade-day]="
          day.toLocaleDateString([], { weekday: 'short' }) === 'Sat' ||
          day.toLocaleDateString([], { weekday: 'short' }) === 'Sun'
        "
      >
        {{ day.toLocaleDateString([], { day: "numeric" }) }}
        <small>{{ timelineService.days[day.getDay()] }}</small>
      </div>
    </div>
  </ng-container>
</div>
