<ng-container *ngIf="questions$ | async as questions">
  <ng-container *ngIf="questions.length > 0">
    <div class="border-l pl-3 flex flex-wrap justify-end">
      <div class="text-secondary mb-2.5 text-justify">
        {{ "GENERALS.ORDER-OFFER-QUESTIONS" | translate }}
      </div>
      <button
        class="btn-outline-none-primary"
        (click)="openQuestions(questions)"
      >
        <mat-icon svgIcon="open-eye" class="btn-icon"></mat-icon>
        {{ "GENERALS.ORDER-OFFER-SEE-QUESTIONS" | translate }}
      </button>
    </div>
  </ng-container>
</ng-container>
