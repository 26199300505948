import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class StatisticsService {
  private url = "statistics/orders";
  private urlRfq = "statistics/rfqs";
  private urlOffers = "statistics/offers";

  constructor(private http: HttpClient) {}

  // get statistics by status
  getOrdersStatisticsStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/by-status",
      {
        params: params,
      }
    );
  }

  // get statistics by month
  getOrdersStatisticsMonth(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/by-month",
      {
        params: params,
      }
    );
  }

  // get recent documents
  getRecentDocuments(id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.url +
        "/documents?" +
        "entity_id=" +
        id
    );
  }

  // get SLA (Service Level)
  getServiceLevel(id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint +
        this.url +
        "/by-service?" +
        "main_category_id=" +
        id
    );
  }

  // get RFQ Invitations
  getRFQInvitations(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlRfq + "/invitations",
      {
        params: params,
      }
    );
  }

  // Get orders cancelled by the provider in the given time frame
  // compared to the previous year
  getOrdersByStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/by-status",
      { params: params }
    );
  }

  // Get Overdue Orders
  getOverdueOrders(params = {}): Observable<any> {
    return this.http.get(environment.gateway_endpoint + this.url + "/overdue", {
      params: params,
    });
  }

  // Get Placed Orders Comparison

  getPlacedOrdersComparison(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/accepted-year-comparison",
      {
        params: params,
      }
    );
  }

  // Get Top Service Offers
  getTopServiceOffers(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlOffers + "/most-popular-services",
      {
        params: params,
      }
    );
  }

  // Get Least Popular Service Offers
  getLeastPopularServiceOffers(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlOffers + "/least-popular-services",
      {
        params: params,
      }
    );
  }

  // get statistics by status
  getOffersByStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlOffers + "/by-status",
      {
        params: params,
      }
    );
  }

  // get Sold Offers
  getSoldOffers(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlOffers + "/sold",
      {
        params: params,
      }
    );
  }

  getPlacedOrders(params = {}): Observable<any> {
    return this.http.get(environment.gateway_endpoint + this.url + "/new", {
      params: params,
    });
  }

  // get No of Orders By status ID
  getNoOrdersByStatus(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.url + "/actepted-orders",
      {
        params: params,
      }
    );
  }

  // get Provider RFQ Statistics
  getProviderRfqStatistics(params = {}): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.urlRfq + "/provider-statistics",
      {
        params: params,
      }
    );
  }
}
