<mat-option>
  <ngx-mat-select-search
    [placeholderLabel]="'LABEL.DEPENDENT' | translate"
    [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
    [formControl]="searchControl"
  ></ngx-mat-select-search>
</mat-option>
<mat-option (click)="addDependent()">{{
  "GENERALS.ADD-DEPENDENT" | translate
}}</mat-option>
<mat-option
  #actualOptionElement
  *ngIf="actualOption"
  [value]="actualOption?.id"
>
  {{ actualOption?.first_name }} {{ actualOption?.last_name }}
</mat-option>
<ng-container *ngIf="isLoading && (dependents$ | async) === null; else list">
  <mat-option disabled>
    <div class="flex justify-center w-full">
      <mat-progress-spinner
        diameter="16"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
  </mat-option>
</ng-container>
<ng-template #list>
  <mat-option
    *ngFor="let dependent of dependents$ | async"
    [value]="dependent.id"
  >
    {{ dependent.first_name }} {{ dependent.last_name }}
  </mat-option>
</ng-template>
