import { Component, Input, OnInit } from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { UserData } from "@api/account";
import { ServiceOffersService } from "@modules/service-offers/services/service-offers.service";
import { Observable, Subject } from "rxjs";
import { finalize, map, take, takeUntil } from "rxjs/operators";
import { WorkflowStatus } from "src/app/_models/workflow-status";

export function minimumServicesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }
    const valid = value.some((val) => val.enabled);
    return !valid ? { minimumServices: true } : null;
  };
}
@Component({
  selector: "app-services-selector",
  templateUrl: "./services-selector.component.html",
  styleUrls: ["./services-selector.component.scss"],
})
export class ServicesSelectorComponent implements OnInit {
  caseForm: UntypedFormGroup;
  servicesFromArray: UntypedFormArray;

  items: {
    id: number;
    service_id: number;
    service_name: number;
    enabled: boolean;
  }[] = [];

  @Input() submitEvent: Observable<void>;
  @Input() user: Observable<UserData>;
  @Input() exclude_id: string = ";";

  selectedItems: any;
  isLoading: boolean = false;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private controlContainer: ControlContainer,
    private offersService: ServiceOffersService
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.servicesFromArray = new UntypedFormArray([], Validators.required);
    this.caseForm = <UntypedFormGroup>this.controlContainer.control;
    this.caseForm.get("services").disable();

    this.submitEvent.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (!this.caseForm.get("services").valid) {
        this.caseForm.get("services").setErrors({ required: true });
        this.caseForm.get("services").markAsTouched();
      }
    });

    this.offersService
      .getServiceOffers({
        workflow_status: WorkflowStatus.PUBLISHED,
      })
      .pipe(
        map((response) => response?.result?.items ?? []),

        map((items: any) =>
          items.map((item) => ({
            id: item.id,
            service_id: item.id,
            service_name: item.title,
            enabled: true,
          }))
        )
      )
      .pipe(
        take(1),
        finalize(() => this.caseForm.get("services").enable())
      )
      .subscribe((items) => {
        this.items = items;
      });

    this.caseForm
      .get("services")
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((values) => {
        this.selectedItems = values;
      });
  }

  removeSelectedItem(event, item: any) {
    event.stopPropagation();

    this.selectedItems = this.selectedItems.filter(
      (selectedItem) => item.id !== selectedItem.id
    );

    this.caseForm.get("services").setValue(this.selectedItems);
  }
}
