<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }" class="profile-picture">
  <ngx-smart-modal
    id="addProfilePicture"
    #addProfilePicture
    identifier="addProfilePicture"
    [dismissable]="false"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper p-20">
      <div class="modal-title">
        {{ title ? (title | translate) : ("GENERALS.ADD-DOC" | translate) }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body p-20">
      <ng-container *ngIf="isLoading">
        <app-loader-spinner-general
          [loadingType]="LoadingType.INPUT"
        ></app-loader-spinner-general>
      </ng-container>

      <div class="modal-form" *ngIf="!isLoading">
        <div class="row">
          <div class="form-group" [ngClass]="'w-100'">
            <label>{{ "PORTAL.UPLOAD-PIC" | translate }}</label>

            <div class="custom-upload-box">
              <file-upload
                [(ngModel)]="uploadedFiles.file"
                [control]="fileUploadControl"
              >
                <ng-template #placeholder>
                  <span><i class="icon-upload-cloud"></i></span>
                  <span>{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }} </span>
                  <span class="orange">
                    {{ "GENERALS.BROWSE" | translate }}</span
                  >
                </ng-template>

                <ng-template
                  let-i="index"
                  let-file="file"
                  let-control="control"
                  #item
                >
                  <div class="file-info">
                    <span class="file-name">{{ file.name }}</span>
                    <span
                      class="remove-file"
                      (click)="control.removeFile(file)"
                    >
                      <i class="icon-trash"></i
                      >{{ "GENERALS.REMOVE-FILE" | translate }}</span
                    >
                  </div>
                </ng-template>
              </file-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="modal-footer p-20">
      <app-loader-spinner-general
        *ngIf="isLoading"
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
      <ng-container *ngIf="!isLoading">
        <button
          type="button"
          class="btn gray-bg modal-button"
          (click)="cancelModal()"
        >
          <mat-icon svgIcon="cancel"></mat-icon>
          {{ "GENERALS.CANCEL" | translate }}
        </button>

        <button
          [disabled]="isLoading"
          type="button"
          class="btn orange-bg modal-button"
          (click)="uploadImage()"
        >
          <mat-icon svgIcon="save"></mat-icon>
          {{ "GENERALS.ADD" | translate }}
        </button>
      </ng-container>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->
