import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DocumentData } from "@api/documents/models/document.model";
import { DocumentService } from "@api/documents/services/documents.service";
import { RemindersService } from "@api/reminders/services/reminders.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import moment from "moment";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public documentForm: UntypedFormGroup = new UntypedFormGroup({
    expiration_date: new UntypedFormControl(""),
  });

  public isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DocumentData & { shouldShowPreview: boolean },
    private dialogRef: MatDialogRef<EditComponent>,
    private documentService: DocumentService,
    private remindersService: RemindersService
  ) {}

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public ngOnInit(): void {
    if (this.data.expiration_date) {
      this.documentForm
        .get("expiration_date")
        .setValue(
          moment(this.data.expiration_date).add(1, "days").format("Y-MM-DD")
        );
    }
  }

  public onDateChange(date: moment.Moment): void {
    if (moment(date).isValid()) {
      this.documentForm.get("expiration_date").setValue(date);
    }
  }

  public close(): void {
    this.dialogRef.close({ action: "cancel" });
  }

  public submit(): void {
    this.isLoading = true;

    const { reminder, ...data } = this.documentForm.getRawValue();

    if (data.expiration_date) {
      data.expiration_date = moment.isMoment(data.expiration_date)
        ? data.expiration_date.format("Y-MM-DD")
        : moment(data.expiration_date).subtract(1, "days").format("Y-MM-DD");
    } else {
      delete data.expiration_date;
    }

    if (!data.service_category_id) {
      delete data.service_category_id;
    }

    this.documentService
      .update(this.data.id, data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: { result: { document: DocumentData } }) => {
        if (reminder) {
          this.setReminder(res.result.document, reminder);
        }

        this.dialogRef.close({ action: "saved" });
      });
  }

  public setReminder(document: DocumentData, reminderPeriod: number): void {
    const body = {
      subject_type: "doc",
      subject_id: document.id,
      subscribers: [{ id: document.created_by.id }],
      remind_at: moment(document.expiration_date)
        .subtract(reminderPeriod, "months")
        .hour(12)
        .toDate()
        .toISOString(),
      text: `The document ${document.name} (${document.document_type.text}) for ${document.created_by.text} will expire on ${document.expiration_date}`,
      date: moment(document.expiration_date)
        .subtract(reminderPeriod, "months")
        .toDate()
        .toISOString(),
      visibility: "public",
    };

    this.remindersService.create(body).subscribe(() => {
      this.dialogRef.close({
        type: "saved",
        document,
      });
    });
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
