import { Component, Input } from "@angular/core";
import { PreviewStrategy } from "../preview-strategy.component";
import { DocumentFile } from "@api/documents/models/document.model";

@Component({
  selector: "app-video-preview-strategy",
  templateUrl: "./video-preview-strategy.component.html",
  styleUrls: ["./video-preview-strategy.component.css"],
})
export class VideoPreviewStrategyComponent implements PreviewStrategy {
  @Input() file: DocumentFile;
}
