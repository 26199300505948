<app-loader-spinner-general
  *ngIf="isLoading"
  [repeat]="11"
  class="loading-wrapper"
  [loadingType]="LoadingType.INFO"
></app-loader-spinner-general>

<div class="row">
  <div class="col-12 row horizontal-thumbnails">
    <div
      class="page-thumbnail col-sm"
      *ngFor="let item of thumbnail"
      (click)="choosePage(item.page)"
    >
      <img
        [alt]="item.page"
        [ngClass]="{ 'page-thumbnail-active': item.page === currentPage }"
        [src]="item.url"
        class="w-100 mb-2 pr-0 pl-0"
      />
    </div>
  </div>

  <div class="col-12 col-md-9 pdf-viewer position-relative">
    <div class="drag-zone position-absolute" #elementsZone>
      <div
        class="element"
        [class.inactive-element]="isReadonly"
        [ngStyle]="item.styles"
        (click)="activateElement(item)"
        (cdkDragEnded)="savePositionState(item, $event)"
        cdkDrag
        *ngFor="let item of currentAnnotations"
      >
        <p [class.active]="item.selected">
          {{ item.value }}
          <i
            *ngIf="!isReadonly"
            class="icon-trash m-l-20 pointer"
            (click)="deleteElement(item)"
          ></i>
        </p>
      </div>
    </div>
    <pdf-viewer
      [src]="templateDocumentPath"
      [page]="currentPage"
      [original-size]="false"
      [fit-to-page]="true"
      [show-all]="false"
      [render-text]="true"
      (page-rendered)="pageRendered($event)"
      style="width: 100%; height: 100vh"
    >
    </pdf-viewer>
  </div>
  <div class="col-12 col-md-3 page-thumbnails vertical-thumbnails">
    <div
      class="page-thumbnail"
      *ngFor="let item of thumbnail"
      (click)="choosePage(item.page)"
    >
      <img
        [alt]="item.page"
        [ngClass]="{ 'page-thumbnail-active': item.page === currentPage }"
        [src]="item.url"
        class="w-100 mb-2 pr-0 pl-0"
      />
    </div>
  </div>
</div>
