import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class GetArrayInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const mappedHttpParams = request.params
      .keys()
      .reduce((currentValue, key) => {
        const value = request.params.getAll(key);
        if (value.length > 1) {
          return currentValue.append(key, value.join(";"));
        }
        return currentValue.append(key, request.params.get(key));
      }, new HttpParams());

    return next.handle(
      request.clone({
        params: mappedHttpParams,
      })
    );
  }

  constructor(private cookieService: CookieService) {}
}
