<div class="navigation d-flex align-items-center justify-content-start my-2">
  <div
    *ngIf="timelineService.previousMonth"
    class="navigation__btn btn white-bg me-2"
    [matTooltip]="
      timelineService.previousMonth.toLocaleString('default', { month: 'long' })
    "
    matTooltipClass="timeline-tooltip"
    (click)="timelineService.getAllDaysInMonth(-1)"
  >
    <i class="icon-angle-left"></i>
    {{ "GENERALS.PREVIOUS" | translate }}
  </div>
  <div
    *ngIf="timelineService.nextMonth"
    class="navigation__btn btn white-bg"
    [matTooltip]="
      timelineService.nextMonth.toLocaleString('default', { month: 'long' })
    "
    matTooltipClass="timeline-tooltip"
    (click)="timelineService.getAllDaysInMonth(+1)"
  >
    {{ "GENERALS.NEXT" | translate }}
    <i class="icon-angle-right"></i>
  </div>
  <h5 class="navigation__title mb-0 ms-3">
    {{
      timelineService.currentMonth.toLocaleDateString([], {
        month: "long",
        day: "numeric"
      })
    }}
  </h5>
</div>
