<div class="field">
  <div class="loading-wrapper">
    <app-loader-spinner-general
      *ngIf="isLoading"
      [repeat]="6"
      [loadingType]="LoadingType.INPUT"
    ></app-loader-spinner-general>
  </div>
  <table
    *ngIf="!isLoading"
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="position-relative">
        <button
          mat-icon-button
          aria-label="expand"
          (click)="
            expandedElement = expandedElement === element ? null : element;
            $event.stopPropagation()
          "
        >
          {{ element.label }}
        </button>

        <mat-icon
          *ngIf="
            expandedElement !== element &&
            !element.search &&
            !element.sizes &&
            !expandAllTabs
          "
          class="arrow"
        >
          keyboard_arrow_right
        </mat-icon>
        <mat-icon
          *ngIf="
            expandedElement === element ||
            element.search ||
            element.sizes ||
            expandAllTabs
          "
          class="arrow"
        >
          keyboard_arrow_down
        </mat-icon>
      </td>
    </ng-container>

    <!-- Expanded Content Column -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplayWithExpand.length"
      >
        <div
          class="element-detail"
          [@detailExpand]="
            element === expandedElement ||
            element.search ||
            element.sizes ||
            expandAllTabs
              ? 'expanded'
              : 'collapsed'
          "
        >
          <div class="fields-width">
            <div
              *ngIf="!element.sizes"
              class="m-3"
              [ngClass]="{
                fields: element.elements && element.elements.length > 5
              }"
            >
              <ng-container
                *ngIf="element.elements && element.elements.length > 0"
              >
                <ng-container *ngFor="let field of element.elements" class="">
                  <ng-container *ngIf="field.type === 'input'">
                    <mat-label>{{ field.label }}</mat-label>
                    <input
                      (input)="changeFieldDetection($event, field)"
                      matInput
                      [placeholder]="field.placeholder"
                    />
                  </ng-container>

                  <ng-container
                    *ngIf="field.type !== 'input' && field.active === true"
                  >
                    <div
                      class="border text-field py-2 px-3 white-bg-border"
                      (click)="setupFieldElement(field)"
                    >
                      {{ field.name }}
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

            <div *ngIf="element.sizes" class="formatting-fields">
              <mat-form-field>
                <mat-select [(value)]="element.elements[0]">
                  <mat-option
                    *ngFor="let field of element.elements"
                    [value]="field"
                  >
                    {{ field.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-select
                  [(value)]="element.sizes[0]"
                  (selectionChange)="setElementStyle($event, element)"
                >
                  <mat-option *ngFor="let size of element.sizes" [value]="size">
                    {{ size }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
      class="element-row"
      [ngClass]="element.slug"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="detail-row"
    ></tr>
  </table>
</div>
