<div class="auth-form-wrapper">
  <div class="auth-form-title-wrapper">
    <div class="auth-form-title">
      {{ "GENERALS.CONFIRM-EMAIL" | translate }}
    </div>
  </div>
  <div>
    <p *ngIf="succesConfirm" class="fw-500 fs-18">{{ confirmMsg }}.</p>

    <ng-container *ngIf="!succesConfirm">
      <p class="fw-500 fs-18">
        <ng-container *ngFor="let item of confirmMsg">
          {{ item.message }}.
        </ng-container>
      </p>
      <br />
      <p class="fw-500 fs-16">
        {{ "GENERALS.GO-TO" | translate }}
        <span class="fw-500 action-link-orange fs-16" [routerLink]="['/login']">
          {{ "GENERALS.LOGIN" | translate }}</span
        >.
      </p>
    </ng-container>
    <!-- <p class="fw-500 fs-18">
        {{ 'GENERALS.EMAIL-SUCCESS-CONFIRM' | translate }}.
      </p>

      <p class="fw-500 fs-16">
        {{ 'GENERALS.GO-TO' | translate }} <span class="fw-500 action-link-orange fs-16" [routerLink]="[ '/login' ]"> {{ 'GENERALS.LOGIN' | translate }}</span>.
      </p> -->
  </div>
</div>
