import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { filterNullish } from "src/app/lib";
import { UsersService } from "src/app/_services/users.service";
import { DocumentData } from "@api/documents/models/document.model";
import { DocumentService } from "@api/documents/services/documents.service";

@Component({
  selector: "app-document-selector",
  templateUrl: "./document-selector.component.html",
})
export class DocumentSelectorComponent implements OnInit {
  caseForm: UntypedFormGroup;

  @Input() submitEvent: Observable<void>;

  docsControl: UntypedFormControl;
  docsSearchFrom: UntypedFormControl;
  docs$: Observable<DocumentData[]>;
  filteredDocs$: Observable<DocumentData[]>;

  constructor(
    private readonly userService: UsersService,
    private controlContainer: ControlContainer,
    public readonly documentService: DocumentService
  ) {
    this.docsControl = new UntypedFormControl("", Validators.required);
    this.docsSearchFrom = new UntypedFormControl("");
  }

  ngOnInit() {
    this.submitEvent.subscribe(() => {
      if (!this.docsControl.valid) {
        this.docsControl.setErrors({ required: true });
        this.docsControl.markAsTouched();
      }
    });

    this.caseForm = <UntypedFormGroup>this.controlContainer.control;

    this.caseForm.setControl(
      "document_id",
      new UntypedFormControl("", Validators.required)
    );
    this.docsControl.valueChanges.subscribe((docs) => {
      this.caseForm.patchValue({
        document_id: docs,
      });
    });

    this.docsControl.disable({ emitEvent: false });

    this.docs$ = combineLatest([
      this.caseForm.get("expat_id").valueChanges,
    ]).pipe(
      tap(() => this.docsControl.disable({ emitEvent: false })),
      switchMap(([expatId]) =>
        this.documentService.list("expat", expatId, { per_page: 500 })
      ),
      map((response) => response.items),
      tap(() => this.docsControl.enable({ emitEvent: false })),
      shareReplay(1)
    );
    if (this.caseForm.get("document_id").value) {
      this.docs$
        .pipe(
          take(1),
          map((documents) =>
            documents.find(
              ({ id }) => id == this.caseForm.get("document_id").value
            )
          ),
          filterNullish()
        )
        .subscribe((expat) => {
          this.docsSearchFrom.setValue("");
          this.docsControl.setValue(expat);
        });
    }

    this.filteredDocs$ = combineLatest([
      this.docsSearchFrom.valueChanges.pipe(startWith("")),
      this.docs$,
    ]).pipe(
      map(([searchInput, docs]) => {
        if (!searchInput) {
          return docs;
        }
        const filterValue = searchInput.toLowerCase();

        return docs.filter((doc) =>
          doc.name.toLocaleLowerCase().includes(filterValue)
        );
      }),
      shareReplay(1)
    );
  }
}
