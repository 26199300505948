import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuantityInputComponent } from "./quantity-input/quantity-input.component";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [QuantityInputComponent],
  exports: [QuantityInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
  ],
})
export class CustomFormsModule {}
