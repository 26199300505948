import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-order-activity",
  templateUrl: "./order-activity.component.html",
  styleUrls: ["./order-activity.component.scss"],
})
export class OrderActivityComponent {
  public id;

  constructor(private route: ActivatedRoute) {
    this.route.parent.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }
}
