import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { map, tap } from "rxjs/operators";
import { OrderQuote } from "../_models/order-quote";
import { AuthenticationService } from "./authentication.service";
import { ExpatAssignmentResponse } from "../_models/expat-assignment-response";
import { OrderData } from "@api/billing";

const expatsUrl = "entities/expats";
@Directive()
@Injectable()
export class OrdersService {
  private ordersUrl = "billing/orders";
  private orderUrlProviders = "billing/orders/providers";
  private orderHistory = "history/order";
  orderModel;
  public docTypeInvoice = "invoices";

  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  getOrdersList(params: Record<string, any> = {}, id): Observable<any> {
    if (params.search_text == "") {
      delete params.search_text;
    }
    return this.http.get<any>(environment.gateway_endpoint + this.ordersUrl, {
      params: params,
    });
  }

  getOrderById(params, id): Observable<OrderData> {
    return this.http
      .get<any>(
        environment.gateway_endpoint + this.ordersUrl + "/" + id,
        params
      )
      .pipe(map((response: any) => response.result));
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + "/status-transitions",
      this.auth.setOptions()
    );
  }

  getOrderByIdentityNumber(params, id): Observable<any> {
    return this.http
      .get<any>(
        environment.gateway_endpoint +
          this.ordersUrl +
          "/get-order-by-identity-number/" +
          id,
        params
      )
      .pipe(
        tap((data) => {
          this.orderModel = data.result;
        })
      );
  }

  /**
   * Get search fields
   */
  getSearchFields(): Observable<any> {
    return this.http.get(environment.gateway_endpoint + "orders/search-fields");
  }

  /**
   * Get statuses
   */
  getStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "orders/statuses");
  }

  assignExpatAssignmentOnOrder(
    orderId,
    data
  ): Observable<ExpatAssignmentResponse> {
    return this.http.post<ExpatAssignmentResponse>(
      `${environment.gateway_endpoint}${this.ordersUrl}/${orderId}/assignments`,
      { ...data, order_id: orderId }
    );
  }

  /**
   * Set delivery date on order for expats
   */

  assignOrderExpat(orderID, offerID, expatID, date?): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint +
          this.ordersUrl +
          "/" +
          orderID +
          "/details/" +
          offerID +
          "/expats/" +
          expatID +
          "/dates",
        { delivery_date: date }
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  updateOrderDetails(orderID, params): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + this.ordersUrl + "/details/" + orderID,
        params
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Update Status Bulk
  updateStatusBulk(status_id, order_ids, reason = ""): Observable<any> {
    let body: any = { status_id: status_id, order_ids: order_ids };
    if (reason) {
      body = {
        status_id: status_id,
        order_ids: order_ids,
        declined_reason: reason,
      };
    }
    const response = this.http.patch<any>(
      environment.gateway_endpoint + this.ordersUrl,
      body
    );

    this.statusChange.emit();
    return response;
  }

  deleteAssignedExpat(orderID, offerID, expatID): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          this.ordersUrl +
          "/" +
          orderID +
          "/details/" +
          offerID +
          "/expats/" +
          expatID
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  getExpatById(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + expatsUrl + "/" + id
    );
  }

  // GET Orders history
  getOrderHistory(id, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.orderHistory + "/" + id,
      {
        params: params,
      }
    );
  }

  /**
   * Export filtered orders to CSV
   * @param params
   */
  exportOrders(params = {}) {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + "/export/orders/",
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  /**
   * Get number of providers pending approval
   */
  getPendingCount(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + "/pending/count",
      { params: { "low-priority": "low-priority" } }
    );
  }

  getRatingActivity(providerId: number, params = {}): Observable<any> {
    return this.http.get<any>(
      `${environment.gateway_endpoint}entities/providers/${providerId}/ratings`,
      { params: params }
    );
  }

  /**
   * Add a new quote to the order
   * @param orderId
   * @param quote
   */
  createQuote(orderId, quote: OrderQuote): Observable<any> {
    return this.http.post(
      environment.gateway_endpoint + this.ordersUrl + `/${orderId}/quotes`,
      quote
    );
  }

  /**
   * List quotes for an order
   * @param orderId
   */
  listQuotes(orderId): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + this.ordersUrl + `/${orderId}/quotes`
    );
  }

  /**
   * Remove a quote from an order
   * @param orderId
   * @param quoteId
   */
  deleteQuote(orderId, quoteId): Observable<any> {
    return this.http.delete(
      environment.gateway_endpoint +
        this.ordersUrl +
        `/${orderId}/quotes/${quoteId}`
    );
  }
}
