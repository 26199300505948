<ngx-smart-modal
  id="createAdBanner"
  #createAdBanner
  identifier="createAdBanner"
  [dismissable]="false"
  (onAnyCloseEvent)="close()"
  (onOpen)="isOpen()"
>
  <!-- Modal header -->
  <div class="modal-title-wrapper">
    <div class="modal-title">
      {{ "GENERALS.CREATE-AD-BANNER" | translate }}
    </div>
  </div>

  <div class="clear"></div>

  <!-- Modal body -->
  <div class="custom-modal-body">
    <div class="row">
      <div *ngIf="listBannerType" class="form-group w-100">
        <label>{{ "GENERALS.PLACEMENT" | translate }} * </label>
        <ng-select
          class="customiz-select"
          placeholder="{{ 'GENERALS.SELECT-BANNER-TYPE' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [clearable]="false"
          [searchable]="false"
          (change)="changeBannerType($event)"
          [(ngModel)]="bannerType"
        >
          <ng-option *ngFor="let option of listBannerType" [value]="option">
            {{ "BANNER-TYPE." + option.key | translate }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <ng-container *ngIf="bannerType">
      <div class="row" *ngIf="bannerType.has_category">
        <div class="w-100">
          <app-category-selection
            [categories]="categories"
            [bannerType]="bannerType"
            [mandatoryField]="false"
            [level]="0"
            [isAd]="true"
            (categorySelected)="selectCategory($event)"
          ></app-category-selection>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div *ngIf="performanceType" class="form-group w-50">
        <label>{{ "GENERALS.PERFORMANCE-TYPE" | translate }} * </label>
        <ng-select
          class="customiz-select"
          placeholder="{{ 'GENERALS.SELECT-PERFORMANCE-TYPE' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [clearable]="false"
          [searchable]="false"
          [(ngModel)]="item.performance_type"
        >
          <ng-option
            *ngFor="let option of performanceType"
            [value]="option.value"
          >
            {{ option.value | uppercase }}
          </ng-option>
        </ng-select>
      </div>

      <div *ngIf="countries" class="form-group w-50">
        <label>{{ "GENERALS.AD-COUNTRY" | translate }}</label>

        <ng-select
          class="customiz-select chips-select"
          placeholder="{{ 'GENERALS.SELECT-COUNTRY' | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [clearable]="true"
          [searchable]="true"
          [(ngModel)]="item.country"
        >
          <ng-option *ngFor="let item of countries" [value]="item.name">
            {{ item.name }}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <div class="fix-position-calendar-modal">
          <div class="modal-calendar-wrapper">
            <app-date-range-calendar
              [minDate]="minStartDate"
              [placeholder]="'GENERALS.SELECT-DATE' | translate"
              [singleDate]="item.starts_at"
              (change)="setDate($event, 'start_date')"
            ></app-date-range-calendar>
          </div>
        </div>
      </div>

      <div class="form-group w-50">
        <div class="fix-position-calendar-modal">
          <div class="modal-calendar-wrapper">
            <app-date-range-calendar
              [minDate]="minEndDate"
              [placeholder]="'GENERALS.SELECT-DATE' | translate"
              [singleDate]="item.ends_at"
              (change)="setDate($event, 'end_date')"
            ></app-date-range-calendar>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-50">
        <label>{{ "GENERALS.MAX-COST" | translate }} * </label>
        <input
          type="number"
          name="max_cost"
          class="form-control"
          [(ngModel)]="item.max_cost"
        />
      </div>

      <div class="form-group w-50">
        <label>{{ "GENERALS.ACTION-URL" | translate }} * </label>
        <input
          type="text"
          name="action_url"
          class="form-control"
          [(ngModel)]="item.action_url"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group w-100">
        <label>{{ "GENERALS.UPLOAD-IMG" | translate }} * </label>

        <div class="upload-photo-wrapper">
          <div class="uplaod-photo-box">
            <img [src]="imgURL" *ngIf="imgURL" />
            <span class="orange" *ngIf="message && !imgURL">{{ message }}</span>
          </div>
          <label *ngIf="!imgURL" class="upload-files">
            <div class="btn-small orange-bg">
              <i class="icon-upload-cloud"></i>
              {{ "GENERALS.ADD-PHOTO" | translate }}
            </div>
            <input
              #file
              type="file"
              accept="image/*"
              (change)="preview(file.files)"
            />
          </label>
          <label *ngIf="imgURL" class="upload-files">
            <div (click)="removeUploaded()" class="btn-small orange-bg">
              <i class="icon-trash"></i>
              {{ "GENERALS.REMOVE-PHOTO" | translate }}
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="bannerType">
      <div class="form-group w-100">
        <ng-container [ngSwitch]="bannerType.key">
          <div *ngSwitchCase="'main-carousel'" class="fw-500 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}:
            <span class="fw-600 orange">1140px/440px</span> ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
            <br />
            {{ "GENERALS.CENTRAL-CONTENT" | translate }}.
          </div>
          <div *ngSwitchCase="'main-top-providers'" class="fw-500 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}:
            <span class="fw-600 orange">1460px/300px</span> ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'main-category'" class="fw-500 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}
            <span class="fw-600 orange">1140px/240px</span> ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'offers_category'" class="fw-500 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}
            <span class="fw-600 orange">1140px/200px</span> ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'offers_filter'" class="fw-500 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}
            <span class="fw-600 orange">250px/600px</span> ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
          <div *ngSwitchCase="'offer_page'" class="fw-500 m-t-15">
            {{ "GENERALS.RECOMMENDED-IMG-SIZE" | translate }}
            <span class="fw-600 orange">320px/300px</span> ({{
              "GENERALS.WIDTH-HEIGHT" | translate
            }})
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="clear"></div>
  <!-- Modal footer -->

  <div class="modal-footer">
    <button type="button" class="btn gray-bg modal-button" (click)="close()">
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      type="button"
      class="btn orange-bg modal-button"
      (click)="createAdBanenr()"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</ngx-smart-modal>
