<!-- dashboard top (title[left] - btn or navigation for portal details pages[right]) -->
<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- left -->
  <div *ngIf="!hideTitle" class="fl-l big-title">
    {{ "DOCUMENTS." + docType.toUpperCase() | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="fl-r">
    <div class="btn orange-bg" (click)="openDocModal()">
      <i class="icon-upload-cloud"></i> {{ "DOCUMENTS.UPLOAD" | translate }}
    </div>
    <!-- <label class="upload-files">
        <div class="btn white-bg">
          <i class="icon-upload-cloud"></i> {{ 'DOCUMENTS.UPLOAD' | translate }}
        </div>
        <input type="file" name="file" (change)="uploadFile($event)" />
      </label> -->
  </div>
  <!-- end right -->
</div>
<!-- end dashboard top (title[left] - btn or navigation for portal details pages[right]) -->

<div class="clear"></div>

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- dashboard action (search[left] - calendar and/or elem on page[right]) -->
<div
  windowScrollDashboardBodyAction
  class="dashboard-body-action"
  *ngIf="isFiltersLoaded"
>
  <!-- left -->
  <div class="fl-l advanced-search-wrapper w-100 p-0">
    <app-filters
      [showCalendar]="false"
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; getItems()"
      [total]="total"
      [searchPlaceholder]="'DOCUMENTS.' + docType.toUpperCase()"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>
  <!-- end left -->
</div>
<!-- end dashboard action (search[left] - calendar and/or elem on page[right]) -->

<div class="clear"></div>

<!-- table -->
<div class="table-wrapper">
  <ng-container *ngIf="!isXs; else responsiveTable">
    <div class="table-responsive">
      <table matSort (matSortChange)="sortData($event)">
        <thead>
          <tr *ngIf="docType === service.docTypeInvoice">
            <th mat-sort-header="id" class="text-right">
              {{ "DOCUMENTS.ID" | translate }}
            </th>
            <th mat-sort-header="invoice-number" class="text-right">
              {{ "DOCUMENTS.INVOICE-NUMBER" | translate }}
            </th>
            <th mat-sort-header="invoice-amount" class="text-right">
              {{ "DOCUMENTS.INVOICE-AMOUNT" | translate }}
            </th>
            <th mat-sort-header="invoice-issue-date" class="text-right">
              {{ "DOCUMENTS.INVOICE-ISSUE-DATE" | translate }}
            </th>
            <th mat-sort-header="invoice-due-date" class="text-right">
              {{ "DOCUMENTS.INVOICE-DUE-DATE" | translate }}
            </th>
            <th mat-sort-header="payment-date" class="text-right">
              {{ "DOCUMENTS.PAYMENT-DATE" | translate }}
            </th>
            <th mat-sort-header="name">
              {{ "DOCUMENTS.PAYMENT-INFO" | translate }}
            </th>
            <th mat-sort-header="invoice-status">
              {{ "DOCUMENTS.INVOICE-STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
          <tr *ngIf="docType !== service.docTypeInvoice">
            <th mat-sort-header="id">{{ "DOCUMENTS.ID" | translate }}</th>
            <th mat-sort-header="name">{{ "DOCUMENTS.NAME" | translate }}</th>
            <th mat-sort-header="uploaded_by">
              {{ "DOCUMENTS.UPLOADED_BY" | translate }}
            </th>
            <th mat-sort-header="status_id">
              {{ "GENERALS.STATUS" | translate }}
            </th>
            <th class="ta-r"></th>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="100">
              <app-loader-spinner-general
                [loadingType]="LoadingType.TABLE"
              ></app-loader-spinner-general>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <tr
            class="cursor-pointer"
            *ngFor="
              let item of items
                | paginate
                  : {
                      itemsPerPage: itemsPage,
                      currentPage: p,
                      totalItems: total
                    }
                | sortBy: 'desc':'created_at';
              let i = index
            "
          >
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              class="gray-pointer text-right"
            >
              {{ item.id }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType !== service.docTypeInvoice"
              class="gray-pointer text-right"
            >
              {{ item.name }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType === service.docTypeInvoice"
              class="gray-pointer text-right"
            >
              {{
                item.order_invoice.invoice_number
                  ? item.order_invoice.invoice_number
                  : null
              }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType === service.docTypeInvoice"
              class="gray-pointer text-right"
            >
              {{
                item.order_invoice.invoice_amount &&
                item.order_invoice.invoice_currency
                  ? (item.order_invoice.invoice_amount
                    | currency: item.order_invoice.invoice_currency)
                  : null
              }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType === service.docTypeInvoice"
              class="gray-pointer text-right"
            >
              {{
                item.order_invoice.invoice_issue_date
                  ? (item.order_invoice.invoice_issue_date | date: "dd/MM/yyyy")
                  : null
              }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType === service.docTypeInvoice"
              class="gray-pointer text-right"
            >
              {{
                item.order_invoice.invoice_due_date
                  ? (item.order_invoice.invoice_due_date | date: "dd/MM/yyyy")
                  : null
              }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType === service.docTypeInvoice"
              class="gray-pointer text-right"
            >
              {{
                item.order_invoice.payment_date
                  ? (item.order_invoice.payment_date | date: "dd/MM/yyyy")
                  : null
              }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType === service.docTypeInvoice && item.order_invoice"
              class="gray-pointer"
            >
              <p *ngIf="item.order_invoice.transaction_id">
                <b>{{ "DOCUMENTS.TRANSACTION-ID" | translate }}</b
                >: {{ item.order_invoice.transaction_id }}
              </p>
              <p *ngIf="item.order_invoice.payment_method">
                <b>{{ "DOCUMENTS.PAYMENT-METHOD" | translate }}</b
                >: {{ item.order_invoice.payment_method }}
              </p>
              <p
                class="deleted_reason"
                *ngIf="item.order_invoice.deleted_reason"
              >
                {{ item.order_invoice.deleted_reason }}
              </p>
            </td>

            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType !== service.docTypeInvoice"
              class="gray-pointer"
            >
              {{ item.user_name }}
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType !== service.docTypeInvoice"
            >
              <div class="status-label-{{ item.status }}">
                {{ item.status }}
              </div>
            </td>
            <td
              [style.pointer-events]="docType !== 'invoices' ? 'auto' : 'none'"
              [routerLink]="['../docs', item.id]"
              [queryParams]="{ name: item.name }"
              *ngIf="docType === service.docTypeInvoice"
              class="gray-pointer"
            >
              <div
                class="invoice-status-{{
                  item.order_invoice?.invoice_status?.toLowerCase()
                }}"
              >
                {{
                  item.order_invoice.invoice_status
                    ? item.order_invoice.invoice_status
                    : null
                }}
              </div>
            </td>

            <!-- table cel with action -->
            <td class="ta-r">
              <!-- table action btn -->
              <!-- table action with overlay -->
              <div toggle-action-table class="table-action-wrapper">
                <i class="icon-dot-3"></i>

                <div class="table-action">
                  <ul>
                    <li
                      *ngIf="
                        downloadItems &&
                        downloadItems.length &&
                        downloadItems[i]
                      "
                    >
                      <a
                        href="{{ downloadItems[i].file }}"
                        target="_blank"
                        download=""
                      >
                        {{ "GENERALS.DOWNLOAD-DOCUMENT" | translate }}
                      </a>
                    </li>
                    <li
                      *ngIf="docType !== service.docTypeInvoice"
                      (click)="deleteDocument(item.id)"
                    >
                      {{ "GENERALS.DELETE" | translate }}
                    </li>
                    <li
                      *ngIf="canDeleteItem(item)"
                      (click)="openDeleteInvoiceModal(item.id)"
                    >
                      {{ "GENERALS.DELETE" | translate }}
                    </li>
                    <li
                      *ngIf="this.canConfirmPaymentAsProvider(item)"
                      (click)="openConfirmPaymentModal(item.id)"
                    >
                      {{ "GENERALS.CONFIRM-PAYMENT" | translate }}
                    </li>
                  </ul>
                </div>
              </div>

              <!-- end table action with overlay -->
            </td>
            <td class="ta-l">
              <!-- add confirm payment modal -->
              <confirm-invoice-payment
                *ngIf="this.canConfirmPaymentAsProvider(item)"
                [fileId]="item.id"
                (confirmPaymentEvent)="getItems()"
              ></confirm-invoice-payment>
              <!-- add confirm payment modal -->

              <!-- add confirm delete invoice modal -->
              <delete-order-invoice
                *ngIf="this.canDeleteItem(item)"
                [fileId]="item.id"
                (confirmDeleteOrderInvoiceEvent)="getItems()"
              ></delete-order-invoice>
              <!-- add confirm delete invoice modal -->
            </td>
          </tr>

          <tr *ngIf="items?.length === 0">
            <td
              *ngIf="docType !== service.docTypeInvoice"
              colspan="6"
              class="gray"
            >
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
            <td
              *ngIf="docType === service.docTypeInvoice"
              colspan="11"
              class="gray"
            >
              {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <ng-template #responsiveTable>
    <!-- responsive table -->
    <div class="resposive-table-all-check">
      <ng-container matColumnDef="select">
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disableRipple]="true"
        >
          {{ "GENERALS.SELECT-ALL" | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.TABLE"
    ></app-loader-spinner-general>
    <ng-container *ngIf="!isLoading"> </ng-container>
    <!-- end responsive table -->
  </ng-template>

  <!-- paginator -->
  <div class="paginator">
    <pagination-controls
      (pageChange)="getItems($event)"
      previousLabel=""
      nextLabel=""
    >
    </pagination-controls>
  </div>

  <!-- end paginator -->
</div>
<!-- end table -->

<!-- add doc modal -->
<app-add-doc
  [entityType]="entityType"
  [entityId]="entityId"
  [docType]="docType"
  (changeDoc)="getItems()"
></app-add-doc>
<!-- add doc modal -->
