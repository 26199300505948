import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PageData } from "@modules/shared/models/page.model";
import { environment } from "@environment/environment";
import { map, tap } from "rxjs/operators";
import {
  RFQData,
  RfqDocumentMapperService,
  RfqMapperService,
  RFQProviderData,
  RFQServiceOfferData,
  RFQServiceRequestDocumentData,
} from "@api/rfq";
import { Store } from "@ngrx/store";
import { RfqActions } from "@api/rfq/actions";

export interface RFQListingFilters {
  per_page: string | number;
  page: string | number;
  search_text?: string;
}

@Injectable()
export class RFQService {
  private url = "offers/rfq/";

  constructor(
    private http: HttpClient,
    private readonly mapper: RfqMapperService,
    private readonly documentMapper: RfqDocumentMapperService,
    private readonly store: Store
  ) {}

  public list(
    filters: Partial<RFQListingFilters>
  ): Observable<PageData<RFQData>> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}${this.url}`, {
        params: filters,
      })
      .pipe(
        map((response) => {
          const data = response.result;
          data.items = this.mapper.mapMany(data.items);
          return data;
        })
      );
  }

  public details(id: number): Observable<RFQData> {
    return this.http
      .get<any>(`${environment.gateway_endpoint}${this.url}${id}`)
      .pipe(map((response: any) => this.mapper.mapOne(response.result)));
  }

  public transitions(): Observable<any> {
    return this.http
      .get(`${environment.gateway_endpoint}${this.url}transitions`)
      .pipe(map((response: any) => response.result));
  }

  public doDecline(
    rfqId: number,
    params: Partial<RFQProviderData>
  ): Observable<{
    item: RFQData;
  }> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.url}${rfqId}/providers/${params.provider_id}`,
        params
      )
      .pipe(
        map((response: any) => {
          return {
            item: this.mapper.mapOne(response.result),
          };
        })
      );
  }

  public decline(
    rfqId: number,
    params: RFQProviderData
  ): Observable<{
    item: RFQData;
  }> {
    this.store.dispatch(RfqActions.requestUpdateProvider({ provider: params }));
    return this.doDecline(rfqId, params).pipe(
      tap({
        next: ({ item }) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }

  public doSubmit(
    rfqId: number,
    serviceRequestId: number,
    params: Partial<RFQServiceOfferData>
  ): Observable<{
    item: RFQData;
  }> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.url}${rfqId}/service-requests/${serviceRequestId}/offers`,
        params
      )
      .pipe(
        map((response: any) => {
          return {
            item: this.mapper.mapOne(response.result),
          };
        })
      );
  }

  public submit(
    rfqId: number,
    serviceRequestId: number,
    params: Partial<RFQServiceOfferData>
  ): Observable<{
    item: RFQData;
  }> {
    this.store.dispatch(RfqActions.requestSubmitOffer({ offer: params }));
    return this.doSubmit(rfqId, serviceRequestId, params).pipe(
      tap({
        next: ({ item }) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }

  public doRevoke(
    rfqId: number,
    serviceRequestId: number,
    offerId: number
  ): Observable<{
    item: RFQData;
  }> {
    return this.http
      .delete<any>(
        `${environment.gateway_endpoint}${this.url}${rfqId}/service-requests/${serviceRequestId}/offers/${offerId}`
      )
      .pipe(
        map((response: any) => {
          return {
            item: this.mapper.mapOne(response.result),
          };
        })
      );
  }

  public revoke(
    rfqId: number,
    serviceRequestId: number,
    offerId: number
  ): Observable<{
    item: RFQData;
  }> {
    this.store.dispatch(RfqActions.requestDeleteOffer({ offerId: offerId }));
    return this.doRevoke(rfqId, serviceRequestId, offerId).pipe(
      tap({
        next: ({ item }) =>
          this.store.dispatch(
            RfqActions.requestUpdateRfqSuccess({
              item: item,
            })
          ),
        error: (error) =>
          this.store.dispatch(RfqActions.requestUpdateRfqError({ error })),
      })
    );
  }

  public serviceDocuments(
    id: number,
    serviceRequestId: number
  ): Observable<PageData<RFQServiceRequestDocumentData>> {
    return this.http
      .get(
        `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/documents`
      )
      .pipe(
        map((response: any) => {
          const data = response.result;
          data.items = this.documentMapper.mapMany(data.items);
          return data;
        })
      );
  }

  public offerDocuments(
    id: number,
    serviceRequestId: number,
    serviceOfferId: number
  ): Observable<PageData<RFQServiceRequestDocumentData>> {
    return this.http
      .get(
        `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/offers/${serviceOfferId}/documents`
      )
      .pipe(
        map((response: any) => {
          const data = response.result;
          data.items = this.documentMapper.mapMany(data.items);
          return data;
        })
      );
  }

  public upload(
    id: number,
    serviceRequestId: number,
    serviceOfferId: number,
    file: File
  ): Observable<RFQServiceRequestDocumentData> {
    const formData: FormData = new FormData();
    formData.append("file", file);

    return this.http
      .post<any>(
        `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/offers/${serviceOfferId}/documents`,
        formData
      )
      .pipe(
        map((response: any) => this.documentMapper.mapOne(response.result))
      );
  }

  public deleteDocument(
    id: number,
    serviceRequestId: number,
    serviceOfferId: number,
    documentId: number
  ): Observable<any> {
    return this.http.delete(
      `${environment.gateway_endpoint}${this.url}${id}/service-requests/${serviceRequestId}/offers/${serviceOfferId}/documents/${documentId}`
    );
  }
}
