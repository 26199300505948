import { Component, OnInit, OnDestroy } from "@angular/core";
import { ProvidersService } from "src/app/_services/providers.service";
import { ServiceCategory } from "src/app/_models/servicecategory";
import { ProviderData } from "src/app/_models/provider";
import { UsersService } from "src/app/_services/users.service";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { ProvidersCommissions } from "src/app/_models/providerscommissions";
import { Subject, combineLatest } from "rxjs";
import { ServiceCategoriesService } from "src/app/_services/servicecategories.service";
import { takeUntil } from "rxjs/operators";
import { BreadcrumbService } from "../../../_services/breadcrumb.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-provider-categories",
  templateUrl: "./provider-categories.component.html",
})
export class ProviderCategoriesComponent implements OnInit, OnDestroy {
  categories: ServiceCategory[] = [];
  selectedCategories = {};
  selectedCountries;
  allCategories: ServiceCategory[] = [];

  isLoading: boolean;
  public provider: ProviderData;
  id;
  countries;
  private _onDestroy = new Subject<void>();
  edit = false;
  displayCategories = [];

  constructor(
    private providersService: ProvidersService,
    private userService: UsersService,
    private alertService: AlertService,
    private dictionaryService: DictionariesService,
    public ngxSmartModalService: NgxSmartModalService,
    private categoryService: ServiceCategoriesService,
    private breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList(
        "/categories",
        "Categories & Commissions"
      )
    );
    this.getCurrentUser();
    this.getCountryList();
    this.getServiceCategories();
  }

  getCountryList() {
    this.dictionaryService.getCountryList().subscribe((data) => {
      this.countries = data.result;
    });
  }

  getProviderCategories() {
    this.isLoading = true;
    this.providersService.getProviderCategories(this.id).subscribe((data) => {
      this.categories = data.result;
      this.isLoading = false;
    });
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe(
      (res) => {
        // this.userID = res.id;
        this.id = res.entity_id;
        this.getProviderDetails();
        this.getProviderCategories();
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  getServiceCategories() {
    const params = { status: "active" };
    this.isLoading = true;

    this.categoryService
      .getServiceCategories(params)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data) => {
        this.allCategories = data.items;
        this.isLoading = false;
      });
  }

  getProviderDetails() {
    this.providersService.getProvider(this.id).subscribe((data) => {
      this.provider = data.result;
      if (this.provider.countries.length) {
        this.selectedCountries = [];
        this.provider.countries.forEach((c) => {
          this.selectedCountries.push(c.name.toString().toUpperCase());
        });
      } else {
        this.selectedCountries = [];
      }
    });
  }

  updateCategorySelection(data) {
    if (data.id) {
      if (this.selectedCategories[data.id]) {
        const idx = this.selectedCategories[data.id].findIndex(
          (f) => f.id === data.selection.id
        );
        if (idx > -1 && !data.update) {
          this.selectedCategories[data.id].splice(idx, 1);
        } else if (data.update) {
          this.selectedCategories[data.id][idx] = data.selection;
        } else {
          this.selectedCategories[data.id].push(data.selection);
        }
      } else {
        this.selectedCategories[data.id] = [];
        this.selectedCategories[data.id].push(data.selection);
      }
      if (
        this.selectedCategories[data.id] &&
        this.selectedCategories[data.id].length === 0
      ) {
        delete this.selectedCategories[data.id];
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  previewCategories() {
    const keys = Object.keys(this.selectedCategories);

    if (keys && keys.length) {
      this.displayCategories = [];
      keys.forEach((item) => {
        this.selectedCategories[item].forEach((categ) => {
          this.displayCategories = this.displayCategories.concat(categ);
        });
      });
      this.ngxSmartModalService.getModal("confirmCategories").open();
    } else {
      let countryCodes;

      if (this.selectedCountries) {
        countryCodes = this.countries.filter((f) =>
          this.selectedCountries.find((name) => name === f.name)
        );
        countryCodes = countryCodes.map((f) => f.country_code);
      } else {
        countryCodes = [];
      }

      this.providersService
        .setCountryForCommission({ countries: countryCodes }, this.id)
        .subscribe();

      this.cancel(true);
    }
  }

  addCategories(param?) {
    const commissions = new ProvidersCommissions();
    commissions.provider_id = this.id;
    commissions.categories = [];
    const keys = Object.keys(this.selectedCategories);
    keys.forEach((item) => {
      this.selectedCategories[item].forEach((categ) => {
        commissions.categories.push({
          id: categ.id,
          commissions: "",
        });
      });
    });

    let countryCodes;

    if (this.selectedCountries) {
      countryCodes = this.countries.filter((f) =>
        this.selectedCountries.find((name) => name === f.name)
      );
      countryCodes = countryCodes.map((f) => f.country_code);
    } else {
      countryCodes = [];
    }

    const commission$ = this.providersService.updateCategories(
      commissions,
      this.id
    );
    const countries$ = this.providersService.setCountryForCommission(
      { countries: countryCodes },
      this.id
    );

    combineLatest(commission$, countries$).subscribe(
      ([comm, country]) => {
        this.edit = false;
        this.ngxSmartModalService.getModal("confirmCategories").close();

        this.getProviderDetails();
        this.getProviderCategories();
      },
      (error) => {
        if (error.error) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }

  /**
   * Remove category
   *
   * @param category
   */
  removeCategory(category) {
    this.isLoading = true;
    this.categoryService
      .removeCategory(category.id, this.provider.id)
      .subscribe(
        (res) => {
          this.categories = this.deleteCategoryFromTree(
            this.categories,
            category.id
          );
          this.isLoading = false;
        },
        (error) => {
          if (error.error) {
            this.alertService.errors(error.error.errors);
          }
          this.isLoading = false;
        }
      );
  }

  /**
   * remove deleted category from list
   *
   * @param categories
   * @param categoryId
   */
  private deleteCategoryFromTree(categories, categoryId) {
    for (const [key, category] of Object.entries(categories)) {
      if (category["id"] === categoryId) {
        delete categories[key];

        return categories;
      }

      if (category["children_count"] > 0) {
        categories[key]["children"] = this.deleteCategoryFromTree(
          categories[key]["children"],
          categoryId
        );
      }
    }

    return categories;
  }

  cancel(shouldRefresh: boolean = false): void {
    this.edit = false;

    if (shouldRefresh) {
      this.getProviderDetails();
      this.getProviderCategories();
    }
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
