import { Injectable } from "@angular/core";

@Injectable()
export class FilesHelper {
  /**
   * Download a file
   * @param doc
   */
  download(doc) {
    const link = document.createElement("a");
    link.setAttribute("target", "_blank");
    const extensionArray = (doc.file_path as string).split(".");
    link.setAttribute("href", doc.file);
    link.setAttribute(
      "download",
      doc.name + "." + extensionArray[extensionArray.length - 1]
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
