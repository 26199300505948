import { Injectable } from "@angular/core";
import { CaseData } from "@api/cases";
import { CreateCaseData } from "@api/cases";

@Injectable()
export class CaseMapperService {
  mapOne(input: any): CaseData {
    return {
      ...input,
      end_date: input.end_date ? new Date(input.end_date) : null,
      forecast_end_date: input.forecast_end_date
        ? new Date(input.forecast_end_date)
        : null,
      start_date: input.start_date ? new Date(input.start_date) : null,
      deadline: input.deadline ? new Date(input.deadline) : null,
      tooltipServices: input.services.map((item) => item.service_name),
    };
  }

  mapMany(input: any[]): CaseData[] {
    return input.map((i) => this.mapOne(i));
  }

  prepareCreate(input: CreateCaseData) {
    const services = input.services.filter((service) => service.enabled);

    return { ...input, services };
  }

  prepare(input: any) {
    return { ...input };
  }
}
