import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
  selector: "app-bulk-actions",
  templateUrl: "./bulk-actions.component.html",
})
export class BulkActionsComponent implements OnChanges {
  public availableActions = [];

  @Input() selection: SelectionModel<any>;
  @Input() actions = [];
  @Input() extraActions = [];

  @Output() selectionCleared: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @ViewChild("bulkActionRef") bulkActionRef: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setAvailableStatuses();
    this.selection.changed.subscribe((data) => {
      this.setAvailableStatuses();
    });
  }

  // Following functions are to be used:
  private inBoth(list1, list2) {
    return list1.filter((a) => false === list2.some((b) => a.id === b.id));
  }

  private setAvailableStatuses() {
    this.availableActions = Object.values(
      Object.values(this.actions)
        .reduce(
          (curr, actionList) => [...curr, ...Object.values(actionList)],
          []
        )
        .reduce((curr, action) => ({ ...curr, [action.id]: action }), {})
    );
    const selectedStatuses = this.selection.selected
      .map((item) => item.status_id)
      .filter((value, index, self) => !!value && self.indexOf(value) === index);

    for (const selectedStatus of selectedStatuses) {
      this.availableActions = this.availableActions.filter((availableAction) =>
        this.actions[selectedStatus].some(
          (action) => action.id === availableAction.id
        )
      );
    }
    this.availableActions = [
      ...(this.availableActions ? this.availableActions : []),
      ...this.extraActions,
    ];
  }

  /**
   * Clear bulk selection
   */
  clearSelection() {
    this.selection.clear();
    this.selectionCleared.emit();
  }
}
