import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "alreadySelected",
})
/**
 * used for filering expats when adding them to an order
 * filters out expacts already commited to the other
 * or the ones already selected but not commited
 */
export class AlreadySelectedPipe implements PipeTransform {
  transform(
    items: any,
    alreadySel: any,
    orderDetail: any,
    currentSelection: any
  ): any {
    if (!Array.isArray(items)) {
      return items;
    }

    const newInput = [];

    for (let i = 0; i < items.length; i++) {
      let addToArray = true;
      for (let j = 0; j < alreadySel.length; j++) {
        if (alreadySel[j].id === items[i].id) {
          addToArray = false;
        }
      }

      orderDetail.expats.forEach((expat) => {
        if (expat.expat.id === items[i].id) {
          addToArray = false;
        }
      });
      if (addToArray || items[i].id === currentSelection.id) {
        newInput.push(items[i]);
      }
    }

    return newInput;
  }
}
