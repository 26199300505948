import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { WorkflowModel } from "../../../_models/workflow";
import { WorkflowOptions } from "../../../_models/workflow-dropdown-options";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "selectionWorkflow",
})
@Injectable()
export class SelectionWorkflowPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(workflows: WorkflowModel[], id?: number): Array<WorkflowOptions> {
    const workflowOptions = new Array<WorkflowOptions>();
    workflowOptions.push({
      key: this.translateService.instant("GENERALS.WORKFLOW-BEGINNING"),
      value: 0,
      disabled: false,
    });

    for (let i = 0; i < workflows.length; i++) {
      if (workflows[i].children && workflows[i].children.length) {
        if (workflows[i].id) {
          workflowOptions.push({
            value: workflows[i].id,
            key: workflows[i].name,
            disabled: workflows[i].id === id,
            number: `${i + 1}.`,
            parent_id: workflows[i].parent_id,
          });
        }
        for (let j = 0; j < workflows[i].children.length; j++) {
          if (workflows[i].children[j].id) {
            workflowOptions.push({
              value: workflows[i].children[j].id,
              key: workflows[i].children[j].name,
              disabled: workflows[i].children[j].id === id,
              number: `${i + 1}.${j + 1}`,
              parent_id: workflows[i].children[j].parent_id,
            });
          }
        }
      } else {
        if (workflows[i].id) {
          workflowOptions.push({
            value: workflows[i].id,
            key: workflows[i].name,
            disabled: workflows[i].id === id,
            number: `${i + 1}.`,
            parent_id: workflows[i].parent_id,
          });
        }
      }
    }
    return workflowOptions;
  }
}
