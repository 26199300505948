import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

const statusClasses = {
  default: ["text-black"],
  draft: ["text-gray-dark"],
  published: ["text-green-dark"],
  missing: ["text-orange"],
};

@Directive({
  selector: "[appSecondaryStatus]",
})
export class SecondaryStatusStyleDirective implements OnChanges {
  @Input() appSecondaryStatus: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["appSecondaryStatus"]) {
      this.updateClass(
        changes["appSecondaryStatus"].currentValue,
        changes["appSecondaryStatus"].previousValue
      );
    }
  }

  private updateClass(newStatus: string, oldStatus: string): void {
    // remove styling for the old status
    if (oldStatus && statusClasses[oldStatus]) {
      statusClasses[oldStatus].forEach((status: string) => {
        this.renderer.removeClass(this.el.nativeElement, status);
      });
    }
    // add new styling
    if (newStatus && statusClasses[newStatus]) {
      statusClasses[newStatus].forEach((status: string) => {
        this.renderer.addClass(this.el.nativeElement, status);
      });
    }

    if (!statusClasses[newStatus]) {
      statusClasses.default.forEach((status: string) => {
        this.renderer.addClass(this.el.nativeElement, status);
      });
    }
  }
}
