import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { QuoteModel } from "@modules/quotes/models/quote.model";

import { startWith } from "rxjs";

@Component({
  selector: "app-quote-order-item",
  templateUrl: "./quote-order-item.component.html",
  styleUrls: ["./quote-order-item.component.scss"],
})
export class QuoteOrderItemComponent implements OnInit {
  @Input()
  quoteForm: UntypedFormGroup;

  quote: QuoteModel;

  total: number;

  ngOnInit() {
    this.quote = this.quoteForm.get("quote").value;

    this.quoteForm
      .get("quantity")
      .valueChanges.pipe(startWith(this.quote.quantity || 1))
      .subscribe((value) => {
        this.total = this.quote.unit_price * +value;
      });
  }
}
