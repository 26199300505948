import {AfterViewInit, Directive, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appIsVisible]'
})
export class IsVisibleDirective implements AfterViewInit {
  constructor(
    private vcRef: ViewContainerRef,
    private tplRef: TemplateRef<any>
  ) {}

  ngAfterViewInit() {
    const threshold = 0.2; // how much % of the element is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // run your animation code here
            this.renderContents(entry.isIntersecting);
            observer.disconnect(); // disconnect if you want to stop observing else it will rerun every time its back in view. Just make sure you disconnect in ngOnDestroy instead
          }
        });
      },
      { threshold }
    );
    observer.observe(this.vcRef.element.nativeElement.parentElement);
  }

  renderContents(isIntersecting: boolean) {
    this.vcRef.clear();

    if (isIntersecting) {
      this.vcRef.createEmbeddedView(this.tplRef);
    }
  }
}
