import { Component, OnInit, Input } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { DocumentService } from "@api/documents/services/documents.service";
import { take } from "rxjs/operators";
import { DocumentTag } from "@api/documents/models/document.model";

@Component({
  selector: "app-tag-selector",
  templateUrl: "./tag-selector.component.html",
  styleUrls: ["./tag-selector.component.scss"],
})
export class TagSelectorComponent implements OnInit {
  documentForm: UntypedFormGroup;
  tagControl: UntypedFormControl;
  public filteredTags;
  public tags;
  public isLoading = false;

  searchTerm: string = "";

  @Input() private value: DocumentTag[];

  constructor(
    private controlContainer: ControlContainer,
    private documentService: DocumentService
  ) {
    this.tagControl = new UntypedFormControl([]);
  }

  ngOnInit() {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("tags", this.tagControl);

    this.documentService
      .tags()
      .pipe(take(1))
      .subscribe((tags) => {
        this.tags = tags["result"].tags;
        this.filterTags();
        this.isLoading = false;
      });

    if (this.value) {
      const tagArray = this.value.map(function (tag) {
        return tag.name;
      });
      this.documentForm.get("tags").setValue(tagArray);
    }
  }

  onSearch(event) {
    this.searchTerm = event.term;
  }

  focusOut(event) {
    if (this.searchTerm.length) {
      const value = this.documentForm.get("tags").value;

      value.push(this.searchTerm);

      this.documentForm.get("tags").setValue(value);

      this.searchTerm = "";
    }
  }

  private filterTags() {
    this.filteredTags = this.tags.filter(
      (value) => (value.text = value.name.toLowerCase())
    );
  }
}
