<app-timeline-navigation></app-timeline-navigation>
<div class="timeline" #timelineRef>
  <div class="timeline__header">
    <app-timeline-header></app-timeline-header>
  </div>
  <app-loader-spinner-general
    *ngIf="timelineService.isTimelineLoading"
    [repeat]="3"
    [loadingType]="LoadingType.TIMELINE"
  ></app-loader-spinner-general>
  <app-timeline-sidebar
    *ngIf="!timelineService.isTimelineLoading"
  ></app-timeline-sidebar>
</div>
