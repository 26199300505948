import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { DocumentService } from "@api/documents/services/documents.service";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-third-party-selector",
  templateUrl: "./third-party-selector.component.html",
})
export class ThirdPartySelectorComponent implements OnInit {
  @Input() entityType;
  public expenseForm: UntypedFormGroup;
  public thirdPartySupplierControl: UntypedFormControl;
  public isLoading = false;
  public thirdPartySupplierList;
  private unsubscribe$: Subject<void>;
  public thirdPartySupplier: string;

  handleThirdPartySupplier(event: MatSelectChange) {
    this.thirdPartySupplier = event.source.triggerValue;
  }

  constructor(
    private controlContainer: ControlContainer,
    private documentService: DocumentService
  ) {
    this.thirdPartySupplierControl = new UntypedFormControl(
      "",
      Validators.required
    );
  }

  ngOnInit() {
    this.expenseForm = <UntypedFormGroup>this.controlContainer.control;
    this.expenseForm.setControl(
      "third_party_supplier",
      this.thirdPartySupplierControl
    );

    this.unsubscribe$ = new Subject();

    // TODO change it with thirdPartySupplier endpoint
    this.documentService
      .types(this.entityType)
      .pipe(take(1))
      .subscribe((type) => {
        this.thirdPartySupplierList = type["result"];
        this.isLoading = false;
      });
  }
}
