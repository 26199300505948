import { Action, createReducer, on } from "@ngrx/store";
import * as AccountActions from "../actions/account.actions";
import { UserData } from "../models/user.model";

export const accountFeatureKey = "account";

export interface State {
  user?: UserData;
  loaded: boolean;
}

export const initialState: State = {
  user: undefined,
  loaded: false,
};

const accountReducer = createReducer(
  initialState,

  on(AccountActions.loadAccount, (state) => state),
  on(AccountActions.loadAccountSuccess, (state, action) => ({
    user: action.user,
    loaded: true,
  })),
  on(AccountActions.updateAccountDataSuccess, (state, action) => {
    const user = Object.assign({}, state.user, action.user);
    return { ...state, user };
  }),

  on(AccountActions.loadAccountFailure, (state, action) => state),
  on(AccountActions.clear, (state, action) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return accountReducer(state, action);
}
