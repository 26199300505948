import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { Breadcrumb } from "../../../_models/breadcrumb";

@Component({
  selector: "app-portal-breadcrumbs",
  templateUrl: "./portal-breadcrumbs.component.html",
})
export class PortalBreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[] | null = null;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.breadcrumbService.breadcrumbsChanges$.subscribe((s) => {
      this.breadcrumbs = s;
    });
  }
}
