<mat-form-field appearance="outline" class="w-full" [formGroup]="caseForm">
  <mat-label> {{ "LABEL.ASSIGNMENT" | translate }} </mat-label>
  <mat-select
    [formGroup]="caseForm"
    formControlName="assignment_id"
    [placeholder]="'LABEL.ASSIGNMENT' | translate"
    #singleSelect
    (selectionChange)="handleDocumentType($event)"
    [title]="assignmentVal"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'LABEL.ASSIGNMENT' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="assignmentsSearchForm"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let assignments of filteredAssignments$ | async"
      [value]="assignments.id"
    >
      {{ assignments.home_country }} to
      {{ assignments.host_country }}
      ( {{ assignments.start_date | date: "dd/MM/yyyy" }} -
      {{ assignments.end_date | date: "dd/MM/yyyy" }} )
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors
      [control]="assignmentsSearchForm"
      #errors
    ></app-form-errors>
  </mat-error>
</mat-form-field>
