import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ExpenseData } from "@api/expenses/models/expenses.model";
import { ExpensesService } from "@api/expenses/services/expenses.service";
import { CreateExpenseComponent } from "@modules/expenses/components/create/create.component";
import { EditExpenseComponent } from "@modules/expenses/components/edit/edit.component";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, Subject } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";
import { ExpenseStatusEnum } from "@api/expenses/values/expense-status.enum";
import { ColumnDefinition } from "../../../../_interfaces/column-definition.interface";
import { ReportsService } from "@api/reports/services/reports.service";
import { DocumentService } from "@api/documents/services/documents.service";

@Component({
  selector: "app-expanses-list",
  templateUrl: "./expanses-list.component.html",
  styleUrls: ["./expanses-list.component.scss"],
})
export class ExpansesListComponent
  extends ItemsListComponent
  implements OnInit, OnDestroy
{
  @Input() entityType;
  @Input() entityDocumentType;
  @Input() entityId;
  @Input() providerId;
  @Input() shouldShowConfirmation: boolean = true;
  @Input() displayAddButton = true;
  @Input() report = false;
  protected columns = [
    {
      name: "select",
      label: "Selection",
      visible: true,
      readonly: true,
    } as ColumnDefinition,
    {
      name: "id",
      label: "GENERALS.ID",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "client_name",
      label: "EXPENSES.CLIENT-NAME",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "expat_name",
      label: "EXPENSES.EXPAT-NAME",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "service_name",
      label: "EXPENSES.SERVICE-NAME",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "task_name",
      label: "EXPENSES.TASK-NAME",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "category",
      label: "EXPENSES.COST_CATEGORY",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "title",
      label: "EXPENSES.TITLE",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "amount",
      label: "EXPENSES.AMOUNT",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "converted_amount",
      label: "EXPENSES.CONVERTED_AMOUNT",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "incurred_at",
      label: "EXPENSES.EXPENSE_DATE",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "covered_by_id",
      label: "EXPENSES.COVERED_BY",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "invoice_id",
      label: "EXPENSES.INVOICE-ID",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "invoice_date",
      label: "EXPENSES.INVOICE-DATE",
      visible: false,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "status",
      label: "EXPENSES.STATUS",
      visible: true,
      readonly: false,
    } as ColumnDefinition,
    {
      name: "actions",
      label: "Settings",
      visible: true,
      readonly: true,
    } as ColumnDefinition,
  ];

  actions = [
    [
      {
        id: 0,
        text: "STATUSES.invoice",
        action: "invoice",
        display: true,
      },
      {
        id: 1,
        text: "GENERALS.DOWNLOAD-DOCUMENT",
        action: "download",
        display: true,
      },
    ],
  ];

  private destroyed$: Subject<void> = new Subject();
  isFiltersLoaded: boolean = false;
  searchFilters = [];
  items: ExpenseData[];
  isFormattedParams = true;
  totalCost = 0;

  taskId?: number = null;

  expenses$;
  constructor(
    private expensesService: ExpensesService,
    private documentService: DocumentService,
    private reportService: ReportsService,
    private route: ActivatedRoute,
    protected dialog: MatDialog,
    private router: Router,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.watchQueryParams();
    this.route.params.subscribe((params) => {
      if (params["task"]) {
        this.taskId = +params["task"];
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.complete();
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    this.isLoading = true;
    const params = this.getParams(this.filters.page ?? page);

    if (!this.report) {
      if (this.taskId) {
        params["task_id"] = this.taskId;
      }

      this.expenses$ = this.expensesService.list(this.entityId, params);
    } else {
      this.expenses$ = this.reportService.expenses(params);
    }
    this.request_call = this.expenses$
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe({
        next: (data) => {
          this.searchFilters = data.filters;
          this.processResponse({ result: data });
          this.hideElements();
        },
        error: (error) => {
          this.items = [];
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
    if (!this.report) {
      this.request_call = this.expensesService
        .getTotalCost(this.entityId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((type) => {
          this.totalCost = type["result"].total_cost
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        });
    }
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);

    this.router.navigate([], {
      queryParams: params,
      replaceUrl: true,
      relativeTo: this.route,
    });
  }

  downloadExpense(expenseId, fileId) {
    this.expensesService
      .downloadExpense(
        this.entityDocumentType,
        this.entityId,
        fileId,
        expenseId
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        if (data.result) {
          window.open(data.result);
        }
      });
  }

  editExpense(expense) {
    const dialogRef = this.dialog.open(EditExpenseComponent, {
      data: {
        expense: expense,
        entityType: this.entityType,
        entityId: this.entityId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data && data.type === "saved") {
          this.navigateByUrl(1);
        }
        this.getItems();
      });
  }

  deleteExpense(expenseId) {
    this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: {
            text: this.translate.instant("GENERALS.DELETE"),
            customText: this.translate.instant("EXPENSES.DELETE_CONFIRM"),
          },
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result) {
          this.expensesService
            .deleteExpense(this.entityId, expenseId)
            .subscribe(() => {
              this.getItems();
            });
        }
      });
  }

  archiveExpense(expenseId) {
    this.expensesService
      .archiveExpense(this.entityId, expenseId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getItems();
      });
  }

  openDialog(): void {
    if (this.shouldShowConfirmation) {
      const confirm = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: {
            needs_confirmation: true,
            text: `add expense`,
          },
        },
      });

      confirm.afterClosed().subscribe((res) => {
        if (res) {
          this.openCreateDialog();
        }
      });

      return;
    }

    this.openCreateDialog();
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateExpenseComponent, {
      data: {
        taskId: this.taskId,
      },
    });
    dialogRef.componentInstance.entityType = this.entityType;
    dialogRef.componentInstance.entityId = this.entityId;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data && data.type === "saved") {
          this.navigateByUrl(1);
          this.getItems();
        }
      });
  }

  protected watchQueryParams() {
    this.isLoading = true;
    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      ([params, query]) => {
        const filters = { ...params, ...query };

        super.watchQueryParams(filters);
      }
    );
  }

  private hideElements() {
    /**
     * If more params are in the future, has to be added here in order for right map
     */
    const params = {
      client_id: this.route.snapshot.params.clientId,
      expat_id: this.route.snapshot.params.expatId,
    };

    const formattedParams = Object.keys(params)
      .filter((key) => params[key])
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: params[key],
        });
      }, {});

    this.isFormattedParams = Object.keys(formattedParams).length !== 0;

    this.searchFilters = this.searchFilters.filter((item) => {
      return Object.entries(formattedParams).every(([k]) => item.id !== k);
    });
  }

  public openInputInvoiceAction(expense?: ExpenseData) {
    let ids = [];

    if (expense) {
      ids = [expense.id];
    } else {
      this.selection.selected.forEach((row) =>
        row.status !== ExpenseStatusEnum.INVOICED ? ids.push(row.id) : ids
      );
    }

    if (ids.length < 1) {
      this.alertService.stringError(
        this.translate.instant("EXPENSES.INVOICE-VALIDATION-ERROR")
      );
      return;
    }

    let idText = "";
    ids.forEach((id) => (idText += " #" + id));

    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          customTitle: "Invoice",
          customText:
            (ids.length > 1
              ? this.translate.instant("EXPENSES.CONFIRM-INVOICE-IDS")
              : this.translate.instant("EXPENSES.CONFIRM-INVOICE-ID")) +
            "(" +
            idText +
            " )",
          input: {
            label: this.translate.instant("LABEL.INVOICE-ID"),
          },
          date: {
            label: this.translate.instant("LABEL.INVOICE-DATE"),
          },
        },
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result !== undefined) {
          if (!this.entityType) {
            this.entityType = "cases";
            this.entityId = "report";
          }
          this.isLoading = true;
          this.expensesService
            .updateStatus(this.entityType, this.entityId, {
              ids: ids,
              invoice_id: result?.input ?? null,
              invoice_date: result?.date ?? null,
            })
            .pipe(takeUntil(this.destroyed$), take(1))
            .subscribe({
              next: (data) => {
                this.alertService.success(data.message);
                this.getItems();
              },
              error: (error) => {
                if (error?.error?.errors) {
                  this.alertService.errors(error.error.errors);
                }
              },
            });
        }
      });
  }

  downloadZipFile() {
    const ids = [];
    this.selection.selected.forEach((row) =>
      row.file.id ? ids.push(row.file.id) : null
    );

    this.documentService.downloadDocument(ids).subscribe((data: any) => {
      if (data.result) {
        window.open(data.result);
      }
    });
  }

  bulkAction(event) {
    switch (event.action) {
      case "download":
        this.downloadZipFile();
        break;
      case "invoice":
        this.openInputInvoiceAction();
        break;
    }
  }

  protected getService() {
    return this.expensesService;
  }

  get ExpenseStatus() {
    return ExpenseStatusEnum;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
