import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthorizationModule } from "@modules/authorization/authorization.module";
import { SharedModule } from "@modules/shared/shared.module";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ExpatEmploymentDetailsCardComponent } from "@modules/expats/expats-shared/components/expat-employment-details-card/expat-employment-details-card.component";
import { ExpatNationalitiesCardComponent } from "@modules/expats/expats-shared/components/expat-nationalities-card/expat-nationalities-card.component";
import { ExpatPersonalInformationCardComponent } from "@modules/expats/expats-shared/components/expat-personal-information-card/expat-personal-information-card.component";
import { ExpatDetailsComponent } from "@modules/expats/expats-shared/components/expat-details/expat-details.component";
import { ExpatsListComponent } from "@modules/expats/expats-shared/components/expats-list/expats-list.component";
import { ContactsModule } from "@modules/contacts/contacts.module";
import { CasesSharedModule } from "@modules/cases/cases-shared/cases-shared.module";
import { DeleteExpatDialogComponent } from "./components/delete-expat-dialog/delete-expat-dialog.component";
import { RouterModule } from "@angular/router";
import { ExpatButtonBarComponent } from "./components/expat-details/expat-button-bar/expat-button-bar.component";
import { DependentOptionsComponent } from "./components/dependent-options/dependent-options.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    ExpatDetailsComponent,
    ExpatEmploymentDetailsCardComponent,
    ExpatNationalitiesCardComponent,
    ExpatPersonalInformationCardComponent,
    ExpatsListComponent,
    DeleteExpatDialogComponent,
    ExpatButtonBarComponent,
    DependentOptionsComponent,
  ],
  exports: [
    ExpatDetailsComponent,
    ExpatEmploymentDetailsCardComponent,
    ExpatNationalitiesCardComponent,
    ExpatPersonalInformationCardComponent,
    ExpatsListComponent,
    DependentOptionsComponent,
  ],
  imports: [
    CommonModule,
    ContactsModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    CasesSharedModule,
    MatProgressSpinnerModule,
    AuthorizationModule,
  ],
})
export class ExpatsSharedModule {}
