import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { passwordStrengthValidator } from "@modules/shared/_validators/password-strength-validator";
import { confirmPasswordValidator } from "@modules/shared/_validators/confirm-password-validator";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "src/app/_services/alert.service";
import { UsersService } from "src/app/_services/users.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ChangePasswordDialogComponentInput {
  title?: string;
}

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.scss"],
})
export class ChangePasswordDialogComponent implements OnInit {
  form: FormGroup;
  hide: boolean = true;
  hide_confirmed_pass: boolean = true;
  hideError: boolean = false;
  title?: string = null;

  @Output() changePass: EventEmitter<any> = new EventEmitter();
  @Output() inputModel: EventEmitter<any> = new EventEmitter();

  disabled = false;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private alertService: AlertService,
    private translate: TranslateService,
    private matDialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: ChangePasswordDialogComponentInput
  ) {}

  ngOnInit() {
    this.title = this.data?.title;

    this.setupForm();
  }

  setupForm(): void {
    this.form = this.fb.group({
      current_password: ["", Validators.required],
      password: [
        "",
        [
          passwordStrengthValidator(),
          confirmPasswordValidator("password_confirmation", true),
        ],
      ],
      password_confirmation: [
        "",
        [Validators.required, confirmPasswordValidator("password")],
      ],
    });
  }

  submit() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    const data = this.form.getRawValue();
    this.userService.updateCurrentPassword(data).subscribe({
      next: (data) => {
        this.matDialogRef.close();
        this.translate.get("GENERALS.PASS-SAVE-SUCCESS").subscribe((trans) => {
          this.alertService.success(trans);
        });
      },
      error: (error) => {
        this.alertService.errors(error.error.errors);
      },
    });
  }
}
