import { AssignmentStatusEnum } from "../enums/assignment-status.enum";
import { Injectable } from "@angular/core";
import { AssignmentData } from "@api/assignments/models/assignment.model";

@Injectable()
export class AssignmentHelper {
  public isEditable(assignment: AssignmentData): boolean {
    return (
      assignment.status === AssignmentStatusEnum.PENDING ||
      assignment.status === AssignmentStatusEnum.IN_PROGRESS ||
      assignment.status === AssignmentStatusEnum.APPROVED
    );
  }

  public onlyDatesAreEditable(assignment: AssignmentData): boolean {
    return (
      assignment.status === AssignmentStatusEnum.APPROVED ||
      assignment.status === AssignmentStatusEnum.IN_PROGRESS
    );
  }

  public onlyEndDateIsEditable(assignment: AssignmentData): boolean {
    return assignment.status === AssignmentStatusEnum.IN_PROGRESS;
  }
}
