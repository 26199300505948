import { Component } from "@angular/core";
import { TimelineService } from "@modules/timeline/services/timeline.service";

@Component({
  selector: "app-timeline-header",
  templateUrl: "./timeline-header.component.html",
  styleUrls: ["./timeline-header.component.scss"],
})
export class TimelineHeaderComponent {
  constructor(public timelineService: TimelineService) {}
}
