import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { NgxSmartModalService } from "ngx-smart-modal";
import { AlertService } from "src/app/_services/alert.service";
import { DocumentsService } from "src/app/_services/documents.service";

@Component({
  selector: "confirm-invoice-payment",
  templateUrl: "./confirm-invoice-payment.component.html",
  styleUrls: ["./confirm-invoice-payment.component.scss"],
})
export class ConfirmInvoicePaymentComponent {
  file_id = null;
  @Input() set fileId(value) {
    if (value) {
      this.file_id = value;
      this.paymentConfirmationObj.file_id = value;
    }
  }

  @Output() confirmPaymentEvent: EventEmitter<any> = new EventEmitter();

  setModalOpen = true;
  isCanceled = false;
  isLoading = false;
  paymentMethods = [
    { id: "card", text: "Card" },
    { id: "cash", text: "Cash" },
    { id: "bank_transfer", text: "Bank Transfer" },
    { id: "cheque", text: "Cheque" },
  ];
  paymentConfirmationObj = {
    file_id: this.file_id,
    payment_method: null,
    transaction_id: null,
    payment_date: this.getDateString(new Date()),
  };

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    protected service: DocumentsService,
    private alertService: AlertService
  ) {
    this.setModalOpen = false;
  }

  isModalOpen() {
    this.setModalOpen = true;
    this.isCanceled = false;
  }

  isModalClose() {
    this.setModalOpen = false;
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("confPay_" + this.file_id).close();
    this.isCanceled = true;
  }

  setInvoicePaymentDate(event) {
    this.paymentConfirmationObj.payment_date = this.getDateString(event);
  }

  getDateString(event) {
    const d = new Date(event);
    return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  }

  confirmPayment() {
    this.isLoading = true;
    this.service.confirmPayment(this.paymentConfirmationObj).subscribe(
      (res) => {
        if (res.message) {
          this.alertService.success(res.message);
        }
        this.confirmPaymentEvent.emit();
        this.isLoading = false;

        this.cancelModal();
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
