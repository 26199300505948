<div>
  <div class="modal-title-wrapper">
    <h5 class="modal-title">
      {{ "DASHBOARD.TERMS_AND_CONDITIONS_TITLE" | translate }}
    </h5>
  </div>
  <div
    mat-dialog-content
    class="custom-modal-body col-lg-12 mx-0 px-0 benefit-modal"
  >
    <div class="pdf-viewer">
      <pdf-viewer
        [src]="pdfSrc"
        [render-text]="true"
        [original-size]="true"
        style="width: 100%; height: 350px"
      ></pdf-viewer>
    </div>

    <div class="form-group w-100 fs-16 confirmation-text">
      {{ "DOCUMENTS.CONFIRMATION-TXT" | translate }}
    </div>
  </div>

  <div mat-dialog-actions class="modal-footer mt-3 me-4">
    <button
      type="button"
      class="btn gray-bg"
      role="button"
      (click)="dialogRef.close()"
    >
      {{ "DASHBOARD.CLOSE" | translate }}
    </button>
    <button
      [disabled]="isLoading"
      type="button"
      class="btn orange-bg"
      role="button"
      (click)="accept()"
    >
      {{ "DASHBOARD.SIGN" | translate }}
    </button>
  </div>
</div>
