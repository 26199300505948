import { finalize, take } from "rxjs";
import { Component, OnInit } from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { ItemsListComponent } from "@modules/shared/_components/items-list/items-list.component";
import {
  TemplateService,
  TemplateFieldsMapperService,
  TemplateElement,
  TemplateElementsSections,
} from "@api/templates";
import { MatSelectChange } from "@angular/material/select";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-template-sidebar-fields",
  templateUrl: "./template-sidebar-fields.component.html",
  styleUrls: ["./template-sidebar-fields.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class TemplateSidebarFieldsComponent
  extends ItemsListComponent
  implements OnInit
{
  dataSource: TemplateElementsSections[];
  columnsToDisplay = [];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, "expand"];
  expandedElement: TemplateElementsSections | null;

  expandAllTabs = false;

  constructor(
    public templateService: TemplateService,
    private templateFieldsMapper: TemplateFieldsMapperService
  ) {
    super();
  }

  ngOnInit() {
    this.getElementsSections();

    this.templateService.listenForAnnotationsChanges.subscribe(
      (item: TemplateElement) => {
        const styles = item.styles;
        if (styles) {
          const formatting = this.dataSource.find(
            (el) => el.slug === "formatting"
          );

          if (formatting) {
            if (!styles["font-size"]) {
              styles["font-size"] = "12px";
            }

            formatting.sizes[0] = styles["font-size"].match(/\d+/);
          }
        }
      }
    );
  }

  public getElementsSections() {
    this.isLoading = true;

    this.templateService
      .listElementsSections()
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res: TemplateElementsSections[]) => {
          this.dataSource = this.templateFieldsMapper.mapMany(res);
        },
        error: (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  changeFieldDetection(ev: any, field: TemplateElementsSections): void {
    if (field.placeholder !== "Search fields") {
      this.expandAllTabs = false;
      return;
    }

    const keyword = ev.target.value;

    this.expandAllTabs = !!keyword;

    this.templateFieldsMapper.searchTemplateElements(this.dataSource, keyword);
  }

  setElementStyle(
    event: MatSelectChange,
    fieldElement: TemplateElementsSections
  ): void {
    const element = this.templateService.annotations.find(
      (item) => item.selected
    );

    if (!element) {
      return;
    }

    const styles = element.styles;

    element.styles = {};
    element.styles.left = `${styles.left}px`;
    element.styles.top = `${styles.top}px`;

    switch (fieldElement.slug) {
      case "formatting": {
        element.styles = Object.assign(
          {},
          { "font-size": `${event.value}px` },
          element.styles
        );
      }
    }

    this.templateService.updateElement(element).subscribe(() => {
      this.templateService.listenForAnnotationsChanges.next(true);
    });
  }

  setupFieldElement(element: TemplateElementsSections): void {
    this.templateService.createElement(element).subscribe((item: any) => {
      this.templateService.annotations.push(item.element);

      this.templateService.listenForAnnotationsChanges.next(true);
    });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
