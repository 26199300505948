import { Component, Input } from "@angular/core";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import { UserDetails } from "src/app/_models/user-details";

@Component({
  selector: "app-user-details-page",
  templateUrl: "./user-details.component.html",
})
export class UserDetailsComponent {
  @Input() set entity(value) {
    if (value) {
      switch (value.type) {
        case "expat":
          this.type = "expat";
          this.setExpatDetails(value.data);
          break;
        case "client":
          console.warn("Client");
          break;
      }
    }
  }
  @Input() readonly;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private expatService: ExpatsService
  ) {
    this.user = new UserDetails();
  }
  isLoading;
  user;
  type;
  x = false;

  setExpatDetails(data) {
    this.user.created_at = data.created_at;
    this.user.name = data.expat.user_name;
    this.user.status_id = data.expat.status_id;
    this.user.status_name = data.expat.status_name;
    this.user.details = [];
    let arr = [
      {
        value: data.expat.company_position,
        label: "LABEL.POSITION-IN-COMPANY",
      },
      { value: data.expat.department_name, label: "LABEL.DEPARTMENT" },
      {
        value: data.expat.country_code.toUpperCase(),
        label: "LABEL.HOME_COUNTRY",
      },
    ];

    if (data.user) {
      arr.push({ value: data.user.email, label: "LABEL.EMAIL" });
      arr.push({
        value: data.user.phone_prefix + " " + data.user.phone,
        label: "LABEL.PHONE-NUMBER",
      });
    }

    this.user.details.push({
      title: "PORTAL.ACCOUNT-CONTACT-INFO",
      value: arr,
    });

    const obj = {
      "PORTAL.ADULTS": data.expat.adults_number,
      "PORTAL.CHILDREN": data.expat.children_number,
    };
    arr = [];
    arr = Object.keys(obj).map((k) => ({ label: k, value: obj[k] }));

    this.user.details.push({
      title: "PORTAL.ACCOMPANYING-FAMILY",
      value: arr,
    });
  }

  cancelModal() {
    this.ngxSmartModalService.getModal("userDetails").close();
  }
}
