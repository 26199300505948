export class CitizenshipModel {
  public id?: number;
  public expat_id?: number;
  public country?: string;
  public status_id?: StatusType;
  public status_name?: string;
  public obtained_date?: string;
  public revoked_date?: string;
  public is_primary?: boolean;
  public isCreated?: boolean;
}

export class CitizenshipUpdateModel {
  public status_id?: number;
  public country?: string;
  public obtained_date?: string;
  public revoked_date?: string;
  public is_primary?: boolean;
}

export class CitizenshipCreateModel {
  public status_id: StatusType;
  public country: string;
  public obtained_date: string;
  public revoked_date: string;
}

export enum StatusType {
  ACTIVE = 3,
  INACTIVE = 7,
}
