import { Directive, ElementRef, Input, Optional } from "@angular/core";
import { AbstractControl, ControlContainer, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { merge } from "rxjs";

@Directive({
  selector: "[formControlError]",
})
export class FormErrorDirective {
  @Input()
  formControlError: string | AbstractControl;

  control: AbstractControl;

  public supportedRuleMessage = {
    required: "VALIDATIONS.REQUIRED",
    fileExtension: "VALIDATIONS.FILE_EXTENSION",
    fileTypeExtension: "VALIDATIONS.FILE_EXTENSION",
    fileSizeExtension: "VALIDATIONS.FILE_MAXIMUM_SIZE",
    max: "VALIDATIONS.MAX_NUMBER",
    min: "VALIDATIONS.MIN_NUMBER",
    maxlength: "VALIDATIONS.MAX_LENGTH",
  };

  public get message() {
    return this.el.nativeElement.innerText;
  }
  public set message(value) {
    this.el.nativeElement.innerText = value;
  }

  constructor(
    private translateService: TranslateService,
    @Optional()
    private controlContainer: ControlContainer,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (this.formControlError instanceof AbstractControl) {
      this.control = this.formControlError;
    } else {
      const group = <FormGroup>this.controlContainer?.control;
      this.control = group.get(this.formControlError);
    }
    if (!this.control) {
      console.log("no control!");
      return;
    }
    merge([this.control.statusChanges, this.control.dirty]).subscribe(
      (data) => {
        try {
          this.updateMessage();
        } catch (e) {
          /* empty */
        }
      }
    );
    // contentChildren is set
  }

  updateMessage() {
    try {
      const errors = { ...this.control.errors };
      if (!errors) {
        this.message = "";
      }
      const keys = Object.keys(errors);
      const key = keys.find((k) => !!this.supportedRuleMessage[k]);
      if (!key) {
        this.message = "";
      }
      const message = this.supportedRuleMessage[key];
      this.translateService
        .get(message, errors[key])
        .subscribe((translated) => {
          this.message = translated;
        });
    } catch (e) {
      this.message = "";
    }
  }
}
