<div *ngFor="let follower of followers" class="follower">
  <div class="image">
    <img [src]="follower.avatar" alt="img" />
  </div>
  <div class="name flex-1">
    <div>
      {{ follower.user_name }}
      <ng-container *ngIf="follower.roles?.length">
        - {{ follower.roles.join(", ") }}</ng-container
      >
    </div>
  </div>
  <div class="" *ngIf="resourceId">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="removeFollower(follower)"
        [disabled]="!follower.allowed_actions?.delete"
      >
        {{ "LABEL.DELETE" | translate }}
      </button>
    </mat-menu>
  </div>
</div>
