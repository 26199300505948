export class Reports {
  links: Array<any>;

  constructor(_id: number, filters) {
    this.links = [
      {
        link: "/reports/financial",
        filters: filters,
        label: "Financial",
        page: "financial",
      },
      {
        link: "/reports/case",
        filters: filters,
        label: "Case",
        page: "case",
      },
      {
        link: "/reports/custom",
        filters: filters,
        label: "Custom",
        page: "custom",
      },
    ];
  }
}
