import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Plan } from "@api/billing/models/plan.model";

@Component({
  selector: "app-plan-card",
  templateUrl: "./plan-card.component.html",
  styleUrls: ["./plan-card.component.scss"],
})
export class PlanCardComponent {
  @Output() purchasePlan: EventEmitter<string> = new EventEmitter<string>();

  @Input() plan: Plan;

  @Input() current: boolean;

  purchase(planId: number | string) {
    this.purchasePlan.emit(`${planId}`);
  }
}
