<app-dialog-header> {{ "LABEL.CREATE_CASE" | translate }}</app-dialog-header>
<div class="modal-body-custom p-20">
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>{{ "LABEL.CASE-TYPE" | translate }}</mat-label>
    <mat-select [formControl]="caseTypeFormControl">
      <mat-option
        [value]="item.value"
        *ngFor="let item of this.caseTypeOptions"
      >
        {{ item.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-expat-case-form
    *ngIf="caseTypeFormControl.value === caseType.EXPAT"
    (initialized)="onFormInitialized($event)"
    [data]="inputData"
  ></app-expat-case-form>
  <app-admin-case-form
    *ngIf="caseTypeFormControl.value === caseType.ADMIN"
    (initialized)="onFormInitialized($event)"
    [data]="inputData"
  ></app-admin-case-form>
  <app-document-case-form
    *ngIf="caseTypeFormControl.value === caseType.DOCUMENT"
    (initialized)="onFormInitialized($event)"
    [data]="inputData"
  ></app-document-case-form>
</div>
<div class="clear"></div>

<div class="dialog-footer">
  <button class="btn-primary" (click)="submit()" [disabled]="isLoading">
    <mat-icon svgIcon="save"></mat-icon>
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>
