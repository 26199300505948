import * as moment from "moment";
import { Injectable } from "@angular/core";

@Injectable()
export class CalendarHelper {
  tomorrow() {
    return moment().add(1, "days").toDate();
  }

  isExpired(date: string) {
    return moment(date).isBefore(moment());
  }
}
