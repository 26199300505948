import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { SharedModule } from "@modules/shared/shared.module";
import { CreateComponent } from "./create/create.component";
import { RemindersListComponent } from "./reminders-list/reminders-list.component";
import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
@NgModule({
  declarations: [RemindersListComponent, CreateComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonToggleModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
  ],
  providers: [],
  exports: [RemindersListComponent],
})
export class RemindersSharedModule {}
