<div class="category-tree-group" *ngFor="let item of categories | keyvalue">
  <div toggle-row class="toggle-row-action" *ngIf="item.value.children_count">
    <i class="icon-plus-1"></i>
  </div>
  <div
    class="category-tree-row"
    *ngIf="
      !(
        item.value.provider_commissions &&
        item.value.provider_commissions.length
      ) && item.value.children_count
    "
  >
    <span
      class="category-title"
      [ngClass]="{ 'fw-600': item.value.level === 0 }"
      >{{ item.value.label }}</span
    >
    <span class="fs-12">
      <span
        class="orange m-0"
        *ngIf="item.value.commission_type.id.toString() !== '22'"
        >{{ item.value.commission | currency: "EUR" }}</span
      >
      <span
        class="orange m-0"
        *ngIf="item.value.commission_type.id.toString() === '22'"
        >{{ item.value.commission }} %</span
      >
      {{ "GENERALS.COMMISSION" | translate }}</span
    >
    <span *ngIf="item.value.data" class="gray fs-12">
      ({{ item.value.data }})</span
    >
    <span
      class="set-commission-btn fl-r"
      *ngIf="
        auth.$can(
          permissionActions.UPDATE,
          permissionResources.PROVIDER,
          permissionSecondaryResources.FINANCIAL
        )
      "
    >
      <span class="action-link-orange" (click)="removeCategory(item.value)">{{
        "GENERALS.REMOVE" | translate
      }}</span>
    </span>
  </div>
  <div
    class="category-tree-row"
    id="readonly-periods"
    *ngIf="item.value.children_count === 0"
  >
    <div
      class="category-title"
      [ngClass]="{
        'fw-600': item.value.level === 0,
        'leaf-category-title': item.value.children_count === 0
      }"
    >
      <span>{{ item.value.label }}</span>
      <span class="set-commission-btn fl-r">
        <ng-container
          *ngIf="
            auth.$can(
              permissionActions.UPDATE,
              permissionResources.PROVIDER,
              permissionSecondaryResources.FINANCIAL
            )
          "
        >
          <span
            class="action-link-orange"
            (click)="removeCategory(item.value)"
            >{{ "GENERALS.REMOVE" | translate }}</span
          >
          <span class="gray"> | </span>
        </ng-container>
        <span class="action-link-orange" (click)="downloadTemplate(item.value)">
          {{ "GENERALS.DOWNLOAD-TEMPLATE" | translate }}</span
        >
      </span>
    </div>
    <ng-container
      *ngIf="
        item.value.provider_commissions &&
        item.value.provider_commissions.length
      "
    >
      <div class="tree-commissions-row-wrapper">
        <div
          class="tree-commissions-row"
          *ngFor="
            let commission of item.value.provider_commissions
              | sortDate: 'start_date'
          "
        >
          <div class="fl-l">
            <span
              class="fw-500 m-0"
              *ngIf="commission.commission_type.id.toString() !== '22'"
              >{{ commission.commission | currency: "EUR" }}</span
            >
            <span
              class="fw-500 m-0"
              *ngIf="commission.commission_type.id.toString() === '22'"
              >{{ commission.commission }} %</span
            >
            {{ "GENERALS.COMMISSION" | translate }}
          </div>

          <div class="fl-l">
            {{ "GENERALS.FROM" | translate }}
            <span class="m-0 fw-500">
              {{ commission.start_date | date: "dd/MM/yyyy" }}
            </span>
            <ng-container *ngIf="commission.start_date !== commission.end_date">
              {{ "GENERALS.TO" | translate }}
              <span class="m-0 fw-500">{{
                commission.end_date | date: "dd/MM/yyyy"
              }}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="toggle-tree" *ngIf="item.value.children_count">
    <app-categories-tree
      [categories]="item.value.children"
      (onchange)="onchange.emit($event)"
    ></app-categories-tree>
  </div>
</div>
