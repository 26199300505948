import { Pipe, PipeTransform } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { from, map, Observable, of, switchMap, take } from "rxjs";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { ValueList } from "../models/value-list.model";

/**
 * @usageNotes
 * Needs to be used with async pipe always it returns an observable holding the country name
 * example:
 * * `{{ string | country | async }}`
 */
@Pipe({
  name: "country",
})
export class CountryPipe implements PipeTransform {
  private readonly COUNTRIES_KEY = "countries";

  constructor(
    private storage: Storage,
    private dictionaryService: DictionariesService
  ) {}

  transform(value: string, ...args: unknown[]): Observable<string> {
    if (!value) {
      return of(value);
    }
    return this.getCountries().pipe(
      map((country) => (country[value] ? country[value] : value)),
      map((name: string) => {
        return name
          .split(" ")
          .map(
            (name) =>
              name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase()
          )
          .join(" ");
      })
    );
  }

  getCountries(): Observable<ValueList> {
    return from(this.storage.get(this.COUNTRIES_KEY)).pipe(
      switchMap((countries) => {
        if (countries) {
          return of(countries);
        }

        return this.saveCountries();
      })
    );
  }

  saveCountries(): Observable<ValueList> {
    return this.dictionaryService.getCountryList().pipe(
      map((data) => data.result),
      take(1),
      switchMap((countries) => {
        const countriesObj = {};
        countries.forEach(
          (country) => (countriesObj[country.country_code] = country.name)
        );

        return this.storage.set(this.COUNTRIES_KEY, countriesObj);
      })
    );
  }
}
