import { Component, OnInit } from "@angular/core";
import { ItemsListComponent } from "../../../../modules/shared/_components/items-list/items-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "src/app/_services/alert.service";
import { InvoicesService } from "../../../../_services/invoices.service";
import { saveAs } from "file-saver";
import { WindowResizeHelperService } from "src/app/_services/window-resize-helper.service";
import { BreadcrumbService } from "../../../../_services/breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { StripeService } from "../../../../_services/stripe.service";
import { Invoice } from "src/app/_models/invoice";
import moment from "moment";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { finalize } from "rxjs";

@Component({
  selector: "app-invoices-list",
  templateUrl: "./invoices-list.component.html",
})
export class InvoicesListComponent
  extends ItemsListComponent
  implements OnInit
{
  isFiltersLoaded: boolean = false;
  request_call;
  active = "";
  isLoading = false;
  isXs: boolean;
  constructor(
    private service: InvoicesService,
    private route: ActivatedRoute,
    private router: Router,
    protected dialog: MatDialog,
    protected alertService: AlertService,
    private windowResizeHelper: WindowResizeHelperService,
    private breadcrumbService: BreadcrumbService,
    private stripeService: StripeService
  ) {
    super();
    this.breadcrumbService.changeBreadcrumbs(
      this.breadcrumbService.setForList("/invoices/list", "Invoices")
    );
    this.route.queryParams.subscribe((query) => {
      this.isLoading = true;
      this.watchQueryParams(query);
    });

    this.showBulkActions = false;
  }

  ngOnInit() {
    this.windowResizeHelper.getData().subscribe((data) => {
      this.isXs = data.responsiveStatus;
    });
  }

  navigateByUrl(page = 1) {
    const params = this.getParams(page);
    this.router.navigate([], {
      queryParams: params,
      replaceUrl: true,
      relativeTo: this.route,
    });
  }

  public getItems(page: number = 1) {
    if (this.request_call) {
      this.request_call.unsubscribe();
    }

    let params: any = {};
    params = { ...this.filters };
    this.request_call = this.service
      .getInvoicesList(params)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.isFiltersLoaded = true;
        })
      )
      .subscribe(
        (data) => {
          this.processResponse(data);
          this.updatePayment();
        },
        (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  public exportCsv(invoice: Invoice) {
    const issuedOn = moment(invoice.issued_on).format("DD-MM-Y");

    this.service.exportCsv(invoice.id).subscribe(
      (data) => {
        const blob = new Blob([data], { type: "text/csv" });
        saveAs(
          blob,
          "Invoice_" + invoice.id + "_xpath_global_" + issuedOn + ".csv"
        );
      },
      (error) => {
        if (error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
        return false;
      }
    );
  }

  public exportPdf(invoice: Invoice) {
    const issuedOn = moment(invoice.issued_on).format("DD-MM-Y");

    this.service.exportPdf(invoice.id).subscribe(
      (data) => {
        const blob = new Blob([data], { type: "text/csv" });
        saveAs(
          blob,
          "Invoice_" + invoice.id + "_xpath_global_" + issuedOn + ".pdf"
        );
      },
      (error) => {
        if (error.error && error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
        this.isLoading = false;
        return false;
      }
    );
  }

  updatePayment() {
    const sessionId = this.stripeService.getStripeSessionId();
    if (sessionId) {
      this.stripeService.confirmPayment(sessionId).subscribe((data) => {
        this.items.forEach((item) => {
          if (item.id == data.result.invoice_id) {
            item.status = data.result.payment_status;
            item.status_id = data.result.status_id;
            item.transaction_id = data.result.transaction_id;
            item.paid_on = data.result.payment_date;
            item.payment_method = data.result.payment_method.toUpperCase();
          }
        });
      });
    }
  }

  public setFilters(e) {
    this.defaultFilters = {
      ...this.defaultFilters,
      ...e,
    };
    return this.cleanFilters(this.defaultFilters);
  }

  public cleanFilters(obj) {
    for (const propName in obj) {
      if (obj[propName] === null) {
        delete obj[propName];
      }
    }
    return obj;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
