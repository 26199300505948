<mat-form-field appearance="outline" class="w-100" [formGroup]="form">
  <mat-label> {{ "LABEL.CURRENCY" | translate }}</mat-label>
  <mat-select
    formControlName="{{ useCode ? 'currency_code' : 'currency_id' }}"
    [placeholder]="'GENERALS.SELECT-CURRENCY' | translate"
  >
    <mat-option> </mat-option>
    <mat-option
      *ngFor="let currency of currencies"
      [value]="useCode ? currency.code : currency.id"
    >
      {{ currency.code }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors
      [control]="useCode ? form.get('currency_code') : form.get('currency_id')"
    >
    </app-form-errors>
  </mat-error>
</mat-form-field>
