<div class="details-body-wrapper expat-details" *ngIf="user && expat">
  <!-- 2 col details -->
  <div class="container-fluid">
    <app-expat-button-bar
      [expat]="expat"
      (expatInvited)="handleEvent('newProfilePicture')"
    ></app-expat-button-bar>

    <div class="row">
      <div class="d-flex upper-section">
        <div class="profile-wrapper m-r-15">
          <app-details-action-panel
            *ngIf="user"
            [changeStatusPermission]="changeStatusPermission"
            [user]="expat"
            [entity_type]="entity_type"
            (actionChange)="handleEvent($event)"
          >
          </app-details-action-panel>
        </div>
        <div class="expat-details h-100">
          <div class="white-box-wrapper h-100">
            <div
              class="white-box-row"
              *ngFor="
                let info of user.details;
                let i = index;
                let first = first
              "
            >
              <div class="white-box-header">
                <div class="medium-title fl-l">
                  {{ info.title | translate }}
                </div>
                <button
                  *ngIf="!readonly && i === 0"
                  class="white-box-header-action fl-r edit-info border-white transparent-bg p-0 btn"
                  (click)="handleEvent('edit')"
                >
                  <mat-icon svgIcon="edit" class="button-icon"></mat-icon>
                  {{ "GENERALS.EDIT" | translate }}
                </button>
              </div>
              <div class="clear"></div>
              <div class="white-box-body row">
                <div class="w-50">
                  <span class="m-b-10 d-flex">
                    {{ "EXPATS.EMAIL" | translate }}
                  </span>
                  <p *ngIf="first" class="case-label">
                    <a href="mailto:{{ user.email }}">
                      <i class="icon-mail"></i>
                      {{ user.email }}
                    </a>
                  </p>
                </div>
                <div class="w-50">
                  <span class="m-b-10 d-flex">
                    {{ "EXPATS.PHONE-NUMBER" | translate }}
                  </span>
                  <p *ngIf="first && user.phone" class="case-label">
                    <i class="icon-phone"></i> {{ user.phone_prefix }}
                    {{ user.phone }}
                  </p>
                </div>
                <ng-container *ngFor="let item of info.value">
                  <div class="w-50">
                    <span class="m-b-10 d-flex">
                      {{ item.label | translate }}
                    </span>
                    <p class="case-label">
                      <ng-container>
                        {{ item.value | notAvailable }}
                      </ng-container>
                    </p>
                  </div>
                </ng-container>

                <div class="w-50">
                  <span class="m-b-10 d-flex">
                    {{ "LABEL.RESIDENCE-COUNTRY" | translate }}
                  </span>
                  <p class="case-label">
                    {{ expat.country_code | country | async | notAvailable }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="expat">
      <ng-template #loading>
        <div class="w-100">
          <app-loader-spinner-general class="w-50"></app-loader-spinner-general>
          <app-loader-spinner-general class="w-50"></app-loader-spinner-general>
        </div>
        <div class="w-100">
          <app-loader-spinner-general class="w-50"></app-loader-spinner-general>
          <app-loader-spinner-general class="w-50"></app-loader-spinner-general>
        </div>
      </ng-template>
      <ng-container *ngIf="countries$ | async as countries; else loading">
        <div class="row">
          <div class="col-lg-6 col-12 m-t-24">
            <app-expat-personal-information-card
              [expat]="expat"
              [countries]="countries?.result"
              (getNewExpat)="onGettingNewExpat()"
            >
            </app-expat-personal-information-card>

            <app-expat-employment-details-card
              [expat]="expat"
              (getNewExpat)="onGettingNewExpat()"
            >
            </app-expat-employment-details-card>
          </div>
          <div class="col-lg-6 col-12 m-t-24">
            <app-expat-nationalities-card
              *ngIf="countries"
              [countries]="countries.result"
              [expat]="expat"
            >
            </app-expat-nationalities-card>
            <app-contact-list
              *canView="canViewContacts"
              [contactable]="expat"
              [resource]="'expat'"
            ></app-contact-list>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
