import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ModelData } from "@modules/shared/models/model";
import { EditDialogComponent } from "../components/edit-dialog/edit-dialog.component";
import { ContactData } from "../models/contact.model";

@Injectable({
  providedIn: "root",
})
export class ContactDialogService {
  constructor(private dialog: MatDialog) {}

  private openDialog(): MatDialogRef<EditDialogComponent> {
    return this.dialog.open(EditDialogComponent);
  }

  openUpdate(contactable: ModelData, contact: ContactData) {
    const ref = this.openDialog();
    ref.componentInstance.contact = contact;
    ref.componentInstance.contactable = contactable;
    return ref;
  }

  openCreate(contactable: ModelData) {
    const ref = this.openDialog();
    ref.componentInstance.contactable = contactable;
    return ref;
  }
}
