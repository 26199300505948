<div [formGroup]="dependentForm" class="flex items-center gap-4">
  <mat-checkbox color="primary" class="!w-1/2" formControlName="enabled">
    {{ "LABEL.CASE_FOR_DEPENDENT" | translate }}
  </mat-checkbox>
  <mat-form-field appearance="outline" class="!w-1/2">
    <mat-label> {{ "LABEL.DEPENDENT" | translate }} </mat-label>
    <mat-select
      formControlName="dependent"
      [placeholder]="'LABEL.DEPENDENT' | translate"
      #singleSelect
    >
      <mat-option>
        <ngx-mat-select-search
          [placeholderLabel]="'LABEL.DEPENDENT' | translate"
          [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          [formControl]="dependentSearchForm"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let dependent of filteredDependents$ | async"
        [value]="dependent"
      >
        {{ dependent.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
