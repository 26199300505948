import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { Expat } from "src/app/_models/expat";
import { AlertService } from "src/app/_services/alert.service";

@Component({
  templateUrl: "./delete-expat-dialog.component.html",
})
export class DeleteExpatDialogComponent implements OnInit {
  expat: Expat;

  deleteExpatForm = new FormGroup({
    reason: new FormControl("", Validators.required),
    confirm_token: new FormControl("", Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { expat: Expat },
    private readonly dialogRef: MatDialogRef<ConfirmActionModalComponent>,
    private readonly alertService: AlertService,
    private readonly expatService: ExpatsService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.expat = this.data.expat;
    if (!this.expat) {
      this.dialogRef.close();
    }
    this.deleteExpatForm.controls.confirm_token.addValidators(
      (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.toLowerCase() === this.data.expat.name.toLowerCase()
          ? null
          : { required: true }
    );
  }

  confirm() {
    this.expatService
      .deleteExpat({
        expatId: this.data.expat.id,
        reason: this.deleteExpatForm.controls.reason.value,
      })
      .subscribe({
        next: () => {
          this.dialogRef.close({ success: true });
        },
        error: () => {
          this.alertService.errors(
            this.translateService.instant("GENERALS.GENERIC_ERROR")
          );
        },
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
