import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ExpensesService } from "@api/expenses/services/expenses.service";
import { ExpensesMapperService } from "@api/expenses/services/expenses-mapper.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ExpensesService, ExpensesMapperService, DatePipe],
})
export class ExpensesModule {}
