<mat-form-field appearance="outline" class="w-100" [formGroup]="documentForm">
  <mat-label>{{ "LABEL.DOCS_NAME" | translate }} </mat-label>
  <input matInput [formControl]="nameControl" />
  <!--    <mat-error *ngIf="form.controls.first_name.errors?.required">-->
  <!--        {{'GENERALS.REQUIRED-FIELD' | translate}}-->
  <!--    </mat-error>-->
  <mat-error>
    <app-form-errors [control]="documentForm.get('name')"> </app-form-errors>
  </mat-error>
</mat-form-field>
