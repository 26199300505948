import { Component, OnInit, OnDestroy } from "@angular/core";
import { WindowResizeHelperService } from "./_services/window-resize-helper.service";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Title } from "@angular/platform-browser";
import { GoogleAnalyticsService } from "./_services/google-analytics.service";
import { SvgRegistryService } from "./_services/svg-registry.service";
import { QueryParamsService } from "./_services/query-params.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  innerWidth;
  xs;
  constructor(
    private windowResizeHelper: WindowResizeHelperService,
    private router: Router,
    private titleService: Title,
    private googleAnalyticsService: GoogleAnalyticsService,
    private svgRegistryService: SvgRegistryService,
    private queryParams: QueryParamsService
  ) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }

    this.windowResizeHelper.sendData(this.xs);
    this.svgRegistryService.init();
  }

  ngOnInit() {
    this.googleAnalyticsService.subscribe();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route) => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
        let primaryRoute = route["url"].split("/")[1];
        primaryRoute = primaryRoute.split("?")[0];
        primaryRoute =
          "Provider " +
          primaryRoute.charAt(0).toUpperCase() +
          primaryRoute.slice(1) +
          " | xpath.global";

        this.titleService.setTitle(primaryRoute);
      });

    this.windowResizeHelper.sendData(this.xs);
  }

  ngOnDestroy() {
    // unsubscribe to the gtag
    this.googleAnalyticsService.unsubscribe();
  }
  onResize() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 992) {
      this.xs = true;
    } else {
      this.xs = false;
    }

    this.windowResizeHelper.sendData(this.xs);
  }
}
