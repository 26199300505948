export class CurrentUserStatus {
  public userStatusId: number;
  public entityStatusId: number;
  public entityStatus: string;

  public emailConfirmed: boolean;
  public isUserActive: boolean;
  public isEntityActive: boolean;
  public isActive: boolean;

  constructor() {
    this.emailConfirmed = true;
  }

  setData(data) {
    this.userStatusId = data.user_status_id;
    this.entityStatusId = data.entity_status_id;
    this.entityStatus = data.entity_status;
    this.emailConfirmed = data.email_user_confirmed;

    this.isCurrentUserActive();
  }

  isCurrentUserActive() {
    this.isUserActive = this.userStatusId === 3;
    this.isEntityActive = this.entityStatusId === 3;
    this.isActive =
      this.isUserActive && this.isEntityActive && this.emailConfirmed;
  }
}
