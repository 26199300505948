import { Component, ViewEncapsulation } from "@angular/core";
import { DocumentService } from "@api/documents/services/documents.service";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { FileUploadControl, ValidatorFn } from "@iplab/ngx-file-upload";
import { finalize, last } from "rxjs/operators";
import { AlertService } from "src/app/_services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { fileValidator } from "@modules/shared/_validators/file-extension.validator";
import moment from "moment";
import { RemindersService } from "@api/reminders/services/reminders.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { DocumentActionsEnum } from "@api/documents/enums/document-actions.enum";

@Component({
  selector: "app-create",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateComponent {
  documentForm: UntypedFormGroup;
  file: FileReader;
  isLoading = false;
  public fileUploadControl = new FileUploadControl();
  isExtracted = false;

  entityType: string;
  entityId: string;
  reqBody = null;

  docStatusControl = new UntypedFormControl(DocumentActionsEnum.ACTIVATE);

  constructor(
    private documentService: DocumentService,
    private alertService: AlertService,
    protected dialogRef: MatDialogRef<CreateComponent>,
    public translate: TranslateService,
    public remindersService: RemindersService
  ) {
    this.documentForm = new UntypedFormGroup(
      {
        files: new UntypedFormControl(
          [],
          [Validators.required, fileValidator()]
        ),
        status_id: this.docStatusControl,
      },
      { validators: reminderValidator }
    );

    this.documentForm.setControl("expiration_date", new UntypedFormControl(""));

    this.documentForm.get("files").valueChanges.subscribe((files) => {
      if (this.documentForm.get("files").hasError("fileSizeExtension")) {
        this.alertService.error([
          this.translate.instant("VALIDATIONS.FILE_MAXIMUM_SIZE_MESSAGE"),
        ]);
      }
      if (this.documentForm.get("files").hasError("fileTypeExtension")) {
        this.alertService.error([
          this.translate.instant("VALIDATIONS.FILE_EXTENSION_TYPE"),
        ]);
      }

      this.isExtracted = false;

      if (files.length < 1) {
        this.file = null;
        return;
      }

      this.file = files[0].name;

      this.documentForm.get("name").setValue(files[0]?.name ?? "");
    });
  }

  submit() {
    this.documentForm.markAllAsTouched();
    if (!this.documentForm.valid) {
      return;
    }
    const { reminder, ...data } = this.documentForm.getRawValue();

    this.isLoading = true;

    this.documentService
      .create(
        this.dialogRef.componentInstance.entityType,
        this.dialogRef.componentInstance.entityId,
        data,
        this.dialogRef.componentInstance.reqBody
      )
      .pipe(
        last(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((documentData) => {
        if (reminder) {
          this.setReminder(documentData, reminder);
        }

        this.dialogRef.close({
          type: "saved",
          documentData,
        });
      });
  }

  setReminder(document, reminderPeriod) {
    const body = {
      subject_type: "doc",
      subject_id: document.id,
      subscribers: [{ id: document.created_by.id }],
      remind_at: moment(document.expiration_date)
        .subtract(reminderPeriod, "months")
        .hour(12)
        .toDate()
        .toISOString(),
      text: `The document ${document.name} (${document.document_type.text}) for ${document.created_by.text} will expire on ${document.expiration_date}`,
      date: moment(document.expiration_date)
        .subtract(reminderPeriod, "months")
        .toDate()
        .toISOString(),
      visibility: "public",
    };

    this.remindersService.create(body).subscribe(() => {
      this.dialogRef.close({
        type: "saved",
        document,
      });
    });
  }

  close() {
    this.dialogRef.close();
  }

  private changeFormFieldsStatement(disableFields?: boolean): void {
    Object.keys(this.documentForm.controls).forEach((key) => {
      if (key !== "files" && key !== "tag") {
        if (disableFields) {
          this.documentForm.get(key).disable();
        } else {
          this.documentForm.get(key).enable();
        }
      }
    });
  }

  public extractData(): void {
    this.isLoading = true;

    this.changeFormFieldsStatement(true);

    this.documentService
      .uploadDocumentData(this.documentForm.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.isExtracted = true;

          const fields = data.result.fields;

          this.documentForm.get("document_type_id").setValue(1);
          let familyName = "";

          Object.keys(fields).forEach((index: string) => {
            if (fields[index].name === "document_id") {
              this.documentForm.get("number").setValue(fields[index].value);
            }
            if (fields[index].name === "issue_date") {
              const currentDate = fields[index].value;
              currentDate.replace(" ", "-");
              this.documentForm
                .get("expiration_date")
                .setValue(new Date(currentDate));
            }
            if (
              fields[index].name === "family_name" ||
              fields[index].name === "given_names"
            ) {
              familyName += ` ${fields[index].value}`;
            }
          });

          if (familyName) {
            this.documentForm.get("name").setValue(familyName.trimStart());
          }

          this.changeFormFieldsStatement(false);
        },
        (error) => {
          if (error?.error?.errors) {
            this.alertService.errors(error.error.errors);
          }
        }
      );
  }

  onDateChange(date: any): void {
    if (moment(date).isValid()) {
      this.documentForm.get("expiration_date").setValue(moment(date));
    }
  }

  changeDocStatus(value) {
    this.docStatusControl.setValue(parseInt(value));
  }

  get DocumentType() {
    return DocumentActionsEnum;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}

export const reminderValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const reminderPeriod = control.get("reminder")?.value;
  let expirationDate: moment.Moment = control.get("expiration_date")?.value;

  if (typeof expirationDate !== typeof moment) {
    expirationDate = moment(expirationDate);
  }

  if (reminderPeriod && expirationDate) {
    return reminderPeriod &&
      expirationDate &&
      expirationDate.clone().subtract(reminderPeriod, "months").diff(moment()) <
        0
      ? { invalidReminderPeriod: true }
      : null;
  }

  return null;
};
