import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import phone from "phone";

/** A hero's name can't match the given regular expression */
export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = control.value;
    if (!value) {
      return null;
    }
    if (value.charAt(0) !== "+") {
      value = `+${value}`;
    }
    const invalid = !phone(value, { country: null }).isValid;
    return invalid ? { phone: true } : null;
  };
}
