import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, timeout } from "rxjs/operators";
import { AuthActions } from "../actions";
import { AuthSelectors } from "../selectors";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(public store: Store, public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.store.select(AuthSelectors.selectAuthState).pipe(
      filter(({ loaded }) => loaded),
      timeout(3000),
      map(({ token }) => {
        if (!token) {
          throw Error("Token is not present!");
        }
        return true;
      }),
      catchError((e) => {
        this.router.navigate([], { queryParams: { returnUrl: state.url } });
        this.store.dispatch(AuthActions.unauthenticated());
        return of(false);
      })
    );
  }
}
