import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import * as moment from "moment";
import { Observable } from "rxjs";
import { CardType } from "src/app/_enums/report-card-type-enum";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";
import { FinancialReportChartService } from "src/app/_services/financial-report-chart.service";
import { UsersService } from "src/app/_services/users.service";
import { ReportsService } from "@api/reports/services/reports.service";

type ActivePeriodFilter = "date" | "select";

@Component({
  selector: "app-report-card",
  templateUrl: "./report-card.component.html",
  styleUrls: ["./report-card.component.scss"],
})
export class ReportCardComponent implements OnInit {
  @Input()
  cardType: string;

  @Output() triggerLoading: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dictionariesService: DictionariesService,
    private reportsService: ReportsService,
    private chartService: FinancialReportChartService,
    private userService: UsersService,
    private alertService: AlertService
  ) {
    this.setStartEndDates();
  }

  countrySearchControl: FormControl = new FormControl("");

  public isLoading;

  private dataReq: Observable<any>;

  public serviceRevenueFilters = [
    {
      name: "All services",
      value: "all",
    },
    {
      name: "Relocation",
      value: "relocation",
    },
    {
      name: "Immigration",
      value: "immigration",
    },
    {
      name: "Taxation",
      value: "taxation",
    },
    {
      name: "Moving",
      value: "moving",
    },
  ];

  public serviceCostsFilters = [
    {
      name: "Couriers Fees",
      value: "couriers_fees",
    },
    {
      name: "Governmental Fees",
      value: "governmental_fees",
    },
    {
      name: "Other Costs",
      value: "other_costs",
    },
    {
      name: "Translations And Legalisation",
      value: "translations_and_legalisations",
    },
    {
      name: "All Costs",
      value: "all_costs",
    },
  ];

  public data = [];
  public serviceCategories = [];
  public costs = [];
  public countries: [];
  public filteredCountries;
  public orderedCountries: { countries: string[]; costs: number[] } = {
    countries: [],
    costs: [],
  };
  public axisFormating: any = (value) => {
    return value;
  };

  public showChart = false;

  private entityId: number;

  public dateRange;

  startDate;
  endDate;

  selectedPeriod: number = 3;
  selectedServiceFilter?: string = "all";
  selectedCountry?: string = "";

  activePeriodFilter: ActivePeriodFilter = "select";

  public config: any = {};

  public periods = [
    {
      value: 3,
      name: "Last 3 months",
    },
    {
      value: 6,
      name: "Last 6 months",
    },
    {
      value: 9,
      name: "Last 9 months",
    },
    {
      value: 12,
      name: "Last 12 months",
    },
  ];

  onCountryChange(event) {
    this.selectedCountry = event.value;
    this.fetchData();
  }

  onServiceTypeChange(event) {
    this.selectedServiceFilter = event.value;
    this.fetchData();
  }

  setStartEndDates() {
    if (this.activePeriodFilter === "select") {
      const subtract = this.selectedPeriod - 1;
      this.startDate = moment().subtract(subtract, "months");
      this.endDate = moment();
      this.setDateRange();
    }
  }

  onCalendarChange(event: {
    start_date: moment.Moment;
    end_date: moment.Moment;
  }) {
    if (moment.isMoment(event.start_date) && moment.isMoment(event.end_date)) {
      this.activePeriodFilter = "date";
      this.startDate = moment(event.start_date);
      this.endDate = moment(event.end_date);

      this.selectedPeriod = Math.ceil(
        this.endDate.diff(this.startDate, "months", true)
      );

      this.setDateRange();

      this.fetchData();
    }
  }

  setDateRange() {
    this.dateRange = {
      start_date: this.startDate,
      end_date: this.endDate,
    };
  }

  get maxDate() {
    return new Date();
  }

  onPeriodChange(event) {
    this.activePeriodFilter = "select";
    this.selectedPeriod = event.value;
    this.setStartEndDates();
    this.fetchData();
  }

  fetchData() {
    this.triggerLoading.emit(true);

    switch (this.cardType) {
      case CardType.REVENUE:
        this.dataReq = this.reportsService.revenue({
          entity_type: "provider",
          entity_id: this.entityId,
          country_code: this.selectedCountry,
          start_date: this.startDate.toISOString(),
          end_date: this.endDate.toISOString(),
        });
        break;
      case CardType.COSTS:
        this.dataReq = this.reportsService.monthlyCosts({
          entity_id: this.entityId,
          costs_date_range: `${this.startDate.toISOString()};${this.endDate.toISOString()}`,
        });
        break;
      case CardType.PROFIT:
        this.dataReq = this.reportsService.montlyProfit({
          profits_date_range: `${this.startDate.toISOString()};${this.endDate.toISOString()}`,
        });
        break;
    }

    this.dataReq.subscribe({
      next: (res) => {
        const { config, costs, serviceCategories, data, hasValues } =
          this.chartService.getData(
            res,
            this.startDate,
            this.endDate,
            this.selectedServiceFilter,
            this.cardType
          );

        this.orderedCountries.countries = config.yAxisTicks.map(
          (tick) => tick.country_code
        );

        this.orderedCountries.costs = config.yAxisTicks.map(
          (tick) => tick.costs
        );

        this.axisFormating = (value) => {
          const countryIndex = this.orderedCountries.costs.findIndex(
            (cost) => cost === value
          );

          return this.selectedCountry === ""
            ? `
                ${this.orderedCountries.countries[countryIndex]} (${
                value >= 1000
                  ? `${Math.round(value / 1000)} k`
                  : `${Math.round(value)}`
              })
              `
            : `
                ${
                  value >= 1000
                    ? `${Math.round(value / 1000)} k`
                    : `${Math.round(value)}`
                }
              `;
        };

        this.config = config;
        this.costs = costs;
        this.serviceCategories = serviceCategories;
        this.data = data;
        this.showChart = hasValues;
      },
      error: (error) => {
        if (error.message) {
          this.alertService.stringError(error.message);
        }
      },
      complete: () => {
        this.triggerLoading.emit(false);
      },
    });
  }

  ngOnInit() {
    this.dictionariesService.getCountryList().subscribe((res) => {
      this.countries = res.result;
      this.filteredCountries = this.countries;
    });

    this.userService.getCurrentUser().subscribe({
      next: (res) => {
        this.entityId = res.entity_id;

        this.fetchData();
      },
      error: () => {
        this.triggerLoading.emit(false);
      },
    });

    this.countrySearchControl.valueChanges.subscribe((value) => {
      if (!value) {
        this.filteredCountries = this.countries;

        return;
      }

      this.filteredCountries = this.countries.filter((country: any) =>
        country.name.toLowerCase().includes(value.toLowerCase())
      );
    });
  }

  clearFilters(): void {
    this.selectedPeriod = 3;
    this.selectedServiceFilter = "all";
    this.selectedCountry = "";
    this.activePeriodFilter = "select";
    this.setStartEndDates();

    this.fetchData();
  }

  get CardType() {
    return CardType;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
