import { Component, OnInit, Input } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-number-selector",
  templateUrl: "./number-selector.component.html",
})
export class NumberSelectorComponent implements OnInit {
  documentForm: UntypedFormGroup;
  numberControl: UntypedFormControl;

  @Input() private value: string;

  constructor(private controlContainer: ControlContainer) {
    this.numberControl = new UntypedFormControl("", Validators.min(1));
  }

  ngOnInit() {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("number", this.numberControl);

    if (this.value) {
      this.documentForm.get("number").setValue(this.value);
    }
  }
}
