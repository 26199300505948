import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
  selector: "app-chips",
  templateUrl: "./chips.component.html",
  styleUrls: ["./chips.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChipsComponent,
    },
  ],
})
export class ChipsComponent implements ControlValueAccessor {
  @Input() topLabel;
  @Input() formGroup;
  @Input() itemsList;
  @Input() placeholder;
  @Output() selectedItems: EventEmitter<any> = new EventEmitter();

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipsCtrl = new UntypedFormControl("");
  filteredItems: Observable<string[]>;
  items: string[] = [];
  touched = false;

  disabled = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (items) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};
  @ViewChild("chipInput", { static: true })
  chipInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  constructor(private eRef: ElementRef) {
    this.filteredItems = this.chipsCtrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) =>
        item ? this._filter(item) : this.itemsList.slice()
      )
    );
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (
      !this.eRef.nativeElement.contains(event.target) &&
      this.chipsCtrl.value &&
      this.chipsCtrl.value.length
    ) {
      this.items.push(this.chipsCtrl.value.trim());
      this.selectedItems.emit(this.items);
      this.onChange(this.items);
      this.chipInput.nativeElement.value = "";
    }
  }

  writeValue(obj: any): void {
    this.items = obj;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  addOnBlur(event: Event) {
    const target: HTMLElement = event.target as HTMLElement;

    if (!target || target.tagName !== "MAT-OPTION") {
      const event: MatChipInputEvent = {
        input: this.chipInput.nativeElement,
        value: this.chipInput.nativeElement.value,
      } as MatChipInputEvent;

      this.items.push(event.value.trim());
      this.selectedItems.emit(this.items);
      this.onChange(this.items);
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  add(event: MatChipInputEvent | any): void {
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event

    if (!this.matAutocomplete.isOpen || event.value) {
      const input = event.input;
      const value = event.value;

      // Add our tag
      if ((value || "").trim()) {
        this.items.push(value.trim());
        this.selectedItems.emit(this.items);
        this.onChange(this.items);
      }

      // Reset the input value
      if (input) {
        input.value = "";
      }

      this.chipsCtrl.setValue(null);
      this.chipInput.nativeElement.value = "";
    }
  }

  remove(item: string): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.items.push(event.option.viewValue);
    this.selectedItems.emit(this.items);
    this.onChange(this.items);
    this.chipInput.nativeElement.value = "";
    this.chipInput.nativeElement.blur();
    this.chipsCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.itemsList.filter(
      (item) => item.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
