import { Component } from "@angular/core";
import { TimelineService } from "@modules/timeline/services/timeline.service";

@Component({
  selector: "app-timeline-navigation",
  templateUrl: "./timeline-navigation.component.html",
  styleUrls: ["./timeline-navigation.component.scss"],
})
export class TimelineNavigationComponent {
  constructor(public timelineService: TimelineService) {}
}
