import { Component, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import moment from "moment";
import { reminderValidator } from "../../create/create.component";

@Component({
  selector: "app-set-reminder",
  templateUrl: "./set-reminder.component.html",
  styleUrls: ["./set-reminder.component.scss"],
})
export class SetReminder implements OnInit {
  documentForm: UntypedFormGroup;
  reminderControl: UntypedFormControl;

  setReminder: boolean = false;
  enabled: boolean = false;

  options = [
    {
      value: 1,
      label: "1 month",
    },
    {
      value: 3,
      label: "3 months",
    },
    {
      value: 6,
      label: "6 months",
    },
  ];

  constructor(private controlContainer: ControlContainer) {
    this.reminderControl = new UntypedFormControl({
      value: "",
      disabled: true,
    });
  }

  ngOnInit() {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("reminder", this.reminderControl);

    if (this.documentForm.get("expiration_date").value) {
      this.enabled = true;
    }

    this.documentForm
      .get("expiration_date")
      .valueChanges.subscribe((value: moment.Moment) => {
        if (value) {
          this.enabled = true;
        }
      });
  }

  handleSetReminder(value) {
    if (!value) {
      this.reminderControl.clearValidators();
      this.reminderControl.disable();
      this.reminderControl.setValue(null);
      this.documentForm.removeValidators(reminderValidator);
      this.documentForm.updateValueAndValidity();

      return;
    }

    this.reminderControl.addValidators(Validators.required);
    this.reminderControl.enable();
    this.documentForm.addValidators(reminderValidator);
    this.documentForm.updateValueAndValidity();
  }
}
