<app-dialog-header>
  {{ "MILESTONES.MILESTONE-REPORTS-CONFIG" | translate }}
</app-dialog-header>
<app-loader-spinner-general
  *ngIf="isLoaded"
  [loadingType]="LoadingType.INFO"
></app-loader-spinner-general>
<app-loader-spinner-general
  *ngIf="isLoaded"
  class="flex justify-end"
  [loadingType]="LoadingType.BUTTONS"
  [repeat]="2"
></app-loader-spinner-general>
<div class="custom-modal-wrapper create-assignment" *ngIf="!isLoaded">
  <div
    class="notification_row bg_light_blue flex justify-between items-center py-1 mb-10"
  >
    <div class="flex items-center py-2 px-3">
      <mat-icon class="notification_icon" svgIcon="info"></mat-icon>

      <div class="notification_text">
        {{ "MILESTONES.CONFIGURATION-INFO" | translate }}
      </div>
    </div>
  </div>

  <div class="flex w-full">
    <div class="w-1/2 py-0 px-3 show-estimate">
      <mat-slide-toggle
        [matTooltip]="'MILESTONES.ESTIMATED-END-DATE-HOVER' | translate"
        [formControl]="showDatesCtrl"
        matTooltipPosition="right"
      >
        {{ "MILESTONES.ESTIMATED-END-DATE" | translate }}
      </mat-slide-toggle>
    </div>
    <div class="w-1/2">
      <button
        [disabled]="!this.checklistSelection.hasValue()"
        mat-dialog-title
        class="clear-filters {{
          !this.checklistSelection.hasValue() ? 'disable_clear_filter' : ''
        }}"
        (click)="clearFilters()"
      >
        <mat-icon>close</mat-icon>
        {{ "LABEL.CLEAR-ALL-COLUMNS" | translate }}
      </button>
    </div>
  </div>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node
      class="node-border w-1/3 fl-l"
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      matTreeNodePadding
    >
      <button mat-icon-button disabled></button>
      <mat-checkbox
        class="checklist-leaf-node"
        [checked]="checklistSelection.isSelected(node)"
        (change)="checklistSelection.toggle(node)"
      >
        <div class="checkbox-label" [title]="node.label">
          {{ node.label.split("ID")[0] | titlecase }}
          {{ node.label.indexOf("ID") > -1 ? "ID" : ("" | uppercase) }}
        </div>
      </mat-checkbox>
    </mat-tree-node>
    <mat-tree-node
      class="tree-border w-full"
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'toggle ' + node.filename"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button>
      <mat-progress-bar
        *ngIf="node.isLoading"
        mode="indeterminate"
        class="example-tree-progress-bar"
      ></mat-progress-bar>
      <mat-checkbox
        [checked]="descendantsAllSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="itemSelectionToggle(node)"
        >{{ node.label | uppercase }}
      </mat-checkbox>
    </mat-tree-node>
  </mat-tree>

  <div class="flex w-full" *ngIf="saveButton">
    <mat-form-field appearance="outline">
      <mat-label> {{ "LABEL.NAME" | translate }}</mat-label>
      <input matInput [formControl]="reportNameCtrl" type="text" />
      <mat-error [formControlError]="reportNameCtrl"></mat-error>
    </mat-form-field>
  </div>
  <div class="clear"></div>
</div>

<div class="dialog-footer">
  <button
    class="btn-primary"
    *ngIf="saveButton"
    (click)="generateReport(true)"
    [disabled]="isLoaded"
  >
    <mat-icon svgIcon="save"></mat-icon>
    {{ "REPORTS.SAVE-CONFIGURATION" | translate }}
  </button>
  <button class="btn-primary" (click)="generateReport()" [disabled]="isLoaded">
    <mat-icon svgIcon="save"></mat-icon>
    {{ "REPORTS.GENERATE-REPORT" | translate }}
  </button>
</div>
