import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";

@Directive()
@Injectable()
export class RFQSService {
  private rfqsURL = "rfqs";

  rfq;

  @Output() statusChange: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {}

  /**
   * Get number of RFQs the provider hasn't responded to
   */
  getPendingCount(id): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "rfqs/providers/" + id + "/pending/count",
      { params: { "low-priority": "low-priority" } }
    );
  }
}
