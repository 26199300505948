<mat-form-field appearance="outline" class="w-full" [formGroup]="caseForm">
  <mat-label> {{ "LABEL.ORDER" | translate }} </mat-label>
  <mat-select
    [compareWith]="compareFn"
    [formControl]="orderControl"
    [placeholder]="'LABEL.ORDER' | translate"
    #singleSelect
    (openedChange)="errors.updateMessage()"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="'LABEL.ORDER' | translate"
        [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
        [formControl]="orderSearchForm"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="initialValue" [value]="initialValue">
      {{ initialValue.identity_number }}
    </mat-option>
    <ng-template #loading>
      <mat-option disabled>
        <div class="flex justify-center w-full">
          <mat-progress-spinner
            diameter="16"
            mode="indeterminate"
          ></mat-progress-spinner>
        </div>
      </mat-option>
    </ng-template>
    <ng-container *ngIf="filteredOrders$ | async as orders">
      <mat-option *ngFor="let order of orders" [value]="order">
        {{ order.identity_number }}
      </mat-option>
    </ng-container>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="orderControl" #errors></app-form-errors>
  </mat-error>
</mat-form-field>
