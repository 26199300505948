import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CasesComponent } from "./components/cases/cases.component";
import { CustomComponent } from "./components/custom/custom.component";
import { FinancialComponent } from "./components/financial/financial.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { MilestonesReportListComponent } from "@modules/reports/components/custom/milestones/list/milestones-report-list.component";

const routes: Routes = [
  {
    path: "",
    component: ReportsComponent,
    children: [
      {
        path: "financial",
        component: FinancialComponent,
      },
      {
        path: "case",
        component: CasesComponent,
      },
      {
        path: "custom",
        component: CustomComponent,
      },
      {
        path: "milestone/:id",
        component: MilestonesReportListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
