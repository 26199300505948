import { Component, Input } from "@angular/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";

@Component({
  selector: "app-loader-spinner-general",
  templateUrl: "loader-spinner-general.component.html",
  styleUrls: ["loader-spinner-general.component.scss"],
})
export class LoaderSpinnerGeneralComponent {
  @Input() repeat: number = 1;
  @Input() loadingType: LoadingTypeEnum = LoadingTypeEnum.INFO;

  get LoadingTypeEnum() {
    return LoadingTypeEnum;
  }

  public numberToArray(i: number): number[] {
    return Array(i)
      .fill(0)
      .map((x, i) => i);
  }
}
