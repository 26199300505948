import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { LoggerEntityEnum } from "../_enums/logger-entity-enum";
import { DictionaryResponse } from "../_models/dictionary";
import { shareReplay } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

const CACHE_SIZE = 1;

@Injectable()
export class DictionariesService {
  private departmentsUrl = "entities/enum/departments";
  private companyTypesUrl = "entities/enum/company_types";
  private professionalAssociationUrl = "entities/enum/professional_association";
  private employeesNumberUrl = "entities/enum/number_of_employees";
  private industriesListUrl = "entities/enum/industries";
  private cache$: Observable<any>;

  constructor(private http: HttpClient) {}

  // GET Departments
  getDepartments(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.departmentsUrl
    );
  }

  // GET Company Types
  getCompanyTypes(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.companyTypesUrl
    );
  }

  // GET Industries list
  getProfessionalAssociation(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.professionalAssociationUrl
    );
  }

  // GET Industries list
  getEmployeesNumber(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.employeesNumberUrl
    );
  }

  // GET Offer Statuses
  getOfferStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "offers/statuses");
  }

  // GET Entity Statuses
  getEntityStatuses(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/enum/entity_statuses"
    );
  }

  getCountryList() {
    if (!this.cache$) {
      this.cache$ = this.requestCountries().pipe(shareReplay(CACHE_SIZE));
    }

    return this.cache$;
  }

  private requestCountries(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "countries");
  }

  /**
   * List of company sizes by number of employees
   */
  getCompanySizes(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "entities/enum/number_of_employees"
    );
  }

  getOrderStatuses(): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "orders/statuses");
  }

  getUserRoles(type): Observable<any> {
    return this.http.get(environment.gateway_endpoint + type + "/roles");
  }

  getCurrencies(): Observable<any> {
    return this.http.get(environment.gateway_endpoint + "entities/currencies");
  }

  getNotificationEntities() {
    const logger = LoggerEntityEnum;
    return [
      { id: logger.adBanner, text: "Ad banner" },
      { id: logger.adBannerType, text: "Ad banner type" },
      { id: logger.client, text: "Client" },
      { id: logger.expat, text: "Expat" },
      { id: logger.file, text: "File" },
      { id: logger.offer, text: "Offer" },
      { id: logger.order, text: "Order" },
      { id: logger.orderDetail, text: "Order details" },
      { id: logger.provider, text: "Provider" },
      { id: logger.rfq, text: "RFQs and Bids" },
      { id: logger.user, text: "User" },
    ];
  }

  intCountryPhonePhone(code) {
    const numberPattern = /\d+/g;
    return code.match(numberPattern).join("");
  }

  getPhonePrefix(code) {
    if (!code) {
      return null;
    }
    return "(+" + this.intCountryPhonePhone(code) + ")";
  }

  /**
   * Get cities
   */
  public cities(
    search: string,
    countryCode: string
  ): Observable<DictionaryResponse> {
    return this.http.get<DictionaryResponse>(
      environment.gateway_endpoint + "cities",
      { params: { q: search, country_code: countryCode } }
    );
  }

  // GET Industries list
  getIndustriesList(): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + this.industriesListUrl,
      { params: { "low-priority": "low-priority" } }
    );
  }

  /**
   * List of all cities in a country-selector
   */
  getCities(country, search = "") {
    return this.http.get<any>(environment.gateway_endpoint + "cities", {
      params: {
        q: search,
        country_code: country,
      },
    });
  }
}
