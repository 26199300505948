import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanViewDirective } from './directives/can-view.directive';



@NgModule({
  declarations: [CanViewDirective],
  exports: [
    CanViewDirective
  ],
  imports: [
    CommonModule
  ]
})
export class AuthorizationModule { }
