<!-- error validation (has close option) -->

<div [ngClass]="{ 'alert-wrapper': true, 'open-errors': isErrValidation }">
  <ng-container *ngIf="messagesType === 'error'">
    <div *ngIf="messages">
      <div *ngFor="let message of messages" class="error-description">
        {{ message }}
      </div>
      <div class="center-helper">
        <button (click)="closeError()" class="btn-small orange-bg">
          {{ "GENERALS.CLOSE" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<!-- end error validation (has close option) -->

<!-- notification (open when succes, for X sec) -->

<div
  class="notification-wrapper"
  [ngClass]="[
    isSuccNotif ? 'notificatio-open' : '',
    isSuccNotif ? 'succes-notification' : ''
  ]"
>
  <ng-container *ngIf="messagesType === 'success'">
    <div class="notification-body">
      {{ messages }}
    </div>
  </ng-container>
</div>

<!-- end notification (open when succes, for X sec) -->

<!--  string error validation (has close option) -->

<div
  [ngClass]="{ 'notification-error-wrapper': true, 'open-errors': isErrNotif }"
>
  <ng-container *ngIf="messagesType === 'string-error'">
    <div *ngIf="messages">
      <div class="error-description" [innerHTML]="messages"></div>
      <div class="center-helper">
        <button (click)="closeErrorNotification()" class="btn-small orange-bg">
          {{ "GENERALS.CLOSE" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<!-- end string error validation (has close option) -->
