<mat-option>
  <ngx-mat-select-search
    [placeholderLabel]="'LABEL.COUNTRY' | translate"
    [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
    [formControl]="searchControl"
  ></ngx-mat-select-search>
</mat-option>
<mat-option *ngIf="actualOption" [value]="actualOption.country_code">
  {{ actualOption.name }}
</mat-option>
<mat-option
  *ngFor="let country of countries$ | async"
  [value]="country.country_code"
>
  {{ country.name }}
</mat-option>
