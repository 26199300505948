<div windowScrollDashboardBodyTop class="dashboard-body-top">
  <!-- left -->
  <div class="float-left big-title">
    {{ "LEFT-NAVIGATION.EXPATS" | translate }}
  </div>
  <!-- end left -->

  <!-- right -->
  <div class="button-bar">
    <button class="!btn-primary" (click)="openDialog()">
      <mat-icon svgIcon="plus-circle" class="btn-icon"></mat-icon>
      {{ "GENERALS.ADD-EXPATS" | translate }}
    </button>
  </div>
  <!-- end right -->
</div>

<!-- search and filters -->
<div class="dashboard-body-action" *ngIf="isFiltersLoaded">
  <!-- left -->
  <div class="float-left advanced-search-wrapper w-full p-0">
    <app-filters
      [availableFilters]="availableFilters"
      [activeFilters]="filters"
      (search)="textChange($event)"
      (filter)="filters = $event; navigateByUrl()"
      [total]="total"
      searchPlaceholder="LEFT-NAVIGATION.EXPATS"
      (changePerPage)="perPage = $event; navigateByUrl()"
      (eventClearFilters)="clearFilters(); navigateByUrl()"
    ></app-filters>
  </div>

  <!-- end left -->
</div>
<!-- search and filters -->

<app-loader-spinner-general
  *ngIf="!isFiltersLoaded"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>

<!-- table -->
<div class="details-body-wrapper expats-list">
  <div class="table-wrapper">
    <ng-container *ngIf="!isMobile; else responsiveTable">
      <div class="table-responsive">
        <table
          matSort
          (matSortChange)="sortData($event)"
          [matSortActive]="active"
          [matSortDirection]="direction"
        >
          <thead>
            <tr>
              <ng-container matColumnDef="select">
                <th class="w-1">
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [disableRipple]="true"
                  >
                  </mat-checkbox>
                </th>
              </ng-container>
              <th mat-sort-header="id" class="text-right border-r w-1">
                {{ "GENERALS.ID" | translate }}
              </th>
              <th mat-sort-header="name">
                {{ "LABEL.NAME" | translate }}
              </th>
              <th mat-sort-header="email">{{ "LABEL.EMAIL" | translate }}</th>
              <th mat-sort-header="department_name">
                {{ "LABEL.DEPARTMENT" | translate }}
              </th>
              <th mat-sort-header="citizenship">
                {{ "LABEL.PRIMARY-CITIZENSHIP" | translate }}
              </th>
              <th mat-sort-header="country_of_residence">
                {{ "LABEL.COUNTRY-OF-RESIDENCE" | translate }}
              </th>
              <th mat-sort-header="user_status">
                {{ "User status" | translate }}
              </th>
            </tr>
          </thead>
          <tbody *ngIf="isLoading">
            <tr>
              <td colspan="100">
                <app-loader-spinner-general
                  [loadingType]="LoadingType.TABLE"
                ></app-loader-spinner-general>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!isLoading">
            <tr
              class="cursor-pointer"
              *ngFor="
                let expat of items
                  | paginate
                    : {
                        itemsPerPage: itemsPage,
                        currentPage: p,
                        totalItems: total
                      };
                let i = index
              "
            >
              <td>
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (keyup)="$event.stopPropagation()"
                  (change)="$event ? changeSelection(expat) : null"
                  [checked]="selection.isSelected(expat)"
                  [disableRipple]="true"
                >
                </mat-checkbox>
              </td>
              <td class="text-right border-r">
                <a
                  [routerLink]="[expat.id]"
                  class="text-secondary font-semibold"
                >
                  #{{ expat.id }}
                </a>
              </td>
              <td [routerLink]="[expat.id]">
                {{ expat.name }}
              </td>
              <td [routerLink]="[expat.id]">
                {{ expat.email }}
              </td>
              <td [routerLink]="[expat.id]">
                {{ expat.department_name }}
              </td>
              <td [routerLink]="[expat.id]">
                {{ expat.primary_citizenship?.country | country | async }}
              </td>
              <td [routerLink]="[expat.id]">
                {{ expat.country_code | country | async }}
              </td>
              <td>
                <div
                  class="status"
                  [appStatusStyle]="expat.user?.status | lowercase"
                >
                  {{ expat.user?.status | uppercase }}
                </div>
              </td>
            </tr>

            <tr *ngIf="items?.length === 0">
              <td colspan="100" class="text-secondary">
                <div class="notification_row bg_light_blue my-2.5 p-3">
                  {{ "GENERALS.NOTHING-TO-DISPLAY" | translate }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>

    <ng-template #responsiveTable>
      <!-- responsive table -->
      <div class="resposive-table-all-check">
        <ng-container matColumnDef="select">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [disableRipple]="true"
          >
            {{ "GENERALS.SELECT-ALL" | translate }}
          </mat-checkbox>
        </ng-container>
      </div>
      <app-loader-spinner-general
        *ngIf="isLoading"
        [loadingType]="LoadingType.TABLE"
      ></app-loader-spinner-general>
      <ng-container *ngIf="!isLoading">
        <div
          class="resposive-table-wrapper cursor-pointer"
          *ngFor="
            let expat of items
              | paginate
                : {
                    itemsPerPage: itemsPage,
                    currentPage: p,
                    totalItems: total
                  };
            let i = index
          "
        >
          <div class="responsive-table-row">
            <div class="responsive-table-check">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (keyup)="$event.stopPropagation()"
                (change)="$event ? changeSelection(expat) : null"
                [checked]="selection.isSelected(expat)"
                [disableRipple]="true"
              >
              </mat-checkbox>
            </div>

            <div
              class="responsive-table-header-item"
              (click)="navigateByUrl(filters.page)"
              (keyup)="navigateByUrl(filters.page)"
            >
              {{ "GENERALS.ID" | translate }}
            </div>

            <div class="responsive-table-data-item">{{ expat.id }}</div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "LABEL.NAME" | translate }}
            </div>
            <div class="responsive-table-data-item">{{ expat.name }}</div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "LABEL.EMAIL" | translate }}
            </div>
            <div class="responsive-table-data-item">{{ expat.email }}</div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "LABEL.DEPARTMENT" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{ expat.department_name }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "LABEL.CITIZENSHIP" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{ expat.primary_citizenship?.country | country | async }}
            </div>
          </div>
          <div class="responsive-table-row">
            <div class="responsive-table-header-item">
              {{ "LABEL.COUNTRY-OF-RESIDENCE" | translate }}
            </div>
            <div class="responsive-table-data-item">
              {{ expat.country_code | country | async }}
            </div>
          </div>
        </div>
        <!-- end responsive table -->
      </ng-container>
    </ng-template>

    <!-- paginator -->
    <div class="paginator">
      <pagination-controls
        (pageChange)="navigateByUrl($event)"
        previousLabel=""
        nextLabel=""
      >
      </pagination-controls>
    </div>

    <!-- end paginator -->
  </div>
</div>
<!-- end table -->
