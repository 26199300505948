export enum FileTypeEnum {
  jpg = "jpg",
  jpeg = "jpeg",
  png = "png",
  xls = "xls",
  xlsx = "xlsx",
  doc = "doc",
  docx = "docx",
  pdf = "pdf",
  txt = "txt",
  mp4 = "mp4",
  mov = "mov",
  wmv = "wmv",
  avi = "avi",
  flv = "flv",
  mpeg = "mpeg-2",
}
