<mat-form-field appearance="outline" class="w-100" [formGroup]="documentForm">
  <mat-label>{{ "LABEL.SERVICE-CATEGORY" | translate }} </mat-label>

  <mat-select
    [placeholder]="'GENERALS.SELECT-SERVICE-CATEGORY' | translate"
    [formControl]="categoryControl"
    #singleSelect
  >
    <mat-option
      *ngFor="let category of categories$ | async"
      [value]="category.id"
    >
      {{ category.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
