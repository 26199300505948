import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Subject } from "rxjs";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ReportsService } from "@api/reports/services/reports.service";

export class ItemNode {
  children: ItemNode[];
  item: string;
  label: string;
  service?: string;
}

export class ItemFlatNode {
  item: string;
  label: string;
  service?: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: "app-config-milestone-modal",
  templateUrl: "./config-milestone-modal.component.html",
  styleUrls: ["./config-milestone-modal.component.scss"],
})
export class ConfigMilestoneModalComponent implements OnInit, OnDestroy {
  public isLoaded = false;
  private unsubscribe$: Subject<void>;

  columns = [];
  selectedColumns = [];
  saveButton: boolean = true;
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true);

  dataChange = new BehaviorSubject<ItemNode[]>([]);
  showDatesCtrl = new UntypedFormControl(true);
  reportNameCtrl = new UntypedFormControl("", Validators.required);
  get data(): ItemNode[] {
    return this.dataChange.value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public dialogRef: MatDialogRef<ConfigMilestoneModalComponent>,
    private reports: ReportsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    this.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.unsubscribe$ = new Subject();
    this.isLoaded = true;
    const dialogData = this.dialogData;

    this.saveButton = dialogData?.saveButton ?? true;

    if (dialogData?.reportName) {
      this.reportNameCtrl.setValue(dialogData.reportName);
    }

    this.setMilestoneColumns();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemNode): ItemNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  setSelectedColumns() {
    this.route.queryParams.subscribe((params) => {
      if (params.columns || params.milestone_columns) {
        this.selectedColumns = params.columns;

        if (this.selectedColumns !== undefined) {
          this.selectedColumns = this.selectedColumns.concat(
            params.milestone_columns
          );
        } else {
          this.selectedColumns = params.milestone_columns;
        }
        this.treeControl.dataNodes.forEach((item) => {
          if (this.selectedColumns.indexOf(item.item) !== -1) {
            this.checklistSelection.toggle(item);
            this.treeControl.expand(item);
          }
        });
      }
      if (params.show_end_dates) {
        this.showDatesCtrl.setValue(params.show_end_dates !== "false");
      }
      this.isLoaded = false;
    });
  }
  public generateReport(save = false) {
    const columns = [];
    const milestones = [];
    let params = {};
    const selected = this.checklistSelection.selected;

    selected.forEach((item) => {
      if (item.service == "milestones") {
        milestones.push(item.item);
      } else {
        columns.push(item.item);
      }
    });

    params = { ...params, show_end_dates: this.showDatesCtrl.getRawValue() };
    if (columns.length > 0) {
      params = { ...params, columns: columns };
    }

    if (milestones.length > 0) {
      params = {
        ...params,
        milestone_columns: milestones,
      };
    }

    if (this.showDatesCtrl.getRawValue()) {
      params = { ...params, show_end_dates: true };
    }

    this.isLoaded = false;
    if (save) {
      if (!this.reportNameCtrl.valid) {
        this.reportNameCtrl.setErrors({ required: true });
        this.reportNameCtrl.markAsTouched();
        return;
      }

      params = {
        ...params,
        ...{
          save: true,
          reportName: this.reportNameCtrl.getRawValue(),
        },
      };
    }

    this.dialogRef.close(params);
  }

  public cancel() {
    this.dialogRef.close();
  }

  public setMilestoneColumns() {
    this.reports.milestoneFilters().subscribe((items) => {
      if (items.success) {
        this.columns = items.result.columns;
        this.initialize();
      }
    });
  }

  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new ItemFlatNode();
    flatNode.item = node.item;
    flatNode.label = node.label;
    flatNode.service = node.service;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    );
  }

  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  itemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  initialize() {
    const data = this.buildFileTree();

    // Notify the change.
    this.dataChange.next(data);
    this.setSelectedColumns();
  }

  buildFileTree(): ItemNode[] {
    return Object.keys(this.columns).reduce<ItemNode[]>((accumulator, key) => {
      const value = this.columns[key];
      const node = new ItemNode();
      node.item = key;
      node.label = key;
      node.service = key;

      node.children = Object.values(value).reduce<ItemNode[]>(
        (fields, item) => {
          const children = new ItemNode();
          children.item = item["field"];
          children.label = item["label"];
          children.service = key;

          return fields.concat(children);
        },
        []
      );
      return accumulator.concat(node);
    }, []);
  }

  public clearFilters() {
    this.checklistSelection.clear();
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
