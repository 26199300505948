<div class="custom-modal-wrapper p-0">
  <div class="modal-title-custom p-20">
    {{ "EXPENSES.CREATE" | translate }}
  </div>

  <div class="clear"></div>

  <app-loader-spinner-general
    *ngIf="isLoading"
    [loadingType]="LoadingType.BIG_INFO"
  ></app-loader-spinner-general>

  <form [formGroup]="expenseForm" *ngIf="!isLoading">
    <div class="modal-body-custom p-20">
      <!-- Title, Cost Category -->
      <div class="row">
        <div class="w-50">
          <app-title-selector [formGroup]="expenseForm"></app-title-selector>
        </div>

        <div class="w-50">
          <app-cost-category-selector
            [formGroup]="expenseForm"
          ></app-cost-category-selector>
        </div>
      </div>

      <!-- Amount & Currency + Third Party Supplier -->
      <div class="row">
        <div class="w-50">
          <app-cost-selector [formGroup]="expenseForm"></app-cost-selector>
        </div>

        <div class="w-50">
          <app-currency-selector
            [formGroup]="expenseForm"
            [useCode]="true"
          ></app-currency-selector>
        </div>

        <!-- TODO: comment for the moment third party supplier and assigned to task - endpoints will be implemented in the future -->
        <!--        <div class='w-50'>-->
        <!--          <app-third-party-selector-->
        <!--            [formGroup]='expenseForm'-->
        <!--          ></app-third-party-selector>-->
        <!--        </div>-->
      </div>

      <!-- Expense date, Covered by & Assigned to task -->
      <div class="row">
        <div class="w-50">
          <app-covered-by-selector
            [caseId]="entityId"
            [formGroup]="expenseForm"
          ></app-covered-by-selector>
        </div>

        <div class="w-50">
          <div class="w-100 expenses-date d-flex flex-column">
            <app-date-range-calendar
              formControlName="incurred_at"
              [placeholder]="'EXPENSES.DATE' | translate"
              (change)="setDate($event)"
            ></app-date-range-calendar>

            <div>
              <app-form-errors [control]="expenseForm.get('incurred_at')">
              </app-form-errors>
            </div>
          </div>
        </div>
      </div>

      <!-- Upload doc -->
      <div class="row">
        <div class="w-100 file-wrapper">
          <file-upload
            class="w-100"
            formControlName="file"
            multiple="false"
            [control]="fileUploadControl"
          >
            <ng-template #placeholder>
              <span><i class="icon-upload-cloud"></i></span>
              <span class="m-l-5"
                >{{ "GENERALS.DROP-FILE-TO-UPLOAD" | translate }}
              </span>
              <span class="orange"> {{ "GENERALS.BROWSE" | translate }}</span>
              <span class="d-block"
                >{{ "GENERALS.DOC-TYPE" | translate }}.</span
              >
            </ng-template>
          </file-upload>
          <div class="error-wrapper">
            <app-form-errors [control]="expenseForm.get('file')">
            </app-form-errors>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </form>
  <div class="clear"></div>
  <div class="modal-footer-custom bottom-buttons p-20">
    <app-loader-spinner-general
      *ngIf="isLoading"
      [repeat]="2"
      [loadingType]="LoadingType.BUTTONS"
    ></app-loader-spinner-general>

    <ng-container *ngIf="!isLoading">
      <button class="btn gray-bg fl-l modal-button" (click)="close()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <div class="clear"></div>
  </div>
</div>
