<mat-form-field appearance="outline" class="w-100" [formGroup]="expenseForm">
  <mat-label> {{ "EXPENSES.COVERED_BY" | translate }}</mat-label>
  <mat-select
    [formControl]="coveredByControl"
    [placeholder]="'EXPENSES.SELECT_COVERED_BY' | translate"
    #singleSelect
    (selectionChange)="handleCoveredBy($event)"
    [title]="coveredBy"
    [ngModel]="value"
  >
    <mat-option *ngFor="let supplier of coveredByList" [value]="supplier.id">
      {{ supplier.text }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="expenseForm.get('covered_by')">
    </app-form-errors>
  </mat-error>
</mat-form-field>
