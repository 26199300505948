import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

interface SvgItem {
  name: string;
  path: string;
}

@Injectable()
export class SvgRegistryService {
  private baseIconsPath: string = "assets/icons";

  private svgList: SvgItem[] = [
    {
      name: "accept",
      path: `${this.baseIconsPath}/thumbs-up.svg`,
    },
    {
      name: "calendar",
      path: `${this.baseIconsPath}/calendar-outline.svg`,
    },
    {
      name: "cancel",
      path: `${this.baseIconsPath}/cancel.svg`,
    },
    {
      name: "cancel-dark-gray",
      path: `${this.baseIconsPath}/cancel-dark-gray.svg`,
    },
    {
      name: "cancel-gray",
      path: `${this.baseIconsPath}/cancel-gray.svg`,
    },
    {
      name: "cash",
      path: `${this.baseIconsPath}/cash-outline.svg`,
    },
    {
      name: "check-outline",
      path: `${this.baseIconsPath}/check-outline.svg`,
    },
    {
      name: "ChevronDown",
      path: `${this.baseIconsPath}/ChevronDown.svg`,
    },
    {
      name: "circle-message",
      path: `${this.baseIconsPath}/circle-message.svg`,
    },
    {
      name: "Clock",
      path: `${this.baseIconsPath}/Clock.svg`,
    },
    {
      name: "decline",
      path: `${this.baseIconsPath}/thumbs-down.svg`,
    },
    {
      name: "doc-icon",
      path: `${this.baseIconsPath}/doc-icon.svg`,
    },
    {
      name: "dots-vertical",
      path: `${this.baseIconsPath}/dots-vertical-outline.svg`,
    },
    {
      name: "download",
      path: `${this.baseIconsPath}/cloud-download-outline.svg`,
    },
    {
      name: "edit",
      path: `${this.baseIconsPath}/edit.svg`,
    },
    {
      name: "estimated-duration",
      path: `${this.baseIconsPath}/estimated-duration.svg`,
    },
    {
      name: "estimated-end-date",
      path: `${this.baseIconsPath}/estimated-end-date.svg`,
    },
    {
      name: "estimated-start-date",
      path: `${this.baseIconsPath}/estimated-start-date.svg`,
    },
    {
      name: "export",
      path: `${this.baseIconsPath}/export.svg`,
    },
    {
      name: "external-link",
      path: `${this.baseIconsPath}/external-link-outline.svg`,
    },
    {
      name: "filter",
      path: `${this.baseIconsPath}/filter.svg`,
    },
    {
      name: "sort-up",
      path: `${this.baseIconsPath}/sort-up.svg`,
    },
    {
      name: "sort-down",
      path: `${this.baseIconsPath}/sort-down.svg`,
    },
    {
      name: "search",
      path: `${this.baseIconsPath}/search.svg`,
    },
    {
      name: "finalize",
      path: `${this.baseIconsPath}/check.svg`,
    },
    {
      name: "info",
      path: `${this.baseIconsPath}/info.svg`,
    },
    {
      name: "link-icon",
      path: `${this.baseIconsPath}/link-icon.svg`,
    },
    {
      name: "location",
      path: `${this.baseIconsPath}/location-marker-outline.svg`,
    },
    {
      name: "menu-icon-ads",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-ads.svg`,
    },
    {
      name: "menu-icon-assignments",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-assignments.svg`,
    },
    {
      name: "menu-icon-benefits",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-benefits.svg`,
    },
    {
      name: "menu-icon-billing",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-billing.svg`,
    },
    {
      name: "menu-icon-cases",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-cases.svg`,
    },
    {
      name: "menu-icon-categories",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-categories.svg`,
    },
    {
      name: "menu-icon-cost-projections",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-cost-projections.svg`,
    },
    {
      name: "menu-icon-dashboard",
      path: `${this.baseIconsPath}/left-navigation-icons/dashboard.svg`,
    },
    {
      name: "menu-icon-documents",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-documents.svg`,
    },
    {
      name: "menu-icon-expats",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-expats.svg`,
    },
    {
      name: "menu-icon-financial",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-financial.svg`,
    },
    {
      name: "menu-icon-help",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-help.svg`,
    },
    {
      name: "menu-icon-tasks",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-tasks.svg`,
    },
    {
      name: "menu-icon-orders",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-orders.svg`,
    },
    {
      name: "menu-icon-permissions",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-permissions.svg`,
    },
    {
      name: "menu-icon-processes",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-processes.svg`,
    },
    {
      name: "menu-icon-providers",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-providers.svg`,
    },
    {
      name: "menu-icon-reports",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-reports.svg`,
    },
    {
      name: "menu-icon-rfq-bids",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-rfq-bids.svg`,
    },
    {
      name: "menu-icon-service-offers",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-service-offers.svg`,
    },
    {
      name: "menu-icon-settings",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-settings.svg`,
    },
    {
      name: "menu-icon-templates",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-templates.svg`,
    },
    {
      name: "menu-icon-users",
      path: `${this.baseIconsPath}/left-navigation-icons/menu-icon-users.svg`,
    },
    {
      name: "open-eye",
      path: `${this.baseIconsPath}/open-eye.svg`,
    },
    {
      name: "pdf-color",
      path: `${this.baseIconsPath}/pdf_icon.svg`,
    },
    {
      name: "plus-circle",
      path: `${this.baseIconsPath}/plus-circle-outline.svg`,
    },
    {
      name: "plus-circle-outline-white",
      path: `${this.baseIconsPath}/plus-circle-outline-white.svg`,
    },
    {
      name: "process order",
      path: `${this.baseIconsPath}/process.svg`,
    },
    {
      name: "put on hold",
      path: `${this.baseIconsPath}/access-denied.svg`,
    },
    {
      name: "receipt",
      path: `${this.baseIconsPath}/receipt.svg`,
    },
    {
      name: "refresh",
      path: `${this.baseIconsPath}/refresh-outline.svg`,
    },
    {
      name: "resume",
      path: `${this.baseIconsPath}/refresh-outline.svg`,
    },
    {
      name: "refund",
      path: `${this.baseIconsPath}/refund.svg`,
    },
    {
      name: "RightArrow",
      path: `${this.baseIconsPath}/RightArrow.svg`,
    },
    {
      name: "save",
      path: `${this.baseIconsPath}/save.svg`,
    },
    {
      name: "save-outline",
      path: `${this.baseIconsPath}/save-outline.svg`,
    },
    {
      name: "settings",
      path: `${this.baseIconsPath}/cog-outline.svg`,
    },
    {
      name: "share-icon",
      path: `${this.baseIconsPath}/share-icon.svg`,
    },
    {
      name: "star",
      path: `${this.baseIconsPath}/star-outline.svg`,
    },
    {
      name: "subtasks",
      path: `${this.baseIconsPath}/subtask-count.svg`,
    },
    {
      name: "Trash",
      path: `${this.baseIconsPath}/Trash.svg`,
    },
    {
      name: "trash-bin",
      path: `${this.baseIconsPath}/trash-bin.svg`,
    },
    {
      name: "upload",
      path: `${this.baseIconsPath}/cloud-upload-outline.svg`,
    },
    {
      name: "user",
      path: `${this.baseIconsPath}/user.svg`,
    },
    {
      name: "user-outline",
      path: `${this.baseIconsPath}/user-outline.svg`,
    },
    {
      name: "user-plus",
      path: `${this.baseIconsPath}/user-add-outline.svg`,
    },
    {
      name: "ViewList",
      path: `${this.baseIconsPath}/ViewList.svg`,
    },
    {
      name: "waiting for payment",
      path: `${this.baseIconsPath}/waiting-for-payment.svg`,
    },
    {
      name: "warning-full",
      path: `${this.baseIconsPath}/exclamation-full.svg`,
    },
    {
      name: "warning",
      path: `${this.baseIconsPath}/exclamation-outline.svg`,
    },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public init() {
    this.svgList.forEach((svg: SvgItem) => {
      this.matIconRegistry.addSvgIcon(
        svg.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(svg.path)
      );
    });
  }
}
