import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { GroupedCaseService } from "@api/cases";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { TimelineCaseTask } from "@api/case-tasks/models/timeline-case-task.model";

@Component({
  selector: "app-timeline-services-sidebar",
  templateUrl: "./timeline-services-sidebar.component.html",
  styleUrls: ["./timeline-services-sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TimelineServicesSidebarComponent implements OnInit, OnDestroy {
  public services: GroupedCaseService<TimelineCaseTask>[];
  private unsubscribeAll$: Subject<void>;

  constructor(public timelineService: TimelineService) {
    // Set the private defaults
    this.unsubscribeAll$ = new Subject();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.complete();
  }

  ngOnInit() {
    this.timelineService.onServicesChanged
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe((services) => {
        this.services = services;
      });
  }
}
