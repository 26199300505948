<div class="custom-modal-wrapper">
  <div class="modal-title-custom">
    {{ title ?? "AUTH.CHANGE-PASSWORD" | translate }}
  </div>
  <div class="modal-body-custom" [formGroup]="form">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "GENERALS.CURRENT-PASSWORD" | translate }}</mat-label>
      <input matInput type="password" formControlName="current_password" />
      <mat-error>
        <app-form-errors [control]="form.get('current_password')">
        </app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100 password_validate_field">
      <mat-label>{{ "GENERALS.PASSWORD" | translate }}</mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        (focusin)="hideError = true"
        (focusout)="hideError = false"
        formControlName="password"
      />
      <app-form-errors *ngIf="hideError" [control]="form.get('password')">
      </app-form-errors>
      <mat-icon class="show-pass center" (click)="hide = !hide">{{
        hide ? "visibility_off" : "visibility"
      }}</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "GENERALS.PASSWORD-CONFIRMATION" | translate }}</mat-label>
      <input
        matInput
        [type]="hide_confirmed_pass ? 'password' : 'text'"
        formControlName="password_confirmation"
      />
      <mat-error>
        <app-form-errors [control]="form.get('password_confirmation')">
        </app-form-errors>
      </mat-error>
      <mat-icon
        class="show-pass center"
        (click)="hide_confirmed_pass = !hide_confirmed_pass"
        >{{ hide_confirmed_pass ? "visibility_off" : "visibility" }}</mat-icon
      >
    </mat-form-field>
  </div>
  <div class="modal-footer-custom">
    <button
      type="button"
      class="btn gray-bg fl-l modal-button"
      mat-dialog-close
    >
      <mat-icon svgIcon="cancel"></mat-icon>
      {{ "GENERALS.CANCEL" | translate }}
    </button>

    <button
      type="button"
      class="btn orange-bg fl-r modal-button"
      (click)="submit()"
    >
      <mat-icon svgIcon="save"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
