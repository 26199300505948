<div class="white-box-wrapper">
  <div class="white-box-row">
    <div class="white-box-header">
      <div class="medium-title fl-l">
        {{ "EXPATS.EMERGENCY-CONTACTS" | translate }}
      </div>
      <button
        class="white-box-header-action fl-r add orange border-white transparent-bg p-0 btn"
        (click)="createContact()"
      >
        <b class="d-flex align-items-end">
          <mat-icon svgIcon="plus-circle" class="plus-circle"></mat-icon>
          {{ "GENERALS.ADD" | translate }}
        </b>
      </button>
    </div>
    <app-loader-spinner-general
      *ngIf="isLoading"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
    <!-- table -->
    <div
      class="table-wrapper"
      *ngIf="contacts$ | async as contacts"
      [ngClass]="{ 'expat-page-table': resource === 'expat' }"
    >
      <ng-container *ngIf="!isXs; else responsiveTable">
        <div class="table-responsive" *ngIf="contacts$ | async as contacts">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  {{ "LABEL.NAME" | translate }}
                </th>
                <th>
                  {{ "LABEL.PHONE-NUMBER" | translate }}
                </th>
                <th>
                  {{ "LABEL.EMAIL" | translate }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody *ngIf="!isLoading">
              <tr *ngFor="let contact of contacts; let i = index">
                <td>
                  <mat-icon
                    svgIcon="star"
                    class="orange"
                    *ngIf="contact.isPrimary"
                    [matTooltip]="'LABEL.IS_PRIMARY' | translate"
                  ></mat-icon>
                  <mat-icon
                    svgIcon="warning"
                    class="orange"
                    *ngIf="contact.isEmergency"
                    [matTooltip]="'LABEL.IS_EMERGENCY' | translate"
                  ></mat-icon>
                </td>

                <td class="gray-pointer">{{ contact.name }}</td>
                <td class="gray-pointer">{{ contact.phoneNumber }}</td>
                <td class="gray-pointer">{{ contact.email }}</td>
                <!-- table cel with action -->
                <td class="ta-r">
                  <!-- table action with overlay -->
                  <div toggle-action-table class="table-action-wrapper">
                    <i class="icon-dot-3"></i>

                    <div class="table-action">
                      <ul>
                        <li>
                          <button
                            class="btn transparent-bg p-0"
                            (click)="editContact(contact)"
                          >
                            {{ "edit" | translate | uppercase }}
                          </button>
                        </li>
                        <li>
                          <button
                            class="btn transparent-bg p-0"
                            (click)="deleteContact(contact)"
                          >
                            {{ "delete" | translate | uppercase }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- end table action with overlay -->
                </td>
              </tr>
              <tr *ngIf="contacts?.length === 0">
                <td colspan="7" class="gray p-l-0">
                  {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <ng-template #responsiveTable>
        <app-loader-spinner-general
          *ngIf="isLoading"
          [loadingType]="LoadingType.TABLE"
        ></app-loader-spinner-general>
        <ng-container *ngIf="!isLoading">
          <!-- responsive table -->
          <div class="resposive-table-wrapper" *ngFor="let contact of contacts">
            <div class="responsive-table-row">
              <div
                class="responsive-table-details"
                (click)="editContact(contact)"
              >
                <span class="btn-small orange-bg">{{
                  "GENERALS.EDIT" | translate
                }}</span>
              </div>
            </div>
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "LABEL.LABEL" | translate }}
              </div>
              <div class="responsive-table-data-item">{{ contact.label }}</div>
            </div>

            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "LABEL.NAME" | translate }}
              </div>
              <div class="responsive-table-data-item">{{ contact.name }}</div>
            </div>
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "LABEL.EMAIL" | translate }}
              </div>
              <div class="responsive-table-data-item">{{ contact.email }}</div>
            </div>
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "LABEL.PHONE-NUMBER" | translate }}
              </div>
              <div class="responsive-table-data-item">
                {{ contact.phoneNumber }}
              </div>
            </div>
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "LABEL.IS_EMERGENCY" | translate }}
              </div>
              <div class="responsive-table-data-item">
                {{ contact.isEmergency }}
              </div>
            </div>
            <div class="responsive-table-row">
              <div class="responsive-table-header-item">
                {{ "LABEL.IS_PRIMARY" | translate }}
              </div>
              <div class="responsive-table-data-item">
                {{ contact.isPrimary }}
              </div>
            </div>
          </div>
          <!-- end responsive table -->
        </ng-container>
      </ng-template>

      <!-- end paginator -->
    </div>
  </div>
  <!-- end table -->
</div>
