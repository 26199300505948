import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  OrderData,
  OrderServiceData,
  OrderServiceExpatData,
} from "@api/billing";
import { CreateComponent } from "@modules/cases/cases-shared/create/create.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  BehaviorSubject,
  map,
  merge,
  Observable,
  shareReplay,
  startWith,
} from "rxjs";
import { OpenCaseButtonEnum } from "src/app/_enums/open-case-button-status-enum";
import { CaseStatusEnum } from "../../../../_enums/case-status-enum";
import {
  CaseOrderService,
  OrderCaseData,
  OrderItemData,
} from "../services/order-case.service";
import { filterNullish } from "src/app/lib";

@Component({
  selector: "app-open-case-button",
  templateUrl: "./open-case-button.component.html",
  styleUrls: ["./open-case-button.component.scss"],
})
export class OpenCaseButtonComponent implements OnChanges, OnInit {
  @Input()
  order: OrderData;

  @Input()
  orderItem: OrderServiceData;

  @Input()
  orderExpat: OrderServiceExpatData;

  @Output()
  caseCreated: EventEmitter<void> = new EventEmitter<void>();

  inputCase$: BehaviorSubject<OrderCaseData | null> = new BehaviorSubject(null);

  case$: Observable<{
    loaded: boolean;
    caseData: OrderCaseData | null;
  }>;

  constructor(
    private dialog: MatDialog,
    private caseOrderService: CaseOrderService
  ) {}

  get openCaseButtonStatusEnum() {
    return OpenCaseButtonEnum;
  }

  ngOnInit(): void {
    this.case$ = merge(
      this.inputCase$.pipe(filterNullish()),
      this.caseOrderService.getCaseService(this.getCaseData())
    ).pipe(
      map((caseData) => ({
        loaded: true,
        caseData,
      })),
      startWith({ loaded: false, caseData: null }),
      shareReplay(1)
    );
  }

  getCaseData(): OrderItemData {
    return {
      client_id: this.order.client.id,
      expat_id: this.orderExpat.expat_id,
      order_id: this.orderItem.order_id,
      assignment_id: this.orderExpat.assignment_id,
      dependent_id: this.orderExpat.dependent_id,
      order_detail_id: this.orderItem.id,
      service_id: this.orderItem.offer_id,
      order_number: +this.order.identity_number,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputCase$.next(
      this.orderExpat?.case_id
        ? {
            id: this.orderExpat.case_id,
            status: this.orderExpat.case_status,
          }
        : null
    );
  }
  openCaseDialog(): void {
    this.dialog
      .open(CreateComponent, {
        data: {
          case: this.getCaseData(),
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data && data.type === "saved") {
          this.caseOrderService.getCaseService(this.getCaseData(), true);
        }
      });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  protected readonly CaseStatusEnum = CaseStatusEnum;
}
