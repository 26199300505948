<div class="white-box-wrapper m-t-15">
  <div class="white-box-row">
    <div class="white-box-header">
      <div class="medium-title fl-l">
        <b>{{ "REPORTS.USER-CASES" | translate | titlecase }}</b>
      </div>
    </div>
    <app-loader-spinner-general
      *ngIf="!chartOptions"
      [repeat]="3"
      [loadingType]="LoadingType.INFO"
    ></app-loader-spinner-general>
    <div class="clear"></div>
    <div class="white-box-row">
      <div class="raport-chart-wrapper">
        <div class="white-box-body report-chart">
          <apx-chart
            *ngIf="chartOptions"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [legend]="chartOptions.legend"
            [dataLabels]="chartOptions.dataLabels"
            [colors]="chartOptions.colors"
            [plotOptions]="chartOptions.plotOptions"
            [grid]="chartOptions.grid"
            [xaxis]="chartOptions.xaxis"
            [yaxis]="chartOptions.yaxis"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>
