import { CdkDragEnd } from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TemplateData, TemplateElement, TemplateService } from "@api/templates";
import { ConfirmActionModalComponent } from "@modules/shared/_components/confirm-action-modal/confirm-action-modal.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { QueryParamsService } from "src/app/_services/query-params.service";

@Component({
  selector: "app-template-preview",
  templateUrl: "./template-preview.component.html",
  styleUrls: ["./template-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TemplatePreviewComponent implements OnInit, OnDestroy {
  templateDocumentPath: string;
  templateId: string;
  template: TemplateData;
  @ViewChild("elementsZone", { static: true })
  elementZone: ElementRef;
  currentPage = 1;
  isLoading: boolean = false;

  thumbnail: { page: number; url: string }[] = [];

  @ViewChild(PdfViewerComponent, { static: true })
  pdfViewer?: PdfViewerComponent;
  currentAnnotations: TemplateElement[] = [];

  @Input() isReadonly: boolean;

  private unsubscribeAll$: Subject<void>;
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private templateService: TemplateService,

    private queryParamsService: QueryParamsService
  ) {
    // Set the private defaults
    this.unsubscribeAll$ = new Subject();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll$.complete();
  }

  ngOnInit() {
    this.templateId = this.route.snapshot.params.id;

    if (this.templateId) {
      this.isLoading = true;

      this.templateService
        .getTemplateById(this.templateId)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.template = data;
            this.templateDocumentPath =
              this.template.document.files[0].file_path;

            this.templateService.annotations = this.template.elements;

            this.templateService.templateId = this.templateId;
          },
          error: (error) => {
            if (error?.error) {
              this.router
                .navigate(["/", "template"], {
                  queryParams: this.queryParamsService.defaultParams,
                  replaceUrl: true,
                })
                .then();
            }

            this.isLoading = false;
          },
        });
    }
    // tslint:disable-next-line
    this.pdfViewer.pageInitialized.subscribe((page: any) => {
      this.updateAnnotations();
      this.templateService.templateWrapper = page.source.viewer;
    });

    this.templateService.listenForAnnotationsChanges
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe(() => {
        this.updateAnnotations();
      });
  }

  pageRendered(e): void {
    const scale = e.source.scale;
    const viewport = e.source.viewport;
    this.renderer.setStyle(
      this.elementZone.nativeElement,
      "width",
      `${viewport.width * (1 / scale)}px`
    );
    this.renderer.setStyle(
      this.elementZone.nativeElement,
      "height",
      `${viewport.height * (1 / scale)}px`
    );
    this.renderer.setStyle(
      this.elementZone.nativeElement,
      "transform-origin",
      `0 0`
    );
    this.renderer.setStyle(
      this.elementZone.nativeElement,
      "transform",
      `scale(${scale})`
    );

    if (this.thumbnail.length > 0) {
      const index = this.thumbnail.findIndex(
        (thumbnail) => thumbnail.page === e.pageNumber
      );
      if (index === -1) {
        this.pushElement(e);
      }
    } else {
      this.pushElement(e);
    }

    this.isLoading = false;
  }

  private pushElement(e) {
    this.thumbnail.push({
      page: e.pageNumber,
      url: e.source.canvas.toDataURL(),
    });
  }

  choosePage(page: number): void {
    this.currentPage = page;

    this.templateService.currentDocumentPage = this.currentPage;

    this.updateAnnotations();
  }

  updateAnnotations(): void {
    this.currentAnnotations = this.templateService.annotations
      .filter((annotation: any) => annotation.page === this.currentPage)
      .map((item: any) => {
        item.styles.left = `${item.position.x}px`;
        item.styles.top = `${item.position.y}px`;
        return item;
      });
  }

  savePositionState(item: TemplateElement, event: CdkDragEnd): void {
    const itemIndex = this.findItemIndex(item.id);

    item = { ...item };
    item.position.x += event.distance.x;
    item.position.y += event.distance.y;
    item.styles = Object.assign({}, item.styles);
    item.selected = true;

    this.templateService.annotations[itemIndex] = item;

    this.templateService.updateElement(item).subscribe(() => {
      this.updateAnnotations();
    });
  }

  activateElement(item: TemplateElement): void {
    this.templateService.annotations.map((annotation: TemplateElement) => {
      if (annotation.id !== item.id) {
        annotation.selected = false;
      }
    });

    item.selected = !item.selected;

    this.templateService.listenForAnnotationsChanges.next(item);
  }

  deleteElement(item: TemplateElement): void {
    const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        action: {
          needs_confirmation: true,
          text: `field removal`,
          customText: "TEMPLATE.CONFIRM-DELETE-ITEM",
          hideText: true,
        },
      },
    });

    dialogRef.afterClosed().subscribe((reason: string) => {
      if (reason) {
        this.templateService.deleteElement(item).subscribe(() => {
          this.templateService.annotations.forEach(
            (element: TemplateElement, index: number) => {
              if (element.id === item.id) {
                this.templateService.annotations.splice(index, 1);

                this.templateService.listenForAnnotationsChanges.next(true);
              }
            }
          );
        });
      }
    });
  }

  private findItemIndex(id: string): number {
    return this.templateService.annotations.findIndex(
      (annotation: any) => annotation.id === id
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
