import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { OrderData } from "@api/billing/models/order.model";
import { CaseData, CaseType } from "@api/cases";
import { CasesService } from "@api/cases/services/cases.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, of } from "rxjs";
import { finalize, shareReplay } from "rxjs/operators";
import { AlertService } from "src/app/_services/alert.service";
import { OrdersService } from "src/app/_services/orders.service";
import { UsersService } from "src/app/_services/users.service";

@Component({
  selector: "app-add-services",
  templateUrl: "./add-services.component.html",
  styleUrls: ["./add-services.component.scss"],
})
export class AddServicesComponent implements OnInit {
  case: CaseData;
  public order$: Observable<OrderData>;
  caseID: number;
  caseForm: UntypedFormGroup;
  isLoadingGeneral = false;

  submitForm: Subject<void> = new Subject<void>();

  get CaseType() {
    return CaseType;
  }

  constructor(
    private caseService: CasesService,
    private router: Router,
    protected dialogRef: MatDialogRef<AddServicesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: { case: Partial<AddServicesComponent> },
    private usersService: UsersService,
    private translate: TranslateService,
    private alert: AlertService,
    private orderService: OrdersService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.case = data.case;
    const orderId = this.case.order_id;
    this.order$ = orderId
      ? this.orderService
          .getOrderById({}, this.case.order_id)
          .pipe(shareReplay(1))
      : of();
    this.caseForm = new UntypedFormGroup({
      expat_id: new UntypedFormControl(this.case.expat_id),
      services: new UntypedFormControl([]),
    });
  }

  ngOnInit() {
    this.usersService.getCurrentUser().subscribe((user) => {
      this.caseForm.patchValue({
        provider_name: user.name,
      });
    });
  }

  submit() {
    this.submitForm.next();
    this.caseForm.markAllAsTouched();

    if (!this.caseForm.valid) {
      return;
    }
    const data = this.caseForm.getRawValue();
    this.isLoadingGeneral = true;
    data.services = data.services.filter((p) => p.enabled == true);

    this.caseService
      .addService(this.case.id, data)
      .pipe(finalize(() => (this.isLoadingGeneral = false)))
      .subscribe(
        (caseData) => {
          this.dialogRef.close({
            type: "saved",
            caseData,
          });
        },
        (error) => {
          if (error.error.message) {
            this.alert.stringError(error.error.message);
          } else {
            this.alert.error([
              this.translate.instant("GENERALS.GENERIC_ERROR"),
            ]);
          }
        }
      );
  }

  close() {
    this.dialogRef.close({ cancel: true });
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
