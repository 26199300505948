import { ProviderData } from "./provider";

export class EntityUser {
  company: ProviderData;
  company_position: string;
  department_id: number;
  department_name: string;
  entity_id: number;
  id: number;
  is_primary: boolean;
  user_id: number;
}
