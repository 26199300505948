<mat-form-field appearance="outline" class="w-100" [formGroup]="expenseForm">
  <mat-label> {{ "EXPENSES.COST_CATEGORY" | translate }}</mat-label>
  <mat-select
    [formControl]="costCategoryControl"
    [placeholder]="'EXPENSES.SELECT_COST_CATEGORY' | translate"
    #singleSelect
    (selectionChange)="handleCostCategory($event)"
    [title]="costCategory"
    [ngModel]="value"
  >
    <mat-option *ngFor="let cost of costCategoryList" [value]="cost.id">
      {{ cost.text }}
    </mat-option>
  </mat-select>
  <mat-error>
    <app-form-errors [control]="expenseForm.get('cost_category_id')">
    </app-form-errors>
  </mat-error>
</mat-form-field>
