<ng-container *ngIf="isLoading">
  <app-loader-spinner-general
    [loadingType]="LoadingType.INFO"
  ></app-loader-spinner-general>
</ng-container>

<div class="auth-form-wrapper" *ngIf="!isLoading">
  <form name="form" class="auth-form" (ngSubmit)="sendEmail()" #f="ngForm">
    <div class="auth-form-title-wrapper">
      <div class="auth-form-title">
        {{ "AUTH.FORGOT-PASSWORD" | translate }}
      </div>
    </div>
    <div>
      <p class="forgot-pass">
        {{ "AUTH.FORGOT-PASSWORD-MESSAGE" | translate }}
      </p>
    </div>
    <div>
      <input
        class="form-control"
        name="email"
        [(ngModel)]="userEmail"
        required
        placeholder="{{ 'LABEL.EMAIL' | translate }}"
      />
    </div>
    <button class="btn orange-bg">
      {{ "GENERALS.SEND" | translate }}
    </button>
  </form>
</div>
