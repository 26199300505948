<!-- dialog -->
<div class="p-0">
  <div *ngIf="data">
    <div class="text-2xl border-b px-6 py-3 font-semibold">
      {{ "GENERALS.BILLING-DETAILS" | translate }}
    </div>

    <div class="flex flex-wrap py-6 !px-3">
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.COMPANY-NAME" | translate }}:
        </span>
        <span class="display-field">
          {{ data.company_name }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.TAX_NUMBER" | translate }}:
        </span>
        <span class="display-field">
          {{ data.tax_number }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.ADDRESS" | translate }}:
        </span>
        <span class="display-field">
          {{ data.address }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.COUNTRY" | translate }}:
        </span>
        <span class="display-field">
          {{ data.country_code | country | async }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.CITY" | translate }}:
        </span>
        <span class="display-field">
          {{ data.city }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.BANK_NAME" | translate }}:
        </span>
        <span class="display-field">
          {{ data.bank_name }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.REGION" | translate }}:
        </span>
        <span class="display-field">
          {{ data.region }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.BANK_ACCOUNT_NUMBER" | translate }}:
        </span>
        <span class="display-field">
          {{ data.bank_account_number }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.ZIP-CODE" | translate }}:
        </span>
        <span class="display-field">
          {{ data.zip_code }}
        </span>
      </div>
      <div class="columns-2">
        <span class="text-secondary block font-medium">
          {{ "LABEL.SWIFT_CODE" | translate }}:
        </span>
        <span class="display-field">
          {{ data.swift_code }}
        </span>
      </div>
    </div>

    <div class="flex justify-end px-6 py-3 w-full border-t">
      <button type="button" class="btn-secondary" mat-dialog-close>
        <mat-icon svgIcon="cancel" class="btn-icon"></mat-icon>
        {{ "GENERALS.CLOSE" | translate }}
      </button>
    </div>
  </div>
</div>
<!-- end dialog -->
