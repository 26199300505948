import { phoneValidator } from "./phone.validator";
import { dependentFieldsValidator } from "./dependent-fields.validator";
import { dateRangeValidator } from "./date-range.validator";
import { futureDateValidator } from "./future-date.validator";
import { fileValidator } from "./file-extension.validator";
import { validDateValidator } from "./valid-date.validator";

const CustomValidators = {
  phoneValidator,
  dependentFieldsValidator,
  dateRangeValidator,
  futureDateValidator,
  fileValidator,
  validDateValidator,
};

export { CustomValidators };
