import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-cookie-consent",
  templateUrl: "./cookie-consent.component.html",
  styleUrls: ["./cookie-consent.component.scss"],
})
export class CookieConsentComponent implements OnInit {
  cookieConsent = true;
  constructor(private router: Router, private cookieService: CookieService) {}

  ngOnInit() {
    if (this.cookieService.get(environment.cookieConsent)) {
      this.cookieConsent = false;
    } else {
      this.cookieConsent = true;
    }
  }

  cookieAccept(e) {
    if (e) {
      this.cookieConsent = false;
      this.cookieService.set(environment.cookieConsent, e, 365, "/");
    }
  }

  goToPrivacyPolicy() {
    window.open(environment.marketplace + "privacy-policy");
  }
}
