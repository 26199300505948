<mat-form-field appearance="outline" class="w-100" [formGroup]="formGroup">
  <mat-label> {{ "LABEL.DOCS_TAGS" | translate }} </mat-label>
  <mat-chip-list #chipList aria-label="Tags selection">
    <mat-chip
      *ngFor="let item of items"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(item)"
    >
      {{ item }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="{{ placeholder }}"
      #chipInput
      [formControl]="chipsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputAddOnBlur)="addOnBlur($event)"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      class="w-100"
      *ngFor="let element of itemsList"
      [value]="element.name"
    >
      {{ element.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
