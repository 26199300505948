<div class="white-box-wrapper m-t-15">
  <div class="white-box-row">
    <div class="white-box-header">
      <div class="d-flex justify-content-between mb-3">
        <b>
          {{ cardType | titlecase }}
          {{ "GENERALS.PER-MONTH" | translate }}
        </b>
      </div>
      <div class="white-box-header-action w-100 d-flex">
        <div class="d-flex w-75">
          <div class="d-flex justify-content-around flex-wrap gap">
            <mat-form-field
              appearance="outline"
              *ngIf="cardType === CardType.REVENUE"
            >
              <mat-select
                placeholder="Country"
                [value]="selectedCountry"
                (selectionChange)="onCountryChange($event)"
                panelClass="country-panel"
                disableOptionCentering
              >
                <mat-option>
                  <ngx-mat-select-search
                    [placeholderLabel]="'GENERALS.SELECT-COUNTRY' | translate"
                    [noEntriesFoundLabel]="
                      'GENERALS.NO-ITEMS-DISPLAY' | translate
                    "
                    [formControl]="countrySearchControl"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let country of filteredCountries"
                  [value]="country.country_code"
                  >{{ country.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field
              *ngIf="cardType !== CardType.PROFIT"
              appearance="outline"
            >
              <mat-select
                placeholder="Filter by service"
                [value]="selectedServiceFilter"
                (selectionChange)="onServiceTypeChange($event)"
                panelClass="service-panel"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="
                    let filter of cardType === CardType.REVENUE
                      ? serviceRevenueFilters
                      : serviceCostsFilters
                  "
                  [value]="filter.value"
                >
                  {{ filter.name }}
                </mat-option>
              </mat-select>
              <mat-error> </mat-error>
            </mat-form-field>
            <div
              *ngIf="cardType !== CardType.PROFIT"
              class="date-filter-wrapper"
            >
              <app-date-range-calendar
                [placeholder]="'GENERALS.SELECT-CUSTOM-PERIOD' | translate"
                (change)="onCalendarChange($event)"
                [maxDate]="maxDate"
                [dateRange]="dateRange"
                [multiple]="true"
              ></app-date-range-calendar>
            </div>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Period"
                [value]="selectedPeriod"
                (selectionChange)="onPeriodChange($event)"
                panelClass="period-panel"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let period of periods"
                  [value]="period.value"
                >
                  {{ period.name }}
                </mat-option>
              </mat-select>
              <mat-error> </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-end top-0 w-25 clear-wrapper">
          <button class="clear-btn btn white-bg" (click)="clearFilters()">
            {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="white-box-row" *ngIf="showChart">
      <div class="raport-chart-wrapper">
        <div class="white-box-body report-chart">
          <ngx-charts-bar-vertical-2d
            [results]="data"
            [xAxis]="config.showXAxis"
            [yAxis]="cardType === CardType.REVENUE ? config.showYAxis : false"
            [showXAxisLabel]="config.showXAxisLabel"
            [showYAxisLabel]="config.showYAxisLabel"
            [roundEdges]="config.roundEdges"
            [yAxisTickFormatting]="axisFormating"
            [yAxisTicks]="orderedCountries.costs"
            [customColors]="config.customColors"
            [noBarWhenZero]="false"
            [barPadding]="config.barPadding"
            [groupPadding]="config.groupPadding"
          ></ngx-charts-bar-vertical-2d>
        </div>
        <div class="details">
          <div class="legend white-box-body">
            <ul>
              <li
                *ngFor="let category of serviceCategories"
                [ngClass]="[category.type]"
              >
                {{ category.name }}
              </li>
            </ul>
          </div>
          <div class="total-cost">
            <div class="title">
              <b>{{ "REPORTS.TOTAL-" + cardType.toUpperCase() | translate }}</b>
            </div>
            <ul>
              <li
                *ngFor="let cost of costs"
                [ngClass]="{ 'd-none': cost.value === 0 }"
              >
                <div class="d-contents">
                  <b class="value">{{ cost.value | number }} USD</b>
                  {{ cost.month }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="white-box-row medium-title no-items" *ngIf="!showChart">
      {{ "GENERALS.NO-ITEMS-MESSAGE" | translate }}
    </div>
  </div>
</div>
