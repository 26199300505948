<div class="auth-form-wrapper auth-form-wrapper">
  <div class="auth-form-title-wrapper">
    <div class="auth-form-title">
      {{ "GENERALS.SERVICEOFFERS" | translate }}
    </div>
  </div>

  <ng-container *ngIf="categories">
    <div class="registration-steps">
      <div class="registration-step-item">
        {{ "GENERALS.STEP-3" | translate }}
      </div>
    </div>

    <div class="row">
      <div class="w-100">
        <div class="form-desc">
          {{ "GENERALS.REGISTRATION-STEPT-3-PROVIDER-DESC" | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group w-100" *ngIf="categories">
        <app-add-categories
          [serviceCategories]="categories"
          [level]="0"
          (onselect)="updateCategorySelection($event)"
        ></app-add-categories>
      </div>
    </div>
    <br />
    <div class="row" *ngIf="countries">
      <div class="form-group w-100 chip-select-wrapper">
        <app-chips-select
          [items]="countries"
          [multiple]="true"
          [placeholder]="'GENERALS.COUNTRIES-CHIPS-PLACEHOLDER'"
          [bindValue]="'name'"
          [bindLabel]="'name'"
          (onselect)="selectedCountries = $event"
        ></app-chips-select>
      </div>
    </div>
    <div class="auth-footer">
      <button
        type="button"
        id="service-portfolio-save"
        class="btn orange-bg"
        (click)="addCategories()"
      >
        {{ "GENERALS.FINISH" | translate }}
      </button>
    </div>

    <div class="registration-steps-dots">
      <div class="registration-step-dots-item"></div>
      <div class="registration-step-dots-item"></div>

      <div class="registration-step-dots-item active-setp-dot"></div>
    </div>
  </ng-container>
</div>
