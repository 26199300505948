import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { OfficeModel } from "../../../_models/office";
import { Observable } from "rxjs";
import { ValueList } from "../../shared/models/value-list.model";
import { map } from "rxjs/operators";
import { OfficeMapperService } from "./office-mapper.service";

const url = "entities";

@Injectable({
  providedIn: "root",
})
export class OfficeService {
  constructor(
    private http: HttpClient,
    private officeMapper: OfficeMapperService
  ) {}

  fetchAll(entityId: number, filters: ValueList): Observable<OfficeModel[]> {
    return this.http
      .get(environment.gateway_endpoint + url + `/${entityId}/offices`, {
        params: filters,
      })
      .pipe(map((data: any) => this.officeMapper.mapMany(data.result)));
  }

  getOfficeById(
    entityId: number,
    officeId: number,
    filters: ValueList
  ): Observable<OfficeModel> {
    return this.http
      .get(
        environment.gateway_endpoint + url + `/${entityId}/offices/${officeId}`,
        { params: filters }
      )
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }

  createOffice(entityId: number, office: OfficeModel) {
    const prepared = this.officeMapper.prepare(office);
    return this.http
      .post(
        environment.gateway_endpoint + url + `/${entityId}/offices`,
        prepared
      )
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }

  updateOffice(entityId: number, office: OfficeModel) {
    const prepared = this.officeMapper.prepare(office);
    return this.http
      .put(
        environment.gateway_endpoint +
          url +
          `/${entityId}/offices/${office.id}`,
        prepared
      )
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }

  deleteOffice(entityId: number, office: OfficeModel) {
    return this.http
      .delete(
        environment.gateway_endpoint + url + `/${entityId}/offices/${office.id}`
      )
      .pipe(map((data: any) => this.officeMapper.mapOne(data.result)));
  }
}
