import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgxSmartModalService } from "ngx-smart-modal";

@Component({
  selector: "app-confirm-delete-modal",
  templateUrl: "./confirm-delete-modal.component.html",
  styleUrls: ["./confirm-delete-modal.component.scss"],
})
export class ConfirmDeleteModalComponent {
  @Input() quotationOffers;
  @Output() deletequotationOffers: EventEmitter<any> = new EventEmitter();

  constructor(public ngxSmartModalService: NgxSmartModalService) {}

  close() {
    this.ngxSmartModalService.closeLatestModal();
  }

  confirmDeleteQuotationOffers() {
    this.deletequotationOffers.emit();
    this.ngxSmartModalService.closeLatestModal();
  }
}
