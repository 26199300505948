import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import { CitizenshipUpdateModel } from "@modules/expats/models/citizenship";
import { DictionaryModel } from "@modules/shared/models/dictionary.model";
import { ExpatDependent } from "@modules/expats/models/expat-dependents.model";
import { PageData } from "@modules/shared/models/page.model";
import { Expat } from "src/app/_models/expat";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const expatsUrl = "entities/expats";
const dependentRelationshipUrl = "entities/directories/dependent/relationships";

@Injectable()
export class ExpatsService {
  public clientCanChangeExpatStatus = false;

  constructor(private http: HttpClient) {}

  /**
   * List of expats for the clients
   * @param providerId
   * @param clientId
   * @param params
   */
  public list(providerId, clientId, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        `entities/providers/${providerId}/clients/${clientId}/expats`,
      { params: params }
    );
  }

  public getExpatsList(
    clientId: number,
    params = {}
  ): Observable<PageData<Expat>> {
    return this.http.get<PageData<Expat>>(
      environment.gateway_endpoint + expatsUrl + `/${clientId}/expats`,
      {
        params: params,
      }
    );
  }

  public getExpatById(id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + expatsUrl + "/" + id
    );
  }

  public getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/expats/status-transition"
    );
  }

  public getProfilePicture(id) {
    return this.http.get(
      environment.gateway_endpoint + "expat/" + id + "/show-profile-picture"
    );
  }

  public createExpat(params): Observable<any> {
    return this.http
      .post<any>(environment.gateway_endpoint + expatsUrl, params, httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // When client edits expat details
  public updateExpat(params): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint + expatsUrl + "/" + params.id,
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // When expat edit its own details
  public updateCurrentCompany(params): Observable<any> {
    return this.http
      .patch<any>(
        environment.gateway_endpoint + expatsUrl + "/update-current-company",
        params,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updateStatusBulk(status_id, expats_ids, reason = ""): Observable<any> {
    return this.http.patch<any>(
      environment.gateway_endpoint + "entities/expats/updateStatus",
      { status_id: status_id, ids: expats_ids, declined_reason: reason },
      httpOptions
    );
  }

  // GET Expat history
  public getEntityHistory(id: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "history/expat/" + id,
      { params: params }
    );
  }

  public getMaritalStatus(params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + expatsUrl + "/get-marital-statuses",
      {
        params: params,
      }
    );
  }

  public getEducationForms(params = {}) {
    return this.http.get<any>(
      environment.gateway_endpoint + expatsUrl + "/get-education-forms",
      {
        params: params,
      }
    );
  }

  public getCitizenships(expatId: number, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + expatsUrl + `/${expatId}/citizenships`,
      {
        params: params,
      }
    );
  }

  public getCitizenshipById(
    expatId: number,
    citizenshipId: number,
    params = {}
  ): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint +
        expatsUrl +
        `/${expatId}/citizenships/${citizenshipId}`,
      {
        params: params,
      }
    );
  }

  public updateCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          expatsUrl +
          `/${expatId}/citizenships/${citizenshipId}`,
        citizenshipUpdateModel,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public updatePrimaryCitizenship(
    expatId: number,
    citizenshipId: number,
    citizenshipUpdateModel = {}
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          expatsUrl +
          `/${expatId}/citizenships/${citizenshipId}/primary`,
        citizenshipUpdateModel,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public createCitizenship(
    expatId: number,
    citizenshipUpdateModel: CitizenshipUpdateModel
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + expatsUrl + `/${expatId}/citizenships`,
        citizenshipUpdateModel,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public deleteCitizenship(
    expatId: number,
    citizenshipId: number
  ): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          expatsUrl +
          `/${expatId}/citizenships/${citizenshipId}`,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  public getExpatContacts(
    clientId: number,
    expatId: number,
    params = {}
  ): Observable<any> {
    return this.http
      .get<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts`,
        {
          params: params,
        }
      )
      .pipe(map((_) => _.result.items));
  }

  public createExpatContact(
    clientId: number,
    expatId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts`,
        expatContact,
        httpOptions
      )
      .pipe(map((_) => _.result));
  }

  public updateExpatContact(
    clientId: number,
    expatId: number,
    contactId: number,
    expatContact = {}
  ): Observable<any> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`,
        expatContact,
        httpOptions
      )
      .pipe(map((_) => _.result));
  }

  public deleteExpatContact(
    clientId: number,
    expatId: number,
    contactId: number
  ): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          `entities/clients/${clientId}/expats/${expatId}/contacts/${contactId}`
      )
      .pipe(map((_) => _.result));
  }

  public getExpatDependents(
    expatId: number,
    params = {}
  ): Observable<ExpatDependent[]> {
    return this.http
      .get<any>(
        environment.gateway_endpoint + `entities/expats/${expatId}/dependents`,
        {
          params: params,
        }
      )
      .pipe(map((_) => _.result.dependents));
  }

  public deleteExpatDependents(
    expatId: number,
    dependentId: number
  ): Observable<ExpatDependent> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint +
          `entities/expats/${expatId}/dependents/${dependentId}`
      )
      .pipe(map((_) => _.result));
  }

  public createExpatDependents(
    params,
    expatId: number
  ): Observable<ExpatDependent> {
    return this.http
      .post<any>(
        environment.gateway_endpoint + `entities/expats/${expatId}/dependents`,
        params,
        httpOptions
      )
      .pipe(map((response) => response.result?.dependent));
  }

  public updateExpatDependents(
    params,
    expatId: number,
    dependentId: number
  ): Observable<ExpatDependent> {
    return this.http
      .put<any>(
        environment.gateway_endpoint +
          `entities/expats/${expatId}/dependents/${dependentId}`,
        params,
        httpOptions
      )
      .pipe(map((response) => response.result?.dependent));
  }

  public getExpatDependentRelationships(
    params = {}
  ): Observable<DictionaryModel[]> {
    return this.http
      .get<any>(environment.gateway_endpoint + dependentRelationshipUrl, {
        params: params,
      })
      .pipe(map((_) => _.result));
  }

  public inviteExpat(id) {
    return this.http.post<any>(
      environment.gateway_endpoint + "entities/expats/invite",
      {
        expat_ids: id,
      }
    );
  }

  // Delete Expat
  public deleteExpat(data: {
    expatId: number;
    reason: string;
  }): Observable<any> {
    return this.http
      .delete<any>(
        environment.gateway_endpoint + expatsUrl + "/" + data.expatId,
        {
          body: {
            reason: data.reason,
          },
        }
      )
      .pipe();
  }
}
