import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "../../environments/environment";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const sessionCookieExists: boolean = this.cookieService.check(
      environment.session_local_storage_key
    );
    const userCookie = localStorage.getItem(environment.user_local_storage_key);

    if (sessionCookieExists && userCookie) {
      const details = JSON.parse(userCookie);
      if (
        details.role.toLowerCase() === "provider" ||
        details.role.toLowerCase() === "super admin"
      ) {
        return true;
      }
    }
    // else return to login
    window.location.href = environment.app_host + "/login";

    return false;
  }
}
