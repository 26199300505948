import { AssignmentData } from "@api/assignments/models/assignment.model";
import { CasePermission } from "../values/case-permission.enum";
import { DocumentData } from "@api/documents/models/document.model";
export enum CaseType {
  ADMIN = "admin",
  EXPAT = "expat",
  DOCUMENT = "document",
}

export enum CaseStatus {
  DONE = "done",
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  CANCELLED = "cancelled",
}
export interface CaseData {
  id: number;
  type: CaseType;
  client_id: number;
  name: string;
  client_name: string;
  label: string;
  expat_id: number;
  expat_name: string;
  case_manager_id: number;
  case_manager_name: string;
  assignment_id: number;
  assignment: AssignmentData;
  start_date: Date;
  end_date: Date;
  total_tasks: number;
  order_id: number;
  order_number: number;
  forecast_end_date: Date;

  status_label: string;
  status: string;

  completion_rate: number;
  deadline: Date;
  subject: CaseSubject;
  services: CaseService[];
  tags: CaseTag[];
  daysLeft?: number;
  statistics: CaseStatistics[];
  tooltipServices?: string[];
  allowed_actions?: AllowedActions;
  permissions: CasePermission[];
  renewal_document?: DocumentData;
}

export interface CaseSubject {
  id: number;
  name: string;
  reference_type: string;
  reference_id: number;
}

export interface CaseService {
  id: number;
  expat_case_id: number;
  service_name: string;
  tags: CaseTag[];
  completion_rate: number;
  status: string;
  statistics: any;
  service_id: number;
  processed_at: Date;
  total_tasks: number;
  total_subtasks: number;
  completed_tasks: number;
  completed_subtasks: number;
  active?: boolean;
  activeStatus?: {
    color: string;
    label: string;
    name: string;
    percent: number;
    value: number;
  };
}

export interface GroupedCaseService<T> extends CaseService {
  tasks?: T[];
}
export interface CaseTag {
  name: string;
  slug: string;
}

export interface CaseStatistics {
  label: string;
  name: string;
  sample_count: number;
  items: CaseStatisticsItem;
}

export interface CaseStatisticsItem {
  label: string;
  name: string;
  percent: number;
  color: string;
  value: number;
}

export interface EligibleUserData {
  id: string;
  role_name: string;
  role_type: string;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  primary_image_path: string;
}

export interface CaseFollower {
  user_id: number;
  user_image: string;
  user_name: string;
  role_name: string;
  permissions: string[];
}

export interface RawCaseFollower {
  models: any;
  user_id: number;
  user_image: string;
  user_name: string;
}

export interface AllowedActions {
  create_follower?: boolean;
  can_add_task?: boolean;
}
