import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserData } from "@api/account";
import { ExpatDependent } from "@modules/expats/models/expat-dependents.model";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { combineLatest, Observable } from "rxjs";
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { UsersService } from "src/app/_services/users.service";

interface Dependent extends ExpatDependent {
  name: string;
}

@Component({
  selector: "app-subject-selector",
  templateUrl: "./subject-selector.component.html",
  styleUrls: ["./subject-selector.component.scss"],
})
export class SubjectSelectorComponent implements OnInit {
  parentForm: UntypedFormGroup;

  @Input() submitEvent: Observable<void>;

  @Input()
  formGroup: FormGroup;

  dependentForm: UntypedFormGroup;
  subjectForm: UntypedFormGroup;

  dependentSearchForm: UntypedFormControl;
  dependents$: Observable<Dependent[]>;
  filteredDependents$: Observable<Dependent[]>;
  currentUser$: Observable<UserData>;

  constructor(
    private readonly userService: UsersService,
    private readonly expatService: ExpatsService,
    private controlContainer: ControlContainer
  ) {
    this.currentUser$ = this.userService.getCurrentUser();
    this.dependentSearchForm = new UntypedFormControl("", Validators.required);
    this.subjectForm = new UntypedFormGroup({
      reference_type: new UntypedFormControl(""),
      name: new UntypedFormControl(""),
      reference_id: new UntypedFormControl("", Validators.required),
    });

    this.dependentForm = new UntypedFormGroup({
      enabled: new UntypedFormControl(false),
      dependent: new UntypedFormControl("", Validators.required),
    });
  }

  ngOnInit() {
    this.submitEvent.subscribe(() => {
      if (!this.dependentForm.get("dependent").valid) {
        this.dependentForm.get("dependent").setErrors({ required: true });
        this.dependentForm.get("dependent").markAsTouched();
      }
    });

    this.parentForm = <UntypedFormGroup>this.controlContainer.control;
    this.parentForm.setControl("subject", this.subjectForm);

    combineLatest([
      this.dependentForm.valueChanges.pipe(startWith({ enabled: false })),
      this.parentForm.get("expat_id").valueChanges,
      this.parentForm.get("expat_name").valueChanges,
    ]).subscribe(([dependent, expat_id, expat_name]) => {
      let subjectData = {
        reference_id: expat_id,
        reference_type: "expat",
        name: expat_name,
      };
      if (dependent.enabled) {
        const dep = this.dependentForm.get("dependent").value;
        subjectData = {
          reference_id: dep.id,
          reference_type: "dependent",
          name: dep.name,
        };
      }
      this.parentForm.get("subject").patchValue(subjectData);
    });

    this.dependentForm.get("enabled").valueChanges.subscribe((data) => {
      const control = this.dependentForm.get("dependent");
      if (data) {
        control.enable();
      } else {
        control.disable();
      }
    });
    this.dependentForm.disable();
    this.dependents$ = this.parentForm.get("expat_id").valueChanges.pipe(
      tap(() => this.dependentForm.disable({ emitEvent: false })),
      switchMap((expat_id) =>
        this.expatService.getExpatDependents(expat_id, null)
      ),
      map((response) =>
        response.map((depen) => ({
          ...depen,
          name: [depen.first_name, depen.last_name].join(" "),
        }))
      ),
      tap(() => {
        this.dependentForm.enable({ emitEvent: false });

        if (!this.dependentForm.get("enabled").value) {
          this.dependentForm.get("dependent").disable();
        }
      }),
      shareReplay(1)
    );
    if (this.formGroup.controls.dependent_id.value) {
      this.dependentForm.patchValue({
        enabled: true,
      });
      this.dependents$.pipe(take(1)).subscribe((dependents) => {
        this.dependentForm.patchValue({
          dependent: dependents.find(
            (dep) => dep.id === this.formGroup.controls.dependent_id.value
          ),
        });
      });
    }

    this.filteredDependents$ = combineLatest([
      this.dependentSearchForm.valueChanges.pipe(startWith("")),
      this.dependents$,
    ]).pipe(
      map(([searchInput, dependents]) => dependents),
      shareReplay(1)
    );
  }
}
