import { Component, Input, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { ExpensesService } from "@api/expenses/services/expenses.service";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-covered-by-selector",
  templateUrl: "./covered-by-selector.component.html",
})
export class CoveredBySelectorComponent implements OnInit {
  @Input() caseId;
  @Input() value;
  public expenseForm: UntypedFormGroup;
  public coveredByControl: UntypedFormControl;
  public isLoading = false;
  public coveredByList;
  private unsubscribe$: Subject<void>;
  public coveredBy: string;

  handleCoveredBy(event: MatSelectChange) {
    this.coveredBy = event.source.triggerValue;
  }

  constructor(
    private controlContainer: ControlContainer,
    private expensesService: ExpensesService
  ) {
    this.coveredByControl = new UntypedFormControl(
      this.value ? this.value : "",
      Validators.required
    );
  }

  ngOnInit() {
    this.expenseForm = <UntypedFormGroup>this.controlContainer.control;
    this.expenseForm.setControl("covered_by", this.coveredByControl);

    this.unsubscribe$ = new Subject();
    this.expensesService
      .coveredByList(this.caseId)
      .pipe(take(1))
      .subscribe((type) => {
        this.coveredByList = type["result"];
        this.isLoading = false;
      });
  }
}
