import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserData } from "@api/account";
import { CaseData } from "@api/cases";

import { Observable, Subject, take } from "rxjs";
import { UsersService } from "src/app/_services/users.service";

@Component({
  selector: "app-admin-case-form",
  templateUrl: "admin-case-form.component.html",
  styleUrls: ["./admin-case-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCaseFormComponent implements OnInit {
  @Input()
  data: { case: Partial<CaseData> };

  caseForm: UntypedFormGroup;

  user$: Observable<UserData>;

  @Input()
  submitForm: Subject<void> = new Subject();

  @Output()
  initialized: EventEmitter<any> = new EventEmitter();

  constructor(public usersService: UsersService) {}
  ngOnInit(): void {
    this.caseForm = new UntypedFormGroup({
      name: new UntypedFormControl("", Validators.required),
      provider_name: new UntypedFormControl(""),
      client_id: new UntypedFormControl(""),
      client_name: new UntypedFormControl(""),
      services: new UntypedFormControl([], Validators.required),
    });
    this.usersService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.caseForm.patchValue({
          provider_name: user.name,
          client_id: null,
          client_name: "",
        });
      });

    if (this.data?.case) {
      this.caseForm.patchValue(this.data.case);
    }

    this.initialized.emit({
      form: this.caseForm,
    });
  }
}
