import { Component } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { passwordMatch } from "src/app/_directives/registration-validator";
import { ResetPassword } from "src/app/_models/reset-password";
import { AlertService } from "../../../_services/alert.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { passwordStrengthValidator } from "@modules/shared/_validators/password-strength-validator";
import { confirmPasswordValidator } from "@modules/shared/_validators/confirm-password-validator";

@Component({
  selector: "app-reset-password-form",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent {
  isLoading: boolean;
  reset: ResetPassword;
  token: string;
  hide = true;
  hideError: boolean = false;
  hide_confirmed_pass = true;

  passwordFormGroup = new FormGroup(
    {
      password: new FormControl("", [
        passwordStrengthValidator(),
        confirmPasswordValidator("repeatPassword", true),
      ]),
      repeatPassword: new FormControl("", [
        confirmPasswordValidator("password"),
      ]),
    },
    { validators: [passwordMatch()] }
  );

  registrationFormGroup = new FormGroup({
    username: new FormControl("", Validators.required),
    passwordFormGroup: this.passwordFormGroup,
  });

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.reset = new ResetPassword();

    this.route.params.subscribe((params) => {
      this.token = params.token;
    });
  }

  resetPassword() {
    this.isLoading = true;
    this.reset = {
      password: this.passwordFormGroup.value.password,
      repeatPassword: this.passwordFormGroup.value.repeatPassword,
      reset_token: this.token,
    };
    this.reset.reset_token = this.token;
    this.authenticationService.resetPassword(this.reset).subscribe(
      (data) => {
        this.isLoading = false;
        this.router.navigateByUrl("/dashboard");
        if (data.message) {
          this.alertService.success(data.messages);
        }
      },
      (error) => {
        this.isLoading = false;
        this.alertService.errors(error.error.errors);
      }
    );
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }
}
