<div class="tag-wrapper">
  <div class="select-group-block">
    <mat-label> {{ "LABEL.SELECT-USERS" | translate }} </mat-label>
    <ng-select
      class="multiple-select"
      [formGroup]="documentForm"
      [addTag]="false"
      [placeholder]="'GENERALS.SELECT-USERS' | translate"
      [formControl]="userControl"
      [items]="users"
      [searchable]="true"
      [clearable]="true"
      [multiple]="true"
      bindLabel="text"
      (search)="onSearch($event)"
      notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
      (focusout)="focusOut()"
      appearance="outline"
      appendTo="body"
    >
    </ng-select>
    <app-form-errors [control]="documentForm.get('users_ids')">
    </app-form-errors>
  </div>
</div>
