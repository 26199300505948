<!-- modal -->
<div [ngClass]="{ 'modal-wrapper': setModalOpen }">
  <ngx-smart-modal
    id="confPay_{{ file_id }}"
    identifier="confPay_{{ file_id }}"
    (onOpen)="isModalOpen()"
    (onAnyCloseEvent)="isModalClose()"
  >
    <div class="modal-title-wrapper">
      <div class="modal-title">
        {{ "GENERALS.CONFIRM-PAYMENT" | translate }}
      </div>
    </div>

    <div class="clear"></div>

    <div class="custom-modal-body">
      <ng-container *ngIf="isLoading">
        <app-loader-spinner-general
          [loadingType]="LoadingType.BIG_INFO"
        ></app-loader-spinner-general>
      </ng-container>

      <div class="modal-form" *ngIf="!isLoading">
        <div class="row">
          <div class="form-group w-50">
            <div class="row">
              <div class="form-group w-100">
                <label>{{ "GENERALS.PAYMENT-METHOD" | translate }} *</label>
                <ng-select
                  class="customiz-select"
                  [items]="paymentMethods"
                  bindLabel="text"
                  bindValue="id"
                  [(ngModel)]="paymentConfirmationObj.payment_method"
                  placeholder="{{
                    'GENERALS.SELECT-PAYMENT-METHOD' | translate
                  }}"
                  notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="form-group w-50">
            <div class="fix-position-calendar-modal">
              <div class="modal-calendar-wrapper">
                <label>{{ "GENERALS.SELECT-DATE" | translate }} *</label>
                <app-date-range-calendar
                  [singleDate]="paymentConfirmationObj.payment_date"
                  [placeholder]="'GENERALS.SELECT-DATE' | translate"
                  (change)="setInvoicePaymentDate($event)"
                ></app-date-range-calendar>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group w-50">
            <label>{{ "GENERALS.INVOICE-TRANSACTION-ID" | translate }}</label>
            <input
              type="text"
              name="transaction_id"
              class="form-control"
              [(ngModel)]="paymentConfirmationObj.transaction_id"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="clear"></div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn gray-bg modal-button"
        (click)="cancelModal()"
      >
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>

      <button
        [disabled]="isLoading"
        type="button"
        class="btn orange-bg modal-button"
        (click)="confirmPayment()"
      >
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.CONFIRM" | translate }}
      </button>
    </div>
  </ngx-smart-modal>
</div>
<!-- end modal -->
