import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AccountActions, AccountSelectors, UserData } from "@api/account";
import {
  CustomPermission,
  CustomPermissionData,
  ModalData,
  PermissionData,
  ResourcePermissions,
} from "@api/users/models/permission.model";
import { environment } from "@environment/environment";
import { Store } from "@ngrx/store";
import { Observable, timer } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { UserStatuses } from "../_models/_statuses/user-statuses";
import { ProviderData } from "../_models/provider";
import { User } from "../_models/user";
import { filterNullish } from "../lib";

const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 mins

export interface ChangePasswordInput {
  current_password: string;
  password: string;
  password_confirmation: string;
}

@Injectable({ providedIn: "root" })
export class UsersService {
  private cache$: Observable<any>;
  private timer$;
  user: any;

  constructor(private http: HttpClient, private store: Store) {}

  // GET current user details
  getCurrentEntity(): Observable<any> {
    if (!this.cache$) {
      this.timer$ = timer(0, REFRESH_INTERVAL);

      // For each tick make an http request to fetch new data
      this.cache$ = this.timer$.pipe(
        switchMap((_) => this.requestCurrentUser()),
        shareReplay(CACHE_SIZE)
      );
    }
    return this.cache$;
  }

  // GET current user details
  requestCurrentUser(): Observable<any> {
    const user = JSON.parse(
      localStorage.getItem(environment.user_local_storage_key)
    );
    if (user && user.role) {
      return this.http
        .get<any>(
          environment.gateway_endpoint + "entities/providers/getCurrentUser"
        )
        .pipe(
          map((data) => {
            data = { user: { ...data } };
            this.user = data;
          })
        );
    }
  }

  // GET current user details
  getCurrentUser(forceReload = false): Observable<UserData> {
    if (forceReload) {
      this.store.dispatch(AccountActions.reloadAccount());
    }

    return this.store.select(AccountSelectors.selectUser).pipe(
      filterNullish(),
      map((user) => ({ ...user }))
    );
  }

  /**
   * patch Update password
   */
  updateCurrentPassword(data: ChangePasswordInput): Observable<any> {
    return this.http
      .patch<any>(environment.gateway_endpoint + "users/update-password", data)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * Save provider company profile
   * @param provider
   */
  saveProviderProfile(provider: ProviderData): Observable<any> {
    return this.http
      .post<any>(
        environment.gateway_endpoint +
          "entities/providers/" +
          provider.id +
          "/register",
        provider
      )
      .pipe(
        map((model) => {
          this.getCurrentUser(true);
          return model;
        })
      );
  }

  /**
   * Get user details
   * with search, filters and pagination
   */
  getUserDetails(user_id): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "users/" + user_id
    );
  }

  /**
   * List users
   * with search, filters and pagination
   */
  list(params = {}, context = null): Observable<any> {
    return this.http.get<any>(environment.gateway_endpoint + "users", {
      params,
      context,
    });
  }

  /**
   * Get user search fields
   */
  getSearchFields(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "entities/expats/search-fields"
    );
  }

  /**
   * POST Create User for provider
   */
  createUser(providerId, user): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${providerId}/users`,
        user
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  /**
   * PUT Update User for provider
   */
  updateUserAccount(user): Observable<any> {
    return this.http
      .put<any>(environment.gateway_endpoint + "users/" + user.id, user)
      .pipe(
        map((model) => {
          this.getCurrentUser(true);
          return model;
        })
      );
  }

  // Update user Status
  updateStatusBulk(status_id, user_id, reason = null): Observable<any> {
    const body = { status_id: status_id, user_id: user_id };
    if (reason) {
      body["reason"] = reason;
    }
    return this.http.patch<any>(
      environment.gateway_endpoint + "users/status-update",
      body
    );
  }

  getStatusTransitions(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "users/statuses-transition"
    );
  }

  getUserRoles(): Observable<any> {
    return this.http.get(environment.gateway_endpoint + "provider/roles");
  }

  getCurrentUserStatus(): Observable<any> {
    return this.http.get(
      environment.gateway_endpoint + "users/get-current-user-status"
    );
  }

  getUserRolePermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/role`
    );
  }

  getUserDirectPermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct`
    );
  }

  createUserDirectPermissions(
    userId: number,
    permissions: { permissions: string[] }
  ): Observable<string[]> {
    return this.http.post<string[]>(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct`,
      permissions
    );
  }

  deleteUserDirectPermission(
    userId: number | string,
    permissionId: number | string
  ): Observable<any> {
    return this.http.delete(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct/${permissionId}`
    );
  }

  getAvailableDirectPermissions(userId: number): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/direct/available`
    );
  }

  getAvailableCustomPermissions(
    resourceType: string
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/permissions/custom`,
      {
        params: {
          resource: resourceType,
        },
      }
    );
  }

  getPermissionResources(): Observable<{ result: string[] }> {
    return this.http.get<{ result: string[] }>(
      `${environment.gateway_endpoint}users/permissions/resources`
    );
  }

  grantCustomPermission(
    userId: string,
    customPermission: CustomPermission
  ): Observable<CustomPermissionData> {
    return this.http.post<CustomPermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/custom`,
      customPermission
    );
  }

  getUserPermissions(
    userId: string,
    resourceType: string
  ): Observable<PermissionData> {
    return this.http.get<PermissionData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/custom`,
      {
        params: {
          resource: resourceType,
        },
      }
    );
  }

  getUserModels(
    userId: string,
    resourceType: string,
    params = {}
  ): Observable<ModalData> {
    return this.http.get<ModalData>(
      `${environment.gateway_endpoint}users/${userId}/permissions/models`,
      {
        params: {
          ...params,
          resource: resourceType,
        },
      }
    );
  }

  getResourcePermissions(
    providerId: number,
    resourceType: string,
    resourceId: number
  ): Observable<{ result: User[] }> {
    return this.http.get<{ result: User[] }>(
      `${environment.gateway_endpoint}entities/providers/${providerId}/${resourceType}s/${resourceId}/permissions`
    );
  }

  grantResourcePermission(
    providerId: number,
    resourceType: string,
    resourceId: number,
    permissions: ResourcePermissions
  ): Observable<{ result: User[] }> {
    return this.http.post<{ result: User[] }>(
      `${environment.gateway_endpoint}entities/providers/${providerId}/${resourceType}s/${resourceId}/permissions`,
      permissions
    );
  }

  deleteEntityPermission(
    userId: number,
    model: number,
    resource: string
  ): Observable<any> {
    return this.http.delete(
      `${environment.gateway_endpoint}users/${userId}/permissions/custom`,
      {
        params: {
          resource: resource,
          models: model,
        },
      }
    );
  }

  isUserActive(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user) => user.status_id === UserStatuses.statuses.pending)
    );
  }
}
