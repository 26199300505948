<app-loader-spinner-general
  *ngIf="!id"
  class="d-flex justify-content-end"
  [loadingType]="LoadingType.BUTTONS"
></app-loader-spinner-general>
<app-loader-spinner-general
  *ngIf="!id"
  [loadingType]="LoadingType.FILTER"
></app-loader-spinner-general>
<app-loader-spinner-general
  *ngIf="!id"
  [loadingType]="LoadingType.TABLE"
></app-loader-spinner-general>

<div class="details-body-wrapper">
  <ng-container *ngIf="id">
    <app-documents
      [entityType]="'orders'"
      [entityId]="id"
      [hideTitle]="true"
      [docType]="'docs'"
    ></app-documents>
  </ng-container>
</div>
