import { createAction, props } from "@ngrx/store";
import { RFQData, RFQProviderData, RFQServiceOfferData } from "@api/rfq";
import { Update } from "@ngrx/entity";
import { ModelChangeData } from "@api/case-tasks/models/model-change.data";

export const loadRfq = createAction(
  "[RFQ/API] Load RFQs",
  props<{ items: RFQData[] }>()
);

export const selectRfq = createAction(
  "[RFQ/API] Select RFQ",
  props<{ rfqId: number }>()
);

export const fetchRfq = createAction(
  "[RFQ/API] Fetch RFQ",
  props<{ rfqId: number }>()
);

export const fetchRfqSuccess = createAction(
  "[RFQ/API] Fetch RFQ Success",
  props<{ item: RFQData }>()
);

export const fetchRfqError = createAction(
  "[RFQ/API] Fetch RFQ Error",
  props<{ error: any }>()
);

export const addRfq = createAction(
  "[RFQ/API] Add RFQ",
  props<{ item: RFQData }>()
);

export const addMany = createAction(
  "[RFQ/API] Add RFQs",
  props<{ items: RFQData[] }>()
);

export const upsertRfq = createAction(
  "[RFQ/API] Upsert RFQ",
  props<{ item: RFQData }>()
);

export const upsertMany = createAction(
  "[RFQ/API] Upsert RFQs",
  props<{ items: RFQData[] }>()
);

export const updateRfq = createAction(
  "[RFQ/API] Update RFQ",
  props<{ item: Update<RFQData> }>()
);

export const updateMany = createAction(
  "[RFQ/API] Update RFQs",
  props<{ cases: Update<RFQData>[] }>()
);

// USED TO UPDATE RFQ
export const requestUpdateRfq = createAction(
  "[RFQ/API] Request Update RFQ",
  props<{ item: Update<RFQData> }>()
);

export const requestUpdateRfqSuccess = createAction(
  "[RFQ/API] Request Update RFQ Success",
  props<{
    item: RFQData;
    other_changes?: Array<ModelChangeData>;
  }>()
);

export const requestUpdateRfqError = createAction(
  "[RFQ/API] Request Update RFQ Error",
  props<{ error: any }>()
);

export const deleteRfq = createAction(
  "[RFQ/API] Delete RFQ",
  props<{ id: string }>()
);

export const deleteMany = createAction(
  "[RFQ/API] Delete RFQs",
  props<{ ids: string[] }>()
);

export const clearRfq = createAction("[RFQ/API] Clear RFQs");

// USED TO UPDATE PROVIDER
export const requestUpdateProvider = createAction(
  "[RFQ/API] Request Update Provider",
  props<{ provider: RFQProviderData }>()
);

// USED TO SUBMIT OFFERS
export const requestSubmitOffer = createAction(
  "[RFQ/API] Request Submit Offer",
  props<{ offer: Partial<RFQServiceOfferData> }>()
);

// USED TO DELETE OFFERS
export const requestDeleteOffer = createAction(
  "[RFQ/API] Request Delete Offer",
  props<{ offerId: number }>()
);
