<div
  class="mobile-header-middle-wrapper d-flex px-2 justify-content-between align-items-center"
>
  <div>
    <div (click)="openMobileNav()" class="mobile-menu-btn btn">
      <i class="icon-menu"></i> {{ "GENERALS.MENU" | translate }}
    </div>
    <a
      class="btn orange-bg mobile-middle-header-btn ms-2"
      (click)="goToMarketplace()"
    >
      <i class="icon-right"></i> {{ "GENERALS.SHOP" | translate }}
    </a>
    <div class="mobile-navigation-wrapper">
      <div (click)="closeMobileNav()" class="close-mobile-menu-btn">
        <i class="icon-cancel-2"></i>
      </div>
      <div class="mobile-navigation-items">
        <ul>
          <ng-container *ngFor="let nav of menuList.children">
            <ng-container *ngIf="this.canDisplay(nav) | async">
              <li
                (click)="navigateTo(nav)"
                [ngClass]="{
                  'active-nav': nav.active,
                  'has-sub-nav': nav.children && nav.children.length,
                  'sub-is-open':
                    nav.children && nav.children.length && nav.active
                }"
                expand-menu
              >
                <a>
                  <i [ngClass]="nav.icon"></i>
                  {{ nav.label | translate }}
                  <span
                    class="side-menu-badge orange-bg"
                    *ngIf="nav.badge | async as badge"
                    >{{ badge }}</span
                  >
                </a>
              </li>
              <div
                class="sub-navigation open-sub-navigation"
                *ngIf="nav.children && nav.children.length"
              >
                <ul>
                  <ng-container *ngFor="let subNav of nav.children">
                    <li
                      (click)="navigateTo(subNav)"
                      [ngClass]="{
                        'active-sub-nav': subNav.active
                      }"
                      expand-menu
                    >
                      <a>
                        <i [ngClass]="subNav.icon"></i>
                        {{ subNav.label | translate }}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </ng-container>
          </ng-container>

          <!-- end messaging -->
        </ul>
      </div>
    </div>
  </div>

  <div>
    <div class="eu-funds-img-header-xs">
      <div class="eu-funds-img">
        <img src="assets/images/eu/eu_fedr.svg" alt="image" />
        <img src="assets/images/eu/gr.svg" alt="image" />
        <img src="assets/images/eu/regio.png" alt="image" />
        <img src="assets/images/eu/is.svg" alt="image" />
      </div>
    </div>
    <!-- language selection -->
    <div class="header-top-lang fl-l">
      <app-translate></app-translate>
    </div>
  </div>
</div>
