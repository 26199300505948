<ng-template #loading>
  <app-loader-spinner-general
    [loadingType]="LoadingType.INFO"
  ></app-loader-spinner-general>
</ng-template>

<ng-container *ngIf="countries$ | async as countries; else loading">
  <div class="white-box-wrapper m-t-15">
    <div class="white-box-row">
      <div class="white-box-header">
        <div class="medium-title fl-l">
          {{ "LABEL.OFFICES" | translate }}
        </div>
        <button
          class="white-box-header-action fl-r orange btn transparent-bg p-0"
          (click)="addOrEdit(countries)"
        >
          <mat-icon svgIcon="plus-circle" class="button-icon"></mat-icon>
          {{ "GENERALS.ADD" | translate }}
        </button>
      </div>
      <div class="clear"></div>
      <div class="white-box-body office-card">
        <ng-template #loadOffice>
          <app-loader-spinner-general
            [loadingType]="LoadingType.INFO"
          ></app-loader-spinner-general>
        </ng-template>
        <ng-container *ngIf="offices$ | async as offices; else loadOffice">
          <ng-container *ngFor="let office of offices; let i = index">
            <div class="inserted-white-box">
              <div class="white-box-body billing-details-list">
                <div class="row">
                  <div class="w-50">
                    <span class="m-b-10 d-flex">
                      {{ "GENERALS.COUNTRY" | translate }}
                    </span>
                    <p class="case-label">
                      {{ office.countryCode | notAvailable }}
                    </p>
                  </div>

                  <div class="w-50">
                    <span class="m-b-10 d-flex">
                      {{ "LABEL.CITY" | translate }}
                    </span>
                    <p class="case-label">
                      {{ office.city | notAvailable }}
                    </p>
                  </div>

                  <div class="w-50">
                    <span class="m-b-10 d-flex">
                      {{ "LABEL.COUNTY-STATE" | translate }}
                    </span>
                    <p class="case-label">
                      {{ office.county | notAvailable }}
                    </p>
                  </div>

                  <div class="w-50">
                    <span class="m-b-10 d-flex">
                      {{ "LABEL.ADDRESS" | translate }}
                    </span>
                    <p class="case-label">
                      {{ office.address | notAvailable }}
                    </p>
                  </div>
                </div>
                <div class="white-box-innter-action fl-r">
                  <button
                    class="pointer orange btn transparent-bg p-0"
                    (click)="addOrEdit(countries, office)"
                  >
                    <mat-icon svgIcon="edit" class="button-icon"></mat-icon>
                  </button>
                  <button
                    class="pointer orange btn transparent-bg p-0"
                    (click)="delete(office)"
                  >
                    <mat-icon
                      svgIcon="trash-bin"
                      class="button-icon"
                    ></mat-icon>
                  </button>
                </div>
              </div>
              <div class="clear"></div>
            </div>
            <hr *ngIf="i + 1 < offices.length" />
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
