<div class="white-box-wrapper m-0">
  <div class="white-box-row">
    <div class="white-box-header">
      <div class="medium-title fl-l">
        {{ "DOCUMENTS.FILES" | translate }}
      </div>
    </div>
    <div [formGroup]="form" class="white-box-row m-t-15">
      <file-upload
        formControlName="files"
        class="w-100"
        [control]="fileUploadControl"
      >
        <ng-template #placeholder>
          <span>{{ "DOCUMENTS.DROP-FILE-TO-UPLOAD" | translate }} </span>
          <span class="orange"> {{ "DOCUMENTS.BROWSE" | translate }}</span>
          <div class="label">
            {{ "DOCUMENTS.ACCEPTED-FILES" | translate }}
          </div>
        </ng-template>
      </file-upload>
      <div class="error-wrapper">
        <app-form-errors [control]="form.get('files')"> </app-form-errors>
      </div>
    </div>
    <div
      (click)="add()"
      class="white-box-header-action edit-info fl-r add mt-3"
    >
      <i class="icon-upload-cloud"></i>
      {{ "LABEL.FILE-UPLOAD" | translate }}
    </div>
    <div class="clear"></div>
    <div class="white-box-body pt-0">
      <ng-container>
        <div class="table-wrapper">
          <div class="table-responsive">
            <table>
              <tbody>
                <tr *ngFor="let file of documentData.files; let i = index">
                  <td class="no-right-border">
                    <img
                      class="doc-type-icon"
                      [src]="
                        'assets/images/doc-type-' +
                        file.type.toLowerCase() +
                        '.svg'
                      "
                      alt=""
                    />
                    {{ file.name }}
                  </td>

                  <td class="ta-r">
                    <!-- table action with overlay -->
                    <div toggle-action-table class="table-action-wrapper">
                      <i class="icon-dot-3"></i>

                      <div class="table-action">
                        <ul>
                          <li (click)="download(file.id)">
                            {{ "DOCUMENTS.DOWNLOAD" | translate }}
                          </li>
                          <li (click)="delete(file.id)">
                            {{ "DOCUMENTS.DELETE" | translate }}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- end table action with overlay -->
                  </td>
                  <!-- <hr *ngIf="document.files && i < document.files.length - 1" /> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="!documentData.files || documentData.files.length === 0"
      >
        <div class="p-l-20 gray">
          {{ "DOCUMENTS.NO-FILES-UPLOADED" | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
