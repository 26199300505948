import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "../../../../_services/alert.service";
import { StatusModel } from "../../models/status.model";
import { ConfirmActionModalComponent } from "../confirm-action-modal/confirm-action-modal.component";

@Component({
  selector: "app-update-status",
  templateUrl: "./update-status.component.html",
})
export class UpdateStatusComponent implements OnChanges {
  public statuses: StatusModel[];

  @Input() service;
  @Input() currentStatus;
  @Input() currentItem;

  @Output() statusChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog, private alertService: AlertService) {}

  ngOnChanges() {
    this.getStatusTransitions();
  }

  public getStatusTransitions() {
    this.service.statusTransitions().subscribe((data) => {
      this.statuses = data[this.currentStatus];
    });
  }

  openConfirmAction(action) {
    const ids = [this.currentItem.id];

    if (action.needs_confirmation || action.needs_reason) {
      const dialogRef = this.dialog.open(ConfirmActionModalComponent, {
        data: {
          action: action,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.updateStatus(action.id, ids, result);
        }
      });
    } else {
      this.updateStatus(action.id, ids, null);
    }
  }

  updateStatus(status, ids, reason) {
    this.service.updateStatus(ids, status, reason).subscribe(
      (data) => {
        this.statusChanged.emit();
      },
      (error) => {
        if (error.error.errors) {
          this.alertService.errors(error.error.errors);
        }
      }
    );
  }
}
