import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '@environment/environment';
import { CookieService } from 'ngx-cookie-service';
import { ProvidersService } from '../_services/providers.service';
import { ProviderStatuses } from '../_models/_statuses/provider-statuses';

@Injectable()
export class ProfileGuard implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private service: ProvidersService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const userCookie = localStorage.getItem(environment.user_local_storage_key);
    if (userCookie) {
      const user = JSON.parse(userCookie);
      if (user && user.entity_id) {
        // get entity details to check its status
        this.service.getProvider(user.entity_id).subscribe(
          (data) => {
            const provider = data.result;
            switch (provider.status_id) {
              case ProviderStatuses.statuses.incomplete: {
                this.router.navigateByUrl('/create-profile').then();
                return false;
              }

              case ProviderStatuses.statuses['no-categories']: {
                this.router.navigateByUrl('/service-portfolio').then();
                return false;
              }

              case ProviderStatuses.statuses.active:
                return true;

              case ProviderStatuses.statuses['terms-not-accepted']:
                this.router.navigateByUrl('/dashboard').then();
                return true;

              default:
                this.router.navigateByUrl('/settings').then();
                return false;
            }
          },
          (_error) => {
            return false;
          }
        );
      }

      return true;
    }

    return false;
  }
}
