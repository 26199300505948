import { EntityUser } from "./entity.user";
import { StripeAccount } from "./stripe-account";

export class User {
  id: number;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone_prefix: string;
  phone_code: string;
  phone: number;
  company_name: string;
  department_id: string;
  company_position: string;
  profile_image: string;
  last_login: string;
  created_at: string;
  updated_at: string;
  role: string;
  role_id: number;
  role_name: string;
  permissions: Array<any>;
  status: string;
  is_primary: boolean;
  username: string;
  password: string;
  main_register: number;
  company_legal_name: string;
  primary_image_path: string;
  entity_type: string;
  country_code: any;
  entity_details: EntityUser;
  entity_id: number;
  stripe_account: StripeAccount;
  status_id: number;
  status_name: string;
}
