<div class="custom-modal-wrapper add-expat p-0">
  <div class="modal-title-custom p-20">
    {{ data.action.text | translate }}
  </div>
  <div class="modal-body-custom p-20 m-0">
    <div class="row">
      <div class="form-group w-50 p-0">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.NAME" | translate }}</mat-label>
          <input matInput [formControl]="nameControl" />
          <mat-error>
            <app-form-errors [control]="taskForm.get('name')">
            </app-form-errors>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group w-50 p-0">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.DURATION" | translate }}</mat-label>
          <input matInput type="number" [formControl]="durationControl" />
          <mat-error>
            <app-form-errors [control]="taskForm.get('duration')">
            </app-form-errors>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group w-50 p-0">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "LABEL.START-AFTER" | translate }}</mat-label>
          <mat-select
            [formControl]="startAfterControl"
            (selectionChange)="handleStartAfter($event.value)"
          >
            <mat-option
              *ngFor="let task of startAfterOptions"
              [disabled]="task.disable"
              [value]="task.id"
            >
              <div
                [ngClass]="{
                  'm-l-10': task.subtask
                }"
              >
                #{{ task.id }} - {{ task.name }}
              </div>
            </mat-option>
          </mat-select>
          <mat-error>
            <app-form-errors [control]="taskForm.get('dependent_task_id')">
            </app-form-errors>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group w-50 p-0">
        <mat-form-field appearance="outline" class="w-100">
          <mat-select
            [formControl]="assigneeControl"
            #singleSelect
            [placeholder]="'TASKS.TASK_MANAGER' | translate"
          >
            <mat-option *ngFor="let user of users$ | async" [value]="user.id">
              <div class="image">
                <ng-container *ngIf="!user.primary_image_path">
                  <img
                    *ngIf="user.name"
                    [src]="
                      'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                      user.name
                    "
                  />

                  <img
                    *ngIf="!user.name"
                    [src]="
                      'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                      user.first_name +
                      user.last_name
                    "
                  />
                </ng-container>

                <img
                  *ngIf="user.primary_image_path"
                  [src]="user.primary_image_path"
                  [alt]="
                    'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                    user.first_name +
                    user.last_name
                  "
                />
              </div>
              <ng-container *ngIf="user.name; else twoNamesOption">{{
                user.name
              }}</ng-container>

              <ng-template #twoNamesOption
                >{{ user.first_name }} {{ user.last_name }}</ng-template
              >

              {{ user?.role_type ? "(" + user.role_type + ")" : "" }}
            </mat-option>
          </mat-select>
          <mat-error>
            <app-form-errors [control]="taskForm.get('assignee_id')">
            </app-form-errors>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="clear"></div>

  <div class="modal-footer-custom bottom-buttons p-20">
    <ng-container *ngIf="!isLoading; else loadingButtons">
      <button class="btn gray-bg fl-l modal-button" (click)="close()">
        <mat-icon svgIcon="cancel"></mat-icon>
        {{ "GENERALS.CANCEL" | translate }}
      </button>
      <button class="btn orange-bg fl-r modal-button" (click)="submit()">
        <mat-icon svgIcon="save"></mat-icon>
        {{ "GENERALS.SAVE" | translate }}
      </button>
    </ng-container>
    <ng-template #loadingButtons>
      <app-loader-spinner-general
        [loadingType]="LoadingType.BUTTONS"
        [repeat]="2"
      ></app-loader-spinner-general>
    </ng-template>
    <div class="clear"></div>
  </div>
</div>
