import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { AlertService } from "src/app/_services/alert.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"],
})
export class ConfirmEmailComponent implements OnInit {
  isLoading;
  id;
  succesConfirm;
  confirmMsg;
  isLogged;
  constructor(
    private service: AuthenticationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.id = params.id;
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem(environment.user_local_storage_key)) {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }
    this.mailConfirmation();
  }

  mailConfirmation() {
    const params = {};
    params["user_token"] = this.id;
    this.service.mailConfirmation(params).subscribe(
      (res) => {
        if (res.message) {
          // this.alertService.success(res.message);
          this.succesConfirm = true;
          this.confirmMsg = res.message;
        }
        this.isLoading = false;
        setTimeout(() => {
          if (this.isLogged) {
            this.router.navigate(["/dashboard"]);
          } else {
            this.router.navigate(["/login"]);
          }
        }, 3000);
      },
      (error) => {
        if (error.error.errors) {
          // this.alertService.errors(error.error.errors);
          this.succesConfirm = false;
          this.confirmMsg = error.error.errors;
        }
        this.isLoading = false;
      }
    );
  }
}
