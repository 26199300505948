import { Component, OnInit } from "@angular/core";
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";

@Component({
  selector: "app-permissions-selector",
  templateUrl: "./permissions-selector.component.html",
  styleUrls: ["./permissions-selector.component.scss"],
})
export class PermissionsSelectorComponent implements OnInit {
  public permissionsControl: UntypedFormControl = new UntypedFormControl([]);
  public documentForm: UntypedFormGroup;
  public options = [
    {
      value: "update document details",
      label: "Update",
    },
    {
      value: "view and download document details",
      label: "View and download",
    },
    {
      value: "delete document details",
      label: "Delete",
    },
  ];

  constructor(private controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    this.documentForm = <UntypedFormGroup>this.controlContainer.control;
    this.documentForm.setControl("permissions", this.permissionsControl);

    this.documentForm
      .get("permissions")
      .setValue(["view and download document details"]);
  }
}
