<form [formGroup]="contactFrom" class="create-contact p-20">
  <div class="row">
    <mat-form-field appearance="outline" class="col-12 col-md-6">
      <mat-label> {{ "LABEL.LABEL" | translate }} </mat-label>
      <input matInput type="text" formControlName="label" />
      <mat-error>
        <app-form-errors [control]="contactFrom.get('label')"></app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-6">
      <mat-label> {{ "LABEL.NAME" | translate }} </mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error>
        <app-form-errors [control]="contactFrom.get('name')"></app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-6">
      <mat-label> {{ "LABEL.EMAIL" | translate }} </mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error>
        <app-form-errors [control]="contactFrom.get('email')"></app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-12 col-md-6">
      <mat-label> {{ "LABEL.PHONE-NUMBER" | translate }} </mat-label>
      <input matInput type="text" formControlName="phoneNumber" />
      <mat-error>
        <app-form-errors
          [control]="contactFrom.get('phoneNumber')"
        ></app-form-errors>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <mat-checkbox class="col" formControlName="isPrimary">{{
      "LABEL.IS_PRIMARY" | translate
    }}</mat-checkbox>
    <mat-checkbox class="col" formControlName="isEmergency">{{
      "LABEL.IS_EMERGENCY" | translate
    }}</mat-checkbox>
  </div>
  <div class="row">
    <mat-checkbox class="col" formControlName="hasAddress">{{
      "LABEL.HAS_ADDRESS" | translate
    }}</mat-checkbox>
  </div>
</form>
<form
  [formGroup]="addressForm"
  [ngClass]="{ 'd-none': !contactFrom.get('hasAddress').value }"
  class="p-20"
>
  <div class="row">
    <mat-form-field appearance="outline" class="w-50">
      <mat-label> {{ "LABEL.COUNTRY" | translate }} </mat-label>
      <mat-select formControlName="countryCode" #singleSelect>
        <mat-option>
          <ngx-mat-select-search
            [placeholderLabel]="'LABEL.COUNTRY' | translate"
            [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
            [formControl]="countryFilterControl"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option
          *ngFor="let country of filteredCountries$ | async"
          [value]="country.country_code"
        >
          {{ country.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        <app-form-errors
          [control]="addressForm.get('countryCode')"
        ></app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-50">
      <mat-label> {{ "LABEL.STATE" | translate }} </mat-label>
      <input matInput type="state" formControlName="state" />
      <mat-error>
        <app-form-errors [control]="addressForm.get('state')"></app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-50">
      <mat-label> {{ "LABEL.CITY" | translate }} </mat-label>
      <input matInput type="city" formControlName="city" />
      <mat-error>
        <app-form-errors [control]="addressForm.get('city')"></app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-50">
      <mat-label> {{ "LABEL.ADDRESS" | translate }} </mat-label>
      <input matInput type="address" formControlName="address" />
      <mat-error>
        <app-form-errors
          [control]="addressForm.get('address')"
        ></app-form-errors>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-50">
      <mat-label> {{ "LABEL.ZIP_CODE" | translate }} </mat-label>
      <input matInput type="postCode" formControlName="postCode" />
      <mat-error>
        <app-form-errors
          [control]="addressForm.get('postCode')"
        ></app-form-errors>
      </mat-error>
    </mat-form-field>
  </div>
</form>
