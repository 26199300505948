<div
  class="tree-row-wrapper"
  *ngFor="let category of categories; let i = index"
>
  <div
    toggle-row
    [ngClass]="[
      category.level === 0 ? 'service-portfolio-row-action-' + i : ''
    ]"
    class="toggle-row-action fl-l"
    *ngIf="category.children_count"
    (click)="isFirstLvlClicked($event, category.level)"
  >
    <i class="icon-plus-1"></i>
  </div>

  <div class="tree-row" [ngClass]="{ 'treeRow-lvl1': category.level === 0 }">
    <div
      class="tree-check fl-l"
      *ngIf="!readonlySelection.isSelected(category)"
    >
      <mat-checkbox
        [ngClass]="[
          category.level === 0 ? 'service-portfolio-checkbox-' + i : ''
        ]"
        (click)="$event.stopPropagation()"
        (change)="$event ? changeSelection(category) : null"
        [checked]="selection.isSelected(category)"
        [disableRipple]="true"
      >
      </mat-checkbox>
    </div>
    <div
      class="tree-check dark-green fl-l"
      *ngIf="readonlySelection.isSelected(category)"
    >
      <i class="icon-ok"></i>
    </div>
    <div class="tree-label fl-l">
      <span
        [ngClass]="{
          'fw-700': selection.isSelected(category),
          underline: selection.isSelected(category)
        }"
        >{{ category.label }}
      </span>
      <!-- <span class="fs-12">
        <span
          class="orange m-0"
          *ngIf="category.commission_type_id.toString() !== '22'"
          >{{ category.commission | currency: 'EUR' }}</span
        >
        <span
          class="orange m-0"
          *ngIf="category.commission_type_id.toString() === '22'"
          >{{ category.commission }} %</span
        >
        {{ 'GENERALS.COMMISSION' | translate }}</span
      > -->
      <!-- <span *ngIf="category.children_count"
        >({{ category.children_count }})</span
      > -->
    </div>
  </div>
  <!-- [parentIsSelected]="selection.isSelected(category)" -->

  <div class="toggle-tree" *ngIf="category.children_count">
    <app-add-categories
      [level]="category.level + 1"
      [selectedServiceCategories]="parseSelectedCategory(category.path)"
      [serviceCategories]="category.children"
      [parentIsSelected]="isParentSelected(category)"
      (onselect)="onselect.emit($event)"
      (intermediary)="intermediaryChanges($event, category)"
      [providerId]="providerId"
    >
    </app-add-categories>
  </div>

  <div class="clear"></div>
</div>
