<ng-container *ngIf="isLoading">
  <div class="white-box-wrapper">
    <app-loader-spinner-general
      [loadingType]="LoadingTypeEnum.TABLE"
    ></app-loader-spinner-general>
  </div>
</ng-container>

<ng-container *ngIf="list$ | async as list"
  ><div class="white-box-wrapper" *ngIf="!isLoading">
    <div class="white-box-row">
      <div
        class="white-box-header d-flex align-items-center justify-content-between"
      >
        <div class="medium-title fl-l">
          {{ "DOCUMENTS.SHARED-FILES" | translate }}
        </div>
        <app-loader-spinner-general
          *ngIf="isLoadingAddButton"
          [loadingType]="LoadingTypeEnum.BUTTONS"
        ></app-loader-spinner-general>
        <div
          class="white-box-header-action edit-info fl-r add d-flex align-items-end"
          (click)="add()"
          *ngIf="document.can_update && !isLoadingAddButton"
        >
          <mat-icon svgIcon="plus-circle" class="plus-circle"></mat-icon>
          {{ "GENERALS.ADD" | translate }}
        </div>
      </div>
      <div class="clear"></div>
      <div class="white-box-body p-0">
        <div class="table-wrapper">
          <div class="table-responsive">
            <table>
              <tbody>
                <tr *ngFor="let item of list">
                  <td class="no-right-border">
                    {{ item.company_type }}
                    ({{ item.entity_type }})
                  </td>

                  <td class="ta-r">
                    <!-- table action with overlay -->
                    <div toggle-action-table class="table-action-wrapper">
                      <i class="icon-dot-3"></i>

                      <div
                        class="table-action"
                        [ngClass]="{ 'd-none': isLoading }"
                        *ngIf="document.can_delete"
                      >
                        <ul *ngIf="document.can_delete">
                          <li (click)="stopSharing(item)">
                            {{ "DOCUMENTS.STOP-SHARING" | translate }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="!list.length">
                  <td class="p-0 no-border">
                    {{ "GENERALS.NO-ITEMS-DISPLAY" | translate }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
